import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tabs } from "antd";
import BreadCrumb from "../../../atoms/BreadCrumb";
import DashboardLayout from "../../../molecules/DashboardLayout";
import Overview from "./components/Overview";
import Dependants from "./components/Dependants";
import Documents from "./components/Documents";
import Lease from "./components/Lease";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLease, getTenantDetails, emptyTenantDetail } from "./ducks/actions";

const { TabPane } = Tabs;

const tabs = [
  {
    name: "Overview",
    component: Overview,
  },
  {
    name: "Lease",
    component: Lease,
  },
  {
    name: "Documents",
    component: Documents,
  },
  {
    name: "Dependants",
    component: Dependants,
  },
];

export default (props) => {
  const [tabKey, setTabkey] = useState("0");
  const { id } = useParams();
  const [disableLease, setDisableLease] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const tenantData = useSelector(
    (state: any) => state.tenantsSummary.tenantData,
  );

  //@ts-ignore
  useEffect(() => {
    dispatch(getTenantDetails(id));
    console.log("check", location);
    if (location.pathname.includes("prospect-detail")) {
      setDisableLease(true);
    } else {
      dispatch(getLease(id));
    }
    return () => dispatch(emptyTenantDetail());
  }, []);

  const breadcrumbList: any = [
    {
      label: "Tenants",
      route: "/tenants",
    },
    {
      label:
        !tenantData?.first_name && !tenantData?.last_name
          ? ""
          : `${tenantData?.first_name} ${tenantData?.last_name}`,
      route: "",
    },
  ];

  return (
    <DashboardLayout>
      <Row gutter={[24, 24]} className="mb-2">
        <Col span={24}>
          <BreadCrumb list={breadcrumbList} />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <Card
            bordered={false}
            className="ag-nospace-head ag-nospace-body h-auto"
          >
            <Tabs
              defaultActiveKey={tabKey}
              onChange={setTabkey}
              tabBarGutter={16}
            >
              {tabs.map((item, index) => {
                let TabName = item.component;
                if (disableLease == true) {
                  if (item.name != "Lease" && item.name != "Dependants") {
                    return (
                      <TabPane tab={item.name} key={index}>
                        <TabName />
                      </TabPane>
                    );
                  }
                } else {
                  return (
                    <TabPane tab={item.name} key={index}>
                      <TabName />
                    </TabPane>
                  );
                }
              })}
            </Tabs>
          </Card>
        </Col>
      </Row>
    </DashboardLayout>
  );
};
