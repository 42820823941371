import { Card, Col, Row, Typography } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Grid from "../../../../../atoms/Grid";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getDependants } from "../../ducks/actions";

const { Text } = Typography;

const columns = [
  {
    headerName: "Property Name",
    field: "property_name",
  },
  {
    headerName: "Dependant Name",
    field: "first_name",
  },
  {
    headerName: "Phone Number",
    field: "contact_info",
    cellRendererFramework: viewPhoneNumber,
  },
  {
    headerName: "Email",
    field: "email",
    cellRendererFramework: viewEmail,
  },
  {
    headerName: "Account Status",
    field: "account_status",
    maxWidth: 250,
    cellRendererFramework: viewRender,
  },
];

function viewPhoneNumber(param) {
  return param?.data?.contact_info?.telecommunication_number?.contact_number;
}

function viewEmail(param) {
  return param?.data?.contact_info?.electronic_address?.email;
}

function viewRender() {
  return <Text className="ag-success f-16">Updated</Text>;
}
export default (props) => {
  const [leaseIds, setLeaseIds] = useState([]);
  const [dependantsData, setDependantsData] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();

  const lease = useSelector((state: any) => state.tenantsSummary.leaseData);
  const dependants = useSelector(
    (state: any) => state.tenantsSummary.dependants,
  );
  let dependantsDataTemp = [];

  useEffect(() => {
    console.log("lease", lease);
    setLeaseIds(
      lease.map((e) => {
        dispatch(getDependants(id, e.property_id));
      }),
    );
  }, [lease]);

  useEffect(() => {
    if (dependants?.length > 0) {
      dependantsDataTemp = [...dependantsData];
      dependants?.map((item, index) => {
        dependantsDataTemp?.push(item);
      });
    }
    setDependantsData(dependantsDataTemp);
  }, [dependants]);

  return (
    <Card bordered={false} bodyStyle={{ padding: 0 }}>
      <Row gutter={24}>
        <Col span={24}>
          <Grid
            data={dependantsData}
            columns={columns}
            pagination={false}
            noRowlabel="Dependants"
          />
        </Col>
      </Row>
    </Card>
  );
};
