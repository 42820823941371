/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Form,
  Button,
  Popover,
  message,
  Tabs,
  Select,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { setCookie } from "../../../utils/helper";
import LoginTemplate from "./LoginTemplate";
import axios from "axios";
import { authService } from "../../../configs/constants";
// import thumbIcon from "../../../assets/img/thumb.svg";
// import facebookIcon from "../../../assets/img/facebook.svg";
// import googleIcon from "../../../assets/img/google.svg";
import { useForm } from "react-hook-form";
import {
  InputField,
  InputPassword,
  SelectField,
} from "../../atoms/FormElement";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import { SITEKEY } from "../../../configs/constants";

const { TabPane } = Tabs;
const { Text, Paragraph } = Typography;

export default () => {
  const [loading, setLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [loginStatus1, setLoginStatus1] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [tabkey, setTabkey] = React.useState("1");
  const history = useHistory();
  const [tokenCapcha, setReCapcha] = useState();
  const refReCaptcha = useRef(null);

  const { control, handleSubmit, getValues, errors } = useForm();

  useEffect(() => {
    loadReCaptcha(SITEKEY);
  }, []);

  const verifyCallback = (token) => {
    console.log("recaptch", token);
    setReCapcha(token);
  };

  const prefixSelector = (
    <SelectField
      control={control}
      fieldname="prefix"
      initValue="971"
      noStyle
      selectOption={
        <>
          <Select.Option value="971">+971</Select.Option>
          <Select.Option value="92">+92</Select.Option>
        </>
      }
    />
  );

  const getUserNameFromEmail = (email: string) => {
    let userNameAndDomain = email.split("@");
    let username = "";
    if (userNameAndDomain.length == 2) {
      username = userNameAndDomain[0] + userNameAndDomain[1].replace(".", "");
    }
    return username;
  };
  const onFinish = async (values) => {
    setLoginStatus(false);
    setLoading(true);

    let user = values.username;
    if (values.username) {
      user = user;
      // if (values.username.includes("@")) {
      //   user = getUserNameFromEmail(user).toLocaleLowerCase();
      // }
    } else {
      user = values.phone;
    }

    const url = `${authService}/user/auth`;
    const postJson = {
      username: user,
      password: values.password,
      recapcha: tokenCapcha,
    };
    try {
      const {
        data: { result },
      } = await axios.post(url, postJson, { headers: { application: 1 } });

      await window.localStorage.setItem(
        "login-response",
        JSON.stringify(result),
      );
      setCookie("landlordUser", true, new Date(result?.exp).getDate());
      setTimeout(() => {
        history.push("/dashboard");
      }, 2000);
    } catch (error) {
      setLoading(false);
      const { response } = error;
      refReCaptcha.current.execute();

      if (response?.data?.message) {
        setErrorMessage(response?.data?.message);
        if (response?.data?.message.search("password") > 0) {
          setLoginStatus(true);
        } else {
          setLoginStatus1(true);
        }
      } else {
        setLoginStatus(false);
        setLoginStatus1(false);
        message.error("Invalid Attempt");
      }
    }
  };

  return (
    <LoginTemplate load={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Link to="/login" className="btn-tab-style active">
            Sign in
          </Link>
          <Link to="/signup" className="btn-tab-style">
            Sign up
          </Link>
        </Col>
        <Col span={24}>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={handleSubmit(onFinish)}
          >
            <Tabs activeKey={tabkey} onChange={(e) => setTabkey(e)}>
              <TabPane tab="Email Address" className="mt-2" key="1">
                {tabkey === "1" && (
                  <>
                    <Popover
                      placement="right"
                      content={
                        <Text type="danger">
                          <InfoCircleOutlined /> {errorMessage}
                        </Text>
                      }
                      onVisibleChange={() => setLoginStatus1(false)}
                      trigger="click"
                      visible={loginStatus1}
                    >
                      <InputField
                        fieldname="username"
                        control={control}
                        initValue=""
                        iProps={{
                          placeholder: "Username / Email",
                          size: "large",
                          prefix: (
                            <UserOutlined className="site-form-item-icon" />
                          ),
                        }}
                        rules={{
                          required: "Please input username",
                          pattern: {
                            value: /^\S*$/,
                            message: "Input username without space!",
                          },
                        }}
                        validate={errors.username && "error"}
                        validMessage={
                          errors.username && errors.username.message
                        }
                      />
                    </Popover>
                    <Popover
                      placement="right"
                      content={
                        <Text type="danger">
                          <InfoCircleOutlined /> {errorMessage}
                        </Text>
                      }
                      onVisibleChange={() => setLoginStatus(false)}
                      trigger="click"
                      visible={loginStatus}
                    >
                      <InputPassword
                        fieldname="password"
                        control={control}
                        initValue=""
                        iProps={{
                          placeholder: "Password",
                          size: "large",
                          prefix: (
                            <LockOutlined className="site-form-item-icon" />
                          ),
                        }}
                        rules={{
                          pattern: {
                            // value: /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^!&*+=]).*$/,
                            message: "Please enter correct password",
                          },
                          required: "Please enter password",
                        }}
                        validate={errors.password && "error"}
                        validMessage={
                          errors.password && errors.password.message
                        }
                      />
                    </Popover>
                  </>
                )}
              </TabPane>
              <TabPane className="mt-2" tab="Mobile Number" key="2">
                {tabkey === "2" && (
                  <>
                    <InputField
                      isRequired={true}
                      fieldname="phone"
                      control={control}
                      validate={errors.phone && "error"}
                      validMessage={errors.phone && errors.phone.message}
                      initValue=""
                      rules={{
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Value should be a Number",
                        },
                        minLength: { value: 9, message: "Minimum 9 digits" },
                        maxLength: { value: 9, message: "Maximum 9 digits" },
                        required: "Please enter valid Mobile No.",
                        setValueAs: (value) =>
                          `+${getValues("prefix")}${value}`,
                      }}
                      iProps={{
                        size: "large",
                        addonBefore: prefixSelector,
                        placeholder: "Mobile Number",
                      }}
                    />
                    <Popover
                      placement="right"
                      content={
                        <Text type="danger">
                          <InfoCircleOutlined /> {errorMessage}
                        </Text>
                      }
                      onVisibleChange={() => setLoginStatus(false)}
                      trigger="click"
                      visible={loginStatus}
                    >
                      <InputPassword
                        fieldname="password"
                        control={control}
                        initValue=""
                        iProps={{
                          placeholder: "Password",
                          size: "large",
                          prefix: (
                            <LockOutlined className="site-form-item-icon" />
                          ),
                        }}
                        rules={{
                          pattern: {
                            value: /(?=^.{1,50}$)^\S*$/,
                            message: "Please enter correct password",
                          },
                          required: "Please enter password",
                        }}
                        validate={errors.password && "error"}
                        validMessage={
                          errors.password && errors.password.message
                        }
                      />
                    </Popover>
                  </>
                )}
              </TabPane>
            </Tabs>

            <Form.Item>
              {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item> */}

              <Link to="/forgot-password" className="login-form-forgot">
                Forgot password
              </Link>
            </Form.Item>

            <Form.Item className="mb-0">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
              >
                Sign in
              </Button>
            </Form.Item>
            <ReCaptcha
              ref={refReCaptcha}
              action="main"
              sitekey={SITEKEY}
              verifyCallback={verifyCallback}
            />
          </Form>
        </Col>

        {/* <div className="social-login">
            <span>Or, login with:</span>
            <Button type="link">
              <img src={thumbIcon} alt="Thumb" />
            </Button>
            <Button type="link">
              <img src={facebookIcon} alt="Facebook" />
            </Button>
            <Button type="link">
              <img src={googleIcon} alt="Google" />
            </Button>
          </div> */}
        <Col span={24}>
          <Paragraph className="copyright">Copyright © 2021</Paragraph>
        </Col>
      </Row>
    </LoginTemplate>
  );
};
