import React, { useEffect, useState } from "react";
import { Row, Col, Card, Image, Button, Typography, Space } from "antd";
import { useHistory } from "react-router-dom";
import { RightOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { getPresignedImage } from "../../modules/Properties/AddProperty/ducks/actions/addInfoAction";
import Logo from "../../../assets/img/ag.png";

const { Text, Title } = Typography;

const ReviewCard = (props) => {
  const [propertyImage, setPropertyImage] = useState("");
  const history = useHistory();
  const { data, Action } = props;
  const { asset_id, name, property_info, visits, image } = data;

  // const menu = (
  //   <Menu>
  //     <Menu.Item key="1">
  //       <Button
  //         type="link"
  //         className="p-0 h-auto"
  //         onClick={() => history.push(`/property-detail/${asset_id}`)}
  //       >
  //         View Property
  //       </Button>
  //     </Menu.Item>
  //     <Menu.Item key="2">
  //       <Button type="link" className="p-0 h-auto" danger>
  //         Unpublish Property
  //       </Button>
  //     </Menu.Item>
  //   </Menu>
  // );

  useEffect(() => {
    caller();
  }, [image]);

  const presinedImageUrl = async (media) => {
    if (media) {
      let url = await getPresignedImage(media);
      return url;
    }
  };

  const caller = async () => {
    let img = "";
    if (image.length) {
      img = await presinedImageUrl(image[0]);
    }
    setPropertyImage(img);
  };

  return (
    <Card bordered={false}>
      <Row gutter={24}>
        <Col span={8} className="imageCard">
          <Image
            width={235}
            height={165}
            placeholder={true}
            preview={false}
            alt={name}
            fallback={Logo}
            src={propertyImage ? propertyImage : Logo}
          />
        </Col>
        <Col span={16}>
          <Card
            bordered={false}
            title={name}
            className="ag-nospace-head ag-nospace-body"
            // extra={
            //   <Dropdown.Button overlay={menu} onClick={Action} className="noborder-btn">
            //     <img src={agEdit} alt="" />
            //   </Dropdown.Button>
            // }
          >
            <Space size={40} direction="vertical" className="w-100">
              <Space size={8} direction="vertical">
                {property_info && property_info.length > 0 ? (
                  <Text
                    className="lineHeight28"
                    style={{
                      color: "#000",
                      marginTop: "5px",
                      display: "block",
                    }}
                  >
                    <EnvironmentOutlined style={{ fontSize: "16px" }} />{" "}
                    {property_info[0]?.street_address},{" "}
                    {property_info[0]?.city?.name},{" "}
                    {property_info[0]?.country?.name}
                  </Text>
                ) : (
                  ""
                )}
                <Title level={5} className="lineHeight28 mb-0">
                  {visits} Visit(s)
                </Title>
              </Space>
              <Space className="w-100" style={{ justifyContent: "flex-end" }}>
                <Button
                  type="link"
                  className="p-0"
                  onClick={() =>
                    history.push({
                      pathname: "/property-review",
                      state: { id: asset_id, propertyName: name },
                    })
                  }
                >
                  View Reviews <RightOutlined />
                </Button>
              </Space>
            </Space>
          </Card>
        </Col>
      </Row>
      {/* <Row gutter={24}>
        <Col span="8">
          <Button
            className="w-100"
            type="primary"
            icon={<TeamOutlined />}
            onClick={() => tenantAction()}
          >
            Tenants
          </Button>
        </Col>
        <Col span="8">
          <Button
            className="w-100"
            type="primary"
            icon={<UserOutlined />}
            onClick={maintenanceAction}
          >
            Maintenance
          </Button>
        </Col>
        <Col span="8">
          <Button
            className="w-100"
            type="primary"
            icon={<ExportOutlined />}
            onClick={exportAction}
          >
            Export
          </Button>
        </Col>
      </Row> */}
    </Card>
  );
};

export default ReviewCard;
