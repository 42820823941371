import React from "react";
import { Row, Col, Pagination } from "antd";
import PropertyCard from "../../atoms/PropertyCard";
import ReviewCard from "../../atoms/ReviewCard";

const PropertyList = (props) => {
  const { onExport, propertyData } = props;

  // const onExport = async (param) => {
  //   let url = `${propertyService}/property-landlord/export/tickets?asset_id=${param}`;
  //   try {
  //     let res = await axios.get(url);
  //   } catch (e) {
  //     const { response } = e;
  //     console.log("error", response.data.message);
  //   }
  // };
  const onProperty = (param) => {
    console.log("Property", param);
  };

  return (
    <Row gutter={[24, 24]}>
      {props.pagination && (
        <Col span={24}>
          <Pagination
            className="ag-property-text-pagination"
            total={props.count}
            showTotal={(total, range) =>
              `Showing ${range[0]}-${range[1]} Properties`
            }
            defaultPageSize={6}
            showSizeChanger={false}
            current={props.currentPage}
            onChange={(e) => props.onpageChange(e)}
          />
        </Col>
      )}

      {props.type === "p" &&
        props.data &&
        props.data.map((item, index) => (
          <Col span={12} key={index}>
            <PropertyCard
              property={item}
              exportAction={onExport}
              propertyData={propertyData}
              propertyAction={onProperty}
              onStatusChanged={props.onStatusChanged}
            />
          </Col>
        ))}

      {props.type === "r" &&
        props.data &&
        props.data.map((item, index) => (
          <Col span={12} key={index}>
            <ReviewCard data={item} />
          </Col>
        ))}
    </Row>
  );
};

export default PropertyList;
