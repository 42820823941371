import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Typography,
  Select,
  message,
} from "antd";
import axios from "../../../../../utils/axiosInceptor";
import { useDispatch } from "react-redux";
import { propertyService } from "../../../../../configs/constants";
import { activateSpinner } from "../ducks/actions/spinAction";
import { installmentIndex } from "../../ducks/constants";

const { Title } = Typography;
const { Option } = Select;

export default ({ paymentConfig, setPlanCreated, onAdd, uid }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const address = JSON.parse(window.localStorage.getItem("createdProperty"));
  const multiunit = JSON.parse(window.localStorage.getItem("createdProperty"))
    ?.units_added;
  const assetId = JSON.parse(window.localStorage.getItem("createdProperty"))
    ?.asset?.id;
  const initalPaymentState = {
    propertyAddress:
      address?.physicalAddress?.address_line_1 +
        address?.physicalAddress?.address_line_2 || "",
    properyType: "Building",
    leaseDuration: 12,
    marketRent: null,
    securityDeposit: 0,
    installmentPlan: 0,
    marketRentPerMonth: 0,
    installments: [],
  };
  const [paymentState, setPaymentState] = useState(initalPaymentState);
  const [selectUnit, setSelectUnit] = useState(null);

  const calculateInstallments = (tenure) => {
    const instalmmentArray = [];
    for (let i = 1; i <= tenure; i++) {
      const installmentObj = {
        id: i,
        installmentLable: `${installmentIndex[i - 1]} Installment `,
        installmentValue: parseFloat(
          (paymentState.marketRent / tenure).toFixed(2),
        ),
        vatValue:
          uid != 2
            ? parseFloat(
                (
                  paymentConfig.vat_percentage *
                  (paymentState.marketRent / tenure)
                ).toFixed(2),
              )
            : 0,
      };
      instalmmentArray.push(installmentObj);
    }
    setPaymentState({
      ...paymentState,
      marketRentPerMonth: parseFloat((paymentState.marketRent / 12).toFixed(2)),
      installmentPlan: tenure,
      installments: instalmmentArray,
    });
  };

  const updateInstallment = (value, id) => {
    const installmentArray = [...paymentState.installments];
    const selectedInstallment = installmentArray.filter((e) => e.id === id)[0];
    const filteredValues = installmentArray.filter((e) => e.id !== id);
    selectedInstallment.installmentValue = value;
    selectedInstallment.vatValue = parseFloat(
      (paymentConfig.vat_percentage * value).toFixed(2),
    );
    filteredValues.push(selectedInstallment);
    filteredValues.sort((a, b) => a.id - b.id);
    setPaymentState({ ...paymentState, installments: filteredValues });
  };

  const calculateSecurityDeposit = async () => {
    const securityDespositUrl = `${propertyService}/property-landlord/payment/security-deposit/${paymentState.marketRent}`;
    const secDeposit = await axios.get(securityDespositUrl);
    setPaymentState({
      ...paymentState,
      marketRentPerMonth: parseFloat((paymentState.marketRent / 12).toFixed(2)),
      securityDeposit: parseFloat(
        (secDeposit?.data?.result?.security_deposit).toFixed(2),
      ),
    });
    if (paymentState.installmentPlan) {
      calculateInstallments(paymentState.installmentPlan);
    }
  };

  console.log("Payment state", paymentState);

  const onSave = async () => {
    let asset_ID = 0;
    if (selectUnit) {
      asset_ID = selectUnit;
    } else {
      asset_ID = assetId;
    }
    dispatch(activateSpinner(true));
    const {
      propertyAddress,
      properyType,
      leaseDuration,
      marketRent,
      securityDeposit,
      installmentPlan,
      marketRentPerMonth,
      installments,
    } = paymentState;

    const finalInstallment = installments.map((e) => {
      return {
        type: "installment",
        price: e.installmentValue,
        percentage: 1,
        vat: e.vatValue,
      };
    });

    const postJson = {
      asset_id: asset_ID,
      abbreviation: "AED",
      lease_duration: Number(leaseDuration),
      price: marketRent,
      percentage: 3,
      installment_plan: installmentPlan,
      installments: finalInstallment,
      other_charges: [
        {
          type: "ejari_fee",
          price: paymentConfig.ejari_fee,
          percentage: 0,
          vat: parseFloat(
            (paymentConfig.vat_percentage * paymentConfig.ejari_fee).toFixed(2),
          ),
        },
        {
          type: "security_deposit",
          price: securityDeposit,
          percentage: 0,
          vat:
            uid != 2
              ? parseFloat(
                  (paymentConfig.vat_percentage * securityDeposit).toFixed(2),
                )
              : 0,
        },
        {
          type: "processing_fee",
          price: paymentConfig.processing_fee,
          percentage: 0,
          vat: paymentConfig.vat_percentage * paymentConfig.processing_fee,
        },
      ],
    };

    let inst = [];
    installments.forEach((item) => {
      inst.push({
        price: item.installmentValue,
      });
    });

    const url = `${propertyService}/property-landlord/payment`;

    try {
      const res = await axios.post(url, postJson);
      setPlanCreated && setPlanCreated(false);
      form.resetFields();
      setPaymentState(initalPaymentState);
      dispatch(activateSpinner(false));
      onAdd({
        installment: inst,
        installmentCount: installmentPlan,
        lease_duration: Number(leaseDuration),
        price: marketRent,
        security_deposit: { price: securityDeposit },
      });
      message.success(res?.data?.message);
    } catch (e) {
      dispatch(activateSpinner(false));
      const { response } = e;
      message.error(response.statusText);
    }
    dispatch(activateSpinner(false));
  };

  return (
    <Form layout="vertical" name="userForm" onFinish={onSave} form={form}>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item
            name="propertyAddress"
            label="Property Address"
            initialValue={paymentState.propertyAddress}
          >
            <Input placeholder="Al-Ghurair Residence" type="text" readOnly />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="properyType"
            label="Property Type"
            initialValue={paymentState.properyType}
          >
            <Input placeholder="Apartments" type="text" readOnly />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="leaseDuration"
            label="Lease Duration in month(s)"
            rules={[{ required: true, message: "Lease duration required" }]}
            initialValue={12}
          >
            <Input type="number" readOnly={true} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="marketRent"
            label="Rent"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="120,000"
              type="number"
              prefix="AED"
              value={paymentState.marketRent}
              onChange={(e) =>
                setPaymentState({
                  ...paymentState,
                  marketRent: e.target.value,
                })
              }
              onBlur={(e) => calculateSecurityDeposit()}
            />
          </Form.Item>
        </Col>

        {multiunit.length > 0 && (
          <Col span={6}>
            <Form.Item
              required={true}
              name="propertyUnit"
              label="Property Unit"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select Unit"
                onChange={(e) => setSelectUnit(e)}
              >
                {multiunit.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        <Col span={24}>
          <Title level={5} className="mb-1">
            Rent Details:
          </Title>
        </Col>
        <Col span={6}>
          <Form.Item
            name="installmentPlan"
            label="Installment Plan"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="3 Months"
              disabled={!paymentState.marketRent}
              onSelect={(e) => {
                calculateInstallments(e);
              }}
            >
              <Option value={1}>1 Month</Option>
              <Option value={2}>2 Months</Option>
              <Option value={3}>3 Months</Option>
              <Option value={4}>4 Months</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Rent/Month">
            <Input
              placeholder="20,000"
              readOnly
              prefix="AED"
              value={paymentState.marketRentPerMonth}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Title level={5} className="mb-1">
            Processing Fees:
          </Title>
        </Col>

        <fieldset>
          <Col span={6}>
            <Form.Item label="Ejari Fee" rules={[{ required: true }]}>
              <Input
                placeholder=""
                prefix="AED"
                value={paymentConfig ? paymentConfig.ejari_fee : 0}
                readOnly
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="VAT" rules={[{ required: true }]}>
              <Input
                placeholder=""
                prefix="AED"
                value={
                  paymentConfig.vat_percentage
                    ? parseFloat(
                        (
                          paymentConfig.vat_percentage * paymentConfig.ejari_fee
                        ).toFixed(2),
                      )
                    : 0
                }
                readOnly
              />
            </Form.Item>
          </Col>
        </fieldset>
        <fieldset>
          <Col span={6}>
            <Form.Item
              // name="securityDeposit"
              label="Security Deposit"
              rules={[{ required: true }]}
            >
              <Input
                placeholder=""
                prefix="AED"
                type="number"
                value={paymentState.securityDeposit}
                onChange={(e) =>
                  setPaymentState({
                    ...paymentState,
                    securityDeposit: parseFloat(e.target.value),
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="VAT" rules={[{ required: true }]}>
              <Input
                placeholder=""
                prefix="AED"
                value={
                  uid != 2 && paymentConfig.vat_percentage
                    ? parseFloat(
                        (
                          paymentConfig.vat_percentage *
                          paymentState.securityDeposit
                        ).toFixed(2),
                      )
                    : 0
                }
                readOnly
              />
            </Form.Item>
          </Col>
        </fieldset>
        <fieldset>
          <Col span={6}>
            <Form.Item label="Processing Fee" rules={[{ required: true }]}>
              <Input
                placeholder=""
                prefix="AED"
                value={
                  paymentConfig.processing_fee
                    ? paymentConfig.processing_fee
                    : 0
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="VAT" rules={[{ required: true }]}>
              <Input
                placeholder=""
                prefix="AED"
                value={
                  paymentConfig.vat_percentage
                    ? parseFloat(
                        (
                          paymentConfig.vat_percentage *
                          paymentConfig.processing_fee
                        ).toFixed(2),
                      )
                    : 0
                }
              />
            </Form.Item>
          </Col>
        </fieldset>
        {paymentState.installments.length > 0 && (
          <Col span={24}>
            <Title level={5} className="mb-1">
              Installment Plan:
            </Title>
          </Col>
        )}
        {paymentState.installments.map((e, i) => {
          return (
            <fieldset key={i}>
              <Col span={6}>
                <Form.Item
                  label={e.installmentLable}
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder=""
                    prefix="AED"
                    value={e.installmentValue}
                    onChange={(event) =>
                      updateInstallment(event.target.value, e.id)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="VAT" rules={[{ required: true }]}>
                  <Input placeholder="" prefix="AED" value={e.vatValue} />
                </Form.Item>
              </Col>
            </fieldset>
          );
        })}
      </Row>
      <div className="steps-action py-3">
        {/* <Button type="primary" onClick={() => {}}>
          Reset
        </Button> */}
        <Button type="primary" htmlType="submit">
          Add
        </Button>
      </div>
    </Form>
  );
};
