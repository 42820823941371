import React from "react";
import { Route } from "react-router-dom";

function interpolateParams(string, params = {}) {
  return string.replace(
    /:([a-zA-Z]+)/g,
    (match, token1) => `${params[token1]}`,
  );
}

const Error404 = () => {
  return <h1>Page not found</h1>;
};

const RouteWithSubRoutes = (initialProps) => (
  <Route
    path={initialProps.path}
    render={(props) => {
      const validRoutes = [
        initialProps.path,
        ...initialProps.subRoutes,
      ].map((route) => interpolateParams(route, props.match.params));

      return validRoutes.includes(props.location.pathname) ? (
        <initialProps.baseComponent {...props} />
      ) : (
        <Error404 />
      );
    }}
  />
);

export default RouteWithSubRoutes;
