import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Button, message } from "antd";
import {
  InputField,
  ReactSelectField,
} from "../../../../../../atoms/FormElement";
import {
  EditOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { propertyService } from "../../../../../../../configs/constants";
import axios from "../../../../../../../utils/axiosInceptor";
import { useForm, useWatch, useFieldArray } from "react-hook-form";
import ButtonGroup from "antd/lib/button/button-group";
import {
  installmentIndex,
  installmentsList,
} from "../../../../ducks/constants";
import { PopupConfirm } from "../../../../../../atoms/Popup";

const { Title } = Typography;

export default (props) => {
  const { details, fees, addField, uid } = props;
  // const defaultValues = {
  //   installment: details.installment.map((item) => ({
  //     price: item.price,
  //     vat: 0,
  //   })),
  // };
  const { control, handleSubmit, errors, setValue, getValues } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "installment",
  });
  const [noEdit, setNoEdit] = useState(true);

  useEffect(() => {
    props.addField === true && setNoEdit(false);
  }, [props.addField]);

  useEffect(() => {
    if (fees) {
      setValue("ejari_fee", fees.ejari_fee);
      setValue("ejari_fee_vat", fees.ejari_fee * fees.vat_percentage);
      setValue("processing_fee", fees.processing_fee);
      setValue(
        "processing_fee_vat",
        (fees.processing_fee * fees.vat_percentage)?.toFixed(2),
      );
    }
  }, [fees]);

  useEffect(() => {
    if (details && !props.addField) {
      setValue("price", details.price);
      setValue("lease_duration", details.lease_duration);
      setValue(
        "installment_plan",
        installmentsList.find((item) => item.value == details.installmentCount),
      );
      if (details.security_deposit?.price) {
        setValue(
          "security_deposit",
          parseFloat(details.security_deposit?.price)?.toFixed(2),
        );
      } else {
        calculateSecurity();
      }
      setValue(
        "installment",
        details.installment?.map((item) => ({
          price: item.price,
          vat: uid != 2 ? (item.price * fees.vat_percentage).toFixed(2) : 0,
          total:
            uid != 2
              ? item.price + item.price * fees.vat_percentage
              : item.price,
        })),
      );
    }
  }, [details]);

  let checkTenure = useWatch({
    control,
    name: "installment_plan",
    defaultValue: "",
  });

  const generateInstallment = (tenure, rent) => {
    let inst = [];
    Array.from(Array(tenure).keys()).map((i) => {
      inst.push({
        price: rent,
        vat: uid != 2 ? (rent * fees.vat_percentage).toFixed(2) : 0,
        total: uid != 2 ? rent + rent * fees.vat_percentage : rent,
      });
    });
    setValue("installment", inst);
  };

  useEffect(() => {
    if (checkTenure) {
      let mrate: any = getValues("price");
      let instplan: any = getValues("installment_plan");
      let rentAmount = Math.floor(mrate / instplan.value);
      console.log("chekkkk", instplan, mrate);
      generateInstallment(instplan.value, rentAmount);
      setValue("rent", (mrate / 12).toFixed(2));
    }
  }, [checkTenure]);

  const calculateSecurity = async () => {
    let mrate: any = getValues("price");
    let instplan: any = getValues("installment_plan");
    let rentAmount = Math.floor(mrate / instplan.value);
    if (mrate) {
      const securityDespositUrl = `${propertyService}/property-landlord/payment/security-deposit/${mrate}`;
      const secDeposit = await axios.get(securityDespositUrl);
      setValue(
        "security_deposit",
        secDeposit?.data?.result?.security_deposit?.toFixed(2),
      );
      generateInstallment(instplan.value, rentAmount);
      setValue("rent", (mrate / 12).toFixed(2));
    }
  };

  const onDelete = async () => {
    let url = `${propertyService}/property-landlord/${props.asset_id}/payment/${details.id}`;
    try {
      await axios.delete(url);
      message.success("Payment Plan Successfully Deleted");
      setNoEdit(true);
      props.update();
    } catch (e) {
      console.log("error", e);
      message.error("Something went wrong");
    }
  };

  const onConfirmDelete = () => {
    PopupConfirm({
      title: `Are you sure you want to delete the payment plan`,
      onOk: onDelete,
      okText: "Yes",
    });
  };

  const onSubmit = async (val) => {
    let installments = [];
    let previousInstallment = [];
    val.installment.forEach((item) => {
      installments.push({
        type: "installment",
        price: item.price,
        vat: Number(item.vat).toFixed(2),
        percentage: 1,
      });
    });

    if (!props.addField) {
      details.installment?.forEach((item) => {
        previousInstallment.push(item.price_component_id);
      });
      const postJson = {
        asset_id: props.asset_id,
        abbreviation: "AED",
        lease_duration: Number(val.lease_duration),
        installment_plan: val.installment_plan.value,
        price: Number(val.price),
        other_charges: [
          {
            type: "security_deposit",
            price: val.security_deposit,
            vat: val.security_vat,
            fee_id: props.details.security_deposit.price_component_id,
            vat_id: props.details.security_deposit.vat.price_component_id,
          },
        ],
        installments: installments,
        previous_installments_ids: previousInstallment,
      };
      let url = `${propertyService}/property-landlord/payment/${details.id}`;
      try {
        await axios.put(url, postJson);
        message.success("Payment Details Updated");
        setNoEdit(true);
        props.update();
      } catch (e) {
        console.log("error", e);
        message.error("Something went wrong");
        setNoEdit(true);
      }
    } else {
      const postJson = {
        asset_id: props.asset_id,
        abbreviation: "AED",
        lease_duration: Number(val.lease_duration),
        installment_plan: val.installment_plan.value,
        price: Number(val.price),
        percentage: 3,
        installments: installments,
        other_charges: [
          {
            type: "ejari_fee",
            price: val.ejari_fee,
            percentage: 0,
            vat: val.ejari_fee_vat,
          },
          {
            type: "security_deposit",
            percentage: 0,
            price: val.security_deposit,
            vat: val.security_vat,
          },
          {
            type: "processing_fee",
            price: val.processing_fee,
            percentage: 0,
            vat: val.processing_fee_vat,
          },
        ],
      };

      let url = `${propertyService}/property-landlord/payment`;
      try {
        const res = await axios.post(url, postJson);
        message.success("Payment Successfully Added");
        setNoEdit(true);
        props.update();
      } catch (e) {
        const { response } = e;
        message.error(response.statusText);
      }
    }
  };

  return (
    <Form
      layout="vertical"
      name="bookingform"
      onFinish={handleSubmit(onSubmit)}
    >
      {!props.addField && !props.staticData && (
        <ButtonGroup className="linkbtn">
          <Button
            type="link"
            className={`p-0 no-border no-shadow ${noEdit && "opacity0"}`}
            icon={<CheckCircleOutlined />}
            htmlType="submit"
          >
            Save
          </Button>

          <Button
            type="link"
            danger
            className={`p-0 no-border no-shadow ${!noEdit && "opacity0"}`}
            icon={<DeleteOutlined />}
            onClick={onConfirmDelete}
            htmlType="button"
          />
          <Button
            type="link"
            className={`p-0 no-border no-shadow ${!noEdit && "opacity0"}`}
            icon={<EditOutlined />}
            onClick={() => setNoEdit(false)}
            htmlType="button"
          />
        </ButtonGroup>
      )}
      <Row gutter={24}>
        <Col span={8}>
          <InputField
            isRequired={!noEdit}
            label="Lease Duration in month(s)"
            fieldname="lease_duration"
            control={control}
            initValue={12}
            classes={`${noEdit ? "noEditLabel" : ""}`}
            iProps={{
              type: "number",
              readOnly: true,
              // min: 1,
              // max: 12,
              className: `${noEdit ? "noEdit" : ""}`,
            }}
            rules={{ required: "Lease duration required" }}
            validate={errors.lease_duration && "error"}
            validMessage={
              errors.lease_duration && errors.lease_duration.message
            }
          />
        </Col>
        <Col span={8}>
          <InputField
            isRequired={!noEdit}
            label="Rent"
            fieldname="price"
            control={control}
            initValue={0}
            classes={`${noEdit ? "noEditLabel" : ""}`}
            iProps={{
              type: "number",
              prefix: "AED",
              readOnly: noEdit,
              className: `${noEdit ? "noEdit" : ""}`,
            }}
            onBlur={calculateSecurity}
            rules={{ required: "Lease duration required" }}
            validate={errors.price && "error"}
            validMessage={errors.price && errors.price.message}
          />
        </Col>
        <Col span={24}>
          <Title level={5} className="mb-1">
            Rent Details:
          </Title>
        </Col>
        <Col span={8}>
          <ReactSelectField
            isRequired={!noEdit}
            label="Installment Plan"
            fieldname="installment_plan"
            control={control}
            classes={`${noEdit ? "noEditLabel" : ""}`}
            initValue=""
            iProps={{
              placeholder: "Select months",
              isDisabled: noEdit,
              className: `${noEdit ? "disablerselect" : ""}`,
            }}
            selectOption={installmentsList}
            rules={{ required: "Please select" }}
            validate={errors.installment_plan && "error"}
            validMessage={
              errors.installment_plan && errors.installment_plan.message
            }
          />
        </Col>
        <Col span={8}>
          <InputField
            isRequired={!noEdit}
            label="Rent/Month"
            fieldname="rent"
            control={control}
            classes={`${noEdit ? "noEditLabel" : ""}`}
            initValue={0}
            iProps={{
              type: "number",
              prefix: "AED",
              readOnly: true,
              className: `${noEdit ? "noEdit" : ""}`,
            }}
            rules={{ required: "Lease duration required" }}
            validate={errors.rent && "error"}
            validMessage={errors.rent && errors.rent.message}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Title level={5} className="mb-1">
            Processing Fees:
          </Title>
        </Col>
        <Col span={8}>
          <InputField
            label="Ejari Fee"
            fieldname="ejari_fee"
            control={control}
            classes={`${noEdit ? "noEditLabel" : ""}`}
            initValue={0}
            iProps={{
              type: "number",
              prefix: "AED",
              readOnly: true,
              className: `${noEdit ? "noEdit" : ""}`,
            }}
          />
        </Col>
        <Col span={8}>
          <InputField
            label="VAT"
            fieldname="ejari_fee_vat"
            control={control}
            classes={`${noEdit ? "noEditLabel" : ""}`}
            initValue={0}
            iProps={{
              type: "number",
              prefix: "AED",
              readOnly: true,
              className: `${noEdit ? "noEdit" : ""}`,
            }}
          />
        </Col>
        <Col span={24}></Col>
        <Col span={8}>
          <InputField
            label="Security Deposit"
            fieldname="security_deposit"
            control={control}
            classes={`${noEdit ? "noEditLabel" : ""}`}
            initValue={0}
            iProps={{
              readOnly: noEdit,
              type: "number",
              prefix: "AED",
              className: `${noEdit ? "noEdit" : ""}`,
            }}
            rules={{
              required: "Security Deposit Required",
              min: { value: 1, message: "Minimum value should be 1" },
            }}
            validate={errors.security_deposit && "error"}
            validMessage={
              errors.security_deposit && errors.security_deposit.message
            }
          />
        </Col>
        <Col span={8}>
          <InputField
            label="VAT"
            fieldname="security_vat"
            control={control}
            classes={`${noEdit ? "noEditLabel" : ""}`}
            initValue={0}
            setValue={setValue}
            valueGot={
              uid != 2
                ? (
                    getValues("security_deposit") * fees.vat_percentage
                  )?.toFixed(2)
                : 0
            }
            iProps={{
              type: "number",
              prefix: "AED",
              readOnly: true,
              className: `${noEdit ? "noEdit" : ""}`,
            }}
          />
        </Col>
        <Col span={24}></Col>
        <Col span={8}>
          <InputField
            label="Processing Fee"
            fieldname="processing_fee"
            control={control}
            classes={`${noEdit ? "noEditLabel" : ""}`}
            initValue={0}
            iProps={{
              type: "number",
              prefix: "AED",
              readOnly: true,
              className: `${noEdit ? "noEdit" : ""}`,
            }}
          />
        </Col>
        <Col span={8}>
          <InputField
            label="VAT"
            fieldname="processing_fee_vat"
            control={control}
            classes={`${noEdit ? "noEditLabel" : ""}`}
            initValue={0}
            iProps={{
              type: "number",
              prefix: "AED",
              readOnly: true,
              className: `${noEdit ? "noEdit" : ""}`,
            }}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        {fields.length > 0 && (
          <Col span={24}>
            <Title level={5} className="mb-1">
              Installment Plan:
            </Title>
          </Col>
        )}
        {fields.map((item, index) => (
          <React.Fragment key={item.id}>
            <Col span={8}>
              <InputField
                label={`${installmentIndex[index]} installment`}
                fieldname={`installment[${index}].price`}
                control={control}
                classes={`${noEdit ? "noEditLabel" : ""}`}
                initValue={item.price}
                iProps={{
                  type: "number",
                  prefix: "AED",
                  readOnly: true,
                  className: `${noEdit ? "noEdit" : ""}`,
                }}
              />
            </Col>
            <Col span={8}>
              <InputField
                label="VAT"
                fieldname={`installment[${index}].vat`}
                control={control}
                classes={`${noEdit ? "noEditLabel" : ""}`}
                initValue={item.vat}
                iProps={{
                  type: "number",
                  prefix: "AED",
                  readOnly: true,
                  className: `${noEdit ? "noEdit" : ""}`,
                }}
                setValue={setValue}
                valueGot={
                  uid != 2
                    ? getValues(`installment[${index}].price`) *
                      fees.vat_percentage
                    : 0
                }
              />
            </Col>
            <Col span={8}>
              {!noEdit && (
                <InputField
                  label="Total"
                  fieldname={`installment[${index}].total`}
                  control={control}
                  initValue={item.total}
                  iProps={{
                    type: "number",
                    prefix: "AED",
                    readOnly: true,
                    className: `${noEdit ? "noEdit" : ""}`,
                  }}
                  setValue={setValue}
                  valueGot={
                    Number(getValues(`installment[${index}].price`)) +
                    Number(getValues(`installment[${index}].vat`))
                  }
                />
              )}
            </Col>
            {/* <Col span={noEdit ? 12 : 8}></Col> */}
          </React.Fragment>
        ))}
        {props.addField && (
          <Col span={24}>
            <Button type="primary" htmlType="submit">
              Add Plan
            </Button>
          </Col>
        )}
      </Row>
    </Form>
  );
};
