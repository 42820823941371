import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";

import { InputField, ReactSelectField } from "../../../atoms/FormElement";
import { useSelector, useDispatch } from "react-redux";
import {
  getMaritalStatus,
  getTenantNationalities,
} from "../../ContractTenantDetails/ducks/actions";

var _ = require("lodash");

export default (props) => {
  const { control, errors, setValue, detailsApi } = props;

  const dispatch = useDispatch();

  const maritalStatusApi = useSelector(
    (state: any) => state.tenantDetails.getMarital,
  );
  const nationalityApi = useSelector(
    (state: any) => state.tenantDetails.getNationalities,
  );

  useEffect(() => {
    dispatch(getTenantNationalities());
    dispatch(getMaritalStatus());
  }, []);

  useEffect(() => {
    if (detailsApi) {
      setValue("tenant_name", detailsApi.tenant_name);
      if (detailsApi.contact_info?.contact_number) {
        let str: any = detailsApi.contact_info?.contact_number;
        setValue("contact_number", str.substring(str.length - 9));
      }
      setValue("po_box", detailsApi?.po_box);
      setValue("number_of_occupants", detailsApi?.number_of_occupants);
      setValue("email", detailsApi?.contact_info?.email);
      setValue("marital_status", {
        label: detailsApi.marital_status?.name,
        value: detailsApi.marital_status?.id,
      });
      if (detailsApi.contact_info?.nationality) {
        setValue("nationality", {
          label: detailsApi.contact_info?.nationality?.name,
          value: detailsApi.contact_info?.nationality?.id,
        });
      }
      // if (detailsApi?.contacts?.length) {
      //   const selValue = detailsApi?.contacts?.find(
      //     (e) => e?.party_id === detailsApi?.selectedContact,
      //   );
      //   if (selValue) {
      //     setValue("contacts", {
      //       label: selValue.first_name,
      //       value: selValue.party_id,
      //     });
      //   }
      // }
    }
  }, [detailsApi]);

  return (
    <Row gutter={24}>
      <Col span={8}>
        <InputField
          label="Tenant Name"
          fieldname="tenant_name"
          control={control}
          initValue=""
          iProps={{ placeholder: "Tenant Name", disabled: true }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="Nationality"
          fieldname="nationality"
          control={control}
          initValue=""
          iProps={{ placeholder: "Select Nationality", isDisabled: true }}
          selectOption={_.map(nationalityApi, (item) => ({
            value: item.id,
            label: item.name,
          }))}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="Mobile number"
          fieldname="contact_number"
          control={control}
          initValue=""
          iProps={{ addonBefore: "+971", disabled: true }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="Marital Status"
          fieldname="marital_status"
          control={control}
          initValue=""
          iProps={{ placeholder: "Select Marital Status", isDisabled: true }}
          selectOption={_.map(maritalStatusApi, (item) => ({
            value: item.id,
            label: item.name,
          }))}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="Email Address"
          fieldname="email"
          control={control}
          iProps={{ placeholder: "Enter Email Address", disabled: true }}
          initValue=""
        />
      </Col>
      <Col span={8}>
        <InputField
          isRequired={true}
          label="No. of occupants"
          fieldname="number_of_occupants"
          control={control}
          validate={errors.number_of_occupants && "error"}
          validMessage={
            errors.number_of_occupants && errors.number_of_occupants.message
          }
          initValue={1}
          iProps={{ type: "number", min: 1, disabled: props.disableAll }}
          rules={{ required: "Please enter occupants" }}
        />
      </Col>

      {/* <Col span={8}>
        <ReactSelectField
          label="Contacts Name"
          fieldname="contacts"
          control={control}
          iProps={{
            placeholder: "Select contacts",
            isDisabled: props.disableAll,
          }}
          selectOption={_.map(detailsApi?.contacts, (e) => {
            return { label: e.first_name, value: e.party_id };
          })}
        />
      </Col> */}
      <Col span={8}>
        <InputField
          label="P.O Box"
          fieldname="po_box"
          control={control}
          initValue=""
          iProps={{
            placeholder: "Enter P.O. Box",
            type: "number",
            disabled: true,
          }}
        />
      </Col>
    </Row>
  );
};
