import React, { useState, useEffect } from "react";
import { Col } from "antd";
import { InputField, ReactSelectField } from "../../../../../atoms/FormElement";
import { useDispatch, useSelector } from "react-redux";
import { getUsageType } from "../../ducks/actions/addInfoAction";
import { bedroomCategory, bathList, tfValue } from "../../../ducks/constants";
var _ = require("lodash");

export default (props) => {
  const {
    control,
    errors,
    setValue,
    noEdit,
    hasUnit,
    getValues,
    hasComm,
  } = props;

  const dispatch = useDispatch();
  const apiUsageTypeData = useSelector(
    (state: any) => state.addInfoReducer.getUsageType,
  );
  const apiUnitTypeData = useSelector(
    (state: any) => state.addInfoReducer.getUnitType,
  );
  const apiUnitSubTypeData = useSelector(
    (state: any) => state.addInfoReducer.getUnitSubType,
  );

  useEffect(() => {
    dispatch(getUsageType());
  }, []);

  console.log("checking", noEdit, hasUnit);

  return (
    <>
      <Col span={6}>
        <ReactSelectField
          isRequired={!noEdit}
          label="Usage Type"
          fieldname="usage_type_id"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Select one",
            isDisabled: noEdit,
            className: `${noEdit ? "disablerselect" : ""}`,
          }}
          selectOption={_.map(apiUsageTypeData, (item) => ({
            value: item.id,
            label: item.name,
          }))}
          onChange={(e) => {
            setValue("unit_type_id", "");
            setValue("unit_subtype_id", "");
            setValue("number_of_beds", "");
          }}
          rules={{ required: "Please enter Usage Type" }}
          validate={errors.usage_type_id && "error"}
          validMessage={errors.usage_type_id && errors.usage_type_id.message}
        />
      </Col>

      <Col span={6}>
        <ReactSelectField
          isRequired={!noEdit}
          label="Unit Type"
          fieldname="unit_type_id"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Select one",
            isDisabled: noEdit,
            className: `${noEdit ? "disablerselect" : ""}`,
          }}
          selectOption={_.map(apiUnitTypeData, (item) => ({
            value: item.id,
            label: item.name,
          }))}
          onChange={(e) => {
            if (e.value === 3) {
              setValue("unit_subtype_id", "");
              setValue("number_of_beds", "");
            }
          }}
          rules={{ required: "Please enter Unit Type" }}
          validate={errors.unit_type_id && "error"}
          validMessage={errors.unit_type_id && errors.unit_type_id.message}
        />
      </Col>

      {hasUnit && (
        <>
          <Col span={6}>
            <ReactSelectField
              isRequired={!noEdit}
              label="Unit Sub Type"
              fieldname="unit_subtype_id"
              control={control}
              classes={`${noEdit ? "noEditLabel" : ""}`}
              initValue=""
              iProps={{
                placeholder: "Select one",
                isDisabled: noEdit,
                className: `${noEdit ? "disablerselect" : ""}`,
              }}
              selectOption={_.map(apiUnitSubTypeData, (item) => ({
                value: item.id,
                label: item.name,
              }))}
              rules={{ required: "Please enter Unit Sub Type" }}
              validate={errors.unit_subtype_id && "error"}
              validMessage={
                errors.unit_subtype_id && errors.unit_subtype_id.message
              }
              onChange={(e) => {
                if (getValues("usage_type_id").value != 1) {
                  setValue("number_of_beds", {
                    label: e.label,
                    value: e.label == "Studio" ? 0 : Number(e.label.charAt(0)),
                  });
                } else {
                  setValue("number_of_beds", "");
                }
              }}
            />
          </Col>
          {hasComm && (
            <>
              <Col span={6}>
                <ReactSelectField
                  isRequired={!noEdit}
                  label="Bed Rooms"
                  fieldname="number_of_beds"
                  control={control}
                  classes={`${noEdit ? "noEditLabel" : ""}`}
                  initValue=""
                  iProps={{
                    placeholder: "Select one",
                    isDisabled: true,
                    className: `${noEdit ? "disablerselect" : ""}`,
                  }}
                  selectOption={bedroomCategory}
                />
              </Col>
              <Col span={6}>
                <ReactSelectField
                  isRequired={!noEdit}
                  label="Bath Rooms"
                  fieldname="number_of_baths"
                  control={control}
                  classes={`${noEdit ? "noEditLabel" : ""}`}
                  initValue=""
                  iProps={{
                    placeholder: "Select one",
                    isDisabled: noEdit,
                    className: `${noEdit ? "disablerselect" : ""}`,
                  }}
                  selectOption={bathList}
                  rules={{ required: "Please enter Bathroom" }}
                  validate={errors.number_of_baths && "error"}
                  validMessage={
                    errors.number_of_baths && errors.number_of_baths.message
                  }
                />
              </Col>
            </>
          )}
        </>
      )}

      <Col span={6}>
        <InputField
          isRequired={!noEdit}
          label="Size, Sq.ft"
          fieldname="size"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Enter Size",
            type: "number",
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          rules={{
            required: "Please enter Size",
            pattern: {
              value: /^(?!\.?$)\d{0,9}(\.\d{0,2})?$/,
              message:
                "Value should be a Number and not greater than 2 decimal places",
            },
          }}
          validate={errors.size && "error"}
          validMessage={errors.size && errors.size.message}
        />
      </Col>

      <Col span={6}>
        <ReactSelectField
          label="Parking"
          fieldname="is_parking_available"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            isDisabled: noEdit,
            placeholder: "Select one",
            className: `${noEdit ? "disablerselect" : ""}`,
          }}
          selectOption={tfValue}
        />
      </Col>
      <Col span={6}>
        <ReactSelectField
          isRequired={!noEdit}
          label="360° Tour"
          fieldname="is_360tour_available"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Select one",
            isDisabled: noEdit,
            className: `${noEdit ? "disablerselect" : ""}`,
          }}
          selectOption={tfValue}
          rules={{ required: "Please select" }}
          validate={errors.is_360tour_available && "error"}
          validMessage={
            errors.is_360tour_available && errors.is_360tour_available.message
          }
        />
      </Col>
    </>
  );
};
