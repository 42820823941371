import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Layout, Menu } from "antd";
import {
  DashboardOutlined,
  HomeOutlined,
  ToolOutlined,
  EditOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FileTextOutlined,
  CalendarOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = (props) => {
  const [select, setSelect] = useState("1");
  const [openKeys, setOpenKeys] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const role = JSON.parse(window.localStorage.getItem("login-response"))?.data
    ?.role_name;
  const onCollapse = (isCollapsed) => {
    setCollapsed(isCollapsed);
  };

  const CustomTrigger = () =>
    collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />;

  const onOpenChange = (options) => {
    setOpenKeys(options[1]);
  };

  return (
    <Sider
      width="236"
      trigger={<CustomTrigger />}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <Menu
        theme="dark"
        openKeys={[openKeys]}
        onOpenChange={onOpenChange}
        selectedKeys={[location.pathname]}
        mode="inline"
      >
        <Menu.Item key="/dashboard" icon={<DashboardOutlined />}>
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>
        <SubMenu key="sub1" icon={<HomeOutlined />} title="Properties">
          {role == "Landlord" && (
            <Menu.Item key="/create-a-property">
              <Link to="/create-a-property">Add a new property</Link>
            </Menu.Item>
          )}
          <Menu.Item key="/properties">
            <Link to="/properties">List all properties</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="/reviews" icon={<EditOutlined />}>
          <Link to="/reviews">Reviews</Link>
        </Menu.Item>
        <SubMenu key="sub4" icon={<ToolOutlined />} title="Tenants">
          <Menu.Item key="/tenants">
            <Link to="/tenants">All Tenants</Link>
          </Menu.Item>
          <Menu.Item key="/prospects">
            <Link to="/prospects">All Prospects</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub2" icon={<ToolOutlined />} title="Lead Management">
          <Menu.Item key="/leads">
            <Link to="/leads">Leads</Link>
          </Menu.Item>
          <Menu.Item key="/opportunities">
            <Link to="/opportunities">Opportunities</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub3" icon={<FileTextOutlined />} title="Contracts">
          <Menu.Item key="/lease">
            <Link to="/lease">Lease</Link>
          </Menu.Item>
          <Menu.Item key="/bookingforms">
            <Link to="/bookingforms">Booking Forms</Link>
          </Menu.Item>
          <Menu.Item key="/ejari">
            <Link to="/ejari">Ejari</Link>
          </Menu.Item>
          <Menu.Item key="/contracts">
            <Link to="/contracts">Contracts</Link>
          </Menu.Item>
          <Menu.Item key="/post-dated-cheques">
            <Link to="/post-dated-cheques">Post-Dated Cheques</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="/visit-scheduling" icon={<CalendarOutlined />}>
          <Link to="/visit-scheduling">Visit Scheduling</Link>
        </Menu.Item>
        {/* <Menu.Item key="7">
          <Link to="/tenants">Tenants</Link>
        </Menu.Item> */}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
