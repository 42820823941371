import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { propertyService } from "../../../../configs/constants";

// const mapUrl = `${propertyService}/property-landlord/dashboard/fetchPropertyLongLat`;
// const statsUrl = `${propertyService}/property-landlord/dashboard/stats`;
const properties = `${propertyService}/property-landlord/review/user/properties`;

const headers = {
  Authorization:
    "Bearer" +
    " " +
    JSON.parse(window.localStorage.getItem("login-response"))?.token,
};

export const getPropertiesList = (search, page, limit, isList) => {
  return async (dispatch) => {
    let url = `${properties}?${isList && "property_type=unit" + "&"}${
      search && "asset_name=" + search + "&"
    }limit=${limit}&page=${page}`;
    const {
      data: { result },
    } = await axios.get(url, { headers });
    dispatch({
      type: action_types.GET_REVIEW_PROPERTIES,
      data: result,
    });
  };
};

export const getReviewsByProperty = (id, search, page, limit) => {
  return async (dispatch) => {
    const reviews = `${propertyService}/property/getTechnicianReviewsForAsset?asset_id=${id}`;
    const url = `${reviews}&${
      search && "asset_name=" + search + "&"
    }limit=${limit}&page=${page}`;
    const {
      data: { result },
    } = await axios.get(url, { headers });
    dispatch({
      type: action_types.GET_REVIEWS_FOR_PROPERTY,
      data: result,
    });
  };
};

export const removeReview = (id) => {
  return async (dispatch) => {
    const url = `${propertyService}/property/deleteVisitReview/${id}`;
    const {
      data: { result },
    } = await axios.delete(url, { headers });
    dispatch({
      type: action_types.DELETE_REVIEW,
      data: result,
    });
  };
};
