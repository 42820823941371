import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Button, Form, message } from "antd";
import { EditOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { InputField, ReactSelectField } from "../../../../atoms/FormElement";
import { propertyService } from "../../../../../configs/constants";
import { bedroomCategory, bathList } from "../../ducks/constants";
import axios from "../../../../../utils/axiosInceptor";
import { useDispatch, useSelector } from "react-redux";
import { getUnitSubType } from "../../AddProperty/ducks/actions/addInfoAction";
var _ = require("lodash");

const { Title } = Typography;

export default ({ propertyDetails, parentId, updateApi }) => {
  const { control, handleSubmit, errors, setValue, reset } = useForm();
  const [noEdit, setNoEdit] = useState(true);
  const dispatch = useDispatch();
  const apiUnitSubTypeData = useSelector(
    (state: any) => state.addInfoReducer.getUnitSubType,
  );

  const onSubmit = async (val) => {
    console.log(val);
    const {
      number_of_beds,
      number_of_baths,
      size,
      name,
      unit_subtype_id,
    } = val;
    const postJson = {
      number_of_beds: Number(number_of_beds.value),
      number_of_baths: Number(number_of_baths.value),
      area: size,
      name,
      usage_type_id: propertyDetails.usage_type_id,
      unit_type_id: propertyDetails.unit_type_id,
      unit_subtype_id: val.unit_subtype_id.value,
    };
    let url = `${propertyService}/property-landlord/${parentId}/unit/${propertyDetails.asset_id}`;
    try {
      await axios.put(url, postJson);
      message.success("Unit Details Updated");
      setNoEdit(true);
      updateApi();
    } catch (e) {
      console.log(e);
      setNoEdit(true);
    }
  };

  useEffect(() => {
    if (Object.keys(propertyDetails).length) {
      setValue("name", propertyDetails.name);
      setValue("size", propertyDetails.size);
      setValue(
        "number_of_baths",
        bathList.find(
          (item) => item.value.toString() == propertyDetails.number_of_baths,
        ),
      );
      setValue(
        "number_of_beds",
        bedroomCategory.find(
          (item) => item.value.toString() == propertyDetails.number_of_beds,
        ),
      );
      dispatch(getUnitSubType(propertyDetails.unit_type_id));
    }
  }, [propertyDetails]);

  useEffect(() => {
    if (apiUnitSubTypeData.length > 0 && propertyDetails?.unit_subtype_id) {
      setValue("unit_subtype_id", {
        label: apiUnitSubTypeData.find(
          (x) => x?.id == propertyDetails?.unit_subtype_id,
        )?.name,
        value: apiUnitSubTypeData.find(
          (x) => x?.id == propertyDetails?.unit_subtype_id,
        )?.id,
      });
    }
  }, [apiUnitSubTypeData]);

  return (
    <Form
      layout="vertical"
      name="bookingform"
      onFinish={handleSubmit(onSubmit)}
    >
      <Card
        bordered={false}
        bodyStyle={{ padding: "16px 32px 0" }}
        headStyle={{ padding: "16px 32px 0", borderBottom: 0 }}
        title={
          <Title level={5} className="ag-primary mb-0">
            Unit Information
          </Title>
        }
        extra={[
          <Button
            key={1}
            type="link"
            className={`p-0 no-border no-shadow ${noEdit && "opacity0"}`}
            icon={<CheckCircleOutlined />}
            htmlType="submit"
          >
            Save
          </Button>,
          <Button
            key={2}
            type="link"
            className={`p-0 no-border no-shadow ${!noEdit && "opacity0"}`}
            icon={<EditOutlined />}
            onClick={() => setNoEdit(false)}
            htmlType="button"
          />,
        ]}
      >
        <Row gutter={24}>
          <Col span={6}>
            <InputField
              isRequired={!noEdit}
              label="Unit Name"
              fieldname="name"
              control={control}
              classes={`${noEdit ? "noEditLabel" : ""}`}
              initValue=""
              iProps={{
                placeholder: "Unit Name",
                readOnly: noEdit,
                className: `${noEdit ? "noEdit" : ""}`,
              }}
              rules={{ required: "Please enter Unit Name" }}
              validate={errors.name && "error"}
              validMessage={errors.name && errors.name.message}
            />
          </Col>
          <Col span={6}>
            <ReactSelectField
              isRequired={!noEdit}
              label="Unit Sub Type"
              fieldname="unit_subtype_id"
              control={control}
              classes={`${noEdit ? "noEditLabel" : ""}`}
              initValue=""
              iProps={{
                placeholder: "Select one",
                isDisabled: noEdit,
                className: `${noEdit ? "disablerselect" : ""}`,
              }}
              selectOption={_.map(apiUnitSubTypeData, (item) => ({
                value: item.id,
                label: item.name,
              }))}
              rules={{ required: "Please enter Unit Sub Type" }}
              validate={errors.unit_subtype_id && "error"}
              validMessage={
                errors.unit_subtype_id && errors.unit_subtype_id.message
              }
              onChange={(e) => {
                if (propertyDetails.usage_type_id != 1) {
                  setValue("number_of_beds", {
                    label: e.label,
                    value: e.label == "Studio" ? 0 : Number(e.label.charAt(0)),
                  });
                } else {
                  setValue("number_of_beds", "");
                }
              }}
            />
          </Col>
          <Col span={6}>
            <ReactSelectField
              isRequired={!noEdit}
              label="Bed Rooms"
              fieldname="number_of_beds"
              control={control}
              classes={`${noEdit ? "noEditLabel" : ""}`}
              initValue=""
              iProps={{
                placeholder: "Select one",
                isDisabled: true,
                className: `${noEdit ? "disablerselect" : ""}`,
              }}
              selectOption={bedroomCategory}
            />
          </Col>
          <Col span={6}>
            <ReactSelectField
              isRequired={!noEdit}
              label="Bath Rooms"
              fieldname="number_of_baths"
              control={control}
              classes={`${noEdit ? "noEditLabel" : ""}`}
              initValue=""
              iProps={{
                placeholder: "Select one",
                isDisabled: noEdit,
                className: `${noEdit ? "disablerselect" : ""}`,
              }}
              selectOption={bathList}
              rules={{ required: "Please enter bathrooms" }}
              validate={errors.number_of_baths && "error"}
              validMessage={
                errors.number_of_baths && errors.number_of_baths.message
              }
            />
          </Col>
          <Col span={6}>
            <InputField
              isRequired={!noEdit}
              label="Size, Sq.ft"
              fieldname="size"
              control={control}
              classes={`${noEdit ? "noEditLabel" : ""}`}
              initValue=""
              iProps={{
                readOnly: noEdit,
                className: `${noEdit ? "noEdit" : ""}`,
              }}
              rules={{
                required: "Please enter Size",
                pattern: {
                  value: /^(?!\.?$)\d{0,9}(\.\d{0,2})?$/,
                  message:
                    "Value should be a Number and not greater than 2 decimal places",
                },
              }}
              validate={errors.size && "error"}
              validMessage={errors.size && errors.size.message}
            />
          </Col>
        </Row>
      </Card>
    </Form>
  );
};
