import React from "react";
import { Menu, Space, Typography, Tabs, Card, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import axios from "../../../utils/axiosInceptor";
import { leaseService } from "../../../configs/constants";
import {
  NOTIFICATION_TYPE,
  TYPE_NOTIFY,
  TYPE_CONTRACT,
} from "../Header/duck/constants";
import moment from "moment";

const { Text } = Typography;
const { TabPane } = Tabs;

const NotificationPanel = ({ notificationsData, newNotificationsData }) => {
  const history = useHistory();

  const gotoLease = (item) => {
    history.push(`/lease/${item.identifier_value}`);
  };

  const gotoBooking = (item) => {
    const { assetId, leadId, partyId } = JSON.parse(item.identifier_value);
    history.push(`/leads/${leadId}/booking-form/${assetId}/${partyId}`);
  };

  const gotoOpportunity = (item) => {
    const { assetId, leadId, partyId } = JSON.parse(item.identifier_value);

    history.push(`/opportunity-detail/${leadId}/${assetId}/${partyId}`);
  };
  const gotoTenant = (item) => {
    const { assetId, leadId, partyId } = JSON.parse(item.identifier_value);

    history.push(`/detail-tenant/${leadId}/${partyId}`);
  };

  const gotoContract = (item) => {
    history.push(`/contract-detail/${item.identifier_value}`);
  };

  const handleLinkClick = async (item) => {
    window.localStorage.setItem(
      "selectedOffer",
      JSON.stringify({
        identifier: item.identifier_value,
        id: item.id,
      }),
    );
    const url = `${leaseService}/leads/notifications/update/status`;
    const postData = {
      notification_ids: [item.id],
    };
    await axios.post(url, postData);

    switch (item.type) {
      case NOTIFICATION_TYPE.CONTRACT_ID: {
        history.push("/contracts");
        break;
      }

      case NOTIFICATION_TYPE.ASSET_ID: {
        history.push(`/visit-scheduling/${item.identifier_value}`);
        break;
      }
      case NOTIFICATION_TYPE.VISIT_ID: {
        history.push({
          pathname: `/visit-scheduling/${item.identifier_value}`,
          state: { code: item.identifier_value },
        });
        break;
      }
      case NOTIFICATION_TYPE.LEAD_ITEM_ID: {
        history.push(`/opportunity-detail/${item.identifier_value}`);
        break;
      }

      case NOTIFICATION_TYPE.LEASE_ID: {
        switch (item.notification_type) {
          case TYPE_CONTRACT.CONTRACT_SIGNED: {
            gotoContract(item);
            break;
          }
          case TYPE_CONTRACT.CONTRACT_RENEWED: {
            gotoContract(item);
            break;
          }
          case TYPE_CONTRACT.CONTRACT_RENEWED1: {
            gotoContract(item);
            break;
          }
          case TYPE_CONTRACT.RENEWAL_FEEDBACK: {
            history.push(`/contracts`);
            break;
          }
          default:
            history.push(`/ejari`);
            break;
        }
        break;
      }

      case NOTIFICATION_TYPE.MAINTENANCE_ITEM_ID: {
        history.push({
          pathname: `/maintenance/${item.identifier_value}`,
          state: {
            from: "notification",
          },
        });
        break;
      }
      case NOTIFICATION_TYPE.LEAD_ID: {
        switch (item.notification_type) {
          case TYPE_NOTIFY.LEASE_FORM_SENT_FOR_APPROVAL: {
            gotoLease(item);
            break;
          }
          case TYPE_NOTIFY.LEASE_FORM_APPROVED: {
            gotoLease(item);
            break;
          }
          case TYPE_NOTIFY.LEASE_FORM_REJECTED: {
            gotoLease(item);
            break;
          }
          case TYPE_NOTIFY.SENT_TO_TENANT_FOR_CHEQUES: {
            gotoLease(item);
            break;
          }
          case TYPE_NOTIFY.TENANT_CHEQUES_SCHEDULED_DROPOFF: {
            gotoLease(item);
            break;
          }
          case TYPE_NOTIFY.TENANT_CHEQUES_SCHEDULED_PICKUP: {
            gotoLease(item);
            break;
          }
          case TYPE_NOTIFY.BOOKING_FORM_SIGNED: {
            gotoLease(item);
            break;
          }
          case TYPE_NOTIFY.BOOKING_OPPORTUNITY: {
            gotoBooking(item);
            break;
          }
          case TYPE_NOTIFY.BOOKING_APPROVED: {
            gotoBooking(item);
            break;
          }
          case TYPE_NOTIFY.BOOKING_REJECTED: {
            gotoBooking(item);
            break;
          }
          case TYPE_NOTIFY.BOOKING_RECEIVED: {
            gotoOpportunity(item);
            break;
          }
          case TYPE_NOTIFY.DOCUMENT_SUBMITED: {
            gotoTenant(item);
            break;
          }
        }
        break;
      }
      default:
        history.push(`/dashboard`);
    }
  };

  return (
    <Card bordered={false} className="ag-nospace-body">
      <Tabs defaultActiveKey="1" tabBarGutter={24}>
        <TabPane
          tab={`New (${newNotificationsData[0]?.total_count || 0})`}
          key="1"
        >
          <Menu className="notif-panel" selectable={false}>
            {newNotificationsData.map((item, index) => (
              <Menu.Item
                key={index}
                className={`notif-item ${
                  item.status === "seen" ? "notify-read" : ""
                }`}
              >
                <Space direction="vertical" size={4}>
                  <Text className="ag-primary breakText">
                    {item.text || ""}
                  </Text>
                  <Text className="smallFont12">
                    <Link
                      className="ag-secondary breakText"
                      onClick={() => handleLinkClick(item)}
                    >
                      {item.identifier || "Click here."}
                    </Link>
                  </Text>
                  <Text className="smallFont12">
                    {item.createdAt
                      ? moment(item.createdAt).format("MMMM Do YYYY, h:mm:ss a")
                      : ""}
                  </Text>
                </Space>
              </Menu.Item>
            ))}
            {newNotificationsData[0]?.total_count > 5 && (
              <Menu.Item className="notif-item text-right">
                <Button
                  type="primary"
                  onClick={() =>
                    history.push({
                      pathname: `/notifications`,
                      state: {
                        selected: "new",
                      },
                    })
                  }
                >
                  View All
                </Button>
              </Menu.Item>
            )}
          </Menu>
        </TabPane>
        <TabPane
          tab={`All (${notificationsData[0]?.total_count || 0})`}
          key="2"
        >
          <Menu className="notif-panel" selectable={false}>
            {notificationsData.map((item, index) => (
              <Menu.Item
                key={index}
                className={`notif-item ${
                  item.status === "seen" ? "notify-read" : ""
                }`}
              >
                <Space direction="vertical" size={4}>
                  <Text className="ag-primary breakText">
                    {item.text || ""}
                  </Text>
                  <Text className="smallFont12">
                    <Link
                      className="ag-secondary"
                      onClick={() => handleLinkClick(item)}
                    >
                      {item.identifier || "Click here."}
                    </Link>
                  </Text>
                  <Text className="smallFont12">
                    {item.createdAt
                      ? moment(item.createdAt).format("MMMM Do YYYY, h:mm:ss a")
                      : ""}
                  </Text>
                </Space>
              </Menu.Item>
            ))}
            {notificationsData[0]?.total_count > 5 && (
              <Menu.Item className="notif-item text-right">
                <Button
                  type="primary"
                  onClick={() =>
                    history.push({
                      pathname: `/notifications`,
                      state: {
                        selected: "all",
                      },
                    })
                  }
                >
                  View All
                </Button>
              </Menu.Item>
            )}
          </Menu>
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default NotificationPanel;
