import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "antd";
import DashboardLayout from "../../molecules/DashboardLayout";
import SectionHeader from "../../molecules/SectionHeader";
import BreadCrumb from "../../atoms/BreadCrumb";
import PropertyList from "../../molecules/PropertyList";
import { getPropertiesList } from "./ducks/actions";
import { useDispatch, useSelector } from "react-redux";
import reviewIcon from "../../../assets/img/review.png";

const breadcrumbList: any = [
  {
    label: "Reviews",
    route: "/reviews",
  },
];

const list = {
  search: "",
  page: 0,
  limit: 6,
  isList: true,
};

const Reviews = (props) => {
  const dispatch = useDispatch();
  const [propertyCard, setPropertyCard] = useState(list);
  const propertiesCardApi = useSelector(
    (state: any) => state.reviews.reviewProperties,
  );

  function listCard() {
    dispatch(
      getPropertiesList(propertyCard.search, propertyCard.page, 6, true),
    );
  }

  useEffect(() => {
    listCard();
  }, [propertyCard]);

  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BreadCrumb list={breadcrumbList} />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <SectionHeader
            heading="Reviews"
            total={
              (propertiesCardApi?.properties && propertiesCardApi?.count) || 0
            }
            search={(e) =>
              setPropertyCard({
                ...propertyCard,
                search: e.target.value,
                page: 0,
              })
            }
          />
        </Col>
        <Col span={24}>
          {propertiesCardApi?.properties ? (
            <PropertyList
              data={propertiesCardApi?.properties}
              type="r"
              pagination={true}
              count={propertiesCardApi?.count}
              currentPage={propertyCard.page + 1}
              onpageChange={(e) =>
                setPropertyCard({ ...propertyCard, page: e - 1 })
              }
            />
          ) : (
            <Card
              className="noDataCard"
              style={{ width: "100%" }}
              cover={
                <img alt="review" style={{ width: "auto" }} src={reviewIcon} />
              }
            >
              There are currently no <br />
              reviews for this property.
            </Card>
          )}
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default Reviews;
