import React from "react";
import { Row, Col } from "antd";
import {
  DateField,
  InputField,
  TextAreaField,
} from "../../../../../atoms/FormElement";
import moment from "moment";

var _ = require("lodash");

export default (props) => {
  const { control, errors, noEdit } = props;

  return (
    <Row gutter={24}>
      <Col span={12}>
        <InputField
          isRequired={!noEdit}
          label="Name"
          fieldname="name"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Property Name",
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          rules={{ required: "Please enter Property Name" }}
          validate={errors.name && "error"}
          validMessage={errors.name && errors.name.message}
        />
      </Col>
      <Col span={6}>
        <DateField
          isRequired={false}
          label="Built In"
          fieldname="builtIn"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            className: `${noEdit ? "noEdit nodateset" : ""}`,
            disabled: noEdit,
            format: "YYYY-MM-DD",
          }}
        />
      </Col>
      <Col span={6}>
        <DateField
          isRequired={!noEdit}
          label="Available From"
          fieldname="available_from"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            className: `${noEdit ? "noEdit nodateset" : ""}`,
            disabled: noEdit,
            format: "YYYY-MM-DD",
          }}
          rules={{
            required: "Please enter date",
            setValueAs: (value) => moment(value, "YYYY-MM-DD"),
          }}
          validate={errors.available_from && "error"}
          validMessage={errors.available_from && errors.available_from.message}
        />
      </Col>
      <Col span={24}>
        <TextAreaField
          label="Description"
          fieldname="description"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
            autoSize: { minRows: 4, maxRows: 4 },
          }}
          rules={{
            required: "Please enter description",
          }}
          validate={errors.description && "error"}
          validMessage={errors.description && errors.description.message}
        />
      </Col>
    </Row>
  );
};
