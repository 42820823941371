import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { Col, Row, Button, Typography } from "antd";
import {
  InputField,
  DateField,
  ReactSelectField,
} from "../../../atoms/FormElement";
import { useFieldArray } from "react-hook-form";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { getTermsConditions } from "../ducks/actions";
import Billings from "./Billings";
import { installmentsList } from "../../Properties/ducks/constants";
import { getBillingCharges } from "../../LeasingForm/ducks/actions";

var _ = require("lodash");
const { Text } = Typography;

// const billingSelection = [
//   { label: "Base Rent", value: 1, isDisabled: true },
//   { label: "EJARI FEE", value: 2, isDisabled: true },
//   { label: "Security Deposit", value: 3, isDisabled: true },
//   { label: "Parking Charges", value: 4, isDisabled: false },
//   { label: "Management Charges", value: 5, isDisabled: false },
//   { label: "Rental Comission", value: 6, isDisabled: false },
//   { label: "Housekeeping Services", value: 7, isDisabled: false },
//   { label: "Gen Services Charges", value: 8, isDisabled: false },
//   { label: "Furniture Charges", value: 9, isDisabled: false },
//   { label: "Utilities", value: 10, isDisabled: false },
// ];

const initcharge = {
  itemId: null,
  type: "",
  intallments: { label: 1, value: 1 },
  amount: 0,
};
const initPark = {
  itemId: null,
  type: { label: "PARKING", value: "parking_charges" },
  intallments: { label: 1, value: 1 },
  amount: 0,
  slot: null,
};

export default (props) => {
  const dispatch = useDispatch();
  const {
    setValue,
    control,
    details,
    errors,
    getValues,
    parking,
    billingsApi,
    paid,
    leasestatus,
  } = props;
  const [noIstallment, setNoInstallment] = useState([]);
  const [billingType, setBillingType] = useState([]);
  const termsConditions = useSelector(
    (state: any) => state.leasingForm.termsConditions,
  );

  const get_billing_charges = useSelector(
    (state: any) => state.leasingForm.getBillingCharges,
  );

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: "othercharges",
  });

  useEffect(() => {
    dispatch(getTermsConditions());
    dispatch(getBillingCharges());
  }, []);

  useEffect(() => {
    let billingTypeTemp = [];
    if (get_billing_charges.length > 0) {
      get_billing_charges?.map((item, index) => {
        let isDisabledTemp = false;
        if (
          item.key == "total_rent" ||
          item.key == "ejari_fee" ||
          item.key == "security_deposit"
        ) {
          isDisabledTemp = true;
        }
        if (item.key != "base_rent_ic") {
          billingTypeTemp.push({
            label: item.name,
            value: item.key,
            isDisabled: isDisabledTemp,
          });
        }
      });
      setBillingType(billingTypeTemp);
    }
  }, [get_billing_charges]);

  const parkingLimit = () => {
    append(initcharge);
    disableClone("new");
  };

  const disableClone = (type) => {
    let arr = [];
    arr = getValues().othercharges;
    let check = arr.filter((item) => item.type.label == type);
    if (
      (type == "PARKING" && check.length > 4) ||
      (type != "PARKING" && check.length > 0)
    ) {
      let newArr = [];
      newArr = [...billingType];
      let findType = null;
      findType = newArr.findIndex((item) => item.label == type);
      if (findType) {
        billingType.map((item, index) => {
          let b = [];
          b = arr.filter((y) => item.label === y.type.label);
          if (b.length > 0) {
            if (item.label === "PARKING") {
              if (b.length > 4) {
                newArr[index].isDisabled = true;
              } else {
                newArr[index].isDisabled = false;
              }
            } else {
              newArr[index].isDisabled = true;
            }
          } else {
            newArr[index].isDisabled = false;
          }
        });
        setBillingType(Object.assign([], newArr));
      }
    } else {
      let newArr = [];
      newArr = [...billingType];
      let check2 = arr.filter((item) => item.type.label == "PARKING");
      arr.map((x) => {
        let a = newArr.findIndex((item) => item.label == x.type.label);
        if (a) {
          if (x.type.label != "PARKING") {
            newArr[a].isDisabled = true;
          } else {
            if (check2.length > 3) {
              newArr[a].isDisabled = true;
            }
          }
        }
      });
      setBillingType(Object.assign([], newArr));
    }
  };

  const enableClone = (type) => {
    let newArr = [];
    newArr = [...billingType];
    let findType = null;
    findType = newArr.findIndex((item) => item.label == type);
    newArr[findType].isDisabled = false;
    setBillingType(Object.assign([], newArr));
  };

  useEffect(() => {
    if (details) {
      let installmentNo = details?.baseRentNoOfInstallments || 1;
      setValue("annualBaseRent", details.annualBaseRent);
      setValue("noOfInstallments", {
        label: installmentNo,
        value: installmentNo,
      });
      setValue("nonRefundableAmount", details?.securityDeposit);
      setValue("poBox", details.poBox);
      if (details?.bookingStartDate) {
        setValue(
          "bookingStartDate",
          moment(details?.bookingStartDate, "YYYY-MM-DD"),
        );
      }
      if (details?.bookingEndDate) {
        setValue(
          "bookingEndDate",
          moment(details?.bookingEndDate, "YYYY-MM-DD"),
        );
      }

      if (billingsApi.length > 0 && billingType.length > 0) {
        billingsApi.map((item) => {
          if (item.type == "total_rent") {
            insert(0, {
              itemId: item.itemId,
              type: billingType.find((e) => e.value == item.type),
              intallments: {
                label: item.installments?.length || 1,
                value: item.installments?.length || 1,
              },
              amount: item.amount,
              slot: item.parkingSlot,
            });
          } else if (item.type == "ejari_fee") {
            insert(1, {
              itemId: item.itemId,
              type: billingType.find((e) => e.value == item.type),
              intallments: {
                label: item.installments?.length || 1,
                value: item.installments?.length || 1,
              },
              amount: item.amount,
              slot: item.parkingSlot,
            });
          } else if (item.type == "security_deposit") {
            insert(2, {
              itemId: item.itemId,
              type: billingType.find((e) => e.value == item.type),
              intallments: {
                label: item.installments?.length || 1,
                value: item.installments?.length || 1,
              },
              amount: item.amount,
              slot: item.parkingSlot,
            });
          } else {
            if (item.type) {
              let type = billingType.find((e) => e.value == item.type);
              if (type) {
              }
              append({
                itemId: item.itemId,
                type: type,
                intallments: {
                  label: item.installments?.length || 1,
                  value: item.installments?.length || 1,
                },
                amount: item.amount,
                slot: item.parkingSlot,
              });
            }
          }
        });
        if (leasestatus == "lease" && paid && paid?.no_of_paid_parking_slots) {
          let x = billingsApi?.filter((item) => item.type == "parking_charges");
          for (
            let i = 0;
            i < Number(paid?.no_of_paid_parking_slots) - x.length;
            i++
          )
            append(initPark);
        }
      }
    }
  }, [details]);

  useEffect(() => {
    if (details?.termsAndConditionsEntity && termsConditions.length) {
      let y = termsConditions.find(
        (x) => x.value == details?.termsAndConditionsEntity,
      );
      if (y) {
        setValue("terms_and_conditions", y);
      }
    }
  }, [details, termsConditions]);

  return (
    <>
      <Col span={8}>
        <InputField
          label="Annual base rent"
          fieldname="annualBaseRent"
          control={control}
          initValue=""
          iProps={{ placeholder: "Enter amount", disabled: true }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="No. of Instalments"
          fieldname="noOfInstallments"
          control={control}
          initValue=""
          iProps={{ placeholder: "Select one", isDisabled: true }}
          selectOption={installmentsList}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="Non-Refundable booking amount"
          fieldname="nonRefundableAmount"
          control={control}
          initValue={0}
          iProps={{ placeholder: "Enter rent amount", disabled: true }}
        />
      </Col>
      <Col span={8}>
        <DateField
          label="Booking start date"
          fieldname="bookingStartDate"
          control={control}
          initValue=""
          iProps={{ disabled: true }}
        />
      </Col>
      <Col span={8}>
        <DateField
          label="Booking End date"
          fieldname="bookingEndDate"
          control={control}
          setValue={setValue}
          initValue=""
          iProps={{ disabled: true }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="Terms &amp; Conditions entity"
          fieldname="terms_and_conditions"
          control={control}
          iProps={{ placeholder: "Select one", isDisabled: true }}
          selectOption={termsConditions}
        />
      </Col>

      <Col span={23}>
        {fields.map((item, index) => (
          <Fragment key={item.id}>
            <Billings
              key={item.id}
              item={item}
              billingType={billingType}
              control={control}
              errors={errors}
              remove={remove}
              setValue={setValue}
              disableAll={props.disableAll}
              getValues={getValues}
              index={index}
              noIstallment={noIstallment}
              noIData={installmentsList}
              parking={parking}
              setNoInstallment={setNoInstallment}
              disableClone={disableClone}
              enableClone={enableClone}
            />
          </Fragment>
        ))}
        {!props.disableAll && (
          <Row gutter={24}>
            <Col span={24}>
              <Button
                type="link"
                className="p-0"
                htmlType="button"
                icon={<PlusOutlined />}
                onClick={parkingLimit}
              >
                <Text
                  className="ag-primary fontWeight600"
                  style={{ fontSize: "22px" }}
                >
                  Add more billing
                </Text>
              </Button>
            </Col>
          </Row>
        )}
      </Col>
    </>
  );
};
