import React, { useState, useEffect } from "react";
import { Row, Col, Input, Typography, Card, Table, Form } from "antd";

import SectionHeader from "../../../molecules/SectionHeader";
import DashboardLayout from "../../../molecules/DashboardLayout";
import BreadCrumb from "../../../atoms/BreadCrumb";
import { EQARI_GOOGLE_KEY } from "../../../../configs/constants";
import { WrappedMap } from "../../../../utils/WrappedMap";
import { useDispatch, useSelector } from "react-redux";
import { getPinProperties } from "../ducks/actions";
import { getPresignedImage } from "../AddProperty/ducks/actions/addInfoAction";

const { Title, Text } = Typography;
const { Search } = Input;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    render: (text) => <span className="ag-default">{text}</span>,
  },
  {
    title: "location",
    dataIndex: "pcity",
    render: (text) => <span className="ag-success">{text}</span>,
  },
];

const breadcrumbList: any = [
  {
    label: "Properties on Map",
    route: "/properties-map",
  },
];

export default () => {
  const dispatch = useDispatch();
  const [marker, setMarker] = useState([]);
  const [allMarker, setAllMarker] = useState([]);
  const [filterTable, setFilterTable] = useState(null);
  const markerApi = useSelector((state: any) => state.dashboard.mapData);

  const searchTech = (value) => {
    const filterTable = allMarker.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase()),
      ),
    );
    setFilterTable(filterTable);
  };

  const presinedImageUrl = async (media) => {
    let url = await getPresignedImage(media);
    return url;
  };

  const updateMarker = async () => {
    var temp = [];
    await Promise.all(
      markerApi.map(async (e, index) => {
        var img = "";
        var d = {};
        if (e.image && e.image.length > 0) {
          img = await presinedImageUrl(e.image[0].data);
          d = { ...e, key: index, pcity: e.street_address, imgurl: img };
          temp.push(d);
        } else {
          img = "";
          d = { ...e, key: index, pcity: e.street_address, imgurl: img };
          temp.push(d);
        }
      }),
    );
    setAllMarker(temp);
    return true;
  };

  useEffect(() => {
    if (markerApi.length) {
      updateMarker();
    }
  }, [markerApi]);

  useEffect(() => {
    dispatch(getPinProperties());
  }, []);

  useEffect(() => {
    console.log(marker);
  }, [marker]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setMarker(selectedRows);
    },
  };

  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BreadCrumb list={breadcrumbList} />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <SectionHeader heading="Properties on Map" />
          <Card
            bordered={false}
            className="ag-nospace-head ag-nospace-body h-auto"
          >
            <Row>
              <Col xxl={6} xl={8}>
                <Card bordered={false}>
                  <Title level={5} className="mb-1">
                    All Properties
                  </Title>
                  <Form.Item>
                    <Search
                      placeholder="Search a property"
                      allowClear
                      onSearch={searchTech}
                    />
                  </Form.Item>
                  <Table
                    className="w-100 noborder-table"
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelection,
                    }}
                    pagination={{
                      className: "ag-property-text-pagination mt-1",
                    }}
                    columns={columns}
                    dataSource={filterTable == null ? allMarker : filterTable}
                  />
                </Card>
              </Col>
              <Col xxl={18} xl={16}>
                <WrappedMap
                  isMarkerShown
                  positions={marker}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${EQARI_GOOGLE_KEY}`}
                  loadingElement={<div style={{ height: "100%" }} />}
                  containerElement={
                    <div style={{ height: "100%", minHeight: "790px" }} />
                  }
                  mapElement={<div style={{ height: "100%" }} />}
                  dashboard={false}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </DashboardLayout>
  );
};
