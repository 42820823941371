import React, { useState, useEffect, Fragment } from "react";
import { Popup } from "../../../atoms/Popup";
import {
  Card,
  Row,
  Col,
  Typography,
  Radio,
  Button,
  Image,
  Upload,
  message,
  Space,
  Anchor,
  Form,
  Input,
} from "antd";
import {
  FileTextOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { ReactSelectField } from "../../../atoms/FormElement";
import PdfViewer from "../../../molecules/PDFViewer/pdfView";
import { getPresignedImage } from "../../../modules/Properties/AddProperty/ducks/actions/addInfoAction";
import { useWatch, useFieldArray, Controller } from "react-hook-form";
import { dummyRequest } from "../../Properties/utils/utility";
import { useSelector } from "react-redux";

const { Link } = Anchor;

export default (props) => {
  const [visible, Setvisible] = useState(false);
  const {
    control,
    disableAll,
    leaseData,
    deletedAdditionalDocs,
    setDeletedAdditionalDocs,
  } = props;
  const { Title } = Typography;
  const [viewOrDownloadUrl, setViewOrDownloadUrl] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);

  const [additionalDocuments, setAdditionalDocuments] = useState([]);

  const [existingDocuments, setExistingDocuments] = useState([]);

  const docsFromApi = useSelector(
    (state: any) => state.leasingForm.getAdditionalDocumentList,
  );

  useEffect(() => {
    if (leaseData?.additionalDocs?.length) {
      setExistingDocuments(leaseData?.additionalDocs);
    }
  }, [leaseData?.additionalDocs]);

  useEffect(() => {
    if (docsFromApi && docsFromApi.length) {
      const modData = docsFromApi.map((e) => {
        return { label: e.name, value: e.id };
      });
      setAdditionalDocuments(modData);
    }
  }, [docsFromApi]);

  const onClosePopUp = () => {
    Setvisible(false);
  };

  const initDocBar = {
    type: "",
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "additionalDocs",
  });

  const onOpenPopUpHandler = async (key) => {
    if (key?.includes("pdf")) {
      await getUrl(key);
      setPdfFile(viewOrDownloadUrl);
      setShowPdf(!showPdf);
    } else {
      await getUrl(key);
      Setvisible(true);
    }
  };

  const getUrl = async (data) => {
    const url = await getPresignedImage(data);
    setViewOrDownloadUrl(url);
  };

  const popupContent = {
    closable: true,
    visibility: visible,
    content: (
      <Image
        preview={false}
        width="100%"
        style={{ padding: "20px" }}
        src={viewOrDownloadUrl}
      />
    ),
    width: 650,
    onCancel: onClosePopUp,
  };

  const handleExistingDocDelete = (id) => {
    const filteredArray = existingDocuments.filter(
      (e) => e.lead_object_id !== id,
    );
    setExistingDocuments(filteredArray);
    const delArray = [...deletedAdditionalDocs];
    delArray.push(id);
    setDeletedAdditionalDocs(delArray);
  };

  return (
    <>
      {existingDocuments?.map((e) => {
        return (
          <Card bordered={false} key={e.lead_object_id} className="h-auto mb-2">
            <PdfViewer
              pdf={pdfFile}
              onCancel={() => setShowPdf(false)}
              visible={showPdf}
            />
            <Row gutter={24} justify="space-between" align="middle">
              <Col span={12} style={{ display: "flex" }}>
                <FileTextOutlined className="require-doc" />
                <Title level={5}>{e.purpose_type}</Title>
              </Col>
              <Col>
                <Space>
                  <Button
                    type="link"
                    onClick={() => onOpenPopUpHandler(e.object_key)}
                  >
                    View
                  </Button>
                  {!disableAll && (
                    <Button
                      danger
                      type="link"
                      htmlType="button"
                      icon={<DeleteOutlined />}
                      onClick={() => handleExistingDocDelete(e.lead_object_id)}
                    />
                  )}
                </Space>
              </Col>
            </Row>
          </Card>
        );
      })}
      <Popup {...popupContent} />

      {fields.map((item, index) => {
        return (
          <Card bordered={false} key={item.id} className="h-auto mb-2">
            <Row gutter={24} justify="space-between" align="middle">
              <Col flex="300px" style={{ display: "flex" }}>
                <FileTextOutlined className="require-doc" />
                <Col span={24}>
                  <ReactSelectField
                    label=""
                    classes="mb-0"
                    fieldname={`additionalDocs[${index}].type`}
                    control={control}
                    iProps={{ placeholder: "Document Type" }}
                    initValue={item.type}
                    selectOption={additionalDocuments}
                  />
                </Col>
              </Col>
              <Col>
                <Space>
                  <Form.Item className="mb-0">
                    <Controller
                      name={`additionalDocs[${index}].file`}
                      control={control}
                      defaultValue=""
                      render={({ value, onChange }) => (
                        <Upload
                          className="uploadWithbtn"
                          showUploadList={false}
                          accept="image/*"
                          maxCount={1}
                          customRequest={dummyRequest}
                          onChange={(e) => onChange(e)}
                        >
                          <Input
                            bordered={false}
                            size="middle"
                            className="ag-upload-btn ag-ad-btn"
                            value={value ? value.fileList[0].name : "Upload"}
                          />
                        </Upload>
                      )}
                    />
                  </Form.Item>
                  {!disableAll && (
                    <Button
                      danger
                      type="link"
                      htmlType="button"
                      icon={<DeleteOutlined />}
                      onClick={() => remove(index)}
                    />
                  )}
                </Space>
              </Col>
            </Row>
          </Card>
        );
      })}

      {!disableAll && (
        <Row gutter={[24, 24]} justify="end">
          <Col span={24}>
            <Button
              className="add-more"
              icon={<PlusOutlined style={{ fontSize: "initial" }} />}
              type="link"
              onClick={() => {
                append(initDocBar);
              }}
            >
              Add more documents
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};
