import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { propertyService, leaseService } from "../../../../configs/constants";

export const getTenants = (id, limit, page, search, searchBy) => {
  return async (dispatch) => {
    let tenants = "";
    let url = "";
    if (id) {
      // tenants = `${propertyService}/property/${id}/tenants`;
      tenants = `${leaseService}/leads/get-tenant-leases?asset_id=${id}`;
      url = `${tenants}&limit=${limit}&page=${page}`;
    } else {
      tenants = `${leaseService}/leads/get-tenant-leases`;
      url = `${tenants}?limit=${limit}&page=${page}${
        search && "&" + searchBy + "=" + search
      }`;
    }

    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_TENANTS,
      data: result,
    });
  };
};

export const emptyTenants = () => {
  return (dispatch) => {
    dispatch({
      type: action_types.EMPTY_TENANTS,
      data: {
        count: 0,
        results: [],
      },
    });
  };
};

export const getPropects = (id, limit, page, search, searchBy) => {
  return async (dispatch) => {
    let prospects = "";
    if (id) {
      prospects = `${propertyService}/property/${id}/tenants`;
    } else {
      prospects = `${leaseService}/leads/get-tenant-prospects`;
    }

    const url = `${prospects}?limit=${limit}&page=${page}${
      search && "&" + searchBy + "=" + search
    }`;
    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_PROSPECTS,
      data: result,
    });
  };
};
