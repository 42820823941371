import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Form,
  Collapse,
  Upload,
  Typography,
  Image,
  Space,
  message,
  Row,
  Col,
  Radio,
  Tooltip,
  Select,
} from "antd";
import { useForm } from "react-hook-form";
import axios from "../../../../../utils/axiosInceptor";
import { QuestionCircleFilled, PlusOutlined } from "@ant-design/icons";
import CloudUpload from "../../../../../assets/img/cloud-upload.svg";
import { getUnitSubType, getUnitType } from "../ducks/actions/addInfoAction";
import { activateSpinner } from "../ducks/actions/spinAction";
import {
  getSingleUpload,
  uniquiFileName,
} from "../../../../../utils/fileUpload";
import { propertyService } from "../../../../../configs/constants";
import General from "./AddInformation/General";
import Address from "./AddInformation/Address";
import { InputRadio } from "../../../../atoms/FormElement";
import MoreDetails from "./AddInformation/MoreDetails";
import AddUnit from "./AddInformation/AddUnit";
import { dummyRequest, uniq } from "../../utils/utility";
import { getFeaturesAmenities } from "../ducks/actions/addInfoAction";
import { Popup } from "../../../../atoms/Popup";
import AddFeatures from "../../PropertyDetail/propertyInfoTab/AddFeatures";
import moment from "moment";

const { Text } = Typography;
const { Panel } = Collapse;
const { Dragger } = Upload;
const { Option } = Select;

export default ({
  currentStep,
  setCurrentStep,
  profilepicUploaded,
  galaryPicUploaded,
  setUid,
}) => {
  const dispatch = useDispatch();
  const [featureTags, setFeaturesTags] = useState([]);
  const [amenitiesTags, setAmenitiesTags] = useState([]);
  const [subFeatures, setSubFeatures] = useState([]);
  const [valuedSubFeature, setValuedSubFeature] = useState([]);
  const [subAmenities, setSubAmenities] = useState([]);
  const [valuedSubAmeninties, setValuedSubAmeninties] = useState([]);
  const [trigger, setTrigger] = useState(0);
  const [visible, setVisible] = useState(false);

  const [docList, setDocList] = useState([]);
  const [comm, setComm] = useState(false);
  const [profilePic, setProfilePic] = useState([]);
  const [galaryImage, setGalaryImage] = useState([]);
  const apiFeatureAmenitiesData = useSelector(
    (state: any) => state.addInfoReducer.getFeaturesAmenities,
  );

  const {
    control,
    handleSubmit,
    errors,
    setValue,
    getValues,
    watch,
  } = useForm();

  const usageWatch = watch("usage_type_id");
  const unitWatch = watch("unit_type_id");
  const pType = watch("type");

  useEffect(() => {
    if (usageWatch) {
      dispatch(getUnitType(usageWatch.value));
      if (usageWatch.value == 2) {
        setComm(true);
      } else {
        setComm(false);
      }
    }
  }, [usageWatch]);

  useEffect(() => {
    if (unitWatch) {
      dispatch(getUnitSubType(unitWatch.value));
    }
  }, [unitWatch]);

  useEffect(() => {
    if (profilepicUploaded.length) {
      setProfilePic(profilepicUploaded);
    }
  }, [profilepicUploaded]);

  useEffect(() => {
    if (galaryPicUploaded.length) {
      setGalaryImage(galaryPicUploaded);
    }
  }, [galaryPicUploaded]);

  // document upload properties
  const draggerOptions = {
    name: "file",
    multiple: true,
    accept: ".pdf,.doc,.docx",

    onRemove(data) {
      const filtered = docList.filter((e) => e.uid !== data.uid);
      setDocList(filtered);
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        setDocList(info.fileList);
      }
    },

    showUploadList: {
      showRemoveIcon: true,
      removeIcon: "Remove",
    },
  };

  const featureSubmit = () => {
    dispatch(getFeaturesAmenities());
    setVisible(false);
  };

  useEffect(() => {
    dispatch(getFeaturesAmenities());
  }, []);

  useEffect(() => {
    if (
      apiFeatureAmenitiesData &&
      (apiFeatureAmenitiesData?.property_features ||
        apiFeatureAmenitiesData?.property_amenities)
    ) {
      let features = apiFeatureAmenitiesData?.property_features
        ? uniq(apiFeatureAmenitiesData?.property_features)
        : [];
      let amenity = apiFeatureAmenitiesData?.property_amenities
        ? uniq(apiFeatureAmenitiesData?.property_amenities)
        : [];

      setFeaturesTags(features);
      setAmenitiesTags(amenity);
    }
  }, [apiFeatureAmenitiesData]);

  const popup = [
    {
      title: "Add Amenities",
      content: (
        <AddFeatures
          selected="property_amenities"
          type={pType === 1}
          onCancel={() => setVisible(false)}
          onSubmit={featureSubmit}
        />
      ),
      width: 650,
    },
    {
      title: "Add Features",
      content: (
        <AddFeatures
          selected="property_features"
          type={pType === 1}
          onCancel={() => setVisible(false)}
          onSubmit={featureSubmit}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  const addFeatureTag = async (value) => {
    const selectedIds = value.map((e) => e.split("-")[1]);
    const seletedFeatures = [];
    for (let i of selectedIds) {
      for (let j of featureTags) {
        if (i == j.labelId) {
          seletedFeatures.push(j);
        }
      }
    }
    setSubFeatures(seletedFeatures);
  };

  const addAmenityTag = async (value) => {
    const selectedIds = value.map((e) => e.split("-")[1]);
    const seletedAmenities = [];
    for (let i of selectedIds) {
      for (let j of amenitiesTags) {
        if (i == j.labelId) {
          seletedAmenities.push(j);
        }
      }
    }
    setSubAmenities(seletedAmenities);
  };

  const onSubmit = async (val) => {
    let multiUnits = [];
    const mediaArray = [];
    if (profilepicUploaded.length > 0 && galaryPicUploaded.length > 0) {
      // FOR PROPIC UPLOAD
      const singleImagKeyLocal = [];
      // dummy await
      await getSingleUpload("testcall", profilepicUploaded[0].originFileObj);
      profilepicUploaded.forEach((e) =>
        singleImagKeyLocal.push({
          ...e,
          modifiedName: uniquiFileName(e?.originFileObj.name),
        }),
      );
      await getSingleUpload(
        singleImagKeyLocal[0].modifiedName,
        singleImagKeyLocal[0].originFileObj,
      );

      // FOR GALARY PIC UPLOADS

      const norMalizedMutiImage = [];
      galaryImage.forEach((e) =>
        norMalizedMutiImage.push({
          ...e,
          modifiedName: uniquiFileName(e?.originFileObj.name),
        }),
      );
      await Promise.all(
        norMalizedMutiImage.map(async (e, index) => {
          await getSingleUpload(e.modifiedName, e.originFileObj);
        }),
      );

      // FOR UNIT PROFILE PIC UPLOADS
      const norMalizedMutiUnit = [];
      if (val.type === 1) {
        val.unit.forEach((e) =>
          norMalizedMutiUnit.push({
            ...e,
            modifiedName: uniquiFileName(
              e?.image?.fileList[0]?.originFileObj.name,
            ),
          }),
        );
        await Promise.all(
          norMalizedMutiUnit.map(async (e, index) => {
            await getSingleUpload(
              e.modifiedName,
              e.image.fileList[0].originFileObj,
            );
          }),
        );
      }
      // FOR DOCS UPLOADS

      const normalizedDocs = [];
      if (docList.length > 0) {
        docList.forEach((e) =>
          normalizedDocs.push({
            ...e,
            modifiedName: uniquiFileName(e?.originFileObj.name),
          }),
        );
        await Promise.all(
          normalizedDocs.map(async (e, index) => {
            await getSingleUpload(e.modifiedName, e.originFileObj);
          }),
        );
      }

      singleImagKeyLocal.forEach((e) => {
        const mediaObj = {
          url: "http://s3LikeURL.aws.amazon.com",
          content_type: "image",
          mime_type: e.originFileObj.name.split(".")[1],
          content_purpose: "profile_photo",
          object_key: e.modifiedName,
        };
        mediaArray.push(mediaObj);
      });
      norMalizedMutiImage.forEach((e) => {
        const mediaObj = {
          url: "http://s3LikeURL.aws.amazon.com",
          content_type: "image",
          mime_type: e.originFileObj.name.split(".")[1],
          content_purpose: "property_photo",
          object_key: e.modifiedName,
        };
        mediaArray.push(mediaObj);
      });

      if (val.type === 1) {
        norMalizedMutiUnit.forEach((e) => {
          var mediaDetail = [
            {
              url: "http://s3LikeURL.aws.amazon.com",
              content_type: "image",
              mime_type: e.image.fileList[0].originFileObj.name.split(".")[1],
              content_purpose: "profile_photo",
              object_key: e.modifiedName,
            },
          ];
          multiUnits.push({
            name: e.name,
            size: e.size,
            number_of_baths: e.baths.value,
            number_of_beds: e.beds.value,
            media: mediaDetail,
            usage_type_id: val.usage_type_id.value,
            unit_type_id: val.unit_type_id.value,
            unit_subtype_id: e.unit_subtype_id.value,
          });
        });
      }

      if (docList.length > 0) {
        normalizedDocs.forEach((e) => {
          const mediaObj = {
            url: "http://s3LikeURL.aws.amazon.com",
            content_type: "document_object",
            mime_type: e.originFileObj.name.split(".")[1],
            content_purpose: "document",
            object_key: e.modifiedName,
          };
          mediaArray.push(mediaObj);
        });
      }
    } else {
      message.error("Please upload Property Profile Pic, Gallery Pics");
      dispatch(activateSpinner(false));
      return;
    }

    const featuresArray = [];
    const amenitiesArray = [];

    for (let i of subFeatures) {
      const relatedSubFeatures = valuedSubFeature.filter(
        (e) => e.lable === i.labelName,
      );
      if (relatedSubFeatures.length) {
        for (let j of relatedSubFeatures) {
          const featuresObject = {
            component_label: i.labelName,
            component_value: j.subId,
            value_type_meta_id: i.valueTypeId,
            feature_classification: "property_features",
          };
          featuresArray.push(featuresObject);
        }
      } else {
        const featuresObject = {
          component_label: i.labelName,
          component_value: true,
          value_type_meta_id: i.valueTypeId,
          feature_classification: "property_features",
        };
        featuresArray.push(featuresObject);
      }
    }
    for (let i of subAmenities) {
      const relatedAmenities = valuedSubAmeninties.filter(
        (e) => e.lable === i.labelName,
      );
      if (relatedAmenities.length) {
        for (let j of relatedAmenities) {
          const amenitiesObject = {
            component_label: i.labelName,
            component_value: j.subId,
            value_type_meta_id: i.valueTypeId,
            feature_classification: "property_amenities",
          };
          amenitiesArray.push(amenitiesObject);
        }
      } else {
        const amenitiesObject = {
          component_label: i.labelName,
          component_value: true,
          value_type_meta_id: i.valueTypeId,
          feature_classification: "property_amenities",
        };
        amenitiesArray.push(amenitiesObject);
      }
    }

    let builtinDate = null;
    let bed = null;
    let bath = null;

    // let subType = 1;
    // if (val.unit_subtype_id) {
    //   if (val.unit_subtype_id.value != 0) {
    //     subType = val.unit_subtype_id.value;
    //   }
    // }

    if (val.number_of_baths) {
      bath = val.number_of_baths.value;
    }
    if (val.number_of_beds) {
      bed = val.number_of_beds.value;
    }

    if (val.builtIn != "") {
      builtinDate = moment(val.builtIn).format("YYYY-MM-DD");
    }

    let postJson = {
      name: val.name,
      built_in: builtinDate,
      asset_type_id: val.type,
      available_from: moment(val.available_from).format("YYYY-MM-DD"),
      description: val.description,
      street_address: val.street_address,
      neighborhood: val.neighborhood,
      zipcode: val.zipcode,
      region_id: val.region.value,
      city_id: val.city.value,
      country_id: val.country.value,
      usage_type_id: val.usage_type_id.value,
      unit_type_id: val.unit_type_id.value,
      unit_subtype_id: val.unit_subtype_id?.value || 1,
      size: val.size,
      number_of_baths: +bath,
      number_of_beds: +bed,
      is_parking_available: val.is_parking_available.value === "true",
      is_360tour_available: val.is_360tour_available.value === "true",
      property_features: [...featuresArray, ...amenitiesArray],
      media: mediaArray,
      latitude: val.latitude.toString(),
      longitude: val.longitude.toString(),
      location: val.location.value,
      sub_location: val.sub_location.value,
    };

    setUid(val.usage_type_id.value);

    if (val.type === 1) {
      postJson["units"] = multiUnits;
    }

    console.log("payload", postJson, multiUnits);
    const url = `${propertyService}/property-landlord`;
    try {
      const response = await axios.post(url, postJson);
      window.localStorage.setItem(
        "createdProperty",
        JSON.stringify(response.data.result),
      );
      setCurrentStep(currentStep + 1);
      dispatch(activateSpinner(false));
    } catch (e) {
      dispatch(activateSpinner(false));
      const { response } = e;
      message.error(response?.data?.message);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        name="bookingform"
        onFinish={handleSubmit(onSubmit)}
        scrollToFirstError
      >
        <Collapse
          defaultActiveKey={["1", "2", "3", "4"]}
          expandIconPosition="right"
          ghost
          className="ag-collapse"
        >
          <Panel header="1. General Information" key="1">
            <General control={control} errors={errors} noEdit={false} />
            <Row gutter={24}>
              <Address
                control={control}
                errors={errors}
                setValue={setValue}
                noEdit={false}
              />
              <Col span={24}>
                <InputRadio
                  isRequired={false}
                  label=""
                  fieldname="type"
                  noStyle={true}
                  control={control}
                  initValue={2}
                  options={
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Radio value={2}>
                          Single Unit
                          <span className="ag-tooltip-icon">
                            <Tooltip title="Single Unit" placement="right">
                              <QuestionCircleFilled />
                            </Tooltip>
                          </span>
                        </Radio>
                      </Col>
                      <Col span={24}>
                        <Radio value={1}>
                          Multi Unit
                          <span className="ag-tooltip-icon">
                            <Tooltip title="Multi Unit" placement="right">
                              <QuestionCircleFilled />
                            </Tooltip>
                          </span>
                        </Radio>
                      </Col>
                    </Row>
                  }
                />
              </Col>
            </Row>
          </Panel>

          <Panel header="2. Property Details" key="2">
            <Row gutter={24}>
              <MoreDetails
                control={control}
                errors={errors}
                setValue={setValue}
                noEdit={false}
                getValues={getValues}
                watch={watch}
                hasUnit={pType === 1 ? false : true}
                hasComm={comm}
              />
            </Row>
          </Panel>

          {pType === 1 && (
            <Panel header="3. Unit Details" key="3">
              <AddUnit setValue={setValue} control={control} errors={errors} />
            </Panel>
          )}
          <Panel
            header={`${
              pType === 1 ? "4" : "3"
            }. Add Features/Amenities (Optional)`}
            key={pType === 1 ? "4" : "3"}
          >
            {/* <FeaturesAmenities
              control={control}
              noEdit={false}
              hasUnit={pType === 1 ? true : false}
            /> */}

            <Row gutter={24}>
              <Col span={24}>
                <Space direction="vertical" size={14} style={{ width: "100%" }}>
                  <Text>
                    Select Add Features (You can select more than one):
                  </Text>
                  <Form.Item name="features">
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Select Features"
                      onChange={addFeatureTag}
                    >
                      {featureTags.map((item) => (
                        <Option
                          key={item.labelId}
                          value={`${item.labelName}-${item.labelId}`}
                        >
                          {item.labelName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Space>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="link"
                    className="text-left ag-bold p-0"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setTrigger(1);
                      setVisible(true);
                    }}
                  >
                    Add Features
                  </Button>
                </Form.Item>
              </Col>
              {subFeatures.map((e, i) => {
                return (
                  <Col span={6} key={i}>
                    {e?.values?.length ? (
                      <Form.Item name={e.labelName} label={e.labelName}>
                        <Select
                          placeholder="Select one"
                          onSelect={(sel) => {
                            setValuedSubFeature([
                              ...valuedSubFeature,
                              { lable: e.labelName, subId: sel },
                            ]);
                          }}
                        >
                          {e?.values?.map((sub, i) => (
                            <Option key={i} value={sub.id}>
                              {sub.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : null}
                  </Col>
                );
              })}
              <Col span={24}>
                <Space direction="vertical" size={14} style={{ width: "100%" }}>
                  <Text>Add Amenities (You can select more than one):</Text>
                  <Form.Item name="property_amenities">
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Select Amenities"
                      onChange={addAmenityTag}
                    >
                      {amenitiesTags.map((item) => (
                        <Option
                          key={item.labelId}
                          value={`${item.labelName}-${item.labelId}`}
                        >
                          {item.labelName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Space>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="link"
                    className="ag-bold p-0"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setTrigger(0);
                      setVisible(true);
                    }}
                  >
                    Add Amenities
                  </Button>
                </Form.Item>
              </Col>
              {subAmenities.map((e, i) => {
                return (
                  <Col span={6} key={i}>
                    {e?.values?.length ? (
                      <Form.Item name={e.labelName} label={e.labelName}>
                        <Select
                          placeholder="Select one"
                          onSelect={(sel) => {
                            setValuedSubAmeninties([
                              ...valuedSubAmeninties,
                              { lable: e.labelName, subId: sel },
                            ]);
                          }}
                        >
                          {e?.values?.map((sub, i) => (
                            <Option key={i} value={sub.id}>
                              {sub.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : null}
                  </Col>
                );
              })}
            </Row>
          </Panel>
        </Collapse>

        <div className="upload_docs">
          <Dragger {...draggerOptions} customRequest={dummyRequest}>
            <Space direction="vertical" size={12}>
              <Image
                src={CloudUpload}
                preview={false}
                alt="Upload supporting documents"
              />
              <Text strong>Upload Supporting Documents</Text>
            </Space>
          </Dragger>
        </div>
        <div className="steps-action py-3">
          <Button
            type="primary"
            // onClick={() => setCurrentStep(currentStep + 1)}
            htmlType="submit"
          >
            Next
          </Button>
        </div>
      </Form>
      <Popup {...popupProps} />
    </>
  );
};
