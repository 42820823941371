import React from "react";
import { Form, Row, Col, Button } from "antd";
import { TextAreaField } from "../../../atoms/FormElement";
import { useForm } from "react-hook-form";

export default (props) => {
  const { onClose, onReject } = props;
  const { control, handleSubmit, errors } = useForm();

  const handleReject = (data) => {
    onReject(data.rejectReason);
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(handleReject)}>
      <Row gutter={24}>
        <Col span={24}>
          <TextAreaField
            isRequired={true}
            label="Enter a comment for rejection:"
            fieldname="rejectReason"
            iProps={{ placeholder: "Some reason...." }}
            initValue=""
            control={control}
            rules={{ required: "Reason required" }}
            validate={errors.rejectReason && "error"}
            validMessage={errors.rejectReason && errors.rejectReason.message}
          />
        </Col>
      </Row>
      <Row gutter={24} justify="space-between">
        <Col>
          <Button size="large" onClick={onClose}>
            Back
          </Button>
        </Col>
        <Col>
          <Button size="large" className="ag-red-button" htmlType="submit">
            Reject
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
