import React, { useState } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { InputField } from "../../../atoms/FormElement";
import { createEjariContractID } from "../ducks/services";

const defaultValues: any = {
  leaseId: "",
  ejariContractNumber: "",
};

const { Paragraph } = Typography;

export default (props) => {
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const { control, errors, reset, handleSubmit } = useForm({ defaultValues });

  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);
    console.log(values);
    const postJson = {
      leaseId: props.lease,
      ejariContractNumber: values.ejariContractNumber,
    };

    createEjariContractID(postJson)
      .then((res) => {
        setTimeout(() => {
          reset();
          setLoader(false);
          message.success("Ejari Contract Number Added Successfully");
          setSubmited(false);
          props.onSubmit();
        }, 3000);
      })
      .catch((e) => {
        setLoader(false);
        setisError(true);
        const { response } = e;
        setErrorMessage(response?.data?.message);
        console.log("check", response);
      });
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
              {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={24}>
              <InputField
                isRequired={true}
                fieldname="ejariContractNumber"
                label="Ejari Contract Number"
                control={control}
                iProps={{ placeholder: "Enter Number" }}
                rules={{
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Value should be a Number",
                  },
                  minLength: { value: 16, message: "Minimum 16 digits" },
                  maxLength: { value: 20, message: "Maximum 20 digits" },
                  required: "Please enter valid number",
                }}
                initValue=""
                validate={errors.ejariContractNumber && "error"}
                validMessage={
                  errors.ejariContractNumber &&
                  errors.ejariContractNumber.message
                }
              />
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-gray-button-outline"
                htmlType="button"
                onClick={props.onCancel}
              >
                Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                Add
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};
