import React, { useState, useEffect } from "react";
import { Card, Space, Typography, Image, List, Button, message } from "antd";
import { LocationIcon } from "../../../../atoms/Icons";
import { useHistory } from "react-router-dom";
import { getPresignedImage } from "../../AddProperty/ducks/actions/addInfoAction";
import { propertyService } from "../../../../../configs/constants";
import axios from "axios";
import * as status_types from "../../../../atoms/PropertyCard/constants";
import { PopupConfirm } from "../../../../atoms/Popup";
import Logo from "../../../../../assets/img/ag.png";

const { Title, Text } = Typography;

export const GetImageUrl = ({ data }) => {
  const [imgurl, setImgurl] = useState("");

  const geturl = async (item) => {
    if (item.image && item.image.length > 0) {
      let i = await getPresignedImage(item.image[0].data);
      i && setImgurl(i);
    }
  };

  useEffect(() => {
    data && geturl(data);
  }, [data]);

  return (
    <Image
      src={imgurl ? imgurl : Logo}
      placeholder={true}
      preview={false}
      width={164}
      height={115}
      fallback={Logo}
    />
  );
};

export default ({ propertyDetails, units, updated }) => {
  const { asset_id, name, property_info } = propertyDetails;
  const history = useHistory();

  const cloneUnit = async (unit, name) => {
    const cloneJson = {
      unit_id: unit,
      asset_id,
      name: name + " Clone",
    };
    let url = `${propertyService}/property-landlord/clone-unit`;
    try {
      await axios.post(url, cloneJson);
      message.success("Unit Succcessfully Cloned");
      updated();
    } catch (e) {
      const { response } = e;
      message.error(response.data.message);
    }
  };

  const Statuses1 = (status) => {
    switch (status) {
      case status_types.PROPERTY_DISABLED:
        return false;
      case status_types.PROPERTY_DRAFT:
        return false;
      case status_types.PROPERTY_RENTED:
        return true;
      case status_types.PROPERTY_PUBLISHED:
        return true;
      case status_types.PROPERTY_RESERVED:
        return true;
    }
  };

  const Statuses = (stat) => {
    switch (stat) {
      case status_types.PROPERTY_DISABLED:
        return "Disabled";
      case status_types.PROPERTY_DRAFT:
        return "Draft";
      case status_types.PROPERTY_RENTED:
        return "Occupied";
      case status_types.PROPERTY_PUBLISHED:
        return "Vacant";
      case status_types.PROPERTY_RESERVED:
        return "Reserved";
    }
  };

  const onStatus = (id, status) => {
    PopupConfirm({
      title: `Are you sure you want to ${
        status === "disabled" ? "unpublish" : "publish"
      } this property?`,
      content: "This will change the property status",
      onOk: () => onStatusChanged(id, status),
      okText: "Confirm",
    });
  };

  const onStatusChanged = async (id, status) => {
    const url = `${propertyService}/property-landlord/change-property-status/${id}`;
    try {
      const res = await axios.put(url, { property_status: status });
      message.success(res?.data?.message || "Status Updated");
      updated();
    } catch (e) {
      const { response } = e;
      message.error(response.data.message);
    }
  };

  return (
    <Card bordered={false} bodyStyle={{ padding: "32px 24px" }}>
      <List
        bordered={false}
        className="unit_list"
        itemLayout="horizontal"
        dataSource={units}
        renderItem={(item: any) => (
          <List.Item
            className="no-border"
            actions={[
              <Space direction="vertical" size={4} className="text-right">
                <Button
                  type="link"
                  className={`${
                    Statuses1(item.status) === true ? "ag-error" : "ag-success"
                  } p-0 fontWeight700`}
                  onClick={() =>
                    onStatus(
                      item.unit_id,
                      Statuses1(item.status) ? "disabled" : "published",
                    )
                  }
                >
                  {Statuses1(item.status) ? "Unpublish" : "Publish"} Unit
                </Button>
                <Button
                  type="link"
                  className="ag-success p-0 fontWeight700"
                  onClick={() => {
                    cloneUnit(item.unit_id, item.unit_name);
                  }}
                >
                  + Clone This Unit
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    history.push({
                      pathname: `/property-detail/${asset_id}/unit/${item.unit_id}`,
                      state: { parentname: name },
                    })
                  }
                >
                  View Unit
                </Button>
              </Space>,
            ]}
          >
            <List.Item.Meta
              style={{ alignItems: "center" }}
              avatar={
                <Space style={{ position: "relative" }}>
                  <GetImageUrl data={item.media} />
                  <Text
                    className={`statusUnit ${
                      item.status === "disabled" ? "inactive" : ""
                    } ${item.status === "rented" ? "inactive" : ""} ${
                      item.status === "reserved" ? "inactive" : ""
                    }`}
                  >
                    {Statuses(item.status)}
                  </Text>
                </Space>
              }
              description={
                <Space size={0} direction="vertical">
                  <Text className="ag-primary fontWeight700">
                    {item.unit_name}
                  </Text>
                  <Text className="smallFont12">{name}</Text>
                  <Space size={4}>
                    <LocationIcon className="ag-default" />
                    <Text className="smallFont12 ag-primary">
                      {property_info[0]?.city?.name}
                    </Text>
                  </Space>
                  <Text className="smallFont12">
                    {item.number_of_beds} Bed - {item.number_of_baths} Bath -{" "}
                    {item.area} Sq.ft
                  </Text>
                </Space>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};
