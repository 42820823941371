import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  Typography,
  Space,
  Select,
  Form,
  Tooltip,
  Button,
  message,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getTemplates } from "../../AddProperty/ducks/actions/tcAction";
import axios from "../../../../../utils/axiosInceptor";
import { propertyService } from "../../../../../configs/constants";

const { Text, Title, Paragraph } = Typography;
const { Option } = Select;

const html = "<p>Terms And Coditions</p>";

export default ({ propertyDetails, updated }) => {
  const { asset_id, electronic_text } = propertyDetails;
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorData, setEditorData] = useState("");
  const [templates, setTemplates] = useState([]);
  const templatesData = useSelector(
    (state: any) => state.tcReducer.tcTemplates,
  );

  const onEditorStateChange = (editorState) => {
    const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorData(data);
    setEditorState(editorState);
  };

  const settingEditor = (content) => {
    const state = EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(content).contentBlocks),
    );
    const stateData = draftToHtml(convertToRaw(state.getCurrentContent()));
    setEditorState(state);
    setEditorData(stateData);
  };

  const setText = (id) => {
    const content = templatesData.find((item) => item.id === id).content;
    settingEditor(content);
  };

  useEffect(() => {
    settingEditor(html);
    dispatch(getTemplates());
  }, []);

  useEffect(() => {
    if (templatesData.length > 0) {
      setTemplates(templatesData);
    }
  }, [templatesData]);

  useEffect(() => {
    if (electronic_text) {
      settingEditor(electronic_text[0]?.data);
    }
  }, [electronic_text]);

  const onSubmit = async (type) => {
    let url = "";
    let postJson = {};
    if (type === "add") {
      url = `${propertyService}/property-landlord/terms-and-conditions`;
      postJson = {
        property_id: asset_id,
        text: editorData,
      };
    } else {
      url = `${propertyService}/property-landlord/terms-and-conditions/${electronic_text[0].id}`;
      postJson = {
        text: editorData,
      };
    }

    try {
      if (type === "add") {
        await axios.post(url, postJson);
      } else {
        await axios.put(url, postJson);
      }
      message.success("Terms & Coditions Updated Successfully");
      updated();
    } catch (e) {
      message.error("Something went wrong");
      console.log("EEE", e);
    }
  };

  return (
    <Form layout="vertical" name="addInformation">
      <Card
        bordered={false}
        bodyStyle={{ padding: "32px 32px" }}
        className="h-auto"
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space direction="vertical" size={16} className="w-100">
              <Row gutter={24}>
                <Col flex="auto">
                  <Title level={3} className="mb-0">
                    Terms & Conditions
                  </Title>
                </Col>
                <Col flex="150px">
                  {!electronic_text ? (
                    <Button
                      type="primary"
                      className="mt-2 mb-2 mx-2"
                      onClick={() => onSubmit("add")}
                    >
                      Add Terms & Coditions
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      className="mt-2 mb-2 mx-2"
                      onClick={() => onSubmit("update")}
                    >
                      Update
                    </Button>
                  )}
                </Col>
              </Row>

              <Text className="defaultFont ag-secondary">
                You can select one from the drop down menu below.
              </Text>
            </Space>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={8}>
                <Form.Item
                  // name="template_name"
                  label={
                    <span>
                      Choose an existing template&nbsp;
                      <Tooltip title="What template you select?">
                        <QuestionCircleOutlined className="defaultFont" />
                      </Tooltip>
                    </span>
                  }
                >
                  <Select
                    placeholder="Terms and conditions template"
                    onSelect={(e) => setText(e)}
                  >
                    {templates.map((e, i) => (
                      <Option key={i} value={e.id}>
                        {e.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={16}></Col>
              <Col flex="auto">
                <Paragraph className="ag-primary">Preview:</Paragraph>
              </Col>
              <Col flex="135px">
                <Paragraph className="text-right ag-default">
                  Edit this template
                </Paragraph>
              </Col>
              <Col span={24}>
                <Editor
                  editorState={editorState}
                  wrapperClassName="editor-main"
                  editorClassName="editor-body"
                  onEditorStateChange={onEditorStateChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};
