import React, { useEffect } from "react";
import { Row, Col, Form, Typography, Upload, Button, Input } from "antd";
import { bathList, bedroomCategory } from "../../../ducks/constants";
import { Controller, useFieldArray } from "react-hook-form";
import { dummyRequest } from "../../../utils/utility";
import { InputField, ReactSelectField } from "../../../../../atoms/FormElement";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

var _ = require("lodash");
const { Title } = Typography;
const initialUnit = {
  name: "",
  beds: "",
  baths: "",
  unit_subtype_id: "",
  size: "",
  image: [],
};

export default (props) => {
  const { control, errors, setValue } = props;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "unit",
  });

  const apiUnitSubTypeData = useSelector(
    (state: any) => state.addInfoReducer.getUnitSubType,
  );

  useEffect(() => {
    append(initialUnit);
  }, []);

  return (
    <>
      {fields.map((item, index) => (
        <React.Fragment key={item.id}>
          <Row gutter={24} style={{ position: "relative" }}>
            <Col span={24}>
              <Title level={5}>Unit #{index + 1}</Title>
            </Col>
            <Col span={6}>
              <InputField
                isRequired={true}
                label="Unit Name"
                fieldname={`unit[${index}].name`}
                control={control}
                initValue=""
                iProps={{ placeholder: "Enter Unit Name" }}
                rules={{ required: "Please enter unit name" }}
                validate={
                  Object.entries(errors).length > 0 &&
                  errors?.unit?.length > 0 &&
                  errors?.unit[index]?.name &&
                  "error"
                }
                validMessage={
                  Object.entries(errors).length > 0 &&
                  errors?.unit?.length > 0 &&
                  errors?.unit[index]?.name &&
                  errors?.unit[index]?.name?.message
                }
              />
            </Col>
            <Col span={6}>
              <ReactSelectField
                isRequired={true}
                label="Unit Sub Type"
                fieldname={`unit[${index}].unit_subtype_id`}
                control={control}
                initValue=""
                iProps={{ placeholder: "Select one" }}
                selectOption={_.map(apiUnitSubTypeData, (item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                rules={{ required: "Please enter Unit Sub Type" }}
                validate={
                  Object.entries(errors).length > 0 &&
                  errors?.unit?.length > 0 &&
                  errors.unit[index]?.unit_subtype_id &&
                  "error"
                }
                validMessage={
                  Object.entries(errors).length > 0 &&
                  errors?.unit?.length > 0 &&
                  errors.unit[index]?.unit_subtype_id &&
                  errors.unit[index]?.unit_subtype_id.message
                }
                onChange={(e) => {
                  setValue(`unit[${index}].beds`, {
                    label: e.label,
                    value: e.label == "Studio" ? 0 : Number(e.label.charAt(0)),
                  });
                }}
              />
            </Col>

            <Col span={6}>
              <ReactSelectField
                isRequired={true}
                label="Bed Rooms"
                fieldname={`unit[${index}].beds`}
                control={control}
                initValue=""
                iProps={{ placeholder: "Select one", isDisabled: true }}
                selectOption={bedroomCategory}
                rules={{ required: "Please enter Bedroom" }}
                validate={
                  Object.entries(errors).length > 0 &&
                  errors?.unit?.length > 0 &&
                  errors.unit[index]?.beds &&
                  "error"
                }
                validMessage={
                  Object.entries(errors).length > 0 &&
                  errors?.unit?.length > 0 &&
                  errors.unit[index]?.beds &&
                  errors.unit[index]?.beds.message
                }
              />
            </Col>
            <Col span={6}>
              <ReactSelectField
                isRequired={true}
                label="Bath Rooms"
                fieldname={`unit[${index}].baths`}
                control={control}
                initValue=""
                iProps={{ placeholder: "Select one" }}
                selectOption={bathList}
                rules={{ required: "Please enter Bathroom" }}
                validate={
                  Object.entries(errors).length > 0 &&
                  errors?.unit?.length > 0 &&
                  errors.unit[index]?.baths &&
                  "error"
                }
                validMessage={
                  Object.entries(errors).length > 0 &&
                  errors?.unit?.length > 0 &&
                  errors.unit[index]?.baths &&
                  errors.unit[index]?.baths.message
                }
              />
            </Col>
            <Col span={6}>
              <InputField
                isRequired={true}
                label="Size, Sq.ft"
                fieldname={`unit[${index}].size`}
                control={control}
                initValue=""
                iProps={{
                  placeholder: "Enter Size",
                  type: "number",
                }}
                rules={{
                  required: "Please enter Size",
                  pattern: {
                    value: /^(?!\.?$)\d{0,9}(\.\d{0,2})?$/,
                    message:
                      "Value should be a Number and not greater than 2 decimal places",
                  },
                }}
                validate={
                  Object.entries(errors).length > 0 &&
                  errors?.unit?.length > 0 &&
                  errors.unit[index]?.size &&
                  "error"
                }
                validMessage={
                  Object.entries(errors).length > 0 &&
                  errors?.unit?.length > 0 &&
                  errors.unit[index]?.size &&
                  errors.unit[index]?.size.message
                }
              />
            </Col>
            <Col span={12}>
              <Form.Item
                required={true}
                label="Unit Image"
                validateStatus={
                  Object.entries(errors).length > 0 &&
                  errors?.unit?.length > 0 &&
                  errors.unit[index]?.image &&
                  "error"
                }
                help={
                  Object.entries(errors).length > 0 &&
                  errors?.unit?.length > 0 &&
                  errors.unit[index]?.image &&
                  errors.unit[index]?.image.message
                }
              >
                <Controller
                  name={`unit[${index}].image`}
                  control={control}
                  // rules={{ required: "Should accept agreement" }}
                  defaultValue=""
                  render={({ value, onChange }) => (
                    <Upload
                      className="uploadWithbtn"
                      showUploadList={false}
                      accept="image/*"
                      maxCount={1}
                      customRequest={dummyRequest}
                      onChange={(e) => onChange(e)}
                    >
                      <Input
                        size="middle"
                        className="ag-upload-btn"
                        value={value ? value.fileList[0].name : "Choose file"}
                        addonAfter="Browse"
                      />
                    </Upload>
                  )}
                />
              </Form.Item>
            </Col>
            {index != 0 && (
              <>
                <Button
                  type="link"
                  className="ag-bold p-0 removebtn"
                  icon={<MinusCircleOutlined />}
                  onClick={() => remove(index)}
                />
              </>
            )}
          </Row>
        </React.Fragment>
      ))}
      <Form.Item>
        <Button
          type="link"
          className="ag-bold p-0"
          icon={<PlusOutlined />}
          onClick={() => append(initialUnit)}
        >
          Add Unit
        </Button>
      </Form.Item>
    </>
  );
};
