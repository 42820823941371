import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../molecules/DashboardLayout";
import { Row, Col, Pagination, Card, Button, Input, Form, Space } from "antd";
import SectionHeader from "../../../molecules/SectionHeader";
import Grid from "../../../atoms/Grid";
import { useDispatch, useSelector } from "react-redux";
import { getPDC } from "../ducks/actions";
import { SearchOutlined } from "@ant-design/icons";

const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 200,
};

const limit = 10;

export default (props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const PDCApi = useSelector((state: any) => state.contracts.pdcList);
  const [searching, setSearchText] = useState("");

  const indexRenderer = function (params) {
    console.log("pagi", page, params.node.id);
    return parseInt(params.node.id) + page * limit + 1;
  };

  const pdcCol = [
    // {
    //   headerName: "#",
    //   cellRenderer: indexRenderer,
    //   maxWidth: 80,
    //   filter: false,
    // },
    {
      headerName: "Receipt Date",
      field: "item_start_date",
      maxWidth: 160,
    },
    {
      headerName: "Payment Type",
      field: "payment_type",
      maxWidth: 160,
    },
    {
      headerName: "Due Date",
      field: "item_end_date",
      maxWidth: 160,
    },
    {
      headerName: "Unit",
      field: "lease_location",
    },
    {
      headerName: "Process Through",
      field: "process_through",
      cellStyle: (params) => {
        return { textTransform: "Capitalize" };
      },
    },
    {
      headerName: "PDC Cheque No.",
      field: "cheque_number",
    },

    {
      headerName: "Amount",
      field: "amount",
      maxWidth: 120,
      valueFormatter: (params) =>
        params.data.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      headerName: "Enable",
      field: "enable",
      maxWidth: 120,
      cellStyle: (params) => {
        return { textTransform: "Capitalize" };
      },
    },
    {
      headerName: "Paid Status",
      field: "status",
      maxWidth: 160,
      valueFormatter: (params) =>
        params.data.status == "1" ? "True" : "False",
    },
  ];

  useEffect(() => {
    dispatch(getPDC(limit, page, searching));
  }, []);

  const handlePagination = (pg) => {
    setPage(pg);
    dispatch(getPDC(limit, pg, searching));
  };

  const onSearch = (val) => {
    setPage(0);
    if (val.searchText) {
      setSearchText(val.searchText);
      dispatch(getPDC(limit, 0, val.searchText));
    } else {
      setSearchText("");
      dispatch(getPDC(limit, 0, ""));
    }
  };

  const SearchFelds = () => {
    return (
      <Form onFinish={onSearch}>
        <Space>
          <Form.Item
            name="searchText"
            className="mb-0"
            initialValue={searching}
          >
            <Input placeholder="By Unit" value={searching} />
          </Form.Item>
          <Form.Item className="mb-0">
            <Button
              icon={<SearchOutlined />}
              className="ag-gray-button"
              type="link"
              htmlType="submit"
            />
          </Form.Item>
        </Space>
      </Form>
    );
  };

  return (
    <DashboardLayout>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <SectionHeader heading="Post-Dated Cheques" noBorder={true} />
            </Col>
            <Col span={24}>
              <Pagination
                className="ag-property-text-pagination"
                total={PDCApi[0]?.total_count}
                showSizeChanger={false}
                showTotal={(total, range) =>
                  `Showing ${range[0]}-${range[1]}-${total} Cheques`
                }
                defaultPageSize={10}
                defaultCurrent={1}
                current={page + 1}
                onChange={(e) => handlePagination(e - 1)}
              />
            </Col>
            <Col span={24}>
              <Row gutter={[24, 24]} justify="end">
                <Col span={24}>
                  <Card
                    bordered={false}
                    className="ag-nospace-body"
                    extra={<SearchFelds />}
                  >
                    <Grid
                      data={PDCApi}
                      columns={pdcCol}
                      pagination={false}
                      defaultSettings={gridProps}
                      noRowlabel="Cheques"
                      fullPage={true}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </DashboardLayout>
  );
};
