import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Pagination, Card, message } from "antd";
import DashboardLayout from "../../../molecules/DashboardLayout";
import SectionHeader from "../../../molecules/SectionHeader";
import BreadCrumb from "../../../atoms/BreadCrumb";
import ReviewChip from "../../../atoms/ReviewChip";
import { getReviewsByProperty } from "../ducks/actions";
import { removeReview } from "../ducks/services";
import { useLocation } from "react-router-dom";
import reviewIcon from "../../../../assets/img/review.png";

const list = {
  search: "",
  page: 0,
  limit: 4,
};

const PropertyReview = () => {
  const dispatch = useDispatch();
  const [reviewParams, setReviewParams] = useState(list);
  const reviews = useSelector((state: any) => state.reviews.reviewList);

  const location = useLocation();
  const { id, propertyName } = location.state;

  const breadcrumbList: any = [
    {
      label: "Reviews",
      route: "/reviews",
    },
    {
      label: propertyName,
      route: "/",
    },
  ];

  const onDelete = (id) => {
    removeReview(id)
      .then((res) => {
        message.success("Review Deleted successfully");
        fetchPropertyReviews();
      })
      .catch((e) => {
        const { response } = e;
        console.log("error", response);
        message.error(response.data.result);
      });
  };

  const fetchPropertyReviews = () => {
    dispatch(
      getReviewsByProperty(
        id,
        reviewParams.search,
        reviewParams.page,
        reviewParams.limit,
      ),
    );
  };

  useEffect(() => {
    fetchPropertyReviews();
  }, [reviewParams]);

  const heading = propertyName;
  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BreadCrumb list={breadcrumbList} />
        </Col>
        <Col span={24}>
          <SectionHeader
            heading={heading}
            total={(reviews && reviews.length) || 0}
            search={(e) =>
              setReviewParams({
                ...reviewParams,
                search: e.target.value,
                page: 0,
              })
            }
          />
        </Col>
        <Col span={24}>
          <Pagination
            className="ag-property-text-pagination"
            total={
              reviews.length > 0 && reviews[0].full_count
                ? reviews[0].full_count
                : 0
            }
            showTotal={(total, range) =>
              `Showing ${range[0]}-${range[1]} Reviews`
            }
            defaultPageSize={4}
            defaultCurrent={1}
            current={reviewParams.page + 1}
            onChange={(e) => setReviewParams({ ...reviewParams, page: e - 1 })}
          />
        </Col>
        {reviews && reviews.length > 0 ? (
          reviews.map((item, index) => (
            <Col span={24} key={index}>
              <ReviewChip data={item} deleteReview={onDelete} />
            </Col>
          ))
        ) : (
          <Card
            className="noDataCard"
            style={{ width: "100%" }}
            cover={
              <img alt="review" style={{ width: "auto" }} src={reviewIcon} />
            }
          >
            There are currently no <br />
            reviews for this property.
          </Card>
        )}
      </Row>
    </DashboardLayout>
  );
};

export default PropertyReview;
