import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import {
  Row,
  Col,
  Button,
  Typography,
  Form,
  Collapse,
  message,
  Card,
} from "antd";
import { baseContractService } from "../../../configs/constants";
import TenantDetails from "./components/TenantDetails";
import axios from "../../../utils/axiosInceptor";
import { Popup } from "../../atoms/Popup";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  getParkingSlots,
  getLeaseFormDetails,
  getLeaseAdditionalDocumentList,
  emptyLead,
  getPaymentModes,
} from "./ducks/actions";
import UnitDetails from "./components/UnitDetails";
import LeaseTerms from "./components/LeaseTerms";
import {
  uniquiFileName,
  getContractSingleUpload,
  getSingleUpload,
} from "../../../utils/fileUpload";
import BookingTerms from "./components/BookingTerms";
import BillingSchedule from "./components/BillingSchedule";
import DocumentsList from "./components/DocumentsList";
import AdditionalDocument from "./components/AdditionalDocs";
import ApprovalHistory from "./components/ApprovalHistory";
import Rejectform from "./components/RejectFrom";
import PaymentSchedule from "./components/PaymentSchedule";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getPaymentConfig } from "../Properties/AddProperty/ducks/actions/paymentAction";

const { Title } = Typography;
const { Panel } = Collapse;

const fileObjectinit = {
  passportFile: {
    uniqueName: "",
    fileData: "",
  },
  visaFile: {
    uniqueName: "",
    fileData: "",
  },
  emiratesFile: {
    uniqueName: "",
    fileData: "",
  },
  financialSupportFile: {
    uniqueName: "",
    fileData: "",
  },
  signedBookFile: {
    uniqueName: "",
    fileData: "",
  },
  otherDocsFile: {
    uniqueName: "",
    fileData: "",
  },
};

export default (props) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    errors,
    setValue,
    getValues,
    reset,
    register,
  } = useForm();
  const { leadId } = useParams();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [isClearTriggered, setIsClearTriggered] = useState(false);
  const [visible, setVisible] = useState(false);
  const [disableAll, setDisableAll] = useState(false);
  const [disableApproveReject, setDisableApproveReject] = useState(false);
  const [disableLeasebtn, setDisableLeasebtn] = useState(false);
  const [deletedAdditionalDocs, setDeletedAdditionalDocs] = useState([]);
  const [disabletenant, setDisableTenant] = useState(false);
  const [finalRequiredDocuments, setFinalRequiredDocuments] = useState(
    fileObjectinit,
  );
  const [sendEmptyDoc, setSendEmptyDoc] = useState(false);

  const leaseFormData = useSelector(
    (state: any) => state.leasingForm.leaseFormData,
  );

  const parkingSlotsDataFromApi = useSelector(
    (state: any) => state.leasingForm.parkingSlots,
  );
  const loginMode = JSON.parse(window.localStorage.getItem("login-response"))
    ?.data?.role_name;

  useEffect(() => {
    dispatch(getParkingSlots(leadId));
    dispatch(getLeaseFormDetails(leadId));
    dispatch(getPaymentModes());
    dispatch(getLeaseAdditionalDocumentList());
    dispatch(getPaymentConfig());
    loginMode === "Lease_Manager" && setDisableAll(true);
    return (): any => dispatch(emptyLead());
  }, []);

  const clearData = () => {
    return (
      <Button type="link" onClick={() => setIsClearTriggered(true)}>
        Clear All Data
      </Button>
    );
  };

  useEffect(() => {
    if (leaseFormData.status) {
      if (
        leaseFormData.status === "lease_approved" ||
        leaseFormData.status === "lease_sent_for_approval" ||
        leaseFormData.status === "lead_converted" ||
        leaseFormData.status === "awaiting_tenant_cheques"
      ) {
        setDisableAll(true);
      }
      if (
        leaseFormData.status === "lease" ||
        leaseFormData.status === "lease_rejected" ||
        leaseFormData.status === "tenant_cheques_scheduled"
      ) {
        setDisableLeasebtn(true);
        setDisableTenant(true);
      }
      if (leaseFormData.status != "lease_sent_for_approval") {
        setDisableApproveReject(true);
      }
    }
  }, [leaseFormData.status]);

  const popup = [
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Reject Reason
        </Title>
      ),
      width: 650,
    },
  ];

  const popupContent = {
    title: popup[0].title,
    closable: true,
    visibility: visible,
    content: <Rejectform leadId={leadId} onClose={() => setVisible(false)} />,
    width: popup[0].width,
    onCancel: () => setVisible(false),
  };

  const sendToTenant = async () => {
    setLoading(true);
    const payload = {
      leadId: leadId,
    };
    try {
      const url = `${baseContractService}/send-tenant-payment-schedule`;
      await axios.post(url, payload);
      setLoading(false);
      message.success("Lease Form has been sent to tenant");
      setTimeout(() => {
        history.push("/lease");
      }, 1000);
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.statustext);
    }
  };

  const sendForApproval = async () => {
    setLoading(true);
    const payload = {
      leadId: leadId,
    };
    try {
      const url = `${baseContractService}/send-lease-for-approval`;
      await axios.post(url, payload);
      setLoading(false);
      message.success("Lease Form has been sent for approval");
      setTimeout(() => {
        history.push("/lease");
      }, 1000);
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.statustext);
    }
  };

  const acceptForm = async () => {
    setLoading(true);
    const payLoad = {
      leadId: leadId,
    };

    const url = `${baseContractService}/approve-lease`;

    try {
      await axios.post(url, payLoad);
      message.success(`Lease Form Approved successfully`);
      setLoading(false);
      setTimeout(() => {
        history.push("/lease");
      }, 1000);
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.data?.result);
    }
  };

  const onSubmit = async (val) => {
    setLoading(true);
    const billingChargesArray = [];

    const deletedBillingCharges_item_ids = leaseFormData?.billingCharges
      ?.map((item) => item?.itemId)
      .filter(
        (x) => val?.othercharges?.map((item) => item?.itemId).indexOf(x) === -1,
      );

    val?.othercharges?.map((item, index) => {
      if (
        item?.type?.label !== "EJARI FEE" &&
        item?.type?.label !== "SECURITY DEPOSIT"
      ) {
        const amount: any = item?.amount;
        billingChargesArray.push({
          itemId: item.itemId !== "" ? item.itemId : null,
          type: item?.type?.value,
          amount: parseFloat(amount),
          parkingSlot: item?.slot?.label,
        });
      }
    });
    const paymentsArray = [];
    val?.payment?.map((item, index) => {
      if (
        item?.type !== "EJARI FEE" &&
        item?.type !== "SECURITY DEPOSIT" &&
        item?.type !== "RENTAL COMMISSION"
      ) {
        paymentsArray.push({
          description: item?.type,
          amount: parseFloat(parseFloat(item?.amount).toFixed(2)),
          vat: parseFloat(parseFloat(item?.vat).toFixed(2)),
          totalAmount: parseFloat(parseFloat(item?.total).toFixed(2)),
          modeOfPayment: item?.mode?.label,
          documentNumber: item?.docNo,
          installmentDueDate: item?.dueDate,
          chequeDate: item?.chequeDate
            ? moment(item?.chequeDate).format("YYYY-MM-DD")
            : null,
          childs: val?.amount_parking_charges_data_childs[index]?.map(
            (item, i) => {
              const data = {
                amount: null,
                totalAmount: null,
                vat: null,
                installmentStartDate: null,
                installmentEndDate: null,
                parkingSlot: null,
                type: null,
              };
              data.amount = parseFloat(parseFloat(item?.amount).toFixed(2));
              data.totalAmount = parseFloat(parseFloat(item?.total).toFixed(2));
              data.vat = parseFloat(parseFloat(item?.vat).toFixed(2));
              data.installmentStartDate = item?.installmentStartDate;
              data.installmentEndDate = item?.installmentEndDate;
              data.type = item?.typeKey;
              data.parkingSlot = item?.parkingSlot;
              return data;
            },
          ),
        });
      } else if (item?.type === "RENTAL COMMISSION") {
        paymentsArray.push({
          description: item?.type,
          amount: parseFloat(parseFloat(item?.amount).toFixed(2)),
          vat: parseFloat(parseFloat(item?.vat).toFixed(2)),
          totalAmount: parseFloat(parseFloat(item?.total).toFixed(2)),
          modeOfPayment: item?.mode?.label,
          documentNumber: item?.docNo,
          installmentDueDate: item?.dueDate,
          chequeDate: item?.chequeDate
            ? moment(item?.chequeDate).format("YYYY-MM-DD")
            : null,
          childs: [
            {
              amount: parseFloat(parseFloat(item?.amount).toFixed(2)),
              totalAmount: parseFloat(parseFloat(item?.total).toFixed(2)),
              vat: parseFloat(parseFloat(item?.vat).toFixed(2)),
              installmentStartDate: moment(val?.commencementDate).format(
                "YYYY-MM-DD",
              ),
              installmentEndDate: moment(val?.expiryDate).format("YYYY-MM-DD"),
              type: "rental_commission",
            },
          ],
        });
      }
    });

    const paymentSchedule_totalPayment = paymentsArray
      .reduce((a, b) => {
        return parseFloat(a) + parseFloat(b.totalAmount);
      }, 0)
      ?.toFixed(2);

    const freeParkingSlotArray = [];
    val?.free?.map((item, index) => {
      freeParkingSlotArray.push(item?.slot?.value);
    });

    const additionDocFinalData = [];

    const addDocsWithUniqueFileName = val?.additionalDocs?.map((e) => {
      const uniqName = uniquiFileName(e?.file?.file?.name);
      const obj = {
        type: e?.type?.label,
        fileName: uniqName,
      };
      additionDocFinalData.push(obj);
      return { ...e, uniquiFileName: uniqName };
    });

    let dewadetail = "";
    if (val?.dewa_details && val?.dewa_details != null) {
      dewadetail = val?.dewa_details;
    }

    let rentstart = "";
    let rentend = "";

    if (
      val?.rentfreestart != null &&
      val?.rentfreestart != "" &&
      val?.rentfreeend != null &&
      val?.rentfreeend != ""
    ) {
      rentstart = moment(val?.rentfreestart).format("YYYY-MM-DD");
      rentend = moment(val?.rentfreeend).format("YYYY-MM-DD");
    }

    let documnetsArray = [];
    if (sendEmptyDoc) {
      documnetsArray = [
        {
          type: "visa",
          fileName: finalRequiredDocuments?.visaFile?.uniqueName,
        },
        {
          type: "passport",
          fileName: finalRequiredDocuments?.passportFile?.uniqueName,
        },
        {
          type: "emirates_id",
          fileName: finalRequiredDocuments?.emiratesFile?.uniqueName,
        },
        {
          type: "financial_document",
          fileName: finalRequiredDocuments?.financialSupportFile?.uniqueName,
        },
        {
          type: "signed_booking_form",
          fileName: finalRequiredDocuments?.signedBookFile?.uniqueName,
        },
        {
          type: "other_document",
          fileName: finalRequiredDocuments?.otherDocsFile?.uniqueName,
        },
      ];
    }

    const payLoad = {
      leadId: leadId,
      tenantDetails: {
        noOfOccupants: val?.number_of_occupants,
        // selectedContact: val?.contacts?.value,
      },
      unitDetails: {
        dewa: dewadetail,
      },
      leaseTerms: {
        leaseNature: val?.leaseNature?.label,
        moveInDate: moment(val?.moveInDate).format("YYYY-MM-DD"),
        freeParkingSlot: freeParkingSlotArray,
        leaseDuration: {
          commencementDate: moment(val?.commencementDate).format("YYYY-MM-DD"),
          expiryDate: moment(val?.expiryDate).format("YYYY-MM-DD"),
        },
        rentFreeDuration: {
          startDate: rentstart,
          expiryDate: rentend,
        },
      },
      bookingTerms: {
        bookingDuration: {
          startDate: moment(val?.commencementDate).format("YYYY-MM-DD"),
          expiryDate: moment(val?.expiryDate).format("YYYY-MM-DD"),
        },
        termsAndConditionEntities: val?.terms_and_conditions?.value,
        billingCharges: billingChargesArray,
        deletedBillingCharges: deletedBillingCharges_item_ids,
      },
      paymentSchedule: {
        totalAmount: parseFloat(paymentSchedule_totalPayment),
        payments: paymentsArray,
      },
      additionalDocuments: additionDocFinalData,
      deletedAdditionalDocuments: deletedAdditionalDocs,
      documents: documnetsArray,
    };

    console.log("payload", payLoad);

    try {
      // Required doc upload
      if (
        finalRequiredDocuments?.passportFile.uniqueName &&
        finalRequiredDocuments?.passportFile.fileData
      ) {
        await getSingleUpload(
          finalRequiredDocuments?.passportFile.uniqueName,
          finalRequiredDocuments?.passportFile.fileData,
        );
      }
      if (
        finalRequiredDocuments?.visaFile.uniqueName &&
        finalRequiredDocuments?.visaFile.fileData
      ) {
        await getSingleUpload(
          finalRequiredDocuments?.visaFile.uniqueName,
          finalRequiredDocuments?.visaFile.fileData,
        );
      }
      if (
        finalRequiredDocuments?.emiratesFile.uniqueName &&
        finalRequiredDocuments?.emiratesFile.fileData
      ) {
        await getSingleUpload(
          finalRequiredDocuments?.emiratesFile.uniqueName,
          finalRequiredDocuments?.emiratesFile.fileData,
        );
      }
      if (
        finalRequiredDocuments?.financialSupportFile.uniqueName &&
        finalRequiredDocuments?.financialSupportFile.fileData
      ) {
        await getSingleUpload(
          finalRequiredDocuments?.financialSupportFile.uniqueName,
          finalRequiredDocuments?.financialSupportFile.fileData,
        );
      }
      if (
        finalRequiredDocuments?.signedBookFile.uniqueName &&
        finalRequiredDocuments?.signedBookFile.fileData
      ) {
        await getSingleUpload(
          finalRequiredDocuments?.signedBookFile.uniqueName,
          finalRequiredDocuments?.signedBookFile.fileData,
        );
      }
      if (
        finalRequiredDocuments?.otherDocsFile.uniqueName &&
        finalRequiredDocuments?.otherDocsFile.fileData
      ) {
        await getSingleUpload(
          finalRequiredDocuments?.otherDocsFile.uniqueName,
          finalRequiredDocuments?.otherDocsFile.fileData,
        );
      }
      // Additional doc upload
      addDocsWithUniqueFileName?.map(async (e) => {
        await getContractSingleUpload(
          e?.uniquiFileName,
          e?.file?.file?.originFileObj,
        );
      });
      const url = `${baseContractService}/save-lease-draft`;
      await axios.post(url, payLoad);
      setLoading(false);
      setTimeout(() => {
        history.push("/lease");
      }, 1000);
    } catch (e) {
      setLoading(false);
      const { response } = e;
      console.log("ckkkk", response);
      message.error(response);
    }
  };

  return (
    <DashboardLayout load={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Title level={2} className="mb-0 ag-fontSize32">
                Leasing Form - Residential Leasing
              </Title>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Form
            layout="vertical"
            name="bookingform"
            onFinish={handleSubmit(onSubmit)}
            scrollToFirstError
          >
            <Collapse
              defaultActiveKey={["1", "2", "3", "4"]}
              expandIconPosition="right"
              ghost
              className="ag-collapse ag-border-card"
            >
              <Panel header="1. Tenant Details" key="1" forceRender={true}>
                <TenantDetails
                  disableAll={disableAll}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                  detailsApi={leaseFormData.tenantDetail}
                />
              </Panel>

              <Panel header="2. Unit Details" key="2" forceRender={true}>
                <Row gutter={24}>
                  <UnitDetails
                    disableAll={disableAll}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                    unitDetails={leaseFormData.unitDetails}
                  />
                </Row>
              </Panel>
              <Panel header="3. Booking Terms" key="3" forceRender={true}>
                <Row gutter={24}>
                  <BookingTerms
                    disableAll={disableAll}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    reset={reset}
                    billingsApi={leaseFormData.billingCharges}
                    paid={leaseFormData.leaseTerms}
                    details={leaseFormData?.bookingTerms}
                    parking={parkingSlotsDataFromApi}
                    leasestatus={leaseFormData.status}
                  />
                </Row>
              </Panel>
              <Panel
                header="4. Lease Terms"
                forceRender={true}
                key="4"
                extra={clearData()}
              >
                <LeaseTerms
                  disableAll={disableAll}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  errors={errors}
                  data={leaseFormData.leaseTerms}
                  parking={parkingSlotsDataFromApi}
                  isClearTriggered={isClearTriggered}
                />
              </Panel>
            </Collapse>
            <Row gutter={24}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col span={24}>
                    <Title level={3} className="mb-0">
                      5. Required Documents:
                    </Title>
                  </Col>
                  <DocumentsList
                    control={control}
                    setValue={setValue}
                    leaseData={leaseFormData}
                    setFinalRequiredDocuments={setFinalRequiredDocuments}
                    setSendEmptyDoc={setSendEmptyDoc}
                  />
                </Row>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Collapse
                  expandIconPosition="right"
                  ghost
                  className="ag-collapse ag-border-card"
                  defaultActiveKey={["6", "7"]}
                >
                  <Panel
                    header="6. Billing Schedule"
                    key="6"
                    forceRender={true}
                  >
                    <BillingSchedule
                      control={control}
                      errors={errors}
                      getValues={getValues}
                      setValue={setValue}
                      register={register}
                    />
                  </Panel>
                  <Panel
                    header="7. Payment Schedule"
                    key="7"
                    forceRender={true}
                  >
                    <PaymentSchedule
                      disableAll={disableAll}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      data={""}
                      getValues={getValues}
                      register={register}
                      paymentSchedule={leaseFormData.paymentSchedule}
                      ejariCharges={leaseFormData?.securityAndEjariFeePayment}
                      statusForm={leaseFormData.status}
                    />
                  </Panel>
                </Collapse>
              </Col>
            </Row>

            <Row gutter={[24, 24]} justify="space-between">
              <Col span={24}>
                <Title level={3}>8. Additional Documents:</Title>
              </Col>
              <Col span={24}>
                <AdditionalDocument
                  disableAll={disableAll}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  leaseData={leaseFormData}
                  deletedAdditionalDocs={deletedAdditionalDocs}
                  setDeletedAdditionalDocs={setDeletedAdditionalDocs}
                />
              </Col>
            </Row>

            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Collapse
                  defaultActiveKey={["9"]}
                  expandIconPosition="right"
                  ghost
                  className="ag-collapse ag-border-card"
                >
                  <Panel
                    header="9. Approval History"
                    key="9"
                    forceRender={true}
                  >
                    <ApprovalHistory
                      control={control}
                      errors={errors}
                      approvalData={leaseFormData.approvalHistory}
                    />
                  </Panel>
                </Collapse>
              </Col>
            </Row>

            <Row gutter={[24, 24]}>
              <Col span={24}>
                <div className="steps-bookingForm">
                  <Button onClick={() => history.push("/lease")}>Back</Button>
                  {loginMode === "Lease_Manager" ? (
                    <div className="buttonSteps">
                      <Button
                        type="primary"
                        danger
                        disabled={disableApproveReject}
                        onClick={() => setVisible(true)}
                      >
                        Reject
                      </Button>
                      <Button
                        type="primary"
                        className="btn-blue"
                        onClick={() => acceptForm()}
                        disabled={disableApproveReject}
                      >
                        Approve
                      </Button>
                    </div>
                  ) : (
                    (loginMode === "Lease_Executive" ||
                      loginMode === "propertyExecutive") && (
                      <div className="buttonSteps">
                        <Button
                          type="primary"
                          className="btn-green"
                          htmlType="submit"
                          disabled={disableAll}
                        >
                          Save as draft
                        </Button>
                        <Button
                          type="primary"
                          htmlType="button"
                          className="btn-blue"
                          disabled={disableAll || disabletenant}
                          onClick={sendToTenant}
                        >
                          Send to tenant
                        </Button>
                        <Button
                          type="primary"
                          className="btn-blue"
                          htmlType="button"
                          disabled={disableAll || disableLeasebtn}
                          onClick={sendForApproval}
                        >
                          Confirm and send for approval
                        </Button>
                      </div>
                    )
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Popup {...popupContent} />
    </DashboardLayout>
  );
};
