import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardLayout from "../../molecules/DashboardLayout";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Pagination,
  Card,
  Button,
  Menu,
  Space,
  Input,
  Dropdown,
  Select,
  Typography,
  message,
  Form,
} from "antd";
import SectionHeader from "../../molecules/SectionHeader";
import Grid from "../../atoms/Grid";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { getBookingForms, visiblePopup, getLeadID } from "./ducks/actions";
import { PopupConfirm, Popup } from "../../atoms/Popup";
import CancelBookingForm from "./cancelBooking";
import {
  downloadBookingForm,
  cancelBookingForm,
} from "../../../utils/fileDownload";
import moment from "moment";
import { baseContractService } from "../../../configs/constants";
import axios from "axios";
const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 220,
};
const dropDown = [
  { id: 1, name: "Download booking form", status: "#184461" },
  { id: 2, name: "Edit", status: "" },
  { id: 3, name: "Add Unit", status: "" },
  { id: 4, name: "Cancel", status: "#FB7171" },
];
const dropDown1 = [
  { id: 1, name: "Download booking form", status: "#184461" },
  { id: 2, name: "Edit", status: "" },
];

const dropDown2 = [
  { id: 1, name: "Download booking form", status: "#184461" },
  { id: 2, name: "Send for tenant's signature", status: "" },
  { id: 3, name: "Add Unit", status: "" },
  { id: 4, name: "View Approved Form", status: "" },
];

function dateFormat(param) {
  return (
    <span>{moment(param.data.created_at).format("YYYY-MM-DD HH:MM:SS")}</span>
  );
}

const getModifiedStatus = (param) => {
  const statusName = param.data.status;
  if (statusName.toLowerCase().includes("draft")) {
    return <span>Draft</span>;
  } else if (statusName.toLowerCase().includes("confirmed")) {
    return <span>Awaiting Approval</span>;
  } else if (statusName.toLowerCase().includes("process")) {
    return <span>N.A</span>;
  } else if (statusName.toLowerCase().includes("opportunity_approved")) {
    return <span>Approved</span>;
  } else if (statusName.toLowerCase().includes("opportunity_rejected")) {
    return <span>Rejected</span>;
  } else {
    return <span className="sentanceCase">{statusName}</span>;
  }
};

const leadCol = [
  {
    headerName: "ID",
    field: "id",
  },
  {
    headerName: "Tenant Name",
    field: "tenant_name",
  },
  {
    headerName: "Unit Code",
    field: "unit_code",
  },
  {
    headerName: "Contract Type",
    field: "contract_type",
  },
  {
    headerName: "Annual Base Rent",
    field: "base_rent",
  },
  {
    headerName: "Status",
    field: "status",
    cellRendererFramework: getModifiedStatus,
  },

  {
    headerName: "Created At",
    field: "created_at",
    cellRendererFramework: dateFormat,
  },
  {
    headerName: "More Action",
    field: "manage",
    cellRendererFramework: (row) => MoreAction(row),
  },
];

const searchBy = [
  { label: "Tenant Name", value: "tenant_name" },
  { label: "Annual Base Rent", value: "base_rent" },
  { label: "Status", value: "status" },
];

const limit = 10;

const MoreAction = (row) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Text } = Typography;
  const [selected, setSelected] = useState([]);
  const onChnageHanldler = async (e) => {
    if (e === "Cancel") {
      onStatus(row.data.id);
    } else if (e === "Add Unit") {
      dispatch(visiblePopup());
      dispatch(getLeadID(row.data.id));
    } else if (e === "Download booking form") {
      downloadBookingForm(row.data.id);
    } else if (e === "View Approved Form") {
      history.push(
        `leads/${row.data.id}/booking-form/${row.data.assest_id}/${row.data.party_id}`,
      );
    } else if (e === "Edit") {
      history.push(
        `leads/${row.data.id}/booking-form/${row.data.assest_id}/${row.data.party_id}`,
      );
    } else if (e === "Send for tenant's signature") {
      try {
        let body = {
          leadId: row.data.id,
        };
        await axios.post(
          `${baseContractService}/booking-form-send-to-prospect`,
          body,
        );
        message.success("Successfully Sent");
      } catch (e) {
        const { response } = e;
        message.error(response?.data?.message);
      }
    }
  };
  const onStatus = (leadID) => {
    PopupConfirm({
      title: `Are you sure you want to cancel`,
      onOk: () => onCancleBookingForm(leadID),
      okText: "Yes",
    });
  };
  const onCancleBookingForm = (leadID) => {
    cancelBookingForm(leadID).then((response) => {
      if (response?.data?.message === "Success") {
        message.success(response?.data?.result);
      }
    });
  };

  useEffect(() => {}, []);

  return (
    <Select
      onChange={onChnageHanldler}
      className="Sentence"
      placeholder="Select an action"
      style={{ width: 150 }}
      allowClear
    >
      <>
        {row.data.status.toLowerCase().includes("draft") &&
          dropDown.map((value, key) => (
            <React.Fragment key={key}>
              <Select.Option value={value.name}>
                <Text style={{ color: `${value.status}` }}>{value.name}</Text>
              </Select.Option>
            </React.Fragment>
          ))}
        {(row.data.status.toLowerCase().includes("confirmed") ||
          row.data.status.toLowerCase().includes("opportunity_rejected")) &&
          dropDown1.map((value, key) => (
            <React.Fragment key={key}>
              <Select.Option value={value.name}>
                <Text style={{ color: `${value.status}` }}>{value.name}</Text>
              </Select.Option>
            </React.Fragment>
          ))}
        {row.data.status == "opportunity_approved" &&
          dropDown2.map((value, key) => (
            <React.Fragment key={key}>
              <Select.Option value={value.name}>
                <Text style={{ color: `${value.status}` }}>{value.name}</Text>
              </Select.Option>
            </React.Fragment>
          ))}
      </>
    </Select>
  );
};

const BookingformList = (props) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchByT, setSearchBy] = useState("tenant_name");
  const [search, setSearch] = useState("");
  const visible = useSelector((state: any) => state.bookings.visiblePopup);
  const bookingformdata = useSelector(
    (state: any) => state.bookings.getBookingForms,
  );

  const SearchFelds = () => {
    return (
      <Form onFinish={onSearch}>
        <Space wrap>
          <Form.Item name="searchText" className="mb-0" initialValue={search}>
            <Input type="text" placeholder="Search" value={search} />
          </Form.Item>
          <Form.Item name="searchBy" className="mb-0" initialValue={searchByT}>
            <Select placeholder="Select" value={searchByT}>
              {searchBy.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className="mb-0 noborder-btn">
            <Button
              htmlType="submit"
              className="ag-gray-button"
              icon={<SearchOutlined />}
            />
          </Form.Item>
        </Space>
      </Form>
    );
  };

  const onSearch = (val) => {
    if (val.searchText) {
      setSearch(val.searchText);
      setSearchBy(val.searchBy);
      dispatch(
        getBookingForms(limit, currentPage, val.searchText, val.searchBy),
      );
    } else {
      setSearch("");
      dispatch(getBookingForms(limit, currentPage, "", ""));
    }
  };

  const onClosePopUp = () => {
    dispatch(visiblePopup());
  };

  const popupProps = {
    title: "Enter Unit Code",
    visibility: visible,
    width: 650,
    content: <CancelBookingForm closePopUp={onClosePopUp} />,
    onCancel: onClosePopUp,
  };

  const handlePagination = (pg) => {
    setCurrentPage(pg);
    dispatch(getBookingForms(limit, pg, search, searchByT));
  };

  useEffect(() => {
    dispatch(getBookingForms(limit, currentPage, "", ""));
  }, []);

  return (
    <Fragment>
      <DashboardLayout>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <SectionHeader
                  heading="Booking Forms"
                  //@ts-ignore
                  total={bookingformdata?.count}
                />
              </Col>
              <Col span={24}>
                <Pagination
                  className="ag-property-text-pagination"
                  total={bookingformdata?.count || 0}
                  showTotal={(total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} Bookings`
                  }
                  showSizeChanger={false}
                  defaultPageSize={10}
                  defaultCurrent={1}
                  current={currentPage + 1}
                  onChange={(e) => handlePagination(e - 1)}
                />
              </Col>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="end">
                  <Col span={24}>
                    <Card
                      bordered={false}
                      className="ag-nospace-body"
                      extra={<SearchFelds />}
                    >
                      <Grid
                        refCol="status"
                        //@ts-ignore
                        data={bookingformdata?.rows}
                        columns={leadCol}
                        pagination={false}
                        defaultSettings={gridProps}
                        noRowlabel="Booking"
                        fullPage={true}
                      />
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </Fragment>
  );
};

export default BookingformList;
