import React, { useState, Fragment, useEffect } from "react";
import { Popup } from "../../atoms/Popup";
import { Card, Row, Col, Typography, Radio, Button, Image, Space } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import PdfViewer from "../../molecules/PDFViewer/pdfView";
import { getPresignedImage } from "../../modules/Properties/AddProperty/ducks/actions/addInfoAction";
import { InputRadio } from "../../atoms/FormElement";

const RequireDoc = (props) => {
  const [visible, setVisible] = useState(false);
  const {
    title,
    popup,
    docsData,
    disableThis,
    isContact,
    control,
    errors,
    fieldName,
    array,
    index,
    field,
    noUpdate,
  } = props;
  const { Title } = Typography;
  const [viewOrDownloadUrl, setViewOrDownloadUrl] = useState("");
  const [showPdf, setShowPdf] = useState(false);

  const onClosePopUp = () => {
    setVisible(false);
  };

  const onOpenPopUpHandler = () => {
    if (docsData?.object_key?.split(".")[1] === "pdf") {
      setShowPdf(!showPdf);
    } else {
      setVisible(true);
    }
  };

  const getUrl = async (data) => {
    const url = await getPresignedImage(data);
    setViewOrDownloadUrl(url);
  };

  useEffect(() => {
    if (docsData) {
      getUrl(docsData?.object_key);
    }
  }, [docsData]);

  const popupContent = {
    closable: true,
    visibility: visible,
    content: (
      <Image
        preview={false}
        width="100%"
        style={{ padding: "20px" }}
        src={viewOrDownloadUrl}
      />
    ),
    width: 650,
    onCancel: onClosePopUp,
  };

  const onChange = (e) => {
    popup(e.target.value, docsData.tenant_object_id, false, null, fieldName);
    // if (e.target.value === "reject") {
    //   popup(e.target.value, docsData.tenant_object_id, false, null);
    // }
    // if (e.target.value === "accept") {
    //   props.onAccept && props.onAccept(index);
    //   props.setDisableFront && props.setDisableFront(true);
    // }
  };

  return (
    <Fragment>
      <PdfViewer
        pdf={viewOrDownloadUrl}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
      />
      <Card
        bordered={false}
        className={`h-auto mb-2 ${isContact ? "lightback" : ""}`}
      >
        <Row gutter={24} justify="space-between" align="middle">
          <Col span={12} style={{ display: "flex" }}>
            <FileTextOutlined className="require-doc" />
            <Title level={5}>{`${title}`}</Title>
          </Col>
          <Col>
            {docsData && (
              <Space>
                {docsData?.tenant_object_id && (
                  <>
                    <Button
                      type="link"
                      href={viewOrDownloadUrl}
                      target="_blank"
                      download
                      // disabled={disableThis}
                    >
                      Download
                    </Button>
                    <Button
                      type="link"
                      onClick={onOpenPopUpHandler}
                      // disabled={disableThis}
                    >
                      View
                    </Button>
                  </>
                )}
                {props.optional == false && (
                  <>
                    {array ? (
                      <InputRadio
                        class="error-set"
                        // isRequired={true}
                        fieldname={fieldName}
                        control={control}
                        onChange={onChange}
                        initValue=""
                        noStyle={false}
                        // rules={{
                        //   required: "Should Accept Documents",
                        // }}
                        // validate={
                        //   Object.entries(errors).length > 0 &&
                        //   errors.contacts?.length > 0 &&
                        //   errors.contacts[index][field] &&
                        //   "error"
                        // }
                        // validMessage={
                        //   Object.entries(errors).length > 0 &&
                        //   errors.contacts?.length > 0 &&
                        //   errors.contacts[index][field] &&
                        //   errors.contacts[index][field].message
                        // }
                        options={
                          <>
                            <Radio
                              className="radio-group-reject"
                              value="reject"
                              disabled={
                                !docsData.tenant_object_id ? true : noUpdate
                              }
                            >
                              Reject
                            </Radio>
                            <Radio
                              className="radio-group-accept"
                              value="accept"
                              disabled={disableThis || noUpdate}
                            >
                              Accept
                            </Radio>
                          </>
                        }
                      />
                    ) : (
                      <InputRadio
                        class="error-set"
                        isRequired={true}
                        fieldname={fieldName}
                        control={control}
                        onChange={onChange}
                        initValue=""
                        noStyle={false}
                        rules={{
                          required: "Should Accept Documents",
                        }}
                        validate={errors[fieldName] && "error"}
                        validMessage={
                          errors[fieldName] && errors[fieldName].message
                        }
                        options={
                          <>
                            <Radio
                              className="radio-group-reject"
                              value="reject"
                              disabled={
                                !docsData.tenant_object_id ? true : disableThis
                              }
                            >
                              Reject
                            </Radio>
                            <Radio
                              className="radio-group-accept"
                              value="accept"
                              disabled={
                                !docsData.tenant_object_id ? true : disableThis
                              }
                            >
                              Accept
                            </Radio>
                          </>
                        }
                      />
                    )}
                  </>
                )}
              </Space>
            )}
          </Col>
        </Row>
      </Card>
      <Popup {...popupContent} />
    </Fragment>
  );
};

export default RequireDoc;
