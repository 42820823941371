import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Space, Button } from "antd";

import HeadingChip from "../../atoms/HeadingChip";
import TicketChip from "../../atoms/TicketChip";

const { Title } = Typography;

const Tickets = (props) => {
  const [tickets, setTickets] = useState([]);
  const { ticketlist, category, viewTicket, count, onCount } = props;

  useEffect(() => {
    ticketlist && setTickets(ticketlist);
  }, [ticketlist]);

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <HeadingChip heading={category} value={count} />
      </Col>
      <Col span={24}>
        <Card bordered={false} style={{ borderRadius: 4 }}>
          {tickets.length > 0 ? (
            <Space size={24} direction="vertical" className="w-100">
              <Card
                bordered={false}
                className="ag-nospace-body ag-noborder-head"
                style={{
                  height: 550,
                  overflowY: "auto",
                }}
              >
                <Space size={24} direction="vertical" className="w-100">
                  {tickets.map((item: any, index) => (
                    <TicketChip
                      key={index}
                      ticketID={item.case_id}
                      type={item.primary_category}
                      subtype={item.secondary_category}
                      status={item.status}
                      tenant={item.tenant}
                      appartment={item.property_name}
                      location={
                        item?.location_info?.length > 0
                          ? `${
                              item?.location_info[0]?.location
                                ? item?.location_info[0]?.location
                                : ""
                            } ${
                              item?.location_info[0]?.sub_location
                                ? item?.location_info[0]?.sub_location
                                : ""
                            }`
                          : ""
                      }
                      recuring={item?.parent_ticket}
                      clickView={() => viewTicket(item)}
                    />
                  ))}
                </Space>
              </Card>
              {tickets.length < count && (
                <Button type="link" block onClick={onCount}>
                  View More
                </Button>
              )}
            </Space>
          ) : (
            <Title
              level={5}
              className="highlight ag-primary mb-0 p-1 text-center"
            >
              There are currently no <br /> {category === "New" && "new"}
              {category === "Closed" && "closed"} tickets{" "}
              {category === "In Progress" && "in progress"}.
            </Title>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default Tickets;
