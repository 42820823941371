import React, { useEffect, useState } from "react";
import { Typography, Collapse, Button, Space, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentPlan } from "../ducks/actions";
import InstallmentPlan from "./components/InstallmentPlan";
import { getPaymentConfig } from "../../AddProperty/ducks/actions/paymentAction";
import { paymentIndex } from "../../ducks/constants";

const { Title } = Typography;
const { Panel } = Collapse;

export default ({ propertyDetails }) => {
  const { asset_id, name, usage_type_id } = propertyDetails;
  const uid = usage_type_id;
  const dispatch = useDispatch();
  const [panelActive, setPanelActive] = useState(["1"]);
  const [addMode, setAddMode] = useState(false);
  const [paymentArray, setPaymentArray] = useState([]);
  const planApi = useSelector((state: any) => state.propertyDetails.plan);
  const paymentConfigData = useSelector(
    (state: any) => state.paymentReducer.getPaymentConfig,
  );

  useEffect(() => {
    dispatch(getPaymentConfig());
    dispatch(getPaymentPlan(asset_id));
  }, []);

  useEffect(() => {
    if (planApi) {
      setPaymentArray(planApi);
      setPanelActive([`${planApi.length}`]);
    }
  }, [planApi]);

  const AddPayment = () => {
    let temp = [...paymentArray];
    temp.push({ id: 1, price: "", add: true });
    setPaymentArray(Object.assign([], temp));
    setAddMode(true);
    let leng = `${temp.length}`;
    setPanelActive([leng]);
  };

  const updation = () => {
    setAddMode(false);
    dispatch(getPaymentPlan(asset_id));
  };

  const callback = (key) => {
    setPanelActive(key);
  };

  return (
    <>
      {!addMode && (
        <Row gutter={24}>
          <Col flex="auto">
            <Title level={5} className="ag-primary mb-0 p-2">
              Installment Plan(s)
            </Title>
          </Col>
          <Col flex="150px">
            <Button
              type="primary"
              className="mt-2 mb-2 mx-2"
              onClick={AddPayment}
            >
              Add Payment Plan
            </Button>
          </Col>
        </Row>
      )}
      <Collapse
        activeKey={panelActive}
        accordion={true}
        onChange={callback}
        expandIconPosition="right"
        ghost
        className="ag-collapse custom-collapse ag-border-card"
      >
        {paymentArray.length > 0 &&
          paymentArray.map((data, index) => (
            <React.Fragment key={index}>
              <Panel
                header={
                  <>
                    <Title level={5} className="mb-0 normalFont18">
                      {index + 1}. {paymentIndex[index]} Installment Plan
                    </Title>
                  </>
                }
                key={`${index + 1}`}
              >
                <InstallmentPlan
                  details={data}
                  fees={paymentConfigData}
                  addField={data.add}
                  asset_id={asset_id}
                  update={updation}
                  uid={uid}
                />
              </Panel>
            </React.Fragment>
          ))}
      </Collapse>
    </>
  );
};
