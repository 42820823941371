import axios from "../../../../../../utils/axiosInceptor";
import * as action_types from "../constants";
import {authService,propertyService,leaseService, maintenanceService} from '../../../../../../configs/constants'
// T & C Page Action

const templatesUrl = `${propertyService}/property-landlord/terms-and-conditions/templates`;


export const getTemplates = () => {
  return async (dispatch) => {
    const headers = {
      Authorization:
        "Bearer" +
        " " +
        JSON.parse(window.localStorage.getItem("login-response")).token,
    };
    const templates = await axios.get(templatesUrl, {headers});
    dispatch({
      type: action_types.GET_TEMPLATES,
      data: templates?.data?.result
    })
  }
}
export const retianTCPageData = (data) => {
  return (dispatch) => {
    dispatch({
      type: action_types.TC_PAGE_DATA,
      data
    })
  }
}
