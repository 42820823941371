import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SideDetail from "../SideDetail";
import BreadCrumb from "../../../../atoms/BreadCrumb";
import DashboardLayout from "../../../../molecules/DashboardLayout";
import PaymentPlan from "../PaymentPlan";
import { getSingleUnit } from "../ducks/actions";
import { useLocation } from "react-router-dom";
// import EarningsChart from "../EarningsChart";
import UnitInfo from "../UnitInfo";

const { TabPane } = Tabs;

export default (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const parentId = location.pathname?.split("/")[2];
  const unitId = location.pathname?.split("/")[4];

  const unitDetailsApi = useSelector(
    (state: any) => state.propertyDetails.unitData,
  );

  useEffect(() => {
    dispatch(getSingleUnit(unitId));
  }, []);

  const onUpdate = () => {
    dispatch(getSingleUnit(unitId));
  };

  const breadcrumbList: any = [
    {
      label: "Properties",
      route: "/properties",
    },
    {
      label: location.state?.parentname,
      route: `/property-detail/${parentId}`,
    },
    {
      label: unitDetailsApi?.name,
      route: "",
    },
  ];

  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BreadCrumb list={breadcrumbList} />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={6}>
          <SideDetail
            propertyDetails={unitDetailsApi}
            updated={onUpdate}
            isUnit={true}
            parentName={location.state?.parentname}
          />
        </Col>
        <Col span={18}>
          <Row gutter={[24, 24]}>
            {/* <Col span={24}>
              <EarningsChart />
            </Col> */}
            <Col span={24}>
              <Card
                bordered={false}
                className="ag-nospace-head ag-nospace-body h-auto"
              >
                <Tabs
                  defaultActiveKey="1"
                  animated={false}
                  tabBarGutter={16}
                  style={{ overflow: "unset" }}
                >
                  <TabPane key="1" tab="Property Information">
                    <UnitInfo
                      propertyDetails={unitDetailsApi}
                      parentId={parentId}
                      updateApi={onUpdate}
                    />
                  </TabPane>
                  <TabPane key="2" tab="Payment Plan">
                    <PaymentPlan propertyDetails={unitDetailsApi} />,
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </DashboardLayout>
  );
};
