import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import agEdit from "../../../assets/img/ag-edit.svg";

import SectionHeader from "../../../../molecules/SectionHeader";
import PropertyList from "../../../../molecules/PropertyList";
import {
  downloadPropertyData,
  getCountProperties,
  getPinProperties,
  getPropertiesList,
} from "../../ducks/actions";
import { propertyService } from "../../../../../configs/constants";
import axios from "axios";
import message from "antd/lib/message";
import { PopupConfirm } from "../../../../atoms/Popup";

const list = {
  search: "",
  sortby: "",
  sort: "",
  page: 0,
  limit: 6,
};

const showOption = [
  {
    value: "All",
    label: "All Properties",
  },
];

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [propertyCard, setPropertyCard] = useState(list);
  const [propertyData, setpropertyData] = useState([]);
  const propertiesCardApi = useSelector(
    (state: any) => state.dashboard.propertiesCard,
  );
  const role = JSON.parse(window.localStorage.getItem("login-response"))?.data
    ?.role_name;
  const fileData = useSelector((state: any) => state.dashboard.downloadFile);

  const addProperty = () => {
    history.push("/create-a-property");
  };

  const handleShow = () => {};

  function listCard() {
    dispatch(
      getPropertiesList(
        propertyCard.search,
        propertyCard.page,
        6,
        true,
        "",
        "",
      ),
    );
  }

  useEffect(() => {
    fileData && setpropertyData(fileData);
  }, [fileData]);

  const onStatus = (id, status) => {
    PopupConfirm({
      title: `Are you sure you want to ${
        status === "disabled" ? "unpublish" : "publish"
      } this property?`,
      content: "This will change the property status",
      onOk: () => onStatusChanged(id, status),
      okText: "Confirm",
    });
  };

  const onStatusChanged = async (id, status) => {
    const url = `${propertyService}/property-landlord/change-property-status/${id}`;
    try {
      const res = await axios.put(url, { property_status: status });
      message.success(res?.data?.message || "Status Updated");
      listCard();
      dispatch(getPinProperties());
      dispatch(getCountProperties());
      dispatch(getPropertiesList(undefined, 0, 10, false, "published", true));
      dispatch(getPropertiesList(undefined, 0, 10, false, "rented", true));
    } catch (e) {
      const { response } = e;
      message.error(response?.data?.message);
    }
  };

  useEffect(() => {
    listCard();
  }, [propertyCard]);

  const onExport = (assetID) => {
    // dispatch(downloadPropertyData(assetID));
  };

  return (
    <>
      <SectionHeader
        heading="All Properties"
        total={propertiesCardApi?.count}
        showOption={showOption}
        handleShow={handleShow}
        search={(e) =>
          setPropertyCard({
            ...propertyCard,
            search: e.target.value,
            page: 0,
          })
        }
        addNewAction={role == "Landlord" ? addProperty : null}
      />
      <PropertyList
        data={propertiesCardApi?.properties}
        count={propertiesCardApi?.count}
        type="p"
        pagination={true}
        currentPage={propertyCard.page + 1}
        onpageChange={(e) => setPropertyCard({ ...propertyCard, page: e - 1 })}
        onStatusChanged={onStatus}
        onExport={onExport}
        propertyData={propertyData}
      />
    </>
  );
};
