import { Card, Col, Row, Typography, Space } from "antd";
import React, { useEffect, useState } from "react";
import ListPanel from "../../../../../molecules/ListPanel";
import { useSelector } from "react-redux";

const { Title, Text } = Typography;

export default (props) => {
  const tenantData = useSelector(
    (state: any) => state.tenantsSummary.tenantData,
  );
  const lease = useSelector((state: any) => state.tenantsSummary.lease);

  const [info, setInfo] = useState([]);
  const [leaseData, setLeaseData] = useState([]);
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    if (tenantData) {
      setInfo([
        {
          key: "Full Name",
          value:
            !tenantData?.first_name && !tenantData?.last_name
              ? "N/A"
              : `${tenantData?.first_name} ${tenantData?.last_name}`,
        },
        {
          key: "Reg. Phone Number",
          value: tenantData?.contact_info?.contact_number || "N/A",
        },
        {
          key: "Reg.Email",
          value: tenantData?.contact_info?.email || "N/A",
        },
        {
          key: "Nationality",
          value: tenantData?.contact_info?.nationality?.name || "N/A",
        },
        {
          key: "Visa Designation",
          value: tenantData?.visa_designation || "N/A",
        },
      ]);
      setDocs([
        {
          key: "Passport Number",
          value: tenantData?.documents?.passport?.passport_no || "N/A",
        },
        {
          key: "Passport Expiry Date",
          value: tenantData?.documents?.passport?.passport_no_expiry || "N/A",
        },
        {
          key: "Emirates ID Number",
          value: tenantData?.documents?.emirates_id?.emirates_no || "N/A",
        },
        {
          key: "Emirates ID Expiry Date",
          value:
            tenantData?.documents?.emirates_id?.emirates_no_expiry || "N/A",
        },
        {
          key: "Visa Number",
          value: tenantData?.documents?.visa?.visa_no || "N/A",
        },
        {
          key: "Visa Expiry Date",
          value: tenantData?.documents?.visa?.visa_no_expiry || "N/A",
        },
        {
          key: "Trade License Number",
          value:
            tenantData?.documents?.trade_license?.trade_license_no || "N/A",
        },
        {
          key: "Trade License Expiry Date",
          value:
            tenantData?.documents?.trade_license?.trade_license_expiry || "N/A",
        },
      ]);
    }
  }, [tenantData]);

  return (
    <Card bordered={false} bodyStyle={{ padding: "3em 3.5em" }}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Space direction="vertical">
            <Title level={3} className="mb-0">
              {!tenantData?.first_name && !tenantData?.last_name
                ? "N/A"
                : `${tenantData?.first_name} ${tenantData?.last_name}`}
            </Title>
            <Text className="d-block defaultFont">
              User ID: {tenantData?.id}
            </Text>
            <Text className="ag-primary">
              Account Status:{" "}
              {tenantData?.active == true ? "Active" : "Inactive"}
            </Text>
          </Space>
        </Col>
        <Col span={24}>
          <Card bordered={false} bodyStyle={{ padding: "0 32% 0 0" }}>
            <Row gutter={[40, 40]}>
              <Col span={12}>
                <ListPanel data={info} title={"Tenant Details"} />
              </Col>
              <Col span={12}>
                <ListPanel data={docs} title={"Documents Summary"} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};
