import React, { useEffect, useState } from "react";
import { Row, Col, Select } from "antd";
import {
  InputField,
  SelectField,
  ReactSelectField,
} from "../../../../atoms/FormElement";
import { getGeoData } from "../../AddProperty/ducks/actions/addInfoAction";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { getTenantDetails } from "../../../TenantDetail/ducks/actions";

const initTenantDetailState = {
  tType: "",
  tName: "",
  tSurname: "",
  maritalStatus: "",
  employmentSector: "",
  visaDesignation: "",
  income: "",
  address: "",
  nationality: "",
  poBox: "",
  country: "",
  city: "",
  mobile: "",
  email: "",
  contactInfoArray: [],
};

export default ({ control, errors }) => {
  const dispatch = useDispatch();
  const [countryList, setCountryList] = useState([]);
  const apiGeoData = useSelector((state: any) => state.addInfoReducer.getGeoData);
  const tenantDetailsData = useSelector(
    (state: any) => state.tenantDetails.getTenantDetils,
  );
  const mStatus = [
    { label: "single", value: "Single" },
    { label: "married", value: "Married" },
  ];
  const { setValue, handleSubmit } = useForm();
  const [tenantState, setTenantState] = useState(initTenantDetailState);
  const partyId = window.localStorage.getItem('selectedPartyId');

  useEffect(() => {
    dispatch(getGeoData());
    dispatch(getTenantDetails(partyId));
  }, []);

  useEffect(() => {
    if (apiGeoData?.countryData) {
      const { countryData } = apiGeoData;
      setCountryList(countryData?.data?.result);
    }
  }, [apiGeoData]);

  useEffect(() => {
    if (Object.keys(tenantDetailsData).length) {
      const detail = {
        tType: tenantDetailsData?.type,
        tName: tenantDetailsData?.first_name,
        tSurname: tenantDetailsData?.last_name,
        maritalStatus: tenantDetailsData?.marital_status,
        employmentSector: tenantDetailsData?.employment_sector,
        visaDesignation: tenantDetailsData?.visa_designation,
        income: tenantDetailsData?.income_range,
        address: tenantDetailsData?.contact_info?.street_address,
        nationality: tenantDetailsData?.contact_info?.nationality?.name,
        poBox: tenantDetailsData?.contact_info?.zipcode,
        country: tenantDetailsData?.contact_info?.country,
        city: tenantDetailsData?.contact_info?.city,
        mobile: tenantDetailsData?.contact_info?.contact_number,
        email: tenantDetailsData?.contact_info?.email,
        contactInfoArray: [],
      };
      setTenantState(detail);
    }
  }, [tenantDetailsData]);

  return (
    <>
      <Col span={8}>
        <InputField
          isRequired={true}
          label="Tenant Name"
          fieldname="tenantName"
          control={control}
          valueGot={tenantState?.tName}
          setValue={setValue}
          initValue=""
          iProps={{ placeholder: "Tenant Name" }}
          rules={{ required: "Please enter Tenant Name" }}
          validate={errors.tenantName && "error"}
          validMessage={errors.tenantName && errors.tenantName.message}
        />
      </Col>
      <Col span={8}>
        <SelectField
          isRequired={true}
          label="Nationality"
          fieldname="nationality"
          control={control}
          valueGot={tenantState?.nationality}
          setValue={setValue}
          rules={{ required: "Please enter Nationality" }}
          iProps={{ placeholder: "Select Nationality" }}
          validate={errors.nationality && "error"}
          validMessage={errors.nationality && errors.nationality.message}
          selectOption={countryList.map((value, key) => {
            return (
              <Select.Option key={key} value={value.name}>
                {value.name}
              </Select.Option>
            );
          })}
        />
      </Col>
      <Col span={8}>
        <InputField
          isRequired={true}
          label="Mobile number"
          fieldname="mobile"
          control={control}
          valueGot={tenantState?.mobile}
          setValue={setValue}
          validate={errors.mobile && "error"}
          validMessage={errors.mobile && errors.mobile.message}
          initValue=""
          rules={{
            pattern: {
              value: /^[0-9]*$/,
              message: "Value should be a Number",
            },
            minLength: { value: 9, message: "Minimum 9 digits" },
            maxLength: { value: 9, message: "Maximum 9 digits" },
            required: "Please enter valid Mobile No.",
            setValueAs: (value) => "+971".concat(value),
          }}
          iProps={{ addonBefore: "+971" }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          isRequired={true}
          label="Marital Status"
          fieldname="maritalstatus"
          control={control}
          valueGot={tenantState?.maritalStatus}
          setValue={setValue}
          iProps={{ placeholder: "Single" }}
          validate={errors.maritalstatus && "error"}
          validMessage={errors.maritalstatus && errors.maritalstatus.message}
          rules={{ required: "Select Marital Status" }}
          selectOption={mStatus}
        />
      </Col>
      <Col span={8}>
        <InputField
          isRequired={true}
          label="Email Address"
          fieldname="email"
          control={control}
          valueGot={tenantState?.email}
          setValue={setValue}
          validate={errors.email && "error"}
          validMessage={errors.email && errors.email.message}
          iProps={{ placeholder: "nabeel_soomro@gmail.com" }}
          initValue=""
          rules={{
            pattern: {
              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Enter valid Email Address",
            },
            required: "Please enter Email Address",
          }}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="P.O Box"
          fieldname={`dewa`}
          control={control}
          valueGot={tenantState?.poBox}
          setValue={setValue}
          initValue=""
          iProps={{ placeholder: "312312" }}
          isRequired={true}
          rules={{ required: "Please enter P.O Box" }}
          validate={errors.dewa && "error"}
          validMessage={errors.dewa && errors.dewa.message}
        />
      </Col>
      {/* <Col span={8}>
        <InputField
          isRequired={true}
          label="No. of occupants"
          fieldname="occupants"
          control={control}
          validate={errors.occupants && "error"}
          validMessage={errors.occupants && errors.occupants.message}
          initValue={1}
          iProps={{ type: "number" }}
          rules={{ required: "Please enter Nationality" }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="Contacts Name"
          fieldname="contacts"
          control={control}
          validate={errors.nationality && "error"}
          validMessage={errors.nationality && errors.nationality.message}
          iProps={{ placeholder: "Select contacts" }}
          selectOption={[
            { key: "father", value: "Father" },
            { key: "mother", value: "Mother" },
          ]}
        />
      </Col> */}
    </>
  );
};
