import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import {
  maintenanceTicketsService,
  singleMaintenanceTicketsService,
  leaseService,
} from "../../../../configs/constants";

const presignedImageUrl = `${leaseService}/leads/download-signed-url`;

const headers = {
  Authorization:
    "Bearer" +
    " " +
    JSON.parse(window.localStorage.getItem("login-response"))?.token,
};

export const fetchTickets = (assetId: number, status: string, page: number) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${maintenanceTicketsService}?asset_id=${assetId}&status=${status}&limit=3&page=${page}`,
        { headers },
      );
      let tempActionType = "";

      if (status === "new") {
        tempActionType = action_types.TICKETS_NEW;
      } else if (status === "inProgress") {
        tempActionType = action_types.TICKETS_PROGRESS;
      } else if (status === "closed") {
        tempActionType = action_types.TICKETS_CLOSED;
      }

      dispatch({
        type: tempActionType,
        data: response?.data?.result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getPresignedImage = async (mediaArray) => {
  const mediaUrls = [];
  for (const i of mediaArray) {
    const postData = { url: i };
    if (postData) {
      const {
        data: { result },
      } = await axios.post(presignedImageUrl, postData, { headers });
      mediaUrls.push(result?.imageUrl);
    }
  }
  return mediaUrls;
};

export const getTicketById = (case_id) => {
  return async (dispatch) => {
    try {
      const url = `${singleMaintenanceTicketsService}?case_id=${case_id}`;
      const response = await axios.get(url);
      dispatch({
        type: action_types.GET_TICKET_BY_ID,
        data: response?.data?.result?.result,
      });
    } catch (e) {
      const { response } = e;
      console.log(response?.statusText);
    }
  };
};
