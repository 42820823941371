import React, { useState } from "react";
import { Card, Button } from "antd";
import {
  PropertyInfo,
  PaymentPlans,
  TermsAndConditions,
} from "./ReviewAndPost";

const tabList = [
  {
    key: "info",
    tab: "Property Information",
  },
  {
    key: "plans",
    tab: "Payment Plans",
  },
  {
    key: "terms",
    tab: "Terms & Conditions",
  },
];

export default ({ currentStep, setCurrentStep }) => {
  const [tabKey, setTabkey] = useState("info");

  const reviewContent = {
    info: <PropertyInfo />,
    plans: <PaymentPlans />,
    terms: <TermsAndConditions />,
  };

  return (
    <>
      <Card
        bordered={false}
        className="ag-nospace-head ag-nospace-body h-auto"
        tabList={tabList}
        activeTabKey={tabKey}
        onTabChange={(key) => setTabkey(key)}
        tabProps={{ tabBarGutter: 16 }}
      >
        {reviewContent[tabKey]}
      </Card>
      <div className="steps-action py-3">
        <Button type="primary" onClick={() => setCurrentStep(currentStep - 1)}>
          Back
        </Button>
        <Button type="primary" onClick={() => setCurrentStep(currentStep + 1)}>
          Next
        </Button>
      </div>
    </>
  );
};
