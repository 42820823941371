import * as action_types from "./constants";

const initialState = {
  unitsList: [],
  plan: [],
  unitData: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.PROPERTY_UNITS:
      return { ...state, unitsList: data };
    case action_types.GET_PAYMENT_PLAN:
      return { ...state, plan: data };
    case action_types.GET_SINGLE_UNIT:
      return { ...state, unitData: data };
    default:
      return state;
  }
};
