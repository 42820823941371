import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import Tickets from "../../molecules/Tickets";
import ViewTicket from "./components/ViewTicket";
import SectionHeader from "../../molecules/SectionHeader";
import { Popup } from "../../atoms/Popup";
import DashboardLayout from "../../molecules/DashboardLayout";
import BreadCrumb from "../../atoms/BreadCrumb";

import {
  fetchTickets,
  getPresignedImage,
  getTicketById,
} from "./ducks/actions";
import { getSingleProperty } from "../Properties/AddProperty/ducks/actions/addInfoAction";
import axios from "../../../utils/axiosInceptor";
import { singleMaintenanceTicketsService } from "../../../configs/constants";

export default () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const [newTickets, setNewTickets] = useState([]);
  const [closedTickets, setClosedTickets] = useState([]);
  const [progressTickets, setProgressTickets] = useState([]);
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [selectedTicket, setSelectedTickets] = useState({});
  const [recurringTicket, setRecurringTicket] = useState({});
  const [newPage, setNewPage] = useState(0);
  const [progressPage, setProgressPage] = useState(0);
  const [closedPage, setClosedPage] = useState(0);

  const ticketsApiNew = useSelector(
    (state: any) => state.maintenance.ticketsDataNew,
  );
  const ticketsApiProgress = useSelector(
    (state: any) => state.maintenance.ticketsDataProgress,
  );
  const ticketsApiClosed = useSelector(
    (state: any) => state.maintenance.ticketsDataClosed,
  );

  const getSingleTicketData = useSelector(
    (state: any) => state.maintenance.getTicketById,
  );

  const getPropertyData = useSelector(
    (state: any) => state.addInfoReducer.getSingleProperty,
  );

  const callingApi = () => {
    dispatch(fetchTickets(id, "new", newPage));
    dispatch(fetchTickets(id, "inProgress", progressPage));
    dispatch(fetchTickets(id, "closed", closedPage));
    dispatch(getSingleProperty(id));
  };

  let breadcrumbList: Array<Object> = [];

  useEffect(() => {
    if (getSingleTicketData && Object.keys(getSingleTicketData).length) {
      console.log("getSingleTicketData", getSingleTicketData);
      const formalizer = async () => {
        const totalImages = [];
        const totalVideos = [];
        const item = getSingleTicketData;
        if (item?.mediaObjects?.length) {
          item?.mediaObjects?.map((e) => {
            if (e?.imageObject) {
              totalImages.push(e?.imageObject);
            }
            if (e?.videoObject) {
              totalVideos.push(e?.videoObject);
            }
          });
          console.log("checi", totalImages, totalVideos);
          const imagesA = await getMedia(totalImages);
          const videoA = await getMedia(totalVideos);
          item.totalImageURL = imagesA;
          item.totalVideoUrls = videoA;
        }
        setVisible(true);
        setSelectedTickets(getSingleTicketData);
        setTrigger(1);
      };
      formalizer();
    }
  }, [getSingleTicketData]);

  if (location.state) {
    breadcrumbList = [
      {
        label: "Properties",
        route: "/properties",
      },
      {
        label: location.state?.name || getPropertyData?.name,
        route: location.state?.parentpath || `/property-detail/${id}`
      },
      {
        label: "Tickets",
        route: "",
      },
    ];
  } else {
    breadcrumbList = [
      {
        label: "Properties",
        route: "/properties",
      },
      {
        label: "Tickets",
        route: "",
      },
    ];
  }

  useEffect(() => {
    callingApi();
  }, []);

  useEffect(() => {
    if (location?.state?.from === "notification") {
      dispatch(getTicketById(id));
    }
  }, [location]);

  useEffect(() => {
    if (ticketsApiNew && ticketsApiNew.rows.length > 0) {
      let temp: any = [];
      temp = [...newTickets];
      temp.push(...ticketsApiNew.rows);
      setNewTickets(temp);
    }
  }, [ticketsApiNew]);

  useEffect(() => {
    if (ticketsApiClosed && ticketsApiClosed.rows.length > 0) {
      let temp: any = [];
      temp = [...closedTickets];
      temp.push(...ticketsApiClosed.rows);
      setClosedTickets(temp);
    }
  }, [ticketsApiClosed]);

  useEffect(() => {
    console.log("ticketsApiProgress", ticketsApiProgress);
    if (ticketsApiProgress && ticketsApiProgress.rows.length > 0) {
      let temp: any = [];
      temp = [...progressTickets];
      temp.push(...ticketsApiProgress.rows);
      setProgressTickets(temp);
    }
  }, [ticketsApiProgress]);

  const countUpdate = (param) => {
    if (param === "new") {
      dispatch(fetchTickets(id, "new", newPage + 1));
      setNewPage(newPage + 1);
    } else if (param === "inProgress") {
      dispatch(fetchTickets(id, "inProgress", progressPage + 1));
      setProgressPage(progressPage + 1);
    } else if (param === "closed") {
      dispatch(fetchTickets(id, "closed", closedPage + 1));
      setClosedPage(closedPage + 1);
    }
  };

  const getAndAssignRecurring = async (caseId) => {
    const url = `${singleMaintenanceTicketsService}?case_id=${caseId}`;
    const response = await axios.get(url);
    setRecurringTicket(response?.data?.result);
  };

  const popup = [
    {
      title: null,
      content: (
        <ViewTicket
          data={recurringTicket}
          onBack={(e) => setTrigger(1)}
          onCancel={() => setVisible(false)}
          trigger={trigger}
        />
      ),
      width: 920,
    },
    {
      title: null,
      content: (
        <ViewTicket
          data={selectedTicket}
          onRecurring={(e) => originalTicket(e)}
          onBack={(e) => setVisible(false)}
          onCancel={() => setVisible(false)}
          trigger={trigger}
        />
      ),
      width: 920,
    },
  ];

  const popupProps = {
    closable: trigger === 1 ? true : false,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  function originalTicket(e) {
    getAndAssignRecurring(e.id);
    setTrigger(0);
  }

  const getMedia = async (totalImages) => {
    const result = await getPresignedImage(totalImages);
    return result;
  };

  async function viewTicket(item) {
    console.log("item", item);
    const totalImages = [];
    const totalVideos = [];
    if (item?.mediaObjects?.length) {
      item?.mediaObjects?.map((e) => {
        if (e?.imageObject) {
          totalImages.push(e?.imageObject);
        }
        if (e?.videoObject) {
          totalVideos.push(e?.videoObject);
        }
      });
      console.log("checi", totalImages, totalVideos);
      const imagesA = await getMedia(totalImages);
      const videoA = await getMedia(totalVideos);
      item.totalImageURL = imagesA;
      item.totalVideoUrls = videoA;
    }
    setVisible(true);
    setSelectedTickets(item);
    setTrigger(1);
  }

  return (
    <>
      <DashboardLayout>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb list={breadcrumbList} />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <SectionHeader
              heading="Tickets"
              total={
                ticketsApiNew.count +
                ticketsApiProgress.count +
                ticketsApiClosed.count
              }
            />
            <Row gutter={24}>
              <Col xl={21} lg={24}>
                <Row gutter={24}>
                  <Col span={8}>
                    <Tickets
                      ticketlist={newTickets}
                      category="New"
                      count={ticketsApiNew.count}
                      onCount={() => countUpdate("new")}
                      viewTicket={(e) => viewTicket(e)}
                    />
                  </Col>
                  <Col span={8}>
                    <Tickets
                      ticketlist={progressTickets}
                      count={ticketsApiProgress.count}
                      onCount={() => countUpdate("inProgress")}
                      category="In Progress"
                      viewTicket={(e) => viewTicket(e)}
                    />
                  </Col>
                  <Col span={8}>
                    <Tickets
                      ticketlist={closedTickets}
                      count={ticketsApiClosed.count}
                      onCount={() => countUpdate("closed")}
                      category="Closed"
                      viewTicket={(e) => viewTicket(e)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};
