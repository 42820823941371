import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  Typography,
  Space,
  Select,
  Form,
  Tooltip,
  Button,
} from "antd";
import { useHistory } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getTemplates, retianTCPageData } from "../ducks/actions/tcAction";
import axios from "../../../../../utils/axiosInceptor";
import { authService, propertyService } from "../../../../../configs/constants";
import { template } from "@babel/core";

const { Text, Title, Paragraph } = Typography;
const { Option } = Select;

export default ({ currentStep, setCurrentStep }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorData, setEditorData] = useState("");
  const [templates, setTemplates] = useState([]);

  const onEditorStateChange = (editorState) => {
    const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorData(data);
    setEditorState(editorState);
    dispatch(retianTCPageData(data));
  };

  const tcPageStoreData = useSelector(
    (state: any) => state.addPropertyReducer.tcPageData,
  );

  const templatesData = useSelector(
    (state: any) => state.tcReducer.tcTemplates,
  );

  useEffect(() => {
    if (templatesData.length > 0) {
      setTemplates(templatesData);
      setText(templatesData[0].id);
    }
  }, [templatesData]);

  const html = "<p>Hey this <strong>editor</strong> rocks 😀</p>";

  useEffect(() => {
    const state = EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(html).contentBlocks),
    );
    const stateData = draftToHtml(convertToRaw(state.getCurrentContent()));
    setEditorState(state);
    setEditorData(stateData);
    dispatch(getTemplates());
    dispatch(retianTCPageData(stateData));
  }, []);

  const onSubmit = async () => {
    const url = `${propertyService}/property-landlord/terms-and-conditions`;
    const assetId = JSON.parse(window.localStorage.getItem("createdProperty"))
      .asset.id;
    const postJson = {
      property_id: assetId,
      text: editorData,
    };
    try {
      const headers = {
        Authorization:
          "Bearer" +
          " " +
          JSON.parse(window.localStorage.getItem("login-response")).token,
      };
      await axios.post(url, postJson, { headers });
      window.localStorage.removeItem("createdProperty");
      history.push("/properties");
    } catch (e) {
      console.log("EEE", e);
    }
  };

  const setText = (id) => {
    const content = templatesData.find((item) => item.id === id).content;
    const state = EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(content).contentBlocks),
    );
    const stateData = draftToHtml(convertToRaw(state.getCurrentContent()));
    setEditorState(state);
    setEditorData(stateData);
  };

  const handleTemplateChange = (id) => {
    setText(id);
  };

  return (
    <Form layout="vertical" name="addInformation" onFinish={onSubmit}>
      <Card
        bordered={false}
        bodyStyle={{ padding: "32px 32px" }}
        className="h-auto"
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space direction="vertical" size={16}>
              <Title level={3} className="mb-0">
                Terms & Conditions
              </Title>
              <Text className="defaultFont ag-secondary">
                You can select one from the drop down menu below.
              </Text>
            </Space>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={8}>
                <Form.Item
                  // name="template_name"
                  label={
                    <span>
                      Choose an existing template&nbsp;
                      <Tooltip title="What template you select?">
                        <QuestionCircleOutlined className="defaultFont" />
                      </Tooltip>
                    </span>
                  }
                >
                  <Select
                    placeholder="Terms and conditions template"
                    onSelect={(e) => handleTemplateChange(e)}
                    value={templates.length > 0 && templates[0].id}
                  >
                    {templates.map((e, i) => (
                      <Option key={i} value={e.id}>
                        {e.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={16}></Col>
              <Col flex="auto">
                <Paragraph className="ag-primary">Preview:</Paragraph>
              </Col>
              <Col flex="135px">
                <Paragraph className="text-right ag-default">
                  Edit this template
                </Paragraph>
              </Col>
              <Col span={24}>
                <Editor
                  editorState={editorState}
                  wrapperClassName="editor-main"
                  editorClassName="editor-body"
                  onEditorStateChange={onEditorStateChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <div className="steps-action py-3">
        {/* <Button type="primary" onClick={() => setCurrentStep(currentStep - 1)}>
          Back
        </Button> */}
        <Button
          type="primary"
          /* onClick={() => setCurrentStep(currentStep + 1)} */ htmlType="submit"
        >
          Submit
        </Button>
      </div>
    </Form>
  );
};
