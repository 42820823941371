import React, { useEffect, useState } from "react";
import { Col, Select } from "antd";
import { InputField, SelectField } from "../../../../atoms/FormElement";
import {
  getUsageType,
  getUnitType,
} from "../../AddProperty/ducks/actions/addInfoAction";
import { useSelector, useDispatch } from "react-redux";

export default ({ control, errors, useWatch, propertyData, setValue }) => {
  const dispatch = useDispatch();
  const [unsageList, setUsageList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [pData, setPdata] = useState({});
  const apiUsageTypeData = useSelector(
    (state: any) => state.addInfoReducer.getUsageType,
  );
  const apiUnitTypeData = useSelector(
    (state: any) => state.addInfoReducer.getUnitType,
  );

  const checkUsage = useWatch({
    control,
    name: "unsagetype",
  });

  useEffect(() => {
    dispatch(getUsageType());
  }, []);

  useEffect(() => {
    if (!Array.isArray(propertyData)) {
      console.log('I AM original', propertyData)
      setPdata(propertyData);
    }
  }, [propertyData]);

  useEffect(() => {
    if (apiUsageTypeData) {
      setUsageList(apiUsageTypeData);
    }
  }, [apiUsageTypeData]);

  useEffect(() => {
    if (checkUsage) {
      dispatch(getUnitType(checkUsage));
    }
  }, [checkUsage]);

  useEffect(() => {
    if (apiUnitTypeData) {
      setUnitList(apiUnitTypeData);
    }
  }, [apiUnitTypeData]);

  return (
    <React.Fragment>
      <Col span={8}>
        <InputField
          isRequired={true}
          label="Building Name"
          fieldname="buildingName"
          control={control}
          validate={errors.buildingName && "error"}
          validMessage={errors.buildingName && errors.buildingName.message}
          initValue=""
          iProps={{ placeholder: "Al-Ghurair Residence" }}
          rules={{ required: "Please enter building name" }}
        />
      </Col>
      <Col span={8}>
        <InputField
          isRequired={true}
          label="Unit No."
          fieldname="unitno"
          control={control}
          initValue=""
          validate={errors.unitno && "error"}
          validMessage={errors.unitno && errors.unitno.message}
          rules={{
            pattern: {
              value: /^[0-9]*$/,
              message: "Value should be a Number",
            },
            required: "Please enter valid Mobile No.",
          }}
        />
      </Col>
      <Col span={8}>
        <SelectField
          isRequired={true}
          label="Type"
          fieldname="unsagetype"
          control={control}
          // @ts-ignore
          valueGot={pData?.type?.id}
          setValue={setValue}
          validate={errors.unsagetype && "error"}
          validMessage={errors.unsagetype && errors.unsagetype.message}
          rules={{ required: "Select Unit Type" }}
          initValue=""
          selectOption={unsageList.map((e, i) => (
            <Select.Option key={i} value={e.id}>
              {e.name}
            </Select.Option>
          ))}
        />
      </Col>

      <Col span={8}>
        <SelectField
          isRequired={true}
          label="Sub-Type"
          fieldname="unittype"
          valueGot={/* pData?.sub_type?.id */15}
          control={control}
          setValue={setValue}
          validate={errors.unittype && "error"}
          validMessage={errors.unittype && errors.unittype.message}
          initValue=""
          rules={{ required: "Select Unit Sub-Type" }}
          selectOption={unitList.map((e, i) => (
            <Select.Option key={i} value={e.id}>
              {e.name}
            </Select.Option>
          ))}
        />
      </Col>
      <Col span={8}>
        <InputField
          isRequired={true}
          label="Size, Sqft"
          fieldname="unitsize"
          control={control}
          validate={errors.unitsize && "error"}
          validMessage={errors.unitsize && errors.unitsize.message}
          initValue=""
          rules={{
            pattern: {
              value: /^[0-9]*$/,
              message: "Value should be a Number",
            },
            required: "Please enter Size.",
          }}
          iProps={{ placeholder: "3500" }}
        />
      </Col>
    </React.Fragment>
  );
};
