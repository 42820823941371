import React from "react";
import { useSelector } from "react-redux";
import { Row, Col, Card, Space, Typography, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Text, Title } = Typography;

export default () => {
  const storeData = useSelector((state: any) => state.addPropertyReducer);
  return (
    <>
      <Card
        bordered={false}
        bodyStyle={{ padding: "16px 32px 0" }}
        headStyle={{ padding: "16px 32px 0", borderBottom: 0 }}
        title={
          <Title level={5} className="ag-primary mb-0">
            General Information
          </Title>
        }
        extra={
          <Link to="#">
            <EditOutlined className="ag-primary ag-fontSize24" />
          </Link>
        }
      >
        <Row gutter={[20, 24]}>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Name
              </Title>
              <Text className="ag-primary" strong>
                Al-Ghurair Residence
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Built in
              </Title>
              <Text className="ag-primary" strong>
                ----
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Size, Sq.ft
              </Title>
              <Text className="ag-primary" strong>
                XXXX
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Status
              </Title>
              <Text className="ag-primary" strong>
                15 Occupied units
              </Text>
            </Space>
          </Col>
          <Col span={24}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Description:
              </Title>
              <Text className="ag-primary lineHeight28" strong>
                Al Ghurair Properties, the real estate development arm of Al
                Ghurair Investment is committed to developing and managing a
                diversifed real estate portfolio that strategically responds to
                the UAE residents’ needs. With convenience and connectivity at
                heart, Al Ghurair Properties projects create vibrant places that
                residents can call home, with amenities including swimming
                pools, health clubs and outstanding maintenance services, all
                while acting as a connection to the main leisure and business
                hubs in the city.
              </Text>
            </Space>
          </Col>
        </Row>
      </Card>
      <Divider className="m-0" />
      <Card
        bordered={false}
        bodyStyle={{ padding: "16px 20px 0" }}
        headStyle={{ padding: "16px 20px 0", borderBottom: 0 }}
        title={
          <Title level={5} className="ag-primary mb-0">
            Property Information
          </Title>
        }
        extra={
          <Link to="#">
            <EditOutlined className="ag-primary ag-fontSize24" />
          </Link>
        }
      >
        <Row gutter={[20, 24]}>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Neighborhood
              </Title>
              <Text className="ag-primary" strong>
                XX Neighborhood
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                City
              </Title>
              <Text className="ag-primary" strong>
                Deira
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Street Address
              </Title>
              <Text className="ag-primary" strong>
                XXXX
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                State/ Region
              </Title>
              <Text className="ag-primary" strong>
                --
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Zip Code
              </Title>
              <Text className="ag-primary" strong>
                ----
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Country
              </Title>
              <Text className="ag-primary" strong>
                Dubai
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Unit
              </Title>
              <Text className="ag-primary" strong>
                XX
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Parking
              </Title>
              <Text className="ag-primary" strong>
                Yes
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Furnished
              </Title>
              <Text className="ag-primary" strong>
                No
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Smoking
              </Title>
              <Text className="ag-primary" strong>
                Both Available
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Laundry
              </Title>
              <Text className="ag-primary" strong>
                Available
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Usage Type
              </Title>
              <Text className="ag-primary" strong>
                Residential
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Unit Type
              </Title>
              <Text className="ag-primary" strong>
                Apartments
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Unit Sub-Type
              </Title>
              <Text className="ag-primary" strong>
                3 Bedrooms
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                360° Tour Available
              </Title>
              <Text className="ag-primary" strong>
                Yes
              </Text>
            </Space>
          </Col>
        </Row>
        <Row gutter={[20, 24]}>
          <Col span={24}>
            <Title level={5} className="ag-primary mb-0">
              Amenities
            </Title>
          </Col>
        </Row>
        <Row gutter={[20, 24]}>
          <Col span={6}>
            <Title level={5} className="ag-secondary mb-0">
              Club house
            </Title>
          </Col>
          <Col span={6}>
            <Title level={5} className="ag-secondary mb-0">
              Day Care
            </Title>
          </Col>
          <Col span={6}>
            <Title level={5} className="ag-secondary mb-0">
              Gymnasium
            </Title>
          </Col>
          <Col span={6}>
            <Title level={5} className="ag-secondary mb-0">
              Elevators
            </Title>
          </Col>
          <Col span={6}>
            <Title level={5} className="ag-secondary mb-0">
              Club house
            </Title>
          </Col>
          <Col span={6}>
            <Title level={5} className="ag-secondary mb-0">
              Day Care
            </Title>
          </Col>
          <Col span={6}>
            <Title level={5} className="ag-secondary mb-0">
              Gymnasium
            </Title>
          </Col>
          <Col span={6}>
            <Title level={5} className="ag-secondary mb-0">
              Elevators
            </Title>
          </Col>
          <Col span={6}>
            <Title level={5} className="ag-secondary mb-0">
              Club house
            </Title>
          </Col>
          <Col span={6}>
            <Title level={5} className="ag-secondary mb-0">
              Day Care
            </Title>
          </Col>
          <Col span={6}>
            <Title level={5} className="ag-secondary mb-0">
              Gymnasium
            </Title>
          </Col>
          <Col span={6}>
            <Title level={5} className="ag-secondary mb-0">
              Elevators
            </Title>
          </Col>
          <Col span={6}>
            <Title level={5} className="ag-secondary mb-0">
              Club house
            </Title>
          </Col>
          <Col span={6}>
            <Title level={5} className="ag-secondary mb-0">
              Day Care
            </Title>
          </Col>
          <Col span={6}>
            <Title level={5} className="ag-secondary mb-0">
              Gymnasium
            </Title>
          </Col>
          <Col span={6}>
            <Title level={5} className="ag-secondary mb-0">
              Elevators
            </Title>
          </Col>
        </Row>
      </Card>
      <Divider className="m-0" />
      <Card
        bordered={false}
        bodyStyle={{ padding: "16px 20px 0" }}
        headStyle={{ padding: "16px 20px 0", borderBottom: 0 }}
        title={
          <Title level={5} className="ag-primary mb-0">
            Rent Information
          </Title>
        }
      >
        <Row gutter={[20, 24]}>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Amount
              </Title>
              <Text className="ag-primary" strong>
                Al-Ghurair Residence
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Invoice Schedule
              </Title>
              <Text className="ag-primary" strong>
                ----
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Starts on
              </Title>
              <Text className="ag-primary" strong>
                XXXX
              </Text>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" size={8}>
              <Title level={5} className="ag-secondary mb-0">
                Ends on
              </Title>
              <Text className="ag-primary" strong>
                15 Occupied units
              </Text>
            </Space>
          </Col>
        </Row>
      </Card>
    </>
  );
};
