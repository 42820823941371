import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Image,
  Typography,
  Spin,
  Upload,
  Space,
  Modal,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PicturePlaceholder from "../../../../../../assets/img/Picture-Placeholder.svg";
import { getPresignedImage } from "../../../AddProperty/ducks/actions/addInfoAction";
import axios from "axios";
import { propertyService } from "../../../../../../configs/constants";
import {
  getSingleUpload,
  uniquiFileName,
} from "../../../../../../utils/fileUpload";

const { Text, Title } = Typography;
const initPreview = {
  image: "",
  visible: false,
  title: "",
};

const dummyRequest = (response) => {
  setTimeout(() => {
    response.onSuccess("ok");
  }, 0);
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default ({ images, assetId, updateProp }) => {
  const [preview, setPreview] = useState(initPreview);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadbtn, setUploadbtn] = useState(false);

  const handleCancel = () => setPreview({ ...preview, visible: false });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreview({
      ...preview,
      image: file.url || file.preview,
      visible: true,
      title: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  const settingGallery = async (galleryPhotos) => {
    const buffer = [];
    let res = "";
    setLoading(true);
    await Promise.all(
      galleryPhotos.map(async (e, index) => {
        if (e.data) {
          res = await getPresignedImage(e.data);
          if (res) {
            buffer.push({
              key: index,
              uid: e.object_id.toString(),
              name: e.data,
              status: "done",
              url: res,
            });
          }
        }
      }),
    );
    if (buffer) {
      setFileList(buffer);
    }
    setLoading(false);
  };

  useEffect(() => {
    images.length > 0 && settingGallery(images);
  }, [images]);

  const removeImage = async (file, newFileList) => {
    setLoading(true);
    try {
      await axios.delete(
        `${propertyService}/property-landlord/${assetId}/gallery/${file.uid}`,
      );
      message.success("Image Successfully Removed");
      setFileList(newFileList);
      setLoading(false);
    } catch (e) {
      message.error("Something went wrong");
      setLoading(false);
    }
  };

  const addGallery = async () => {
    setLoading(true);
    setUploadbtn(false);
    const mediaArray = [];
    let filterImg = fileList.filter((item) => item.response === "ok");
    const norMalizedMutiImage = [];
    filterImg.forEach((e) =>
      norMalizedMutiImage.push({
        ...e,
        modifiedName: uniquiFileName(e?.originFileObj.name),
      }),
    );
    for (let i of norMalizedMutiImage) {
      await getSingleUpload(i.modifiedName, i.originFileObj);
    }
    norMalizedMutiImage.forEach((e) => {
      const mediaObj = {
        url: "http://s3LikeURL.aws.amazon.com",
        content_type: "image",
        mime_type: e.originFileObj.name.substr(
          e.originFileObj.name.lastIndexOf(".") + 1,
        ),
        content_purpose: "property_photo",
        object_key: e.modifiedName,
      };
      mediaArray.push(mediaObj);
    });
    const galleryJson = {
      asset_id: assetId,
      media: mediaArray,
    };
    const urlgal = `${propertyService}/property-landlord/gallery/`;
    try {
      await axios.post(urlgal, galleryJson);
      message.success("Images Uploaded Successfully");
      updateProp();
      setLoading(false);
    } catch (e) {
      message.error("something went wrong");
      setLoading(false);
    }
  };

  return (
    <Space direction="vertical" size={24} className="w-100">
      <Card
        bordered={false}
        className={`${fileList.length > 0 ? "card_gallery" : ""}`}
      >
        <Spin spinning={loading}>
          <Space
            direction="vertical"
            align="center"
            size={12}
            className="w-100"
          >
            {fileList.length < 1 && (
              <Image
                style={{ marginTop: "12px" }}
                src={PicturePlaceholder}
                alt="Profile Picture"
                preview={false}
                className="profileImage"
              />
            )}

            <Upload
              name="file"
              multiple={true}
              accept="image/*"
              onChange={({ fileList: newFileList, file }) => {
                if (file.status === "done") {
                  setFileList(newFileList);
                  setUploadbtn(true);
                }
                if (file.status === "removed") {
                  if (file.response === "ok") {
                    setFileList(newFileList);
                  } else {
                    removeImage(file, newFileList);
                  }
                }
              }}
              fileList={fileList}
              customRequest={dummyRequest}
              listType={fileList.length > 0 ? "picture-card" : "text"}
              onPreview={handlePreview}
            >
              {fileList.length > 0 ? (
                <Space direction="vertical" size={8}>
                  <PlusOutlined />
                  <Text>Upload</Text>
                </Space>
              ) : (
                <Space
                  direction="vertical"
                  align="center"
                  size={12}
                  className="w-100"
                >
                  <Button type="primary">Click to Upload</Button>
                  <Text type="secondary" className="smallFont12">
                    File types accepted include: JPEG, PNG
                  </Text>
                </Space>
              )}
            </Upload>
          </Space>
          <Modal
            visible={preview.visible}
            title={preview.title}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" style={{ width: "100%" }} src={preview.image} />
          </Modal>
        </Spin>
      </Card>
      <Space style={{ width: "100%", justifyContent: "space-between" }}>
        <Title level={3} className="mb-0">
          Gallery <Text className="ag-secondary">({fileList.length})</Text>
        </Title>
        {uploadbtn && (
          <Button type="primary" onClick={addGallery}>
            Update Gallery
          </Button>
        )}
      </Space>
    </Space>
  );
};
