import React, { useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import { InputField } from "../../../atoms/FormElement";
import { useWatch } from "react-hook-form";
import ChargesArray from "./ChargesArray";
import moment from "moment";

const { Text, Title } = Typography;

export default (props) => {
  const { control, getValues, setValue, register } = props;

  const [billingScheduleTotal, setBillingScheduleTotal] = useState(null);

  const otherchargesData: any = useWatch({
    control,
    name: "othercharges",
  });

  const billingsData = useWatch({ control, name: "billings" });
  const commencementDate = useWatch({ control, name: "commencementDate" });
  const expiryDate = useWatch({ control, name: "expiryDate" });
  const billingScheduleData = useWatch({
    control,
    name: "billingScheduleData",
  });
  const usageType = getValues("usage_type")?.value;

  useEffect(() => {
    // let billing_data: any = billingsData;
    // if (billing_data?.length > 0) {
    //   let temp_total_array = [];
    //   billing_data?.map((item, index) => {
    //     let innerData: any = item;
    //     if (innerData?.instaArray?.length > 0) {
    //       innerData?.instaArray?.map((nestItem, nestIndex) => {
    //         temp_total_array.push(parseFloat(nestItem?.total));
    //       });
    //     }
    //   });
    //   setBillingScheduleTotal(
    //     temp_total_array.reduce((a, b) => a + b, 0)?.toFixed(2),
    //   );
    //   register("billingScheduleTotal");
    //   setValue(
    //     "billingScheduleTotal",
    //     temp_total_array.reduce((a, b) => a + b, 0)?.toFixed(2),
    //   );
    // }
  }, [otherchargesData]);

  let totlaamount = [];
  const generateInstallment = (chargeArrayData, tenure, rent) => {
    const start = getValues("commencementDate");
    const end = getValues("expiryDate");
    const intallmentsNumber = chargeArrayData?.intallments?.value;

    let dateSlots = [];
    let total_months = Math.round(moment(end).diff(start, "month", true));
    //if (chargeArrayData?.type?.label == "BASE RENT") {
    // total_months = Math.round(moment(end).diff(start, "month", true));
    //}

    let total_days_array = [];
    let rent_end = "";
    for (let index = 1; index <= intallmentsNumber; index++) {
      let _start_month = moment(start).format("YYYY-MM-DD");

      let divide_minus_one = (12 / intallmentsNumber) * (index - 1);

      if (chargeArrayData?.type?.label === "BASE RENT") {
        divide_minus_one = (12 / intallmentsNumber) * (index - 1);
      }

      let divide = (12 / intallmentsNumber) * index;

      if (index > 1) {
        _start_month = moment(start)
          .add(divide_minus_one, "months")
          .format("YYYY-MM-DD");
      }

      let _end_month = null;
      if (index == 1) {
        _end_month = moment(start)
          .add(divide, "months")
          .subtract(1, "day")
          .format("YYYY-MM-DD");
      }

      if (intallmentsNumber == 1) {
        if (chargeArrayData?.type?.label === "BASE RENT") {
          _end_month = moment(start)
            .add(12, "months")
            .subtract(1, "day")
            .format("YYYY-MM-DD");
        } else {
          _end_month = moment(start)
            .add(total_months, "months")
            .subtract(1, "day")
            .format("YYYY-MM-DD");
        }
      }

      if (index > 1) {
        _end_month = moment(start)
          .add(divide, "months")
          .subtract(1, "day")
          .format("YYYY-MM-DD");
      }

      if (chargeArrayData?.type?.label === "BASE RENT") {
        rent_end = _end_month;
      }

      let installmentsDays = moment(_end_month).diff(_start_month, "days") + 1;
      total_days_array.push(installmentsDays);

      dateSlots.push({
        start: _start_month,
        end: _end_month,
        index,
        d: divide,
        days: installmentsDays,
      });
    }

    if (chargeArrayData?.type?.label === "BASE RENT" && total_months > 12) {
      let divide = (12 / intallmentsNumber) * (intallmentsNumber + 1);
      console.log("check rent end", rent_end);
      let _start_month = moment(rent_end).add(1, "day").format("YYYY-MM-DD");
      let _end_month = moment(start)
        .add(total_months, "months")
        .subtract(1, "day")
        .format("YYYY-MM-DD");

      let installmentsDays = moment(_end_month).diff(_start_month, "days") + 1;

      dateSlots.push({
        start: _start_month,
        end: _end_month,
        index: intallmentsNumber + 1,
        d: total_months,
        days: installmentsDays,
      });
    }

    let total_days = total_days_array.reduce((a, b) => a + b, 0);
    let per_day_installment = rent / total_days;

    if (dateSlots?.length > 0) {
      let insta = [];
      Array.from(Array(tenure).keys()).map((it, index) => {
        let amount = per_day_installment * dateSlots[index].days;
        let vat: any = 0;
        if (usageType == 2) {
          if (
            chargeArrayData?.type?.label != "SECURITY DEPOSIT" &&
            chargeArrayData?.type?.label !== "BASE RENT"
          ) {
            vat = per_day_installment * dateSlots[index].days * 0.05;
          } else {
            vat = "0";
          }
        } else {
          vat = per_day_installment * dateSlots[index].days * 0.05;
        }

        let total = Number(vat) + Number(amount);

        insta.push({
          installmentno: index + 1,
          start_date: dateSlots[index].start,
          end_date: dateSlots[index].end,
          amount: amount,
          vat: vat,
          total: total,
        });
        totlaamount.push(total);
      });
      if (total_months > 12 && chargeArrayData?.type?.label === "BASE RENT") {
        insta.push({
          installmentno: intallmentsNumber + 1,
          start_date: dateSlots[intallmentsNumber].start,
          end_date: dateSlots[intallmentsNumber].end,
          amount: 0,
          vat: 0,
          total: 0,
        });
      }
      register("billingScheduleTotal");
      setValue(
        "billingScheduleTotal",
        totlaamount.reduce((a, b) => a + b, 0),
      );
      return insta;
      //setChargeArrayFormData(insta);
    }
  };

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={6}>
          <Text className="fontWeight600 ag-default">Description</Text>
        </Col>
        <Col span={18}>
          <Row gutter={24}>
            <Col span={4}>
              <Text className="fontWeight600 ag-default">Instalment No.</Text>
            </Col>
            <Col span={4}>
              <Text className="fontWeight600 ag-default">
                Instalment Start Date
              </Text>
            </Col>
            <Col span={4}>
              <Text className="fontWeight600 ag-default">
                Instalment End Date
              </Text>
            </Col>
            <Col span={4}>
              <Text className="fontWeight600 ag-default">Actual Amount</Text>
            </Col>
            <Col span={4}>
              <Text className="fontWeight600 ag-default">VAT</Text>
            </Col>
            <Col span={4}>
              <Text className="fontWeight600 ag-default">Total Amount</Text>
            </Col>
          </Row>
        </Col>

        {otherchargesData?.map((item, index) => (
          <React.Fragment key={index}>
            <Col span={6}>
              <InputField
                label=""
                fieldname={`billings[${index}].billtype`}
                setValue={setValue}
                valueGot={item?.type?.label}
                control={control}
                initValue=""
                iProps={{ readOnly: true }}
              />
            </Col>
            <Col span={18}>
              <ChargesArray
                control={control}
                setValue={setValue}
                getValues={getValues}
                nestIndex={index}
                register={register}
                data={generateInstallment(
                  item,
                  item?.intallments?.value,
                  parseInt(item?.amount),
                )}
                // data={{
                //   ...item,
                //   ...{ start: commencementDate, end: expiryDate },
                // }}
              />
            </Col>
          </React.Fragment>
        ))}
        <Col span={24}>
          <Title level={4} style={{ textAlign: "end" }}>
            Total: {totlaamount.reduce((a, b) => a + b, 0).toFixed(2)}
          </Title>
        </Col>
      </Row>
    </>
  );
};
