import React from "react";
import {
  Row,
  Col,
  Typography,
  Space,
  Tag,
  Button,
  Image,
  Divider,
  message,
  Rate,
} from "antd";
import { EyeFilled, CloseOutlined, CaretRightFilled } from "@ant-design/icons";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { maintenanceService } from "../../../../../configs/constants";
import axios from "../../../../../utils/axiosInceptor";
import moment from "moment";

const { Title, Text } = Typography;

const ViewTicket = (props) => {
  const { data, onCancel, assignTech, trigger } = props;

  let status = data?.status;
  let type = data?.primary_category;
  let stype = data?.secondary_category;
  let appartment = data?.property_name;
  let tenant = data?.tenant;
  let technician = data?.technician_name
    ? data?.technician_name?.first_name
    : "";
  let recuring = data?.parent_ticket;
  let tickid = data?.case_id;

  const originalTicket = () => {
    props.onRecurring(data);
  };

  const viewStatus = () => {
    switch (status) {
      case "initial_assessment":
        return `New - ${technician}`;
      case "ag_not_responsible":
        return "AG Not Responsible";
      case "issue_resolved":
        return "Closed";
      case "issue_not_resolved":
        return "Closed";
      default:
        return `In Progress - ${technician}`;
    }
  };

  // TODO : MAKE CONSTANT
  const tenantStatus = () => {
    switch (status) {
      case "issue_not_resolved":
        return "Not Solved";
      case "ag_not_responsible":
        return "Not Solved";
      case "issue_resolved":
        return "Solved";
    }
  };

  const reopenTicket = async (id) => {
    // TODO : REMOVE PARTY ID
    const url1 = `${maintenanceService}/maintenance/ticket/${id}/reopen-closed?status=inProgress`;
    try {
      const {
        data: { result },
      } = await axios.get(url1);
      message.success("Ticket Reopen Successfully");
      props.onCancel();
    } catch (e) {
      console.log("Err", e);
    }
  };

  // const { type, subtype, status, tenant, appartment, description, technician, priority, datetime, id, confirmation_status, recuring } = data;

  return (
    <Row gutter={24}>
      <Col span={24}>
        {!props.onBack && (
          <Button
            className="p-0 h-auto w-auto ag-default mb-1"
            type="link"
            size="large"
            onClick={onCancel}
            icon={<CloseOutlined />}
          />
        )}
      </Col>
      <Col span={15}>
        <Row gutter={[32, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100" direction="vertical">
              <Title level={3}>
                {type} - {stype}
              </Title>
              <Space>
                <Tag className="tag-lg">{type}</Tag>
                <Tag className="tag-lg">{stype}</Tag>
              </Space>
            </Space>
          </Col>
          <Col span={24}>
            <Title level={5}>Description</Title>
            <Title level={5} className="grayBox">
              {data?.description}
            </Title>
          </Col>
          <Col span={24}>
            <Title level={5}>Media</Title>
            <Space size={[8, 0]} wrap>
              {data?.totalImageURL?.map((e, index) => {
                return (
                  <React.Fragment key={index}>
                    <Button className="ticket_thumb" type="link" size="large">
                      <Image
                        preview={true}
                        alt="Ticket"
                        src={e}
                        width="115"
                        height="118"
                      />
                      <EyeFilled className="thumb_icon" />
                    </Button>
                  </React.Fragment>
                );
              })}
              {data?.totalVideoUrls?.map((e, index) => {
                return (
                  <React.Fragment key={index}>
                    <Button
                      className="ticket_thumb video_thumb"
                      type="link"
                      size="large"
                    >
                      <video src={e} controls width="115" height="118"></video>
                      <CaretRightFilled className="thumb_icon" />
                    </Button>
                  </React.Fragment>
                );
              })}
            </Space>
          </Col>
        </Row>
      </Col>
      <Col span={9}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Title level={5}>Status:</Title>
            <Text className="defaultFont fontWeight600 ag-success">
              {viewStatus()}
            </Text>
          </Col>
          <Col span={24}>
            <Title level={5}>Tenant:</Title>
            <Text className="defaultFont fontWeight600">{tenant}</Text>
          </Col>
          <Col span={24}>
            <Title level={5}>Apartment:</Title>
            <Text className="defaultFont fontWeight600">{appartment}</Text>
          </Col>
          {status != "issue_resolved" &&
          status != "ag_not_responsible" &&
          status != "issue_not_resolved" ? (
            <></>
          ) : (
            // <Col span={24}>
            //   <Title level={5}>Priority:</Title>
            //   <Text className={`defaultFont fontWeight600 sentanceCase ${data.priority === 'high' && 'ag-error'}`}>{data.priority}</Text>
            // </Col>
            <>
              <Col span={24}>
                <Title level={5}>Technician Assigned:</Title>
                <Text className="defaultFont fontWeight600">
                  {technician && technician}
                </Text>
              </Col>
              <Col span={24}>
                <Title level={5}>Confirmation from Tenant:</Title>
                <Text className="defaultFont fontWeight600 ag-primary">
                  {tenantStatus()}
                </Text>
              </Col>
            </>
          )}

          {recuring && trigger === 1 && (
            <Col span={24}>
              <Title level={5}>Issue Type:</Title>
              <Text className="defaultFont fontWeight600 ag-primary">
                Recurring -{" "}
                <Button
                  type="link"
                  className="ag-success p-0"
                  onClick={() => originalTicket()}
                >
                  View original ticket
                </Button>
              </Text>
            </Col>
          )}

          <Divider className="m-0" />
          <Col span={24}>
            <Title level={5}>Date and Time:</Title>
            <Text className="defaultFont fontWeight600">
              {data?.start_date} |{" "}
              {data?.start_time &&
                moment(data?.start_time, "hh:mm:ss").format("LT")}{" "}
              {data?.end_time &&
                `to ${moment(data?.start_time, "hh:mm:ss").format("LT")}`}
            </Text>
          </Col>
          {status === "initial_assessment" && !technician && !props.onBack && (
            <Col span={24}>
              <Button type="primary" size="large" onClick={assignTech}>
                Assign Technician
              </Button>
            </Col>
          )}
          {trigger === 0 && (
            <Col span={24}>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  if (recuring) {
                    props.onBack(data);
                  } else {
                    onCancel();
                  }
                }}
              >
                Go back to ticket
              </Button>
            </Col>
          )}

          {status === "issue_not_resolved" && (
            <Col span={24}>
              <Button
                type="primary"
                size="large"
                onClick={() => reopenTicket(tickid)}
              >
                Reopen Ticket
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ViewTicket;
