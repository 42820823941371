import React, { useState } from "react";
import {
  Layout,
  Row,
  Col,
  Breadcrumb,
  Steps,
  Button,
  message,
  Spin,
  Card,
} from "antd";
import { useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import Sidebar from "../../../molecules/Sidebar";
import Header from "../../../molecules/Header";
import Footer from "../../../molecules/Footer";
import {
  AddInformation,
  PaymentPlans,
  TermsAndConditions,
  PropertySide,
} from "./components";

const antIcon = <LoadingOutlined spin />;
const { Content } = Layout;

const { Step } = Steps;

// CreateProperty;

export default () => {
  const [current, setCurrent] = useState(0);
  const [uid, setUid] = useState(null);
  const [profilepicUploaded, setProfilePicUploaded] = useState([]);
  const [galaryPicUploaded, setGalaryPicUploaded] = useState([]);

  const spinnerValue = useSelector(
    (state: any) => state.spinnerReducer.activateSpinner,
  );

  const steps = [
    {
      title: "Add Information",
      content: (
        <AddInformation
          currentStep={current}
          setCurrentStep={setCurrent}
          profilepicUploaded={profilepicUploaded}
          galaryPicUploaded={galaryPicUploaded}
          setUid={setUid}
        />
      ),
    },
    {
      title: "Enter Payment Plans",
      content: (
        <PaymentPlans
          currentStep={current}
          uid={uid}
          setCurrentStep={setCurrent}
        />
      ),
    },
    {
      title: "Terms And Conditions",
      content: (
        <TermsAndConditions currentStep={current} setCurrentStep={setCurrent} />
      ),
    },
    /* {
      title: "Review and Post",
      content: (
        <ReviewAndPost currentStep={current} setCurrentStep={setCurrent} />
      ),
    }, */
  ];

  return (
    <Spin indicator={antIcon} size="large" spinning={spinnerValue}>
      <Layout className="main-layout">
        <Header />
        <Layout>
          <Sidebar />
          <Layout className="layout-space">
            <Content className="main-content">
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Breadcrumb separator={<RightOutlined />}>
                    {/* <Breadcrumb.Item>
                    <Link to="/dashboard">Dashboard</Link>
                  </Breadcrumb.Item> */}
                    <Breadcrumb.Item>
                      <Link to="/properties">Properties</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Add Property</Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
              </Row>

              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Card bordered={false} bodyStyle={{ padding: "30px 54px" }}>
                    <Steps current={current}>
                      {steps.map((item, i) => (
                        <React.Fragment key={i}>
                          <Step key={item.title} title={item.title} />
                        </React.Fragment>
                      ))}
                    </Steps>
                  </Card>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col
                  flex="0 1 330px"
                  className={`${
                    current === 1 || current === 2 ? "s-hide" : ""
                  }`}
                >
                  <PropertySide
                    currentStep={current}
                    setProfilePicUploaded={setProfilePicUploaded}
                    setGalaryPicUploaded={setGalaryPicUploaded}
                  />
                </Col>
                <Col flex="1 1 500px">{steps[current].content}</Col>
              </Row>
              <Footer />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </Spin>
  );
};
