import * as React from "react";
import {
  Row,
  Col,
  Space,
  Typography,
  Form,
  Select,
  Divider,
  Button,
  Input,
} from "antd";
import {
  PlusOutlined,
  FilterOutlined,
  SearchOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;

export default (props) => {
  const {
    heading,
    total,
    importAction,
    filterAction,
    addNewAction,
    handleShow,
    showOption,
    handleView,
    viewOption,
    search,
  } = props;

  function handleChange(value) {
    console.log(value);
  }

  return (
    <Row gutter={[24, 24]}>
      <Col flex="auto">
        <Space size={8} className="h-100">
          {/* {headingOptions.length > 1 ?
            <Select
            bordered={false}
            labelInValue
            onChange={headingAction}
            size="large"
            >
              <Select.Option value="all">All Request</Select.Option>
              <Select.Option value="selected">Selected</Select.Option>
            </Select>
            :
          <Title level={2} className="custom-title32">{headingOptions[0]}</Title>
          } */}
          <Title level={2} className="custom-title32">
            {heading}
          </Title>
          {typeof total === "number" ? (
            <Title level={5} className="mb-0 head-border">
              {total} Total
            </Title>
          ) : (
            <Title level={5} className="mb-0">
              {total}
            </Title>
          )}
        </Space>
      </Col>
      {importAction && (
        <Col>
          <Button
            size="large"
            icon={<DownloadOutlined />}
            onClick={importAction}
          >
            Import
          </Button>
        </Col>
      )}
      {filterAction && (
        <Col>
          <Button size="large" icon={<FilterOutlined />} onClick={filterAction}>
            Filters
          </Button>
        </Col>
      )}
      {addNewAction && (
        <Col>
          <Button size="large" type="primary" onClick={addNewAction}>
            Add New <PlusOutlined />
          </Button>
        </Col>
      )}
      {!props.noBorder && (
        <>
          <Col span={24}>
            <Form
              className="ag-filter-bar"
              name="property_filter"
              layout="inline"
            >
              {showOption && showOption.length && (
                <Form.Item
                  name="show"
                  label="Show"
                  initialValue={{ value: "All" }}
                >
                  <Select
                    bordered={false}
                    labelInValue
                    onChange={handleShow}
                    size="large"
                    options={showOption}
                  />
                </Form.Item>
              )}
              {viewOption && viewOption.length && (
                <Form.Item
                  name="view"
                  label="View as"
                  initialValue={{ value: "0" }}
                >
                  <Select
                    bordered={false}
                    labelInValue
                    onChange={handleView}
                    size="large"
                    options={viewOption}
                  />
                </Form.Item>
              )}
              {search && (
                <Form.Item>
                  <Input
                    bordered={false}
                    placeholder="Search"
                    prefix={<SearchOutlined />}
                    size="large"
                    onChange={search}
                  />
                </Form.Item>
              )}
            </Form>
          </Col>

          <Divider className="mt-0 ag-border-cold9" />
        </>
      )}
    </Row>
  );
};
