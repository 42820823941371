/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Button,
  Upload,
  Typography,
  Image,
  Space,
  Card,
  Row,
  Col,
  Modal,
} from "antd";
import PicturePlaceholder from "../../../../../assets/img/Picture-Placeholder.svg";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
// import MapImage from "../../../../../assets/img/map1.png";
import ImgCrop from "antd-img-crop";

const { Text, Title } = Typography;

const initPreview = {
  image: "",
  visible: false,
  title: "",
};

export default ({
  currentStep,
  setProfilePicUploaded,
  setGalaryPicUploaded,
}) => {
  const [fileList, setFilelist] = useState([]);
  const [fileList1, setFilelist1] = useState([]);
  const [proPic, setPropic] = useState();
  const [preview, setPreview] = useState(initPreview);

  const dummyRequest = (response) => {
    setTimeout(() => {
      response.onSuccess("ok");
    }, 0);
  };

  const profileprops = {
    name: "file",
    accept: "image/*",
    async onChange(info) {
      if (info.file.status === "done") {
        // @ts-ignore
        setPropic(window.URL.createObjectURL(info?.file?.originFileObj));
        setFilelist(info.fileList);
        let fl = [...info.fileList].slice(-1);
        setProfilePicUploaded(fl);
      }
    },
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleCancel = () => setPreview({ ...preview, visible: false });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreview({
      ...preview,
      image: file.url || file.preview,
      visible: true,
      title: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  return (
    <Space direction="vertical" size={24} className="w-100">
      <Card
        bordered={false}
        className={`${fileList.length > 0 ? "card_coverImage" : ""}`}
        cover={
          fileList.length > 0 ? <Image alt="Pretty Photo" src={proPic} /> : null
        }
      >
        <Space direction="vertical" align="center" size={12} className="w-100">
          {fileList.length < 1 ? (
            <Image
              style={{ marginTop: "12px" }}
              src={PicturePlaceholder}
              alt="Profile Pictuer"
              preview={false}
            />
          ) : null}
          <ImgCrop aspect={1 / 0.7}>
            <Upload
              {...profileprops}
              fileList={fileList}
              customRequest={dummyRequest}
            >
              {fileList.length > 0 ? (
                <Button
                  className="ag-gray-button-outline overButton"
                  icon={<EditOutlined />}
                >
                  Change Photo
                </Button>
              ) : (
                <Button type="primary">Click to Upload</Button>
              )}
            </Upload>
          </ImgCrop>
          {fileList.length < 1 ? (
            <Text type="secondary" className="smallFont12">
              File types accepted include: JPEG, PNG
            </Text>
          ) : null}
        </Space>
      </Card>
      <Title level={3} className="mb-0">
        Property Photo <Text type="danger">*</Text>
      </Title>
      <Card
        bordered={false}
        className={`${fileList1.length > 0 ? "card_gallery" : ""}`}
      >
        <Space direction="vertical" align="center" size={12} className="w-100">
          {fileList1.length < 1 && (
            <Image
              style={{ marginTop: "12px" }}
              src={PicturePlaceholder}
              alt="Profile Pictuer"
              preview={false}
            />
          )}

          <Upload
            name="file"
            multiple={true}
            accept="image/*"
            onChange={async ({ fileList: newFileList, file }) => {
              if (file.status === "done" || file.status === "removed") {
                setFilelist1(newFileList);
                setGalaryPicUploaded(newFileList);
              }
            }}
            fileList={fileList1}
            customRequest={dummyRequest}
            listType={fileList1.length > 0 ? "picture-card" : "text"}
            onPreview={handlePreview}
          >
            {fileList1.length > 0 ? (
              <Space direction="vertical" size={8}>
                <PlusOutlined />
                <Text>Upload</Text>
              </Space>
            ) : (
              <Space
                direction="vertical"
                align="center"
                size={12}
                className="w-100"
              >
                <Button type="primary">Click to Upload</Button>
                <Text type="secondary" className="smallFont12">
                  File types accepted include: JPEG, PNG
                </Text>
              </Space>
            )}
          </Upload>
        </Space>
        <Modal
          visible={preview.visible}
          title={preview.title}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={preview.image} />
        </Modal>
      </Card>
      <Title level={3} className="mb-0">
        Gallery <Text className="ag-secondary">({fileList1.length})</Text>{" "}
        <Text type="danger">*</Text>
      </Title>

      {/* <Row justify="center" gutter={16}>
           <Col span={14}>
           <Image
                alt="Pic1"
                src={window.URL.createObjectURL(fileList1[0]?.originFileObj)}
                preview={false}
                className="w-100"
              />
            </Col>
            <Col span={10}>
              <Space direction="vertical" size={12} className="w-100">
                {fileList1.map((e, index) => {
                  if (index > 0) {
                    return (
                      <Image
                        alt="Pic2"
                        src={window.URL.createObjectURL(e.originFileObj)}
                        preview={false}
                        className="w-100"
                      />
                    );
                  }
                })}
              </Space>
            </Col>
              </Row>
        ) : (
            <Space
              direction="vertical"
              align="center"
              size={12}
              className="w-100"
            >
              <Image
                style={{ marginTop: "12px" }}
                src={PicturePlaceholder}
                alt="Profile Pictuer"
              />
              <Upload
                {...galleryprops}
                fileList={fileList1}
                customRequest={dummyRequest}
              >
                <Button type="primary">Click to Upload</Button>
              </Upload>
              <Text type="secondary" className="smallFont12">
                File types accepted include: JPEG, PNG
            </Text>
            </Space>
          )}
      
      <div className="card_gallery flexContainer">
        {fileList1.length > 0 ? (
          <Upload
            {...galleryprops}
            fileList={fileList1}
            customRequest={dummyRequest}
          >
            <Button
              className="ag-gray-button-outline overButton"
              icon={<EditOutlined />}
            >
              Add Media
            </Button>
          </Upload>
      ) : null}
      </div>
      <Card
        className={`card_coverImage ${currentStep != 3 ? "s-hide" : ""}`}
        bordered={false}
        cover={<Image alt="Map" src={MapImage} />}
      >
        <Button
          className="ag-gray-button-outline overButton overButton-black"
          icon={<EditOutlined />}
        >
          Change Map
        </Button>
      </Card>
      <Title level={3} className={`mb-0 ${currentStep != 3 ? "s-hide" : ""}`}>
        Map <Text type="danger">*</Text>
      </Title> */}
    </Space>
  );
};
