import { baseContractService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";
import {
  GET_BOOKINGFORMS,
  ADD_UNITS_VISIBLE,
  SET_LEAD_ID,
  GET_CURRENT_PAGE,
} from "./constants";

export const getBookingForms = (limit, page = 0, value = "", field) => async (
  dispatch,
) => {
  const url = `${baseContractService}/get-all-bookings?type=Lead&limit=${limit}&page=${page}${
    field ? `&${field}=${value}` : ""
  }`;
  const {
    data: { result },
  } = await axios.get(url);
  if (result) {
    dispatch({
      type: GET_BOOKINGFORMS,
      data: result,
    });
  }
};
export const visiblePopup = () => (dispatch) => {
  dispatch({
    type: ADD_UNITS_VISIBLE,
    data: true,
  });
};

export const getLeadID = (leadID) => (dispatch) => {
  dispatch({
    type: SET_LEAD_ID,
    data: leadID,
  });
};

export const getCurrentPage = (currentPage) => (dispatch) => {
  dispatch({
    type: GET_CURRENT_PAGE,
    data: currentPage,
  });
};
