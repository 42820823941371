import { combineReducers } from "redux";
import signup from "../../app/modules/SignUp/duck/reducer";
import header from "../../app/molecules/Header/duck/reducer";
import addPropertyReducer from "../../app/modules/Properties/AddProperty/ducks/reducers";
import addInfoReducer from "../../app/modules/Properties/AddProperty/ducks/reducers/addInfoReducer";
import paymentReducer from "../../app/modules/Properties/AddProperty/ducks/reducers/paymentReducer";
import tcReducer from "../../app/modules/Properties/AddProperty/ducks/reducers/tcReducer";
import spinnerReducer from "../../app/modules/Properties/AddProperty/ducks/reducers/spinReducer";
import bookingReducer from "../../app/modules/Properties/BookingForm/ducks/reducers";
import dashboard from "../../app/modules/Properties/ducks/reducers";
import tenants from "../../app/modules/Tenants/ducks/reducers";
import tenantsSummary from "../../app/modules/Tenants/TenantDetails/ducks/reducers";
import reviews from "../../app/modules/Reviews/ducks/reducers";
import maintenance from "../../app/modules/Maintenance/ducks/reducer";
import opertunities from "../../app/modules/LeadManagement/OpportunityList/ducks/reducers";
import leads from "../../app/modules/LeadManagement/Leads/ducks/reducers";
import leadDetails from "../../app/modules/LeadManagement/LeadsDetail/ducks/reducers";
import opportunityDetails from "../../app/modules/LeadManagement/OpportunityDetail/ducks/reducers";
import propertyDetails from "../../app/modules/Properties/PropertyDetail/ducks/reducers";
import contractBookingForm from "../../app/modules/ContractFlow/ContractBookingForm/ducks/reducers";
import leasingForm from "../../app/modules/LeasingFormNew/ducks/reducers";
import tenantDetails from "../../app/modules/ContractTenantDetails/ducks/reducers";
import notification from "../../app/modules/Notification/ducks/reducers";
import bookings from "../../app/modules/BookingformList/ducks/reducers";
import contracts from "../../app/modules/Contracts/ducks/reducers";
import leaseForms from "../../app/modules/LeasingForm/ducks/reducers";
import lease from "../../app/modules/LeaseListing/ducks/reducers";
import ejari from "../../app/modules/Ejari/ducks/reducers";

const rootReducer = combineReducers({
  header,
  signup,
  dashboard,
  addPropertyReducer, // old
  addInfoReducer,
  propertyDetails,
  paymentReducer,
  tcReducer,
  spinnerReducer,
  bookingReducer,
  reviews,
  tenants,
  tenantsSummary,
  maintenance,
  opertunities,
  leads,
  leadDetails,
  opportunityDetails,
  tenantDetails,
  contractBookingForm,
  leasingForm,
  notification,
  bookings,
  leaseForms,
  contracts,
  lease,
  ejari,
});

export default rootReducer;
