import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { leaseService } from "../../../../configs/constants";

const notificationUrl = `${leaseService}/leads/notification/receive`;

export const getNotifications = (page) => {
  return async (dispatch) => {
    const url = `${notificationUrl}?limit=10&page=${page}&status=`;
    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_NOTIFICATIONS_ALL,
      data: result,
    });
  };
};

export const getNotificationByCalendar = (start, end) => {
  return async (dispatch) => {
    const url = `${notificationUrl}?page=0&limit=1000&start_date=${start}&end_date=${end}&status=`;
    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_NOTIFICATIONS_BYDATES,
      data: result,
    });
  };
};

export const getNewNotifications = (page) => {
  return async (dispatch) => {
    const url = `${notificationUrl}?limit=10&page=${page}&status=new`;
    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_NEW_NOTIFICATIONS_ALL,
      data: result,
    });
  };
};
