import axios from "../../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { leaseService } from "../../../../../configs/constants";

const tenantdetails = `${leaseService}/leads/tenant`;
// const unitdetails = `${leaseService}/leads/lease/6/property-unit-details`;
const bookingTerms = `${leaseService}/leads/lease`;
const leaseTerms = `${leaseService}/leads/lease`;
const paymentSchedule = `${leaseService}/leads/payment-schedule`;

// AddGeneral Info Actions
const headers = {
  Authorization:
    "Bearer" +
    " " +
    JSON.parse(window.localStorage.getItem("login-response"))?.token,
};

export const getTenantDetails = (param) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${tenantdetails}/${param}`, { headers });
    dispatch({
      type: action_types.GET_TENANT_DETAILS,
      data: result,
    });
  };
};

export const getUnitDetails = (leaseid) => {
  return async (dispatch) => {
    const unitdetails = `${leaseService}/leads/lease/${leaseid}/property-unit-details`;
    const {
      data: { result },
    } = await axios.get(unitdetails, { headers });
    dispatch({
      type: action_types.GET_UNIT_DETAILS,
      data: result,
    });
  };
};

export const getLeaseTerms = (param) => {
  console.log("check url", `${leaseTerms}/${param}/booking-terms`);
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${leaseTerms}/${param}/booking-terms`, { headers });
    dispatch({
      type: action_types.GET_LEASE_TERMS,
      data: result,
    });
  };
};

export const getBookingTerms = (param) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${bookingTerms}/${param}/booking-terms`, { headers });
    dispatch({
      type: action_types.GET_BOOKING_TERMS,
      data: result,
    });
  };
};

export const getPaymentSchedule = (param) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${paymentSchedule}/${param}`, { headers });
    dispatch({
      type: action_types.GET_PAYMENT_SCHEDULE,
      data: result,
    });
  };
};
