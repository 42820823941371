import React from "react";
import { Row, Col, Card, Space, Typography, Avatar, Divider } from "antd";

const { Title, Text } = Typography;

const DashboardHeader = ({ user, figures }) => {
  return (
    <Card
      bordered={false}
      className="ag-nospace-head ag-noborder-head h-auto"
      bodyStyle={{ paddingTop: "3.5rem" }}
    >
      <Row gutter={24} wrap={false}>
        <Col flex="auto">
          <Space size={22}>
            <Avatar size={72} src={user.userPic} />
            <Space direction="vertical" size={12}>
              <Title level={4} className="mb-0">
                Hello, {user.username}. Welcome to your dashboard.
              </Title>
              <Text>{user.designation}</Text>
            </Space>
          </Space>
        </Col>
        <Col>
          <Space size={32}>
            {figures.map((item, index) => (
              <React.Fragment key={index}>
                <Space direction="vertical" size={4} className="text-center">
                  <Text>{item.label}</Text>
                  <Text className="ag-fontSize30 mb-0 ag-default">
                    {item.value}
                  </Text>
                </Space>
                {index != figures.length - 1 && (
                  <Divider
                    type="vertical"
                    className="m-0"
                    style={{ height: 40 }}
                  />
                )}
              </React.Fragment>
            ))}
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default DashboardHeader;
