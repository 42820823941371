import React, { useEffect, useState } from "react";
import { Col } from "antd";
import { InputField, ReactSelectField } from "../../../atoms/FormElement";

import { bedroomCategory } from "../../Properties/ducks/constants";

export default (props) => {
  const { control, unitDetails, setValue } = props;

  useEffect(() => {
    if (unitDetails) {
      setValue("building_name", unitDetails?.building_name);
      setValue("dewa_details", unitDetails?.dewa_details);
      setValue("unit_code", unitDetails?.unit_code);
      if (unitDetails?.type) {
        setValue("usage_type", {
          value: unitDetails?.type?.id,
          label: unitDetails?.type?.name,
        });
      }
      if (unitDetails?.type) {
        setValue("type", {
          value: unitDetails?.unit_type?.id,
          label: unitDetails?.unit_type?.name,
        });
      }
      if (unitDetails?.sub_type) {
        setValue("sub_type", {
          value: unitDetails?.sub_type?.id,
          label: unitDetails?.sub_type?.name,
        });
      }
      setValue("size", unitDetails?.size);
    }
  }, [unitDetails]);

  return (
    <>
      <Col span={8}>
        <InputField
          label="Building Name"
          fieldname="building_name"
          control={control}
          initValue=""
          iProps={{ placeholder: "Al-Ghurair Residence", disabled: true }}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="Unit Code"
          fieldname="unit_code"
          control={control}
          initValue=""
          iProps={{ placeholder: "Unit Code", disabled: true }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="Usage Type"
          fieldname="usage_type"
          control={control}
          initValue=""
          iProps={{ placeholder: "", isDisabled: true }}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="Size, Sqft"
          fieldname="size"
          control={control}
          initValue={""}
          iProps={{ placeholder: "2000", disabled: true }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="Unit Type"
          fieldname="type"
          control={control}
          initValue=""
          iProps={{ placeholder: "Select one", isDisabled: true }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="Sub-Type"
          fieldname="sub_type"
          control={control}
          initValue=""
          iProps={{
            placeholder: "Select one",
            isDisabled: true,
          }}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="DEWA"
          fieldname={`dewa_details`}
          control={control}
          initValue=""
          iProps={{
            placeholder: "Enter details",
            disabled: props.disableAll,
          }}
        />
      </Col>
    </>
  );
};
