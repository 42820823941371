import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { ReactSelectField, DateField } from "../../../atoms/FormElement";
import { useFieldArray, useWatch } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { getLeaseNature } from "../ducks/actions";

var _ = require("lodash");
const initparking = { slot: "" };

export default (props) => {
  const dispatch = useDispatch();
  const {
    control,
    errors,
    getValues,
    setValue,
    data,
    parking,
    isClearTriggered,
  } = props;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "free",
  });

  const [parkSlot, setParkSlot] = useState([]);

  useEffect(() => {
    dispatch(getLeaseNature());
  }, []);

  const leaseNatureData = useSelector(
    (state: any) => state.leasingForm.leaseNature,
  );

  useEffect(() => {
    if (isClearTriggered) {
      setValue("commencementDate", null);
      setValue("expiryDate", null);
      setValue("moveInDate", null);
      setValue("rentfreestart", null);
      setValue("rentfreeend", null);
      let arr = [];
      arr = getValues().free;
      arr.forEach((item, index) => {
        setValue(`free[${index}].slot`, "");
      });
      let temp = [];
      parking.map((item) => {
        temp.push({ label: item, value: item, isDisabled: false });
      });
      setParkSlot(temp);
    }
  }, [isClearTriggered]);

  useEffect(() => {
    if (data) {
      data?.lease_commencement_date &&
        setValue(
          "commencementDate",
          moment(data?.lease_commencement_date, "YYYY-MM-DD"),
        );
      data?.lease_expiry_date &&
        setValue("expiryDate", moment(data?.lease_expiry_date, "YYYY-MM-DD"));

      data?.rent_free_start_date &&
        setValue(
          "rentfreestart",
          moment(data?.rent_free_start_date, "YYYY-MM-DD"),
        );
      data?.rent_free_end_date &&
        setValue("rentfreeend", moment(data?.rent_free_end_date, "YYYY-MM-DD"));

      if (data?.move_in_date) {
        setValue("moveInDate", moment(data.move_in_date, "YYYY-MM-DD"));
      }
      if (data?.no_of_free_parking_slots) {
        for (let i = 0; i < data?.no_of_free_parking_slots; i++)
          if (data?.free_parking_slots.length > 0) {
            append({
              slot: {
                label: data?.free_parking_slots[i],
                value: data?.free_parking_slots[i],
              },
            });
          } else {
            append(initparking);
          }
      }
      if (data?.lease_nature) {
        setValue("leaseNature", {
          label: data?.lease_nature?.name,
          value: data?.lease_nature?.id,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (parking) {
      let temp = [];
      parking.map((item) => {
        temp.push({ label: item, value: item, isDisabled: false });
      });
      setParkSlot(temp);
    }
  }, [parking]);

  const commenceDate = useWatch({
    control,
    name: "commencementDate",
  });

  const setLeaseEndDate = (start) => {
    setValue("expiryDate", moment(start).add(1, "years").subtract(1, "day"));
    setRentfree();
  };

  const disableClone = (slot) => {
    let arr = [];
    arr = getValues().free;
    let check = arr.find((item) => item.slot.label == slot.label);
    if (check) {
      let newArr = [];
      newArr = [...parkSlot];
      parkSlot.map((item, index) => {
        let b = {};
        b = arr.find((y) => y.slot.label == item.label);
        if (b) {
          newArr[index].isDisabled = true;
        } else {
          newArr[index].isDisabled = false;
        }
      });
      setParkSlot(Object.assign([], newArr));
    }
  };

  const setRentfree = () => {
    let x: any = "";
    let enddate = getValues("expiryDate");
    let startdate = getValues("commencementDate");
    x = moment(enddate).diff(moment(startdate), "months", true);
    if (x > 12) {
      setValue("rentfreestart", moment(startdate).add(12, "month"));
      setValue("rentfreeend", moment(enddate));
    } else {
      setValue("rentfreestart", null);
      setValue("rentfreeend", null);
    }
  };

  const disableCommenceDate = (current) => {
    return (
      current &&
      current.valueOf() <
        (data?.lease_commencement_date
          ? moment(data?.lease_commencement_date, "YYYY-MM_DD")
          : moment().subtract(1, "day"))
    );
  };

  const disableExpiryDate = (current) => {
    // console.log(moment(commenceDate).add(1, "year").add(1, "month").subtract(1, "day"))
    // console.log(moment(commenceDate).add(1, "year"))

    let dateFormat = "MM-DD-YYYY";
    let _first_month = moment(commenceDate)
      .add(1, "year")
      .add(1, "month")
      .subtract(1, "day");
    let _second_month = moment(commenceDate)
      .add(1, "year")
      .add(2, "month")
      .subtract(1, "day");
    let currentDateFormat = current.format(dateFormat);

    return (
      current &&
      moment(commenceDate)
        .add(1, "year")
        .subtract(1, "day")
        .format(dateFormat) !== currentDateFormat &&
      _second_month.format(dateFormat) !== currentDateFormat &&
      _first_month.format(dateFormat) !== currentDateFormat
    );
  };

  return (
    <Row gutter={24}>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={8}>
            <ReactSelectField
              label="Lease nature"
              fieldname="leaseNature"
              control={control}
              iProps={{ placeholder: "Select one", isDisabled: true }}
              initValue=""
              selectOption={_.map(leaseNatureData, (e) => ({
                label: e.name,
                value: e.name,
              }))}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={8}>
            <DateField
              isRequired={true}
              label="Lease commencement date"
              fieldname="commencementDate"
              control={control}
              validate={errors.commencementDate && "error"}
              onChange={(e) => setLeaseEndDate(e)}
              validMessage={
                errors.commencementDate && errors.commencementDate.message
              }
              initValue=""
              rules={{
                required: "Enter Lease commencement date",
                setValueAs: (value) => value && value.toString(),
              }}
              iProps={{
                placeholder: "DD-MM-YYYY",
                format: "DD-MM-YYYY",
                disabledDate: disableCommenceDate,
                disabled: props.disableAll,
              }}
            />
          </Col>
          <Col span={8}>
            <DateField
              isRequired={true}
              label="Lease expiry date"
              fieldname="expiryDate"
              control={control}
              onChange={(e) => setRentfree()}
              validate={errors.expiryDate && "error"}
              validMessage={errors.expiryDate && errors.expiryDate.message}
              initValue=""
              rules={{
                required: "Enter Lease expiry date",
                setValueAs: (value) => value && value.toString(),
              }}
              iProps={{
                disabledDate: disableExpiryDate,
                placeholder: "DD-MM-YYYY",
                format: "DD-MM-YYYY",
                disabled: props.disableAll,
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <DateField
          label="Rent free start date"
          fieldname="rentfreestart"
          control={control}
          initValue=""
          iProps={{
            disabled: true,
            placeholder: "DD-MM-YYYY",
            format: "DD-MM-YYYY",
          }}
        />
      </Col>
      <Col span={8}>
        <DateField
          label="Rent free end date"
          fieldname="rentfreeend"
          control={control}
          initValue=""
          iProps={{
            disabled: true,
            placeholder: "DD-MM-YYYY",
            format: "DD-MM-YYYY",
          }}
        />
      </Col>
      {fields.map((item, index) => (
        <Col span={8} key={item.id}>
          <ReactSelectField
            isRequired={true}
            label={`Select Free Parking Slot #${index + 1}`}
            fieldname={`free[${index}].slot`}
            control={control}
            rules={{ required: "Select one" }}
            initValue={item.slot}
            onChange={disableClone}
            iProps={{ placeholder: "Select one", isDisabled: props.disableAll }}
            selectOption={parkSlot}
            validate={
              Object.entries(errors).length > 0 &&
              errors?.free?.length > 0 &&
              errors?.free[index]?.slot &&
              "error"
            }
            validMessage={
              Object.entries(errors).length > 0 &&
              errors?.free?.length > 0 &&
              errors?.free[index]?.slot &&
              errors?.free[index]?.slot?.message
            }
          />
        </Col>
      ))}

      <Col span={8}>
        <DateField
          isRequired={true}
          label="Move-In Date"
          fieldname="moveInDate"
          control={control}
          initValue=""
          iProps={{
            placeholder: "DD-MM-YYYY",
            format: "DD-MM-YYYY",
            disabled: props.disableAll,
          }}
          rules={{ required: "Enter Date" }}
          validate={errors?.moveInDate && "error"}
          validMessage={errors?.moveInDate && errors?.moveInDate.message}
        />
      </Col>
    </Row>
  );
};
