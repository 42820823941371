import { baseContractService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";

export const getLeaseForms = (limit, page = 0, value = "", field) => async (
  dispatch,
) => {
  const url = `${baseContractService}/get-all-lease?limit=${limit}&page=${page}&${`${field}=${value}`}`;
  const {
    data: { result },
  } = await axios.get(url);
  if (result) {
    dispatch({
      type: action_types.GET_LEASEFORMS,
      data: result,
    });
  }
};
