import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Button } from "antd";
import { ReactSelectField } from "../../../../../atoms/FormElement";
import { useDispatch, useSelector } from "react-redux";
import { uniq } from "../../../utils/utility";
import { getFeaturesAmenities } from "../../ducks/actions/addInfoAction";
import AddFeatures from "../../../PropertyDetail/propertyInfoTab/AddFeatures";
import { Popup } from "../../../../../atoms/Popup";
import { PlusOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;
var _ = require("lodash");

export default (props) => {
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(0);
  const [visible, setVisible] = useState(false);
  const [featureList, setFeatureList] = useState([]);
  const [amenitiesList, setAmenitiesList] = useState([]);
  // const [subFeatures, setSubFeatures] = useState([]);
  // const [subAmenities, setSubAmenities] = useState([]);
  const { control, noEdit, hasUnit } = props;

  const apiFeatureAmenitiesData = useSelector(
    (state: any) => state.addInfoReducer.getFeaturesAmenities,
  );

  const featureSubmit = () => {
    dispatch(getFeaturesAmenities());
    setVisible(false);
  };

  useEffect(() => {
    dispatch(getFeaturesAmenities());
  }, []);

  // const addFeatureTag = async (obj) => {
  //   const selectedIds = obj.map((e) => e.value);
  //   const seletedFeatures = [];
  //   for (let i of selectedIds) {
  //     for (let j of apiFeatureAmenitiesData.property_features) {
  //       if (i == j.labelId) {
  //         seletedFeatures.push(j);
  //       }
  //     }
  //   }
  //   setSubFeatures(seletedFeatures);
  // };

  // const addAmenityTag = async (obj) => {
  //   const selectedIds = obj.map((e) => e.value);
  //   const seletedAmenities = [];
  //   for (let i of selectedIds) {
  //     for (let j of apiFeatureAmenitiesData.property_amenities) {
  //       if (i == j.labelId) {
  //         seletedAmenities.push(j);
  //       }
  //     }
  //   }
  //   setSubAmenities(seletedAmenities);
  // };

  const popup = [
    {
      title: "Add Amenities",
      content: (
        <AddFeatures
          selected="property_amenities"
          type={hasUnit}
          onCancel={() => setVisible(false)}
          onSubmit={featureSubmit}
        />
      ),
      width: 650,
    },
    {
      title: "Add Features",
      content: (
        <AddFeatures
          selected="property_features"
          type={hasUnit}
          onCancel={() => setVisible(false)}
          onSubmit={featureSubmit}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  useEffect(() => {
    if (
      apiFeatureAmenitiesData &&
      Object.keys(apiFeatureAmenitiesData).length
    ) {
      let features = [];
      let amenity = [];
      features =
        apiFeatureAmenitiesData?.property_features &&
        uniq(apiFeatureAmenitiesData?.property_features).map((item) => ({
          value: item.labelId,
          label: item.labelName,
        }));
      amenity =
        apiFeatureAmenitiesData?.property_amenities &&
        uniq(apiFeatureAmenitiesData?.property_amenities).map((item) => ({
          value: item.labelId,
          label: item.labelName,
        }));
      setFeatureList(features);
      props.setFeatureList && props.setFeatureList(features);
      setAmenitiesList(amenity);
      props.setAmenitiesList && props.setAmenitiesList(amenity);
    }
  }, [apiFeatureAmenitiesData]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={!noEdit ? 24 : [24, 24]}>
            <Col span={24}>
              <Title
                level={5}
                className={`${noEdit ? "ag-primary mb-0" : "mb-1"}`}
              >
                {noEdit
                  ? "Features"
                  : "Add Features (You can select more than one):"}
              </Title>
            </Col>
            {props.propertyDetails &&
              !props.propertyDetails.property_features &&
              noEdit && (
                <Col span={24}>
                  <Text>No Features</Text>
                </Col>
              )}
            {noEdit ? (
              _.map(
                props.propertyDetails &&
                  props.propertyDetails.property_features,
                (item, index) => (
                  <React.Fragment key={index}>
                    <Col span={6}>
                      <Text>{item.label}</Text>
                    </Col>
                  </React.Fragment>
                ),
              )
            ) : (
              <>
                <Col span={24}>
                  <ReactSelectField
                    fieldname="property_features"
                    control={control}
                    initValue=""
                    iProps={{
                      isMulti: true,
                      placeholder: "Select",
                    }}
                    selectOption={featureList}
                  />
                </Col>
                <Col span={24}>
                  <Button
                    type="link"
                    className="ag-bold p-0"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setTrigger(1);
                      setVisible(true);
                    }}
                  >
                    Add Features
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={!noEdit ? 24 : [24, 24]}>
            <Col span={24}>
              <Title
                level={5}
                className={`${noEdit ? "ag-primary mb-0" : "mb-1"}`}
              >
                {noEdit
                  ? "Amenities"
                  : "Add Amenities (You can select more than one):"}
              </Title>
            </Col>
            {props.propertyDetails &&
              !props.propertyDetails.property_amenities &&
              noEdit && (
                <Col span={24}>
                  <Text>No Amenities</Text>
                </Col>
              )}
            {noEdit ? (
              _.map(
                props.propertyDetails &&
                  props.propertyDetails.property_amenities,
                (item, index) => (
                  <React.Fragment key={index}>
                    <Col span={6}>
                      <Text>{item.label}</Text>
                    </Col>
                  </React.Fragment>
                ),
              )
            ) : (
              <>
                <Col span={24}>
                  <ReactSelectField
                    fieldname="property_amenities"
                    control={control}
                    initValue=""
                    iProps={{
                      isMulti: true,
                      placeholder: "Select",
                    }}
                    // onChange={addAmenityTag}
                    selectOption={amenitiesList}
                  />
                </Col>
                <Col span={24}>
                  <Button
                    type="link"
                    className="ag-bold p-0"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setTrigger(0);
                      setVisible(true);
                    }}
                  >
                    Add Amenities
                  </Button>
                </Col>
                {/* {subAmenities.map((e, i) => {
                  return (
                    <Col span={6} key={i}>
                      {e?.values?.length ? (
                        <ReactSelectField
                          label={e.labelName}
                          fieldname={e.labelName}
                          control={control}
                          initValue=""
                          iProps={{ placeholder: "Select one" }}
                          selectOption={e?.values?.map((sub, i) => ({
                            label: sub.name,
                            value: sub.id,
                          }))}
                        />
                      ) : null}
                    </Col>
                  );
                })} */}
              </>
            )}
          </Row>
        </Col>
      </Row>

      <Popup {...popupProps} />
    </>
  );
};
