import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "antd";
import { InputField, ReactSelectField } from "../../../atoms/FormElement";
import { DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

var _ = require("lodash");

let securityInst = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
];

export default (props) => {
  const {
    item,
    control,
    errors,
    billingType,
    remove,
    index,
    noIstallment,
    parking,
    setValue,
    getValues,
    setNoInstallment,
    noIData,
    disableClone,
    enableClone,
  } = props;

  let rcInst = [{ label: 1, value: 1 }];
  const [enable, setEnable] = useState(true);
  const [disable, setDisable] = useState(false);
  const [instali, setInstalli] = useState([]);
  const [rcDisable, setRCDisable] = useState(false);
  const [typeM, setTypeM] = useState("");
  const configData = useSelector(
    (state: any) => state.paymentReducer.getPaymentConfig,
  );

  useEffect(() => {
    item?.type?.label != "PARKING" && setEnable(false);
    // item?.type?.label != "RENTAL COMMISSION" && setRCDisable(false);
    if (
      item?.type?.label == "EJARI FEE" ||
      item?.type?.label === "BASE RENT" ||
      item?.type?.label == "SECURITY DEPOSIT"
    ) {
      setDisable(true);
    } else if (
      item?.type?.label == "EJARI FEE" ||
      item?.type?.label === "BASE RENT" ||
      item?.type?.label == "SECURITY DEPOSIT"
    )
      disableClone(item?.type?.label);
    onInstallmentChange(item?.intallments);
  }, []);

  const onChange = (e, index) => {
    if (e.label == "RENTAL COMMISSION") {
      setValue(`othercharges[${index}].intallments`, { label: "1", value: 1 });
      setValue(
        `othercharges[${index}].amount`,
        getValues("annualBaseRent") * configData?.rental_commission_percentage,
      );
      setRCDisable(true);
    } else {
      setRCDisable(false);
    }
    e.label == "PARKING" ? setEnable(true) : setEnable(false);
    setTypeM(e.label);
    disableClone(e.label);
  };

  const onInstallmentChange = (e) => {
    if (item?.type?.label === "BASE RENT") {
      setValue("noOfInstallments", e);
      let install = [];
      Array.from(Array(e.value), (_, index) => index + 1).forEach((item) =>
        install.push({ label: item, value: item }),
      );
      let moreArr = getValues().othercharges;
      if (moreArr.length > 0) {
        moreArr.map((item, index) => {
          if (
            item.intallments.value > e.value &&
            item.type.label !== "BASE RENT"
          ) {
            setValue(`othercharges[${index}].intallments`, "");
          }
        });
      }
      setNoInstallment(install);
    }
  };

  const onAmountChange = (e) => {
    if (item?.type?.label === "BASE RENT") {
      setValue("annualBaseRent", e.target.value);
      let moreArr = getValues().othercharges;
      if (moreArr.length > 0) {
        moreArr.map((item, index) => {
          if (item.type.label == "RENTAL COMMISSION") {
            setValue(
              `othercharges[${index}].amount`,
              e.target.value * configData?.rental_commission_percentage,
            );
          }
        });
      }
    } else if (item?.type?.label == "SECURITY DEPOSIT") {
      setValue("nonRefundableAmount", e.target.value);
    }
  };

  return (
    <Row gutter={24} style={{ position: "relative" }}>
      <Col span={6}>
        <InputField
          classes="d-none"
          label="id"
          fieldname={`othercharges[${index}].itemId`}
          iProps={{ type: "number" }}
          control={control}
          initValue={item?.itemId || null}
        />
        <ReactSelectField
          isRequired={true}
          label="Select Billing Charges"
          fieldname={`othercharges[${index}].type`}
          control={control}
          iProps={{
            placeholder: "Select one",
            isDisabled: props.disableAll ? true : disable,
          }}
          initValue={item?.type}
          selectOption={billingType}
          onChange={(e) => onChange(e, index)}
          rules={{ required: "Select one" }}
          validate={
            Object.entries(errors).length > 0 &&
            errors?.othercharges?.length > 0 &&
            errors?.othercharges[index]?.type &&
            "error"
          }
          validMessage={
            Object.entries(errors).length > 0 &&
            errors?.othercharges?.length > 0 &&
            errors?.othercharges[index]?.type &&
            errors?.othercharges[index]?.type?.message
          }
        />
      </Col>
      <Col span={6}>
        <ReactSelectField
          isRequired={true}
          label="No. of Instalments"
          fieldname={`othercharges[${index}].intallments`}
          control={control}
          iProps={{
            placeholder: "Select one",
            isDisabled: props.disableAll
              ? true
              : rcDisable ||
                item?.type?.label == "EJARI FEE" ||
                item?.type?.label == "SECURITY DEPOSIT" ||
                item?.type?.label == "RENTAL COMMISSION",
          }}
          onChange={onInstallmentChange}
          selectOption={
            item?.type?.label === "BASE RENT"
              ? noIData
              : item?.type?.label == "SECURITY DEPOSIT"
              ? securityInst
              : noIstallment
          }
          initValue={item?.intallments}
          rules={{ required: "Select one" }}
          validate={
            Object.entries(errors).length > 0 &&
            errors?.othercharges?.length > 0 &&
            errors?.othercharges[index]?.intallments &&
            "error"
          }
          validMessage={
            Object.entries(errors).length > 0 &&
            errors?.othercharges?.length > 0 &&
            errors?.othercharges[index]?.intallments &&
            errors?.othercharges[index]?.intallments?.message
          }
        />
      </Col>
      <Col span={6}>
        <InputField
          isRequired={true}
          label="Amount"
          fieldname={`othercharges[${index}].amount`}
          iProps={{
            placeholder: "Amount",
            type: "number",
            disabled: props.disableAll
              ? true
              : rcDisable ||
                item?.type?.label == "EJARI FEE" ||
                item?.type?.label == "SECURITY DEPOSIT" ||
                item?.type?.label == "RENTAL COMMISSION",
          }}
          onChange={onAmountChange}
          control={control}
          initValue={item?.amount}
          validate={
            Object.entries(errors).length > 0 &&
            errors?.othercharges?.length > 0 &&
            errors?.othercharges[index]?.amount &&
            "error"
          }
          validMessage={
            Object.entries(errors).length > 0 &&
            errors?.othercharges?.length > 0 &&
            errors?.othercharges[index]?.amount &&
            errors?.othercharges[index]?.amount?.message
          }
          rules={{
            required: "Select one",
            min: { value: 1, message: "Enter amount" },
            setValueAs: (value) => parseFloat(value).toFixed(2),
          }}
        />
      </Col>
      {enable && (
        <>
          <Col span={6}>
            <ReactSelectField
              isRequired={true}
              label="Paid Parking Slot"
              fieldname={`othercharges[${index}].slot`}
              iProps={{
                isDisabled: props.disableAll,
                placeholder: "Select one",
              }}
              control={control}
              initValue={
                item?.slot ? { label: item.slot, value: item.slot } : ""
              }
              selectOption={_.map(parking, (e) => ({ label: e, value: e }))}
              rules={{ required: "Select one" }}
              validate={
                Object.entries(errors).length > 0 &&
                errors?.othercharges?.length > 0 &&
                errors?.othercharges[index]?.slot &&
                "error"
              }
              validMessage={
                Object.entries(errors).length > 0 &&
                errors?.othercharges?.length > 0 &&
                errors?.othercharges[index]?.slot &&
                errors?.othercharges[index]?.slot?.message
              }
            />
          </Col>
        </>
      )}
      {!props.disableAll && index > 2 && (
        <Button
          className="removebtn"
          style={{ top: "2em" }}
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => {
            remove(index);
            enableClone(typeM);
          }}
          danger
        />
      )}
    </Row>
  );
};
