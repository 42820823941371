import * as action_types from "./constants";

const initialState: any = {
  ticketsDataNew: {
    count: 0,
    rows: [],
  },
  ticketsDataProgress: {
    count: 0,
    rows: [],
  },
  ticketsDataClosed: {
    count: 0,
    rows: [],
  },
  getPresignedImage: "",
  getTicketById:{}
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.TICKETS_PROGRESS:
      return { ...state, ticketsDataProgress: data };
    case action_types.TICKETS_CLOSED:
      return { ...state, ticketsDataClosed: data };
    case action_types.TICKETS_NEW:
      return { ...state, ticketsDataNew: data };
    case action_types.PRESIGNED_IMAGE:
      return { ...state, getPresignedImage: data };
    case action_types.GET_TICKET_BY_ID:
      return { ...state, getTicketById: data };
    default:
      return state;
  }
};
