import React from 'react';
import { Row, Col, Card, Form, Typography, Input, Select, DatePicker } from "antd";
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Text, Title } = Typography;
const { Option } = Select;

export default () => {
    return (
        <Card
            bordered={false}
            bodyStyle={{ padding: '16px 24px 0' }}
            headStyle={{ padding: '16px 24px 0', borderBottom: 0 }}
            title={<Title level={5} className='ag-primary mb-0'>Installment Plan(s)</Title>}
            extra={(<Link to="#"><EditOutlined className='ag-primary ag-fontSize24' /></Link>)}
        >
            <Form layout="vertical" name="addInformation">
                <Row gutter={24}>
                    <Col span={24}>
                        <Title level={5} className="mb-1">Rent Details:</Title>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="installment_plan"
                            label="Installment Plan"
                            rules={[{ required: true }]}
                        >
                            <Select placeholder="6 Months">
                                <Option value="3">3 Months</Option>
                                <Option value="6">6 Months</Option>
                                <Option value="12">12 Months</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="market_rent_month"
                            label="Market Rent/Month"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="20,000" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Title level={5} className="mb-1">Processing Fees:</Title>
                    </Col>



                    <Col span={9}>
                        <Form.Item
                            name="ejari_fee"
                            label="Ejari Fee"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="ejari_vat"
                            label="VAT"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="security_deposit"
                            label="Security Deposit"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="300" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="security_vat"
                            label="VAT"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="processing_fee"
                            label="Processing Fee"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="processing_vat"
                            label="VAT"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Title level={5} className="mb-1">Installment Plan:</Title>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="1st_installment"
                            label="1st Installment"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="1st_vat"
                            label="VAT"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="2nd_installment"
                            label="2nd Installment"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="2nd_vat"
                            label="VAT"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="3rd_installment"
                            label="3rd Installment"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="3rd_vat"
                            label="VAT"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="4th_installment"
                            label="4th Installment"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="4th_vat"
                            label="VAT"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="5th_installment"
                            label="5th Installment"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="5th_vat"
                            label="VAT"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="6th_installment"
                            label="6th Installment"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            name="6th_vat"
                            label="VAT"
                            rules={[{ required: true }]}
                        >
                            <Input prefix="AED" placeholder="1234" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
