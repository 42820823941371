import React, { useEffect } from "react";
import { Button, Row, Col, Form, message } from "antd";
import { InputField } from "../../atoms/FormElement";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { addUnit } from "../../../utils/fileDownload";
import { getBookingForms } from "./ducks/actions";

const cancelBooking = (props) => {
  const dispatch = useDispatch();
  const { closePopUp } = props;
  const defaultValues: any = { unitCode: null };
  const { control, handleSubmit, errors, reset } = useForm({ defaultValues });
  const leadID = useSelector((state: any) => state.bookings.getLeadID);
  const visible = useSelector((state: any) => state.bookings.visiblePopup);

  useEffect(() => {
    if (visible === true) {
      reset();
    }
  }, [visible]);

  const onSubmit = async (e) => {
    const addUnitData = {
      ...e,
      leadId: leadID,
    };
    try {
      await addUnit(addUnitData);
      reset();
      closePopUp();
      dispatch(getBookingForms(10, 0, "", ""));
    } catch (e) {
      const { response } = e;
      message.error(response?.data.message);
    }
  };
  return (
    <Form
      layout="vertical"
      name="bookingform"
      onFinish={handleSubmit(onSubmit)}
    >
      <Row gutter={24}>
        <Col span={12}>
          <InputField
            isRequired={true}
            label="Enter Unit Code"
            fieldname="unitCode"
            control={control}
            validate={errors.unitCode && "error"}
            validMessage={errors.unitCode && errors.unitCode.message}
            rules={{ required: "Unit Code Required" }}
            iProps={{ placeholder: "Enter Code" }}
          />
        </Col>
        <Col span={24}>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                htmlType="button"
                onClick={() => {
                  reset(defaultValues);
                  closePopUp();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default cancelBooking;
