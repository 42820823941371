import axios from "../../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { propertyService } from "../../../../../configs/constants";

const url = `${propertyService}/property-landlord`;

export const getPropertyUnits = (propertyId) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${url}/asset/units?asset_id=${propertyId}`);
    dispatch({
      type: action_types.PROPERTY_UNITS,
      data: result,
    });
  };
};

export const getSingleUnit = (propertyId) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${url}/${propertyId}`);
    dispatch({
      type: action_types.GET_SINGLE_UNIT,
      data: result,
    });
  };
};

export const getPaymentPlan = (propertyId) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${url}/${propertyId}/payment-plans`);
    dispatch({
      type: action_types.GET_PAYMENT_PLAN,
      data: result,
    });
  };
};
