import React, { useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Card,
  Typography,
  Space,
  Form,
  Alert,
  message,
  Button,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../../molecules/Sidebar";
import Header from "../../../molecules/Header";
import Footer from "../../../molecules/Footer";
import { getPaymentConfig } from "../AddProperty/ducks/actions/paymentAction";
import {
  getPropertyById,
  getSingleProperty,
} from "../AddProperty/ducks/actions/addInfoAction";
import { leaseService, propertyService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
//componets
import UnitDetails from "../BookingForm/components/UnitDetails";
import PaymentSchedule from "../BookingForm/components/PaymentSchedule";
import { InputField } from "../../../atoms/FormElement";

const { Content } = Layout;
const { Title } = Typography;

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [paymentConfig, setPaymentConfig] = useState({});
  const [assetid, setAssetid] = useState();

  const paymentConfigData = useSelector(
    (state: any) => state.paymentReducer.getPaymentConfig,
  );

  const propertyData = useSelector(
    (state: any) => state.addInfoReducer.getPropertyById,
  );

  const singlePropertyData = useSelector(
    (state: any) => state.addInfoReducer.getSingleProperty,
  );

  const { control, handleSubmit, errors, setValue, getValues } = useForm({});

  useEffect(() => {
    if (propertyData && Object.keys(propertyData).length) {
      const { name, size, sub_type, type, unit_no } = propertyData;
      setValue("buildingName", name);
      setValue("unitno", unit_no);
      // setValue("unittype", type.id);
      // setValue("unitsubtype", sub_type.id);
      setValue("unitsize", size);
    }
  }, [propertyData]);

  useEffect(() => {
    if (singlePropertyData) {
      if (singlePropertyData?.property_info) {
        setValue(
          "propertyAddress",
          singlePropertyData?.property_info[0]?.street_address,
        );
      }
      setValue("properyType", singlePropertyData?.asset_type?.name);
    }
  }, [singlePropertyData]);

  useEffect(() => {
    dispatch(getPaymentConfig());
    const identiFierValue = JSON.parse(
      window.localStorage.getItem("selectedOffer"),
    ).identifier;
    if (identiFierValue) {
      const url = `${leaseService}/leads/notification/${identiFierValue}`;
      const headers = {
        Authorization:
          "Bearer" +
          " " +
          JSON.parse(window.localStorage.getItem("login-response"))?.token,
      };
      axios.get(url, { headers }).then((res) => {
        const offeredData = res?.data?.result;
        setAssetid(offeredData?.asset_id);
        setValue("leaseDuration", offeredData?.lead_duration);

        setValue("marketrent", offeredData?.amount);
        setValue("installmentplan", offeredData?.lead_duration);
        dispatch(getPropertyById(offeredData?.asset_id));
        dispatch(getSingleProperty(offeredData?.asset_id));
      });
    }
  }, []);

  useEffect(() => {
    if (paymentConfigData) {
      const { ejari_fee, processing_fee, vat_percentage } = paymentConfigData;
      setPaymentConfig({
        ejari: ejari_fee,
        processing: processing_fee,
        vat_rate: vat_percentage,
      });
    }
  }, [paymentConfigData]);

  const onApprove = async (values) => {
    const installmentArray = values.installment.map((item) => {
      const obj = {
        type: "installment",
        price: Number(item.installment),
        percentage: 1,
        vat: Number(item.installmentvat),
      };
      return obj;
    });

    const postJson = {
      lead_item_id: Number(
        JSON.parse(window.localStorage.getItem("selectedOffer")).identifier,
      ),
      notification_id: Number(
        JSON.parse(window.localStorage.getItem("selectedOffer")).id,
      ),
      asset_id: Number(assetid),
      price: Number(values.marketrent),
      installment_plan: Number(values.installmentplan),
      installments: installmentArray,
      other_charges: [
        {
          type: "ejari_fee",
          price: values.ejarifee,
          percentage: 0,
          vat: values.ejarifeevat,
        },
        {
          type: "security_deposit",
          price: values.securitydeposit,
          percentage: 0,
          vat: values.securitydepositvat,
        },
        {
          type: "processing_fee",
          price: values.processingfee,
          percentage: 0,
          vat: values.processingfeevat,
        },
      ],
    };
    const headers = {
      Authorization:
        "Bearer" +
        " " +
        JSON.parse(window.localStorage.getItem("login-response")).token,
    };
    const url = `${propertyService}/property-landlord/payment/approve-counter-offer`;
    try {
      const res = await axios.post(url, postJson, { headers });
      message.success(res?.data?.message || "Offer Approved");
      setTimeout(() => {
        history.push("/properties");
      }, 2000);
    } catch (e) {
      message.error(e);
    }
  };

  const onDisApprove = async () => {
    const postJson = {
      lead_item_id: Number(
        JSON.parse(window.localStorage.getItem("selectedOffer")).identifier,
      ),
      notification_id: Number(
        JSON.parse(window.localStorage.getItem("selectedOffer")).id,
      ),
      asset_id: Number(assetid),
    };
    const headers = {
      Authorization:
        "Bearer" +
        " " +
        JSON.parse(window.localStorage.getItem("login-response")).token,
    };
    console.log(postJson);
    const url = `${propertyService}/property-landlord/payment/disapprove-counter-offer`;
    try {
      const res = await axios.post(url, postJson, { headers });
      message.success(res?.data?.message || "Offer Rejected");
      setTimeout(() => {
        history.push("/properties");
      }, 2000);
    } catch (e) {
      message.error(e);
    }
  };

  return (
    <Layout className="main-layout">
      <Header />
      <Layout>
        <Sidebar />
        <Layout className="layout-space">
          <Content className="main-content">
            <Form
              layout="vertical"
              name="addInformation"
              onFinish={handleSubmit(onApprove)}
            >
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Space direction="vertical" size={16}>
                    <Title level={2} className="mb-0 ag-fontSize32">
                      Offer by Tenant
                    </Title>
                    <Alert
                      message="Please review the proposed rent and make a payment schedule accordingly."
                      type="info"
                      showIcon
                      closable
                    />
                  </Space>
                </Col>

                <Col span={24}>
                  <Card
                    bordered={false}
                    className="h-auto"
                    title={
                      <Title level={3} className="mb-0">
                        1. Unit Detail
                      </Title>
                    }
                  >
                    <Row gutter={24}>
                      <UnitDetails
                        control={control}
                        errors={errors}
                        useWatch={useWatch}
                        propertyData={propertyData}
                        setValue={setValue}
                      />
                    </Row>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card
                    bordered={false}
                    className="h-auto"
                    title={
                      <Title level={3} className="mb-0">
                        2. Payment Schedule
                      </Title>
                    }
                  >
                    <Row gutter={24}>
                      <Col span={6}>
                        <InputField
                          label="Property Address"
                          fieldname="propertyAddress"
                          control={control}
                          initValue=""
                          iProps={{
                            placeholder: "Al-Ghurair Residence",
                            readOnly: true,
                          }}
                        />
                      </Col>
                      <Col span={6}>
                        <InputField
                          label="Property Type"
                          fieldname="properyType"
                          control={control}
                          initValue=""
                          iProps={{ placeholder: "Apartments", readOnly: true }}
                        />
                      </Col>
                      <Col span={6}>
                        <InputField
                          label="Lease Duration in month(s)"
                          fieldname="leaseDuration"
                          control={control}
                          initValue=""
                          iProps={{ placeholder: "12 months", readOnly: true }}
                        />
                      </Col>
                    </Row>
                    <PaymentSchedule
                      control={control}
                      errors={errors}
                      useWatch={useWatch}
                      setValue={setValue}
                      getValues={getValues}
                      paymentConfig={paymentConfig}
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col flex="200">
                  <Button htmlType="button" onClick={() => onDisApprove()}>
                    Reject
                  </Button>
                </Col>
                <Col flex="auto">
                  <Button type="primary" htmlType="submit">
                    Approve
                  </Button>
                </Col>
              </Row>
            </Form>
            <Footer />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
