import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";

import {
  Row,
  Col,
  Button,
  Typography,
  Space,
  Form,
  Collapse,
  message,
} from "antd";
import { baseContractService } from "../../../../configs/constants";

import TenantDetails from "./TenantDetails";
import LeaseTerms from "./LeaseTerms";
import UnitDetails from "./UnitDetails";
import BookingTerms from "./BookingTerms";
import ApprovalHistory from "./ApprovalHistory";
import DashboardLayout from "../../../molecules/DashboardLayout";
import RequireDocument from "../../../molecules/RequirDoc";
import axios from "../../../../utils/axiosInceptor";
import { uniquiFileName, getSingleUpload } from "../../../../utils/fileUpload";
import { Popup } from "../../../atoms/Popup";
import Rejectform from "./RejectFrom";
import moment from "moment";

import {
  getTenantDetails,
  getUnitDetails,
  getLeaseNature,
  getParkingSlots,
  getBookingTerms,
  saveBookingDraft,
  getTermsConditions,
  getAprovalHistory,
  getBookingFormDocs,
  getBookingFormDetails,
} from "./ducks/actions";
import { resetState } from "../../Properties/ducks/actions";

const ContractBookingForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { control, handleSubmit, errors, getValues, setValue } = useForm();
  const [sendEmptyDoc, setSendEmptyDoc] = useState(false);
  const [disableSaveDraftButton, setDisableSaveDraftButton] = useState(false);
  const [disableApprovalButton, setDisableApprovalButton] = useState(true);
  const [disableFullLeaseButton, setDisableFullLeaseButton] = useState(true);
  const [rejectionReason, setRejectionReason] = useState(0);
  const [rejectionComment, setRejectionComment] = useState("");
  const [isClearTriggered, setIsClearTriggered] = useState(false);
  const [disableApproveButton, setDisableApproveButton] = useState(false);
  const [disableRejectButton, setDisableRejectButton] = useState(false);

  const { Title } = Typography;
  const { Panel } = Collapse;

  const { leadId, assetId, partyId } = useParams();

  const tenantDetails = useSelector(
    (state: any) => state.contractBookingForm.tenantDetails,
  );
  const unitDetails = useSelector(
    (state: any) => state.contractBookingForm.unitDetails,
  );
  const termsConditions = useSelector(
    (state: any) => state.contractBookingForm.termsConditions,
  );
  const bookingTerms = useSelector(
    (state: any) => state.contractBookingForm.bookingTerms,
  );

  const parkingSlotsDataFromApi = useSelector(
    (state: any) => state.contractBookingForm.parkingSlots,
  );

  const bookingFormDocuments = useSelector(
    (state: any) => state.contractBookingForm.getBookingFormDocs,
  );

  const bookingFormDetails = useSelector(
    (state: any) => state.contractBookingForm.getBookingFormDetails,
  );

  const approvalData = useSelector(
    (state: any) => state.contractBookingForm.getAprovalHistory,
  );

  const loginMode = JSON.parse(window.localStorage.getItem("login-response"))
    ?.data?.role_name;

  useEffect(() => {
    dispatch(getTenantDetails(partyId));
    dispatch(getTermsConditions());
    dispatch(getUnitDetails(assetId));
    dispatch(getLeaseNature());
    dispatch(getParkingSlots(leadId));
    dispatch(getBookingTerms(leadId));
    dispatch(getAprovalHistory(leadId));
    dispatch(getBookingFormDocs(leadId));
    dispatch(getBookingFormDetails(leadId));
    return (): any => dispatch(resetState());
  }, []);

  useEffect(() => {
    if (approvalData && approvalData.length > 0) {
      const activeStatus = approvalData.find((e) => e.active)?.status?.name;
      const isDraft = activeStatus?.includes("draft");
      const isApproved = activeStatus?.includes("appro");
      const isConfimred = activeStatus?.includes("confirm");
      const isRejected = activeStatus?.includes("reject");
      const isProcessImediate = activeStatus?.includes("process");

      console.log("activeStatus", activeStatus);

      if (isDraft) {
        setDisableSaveDraftButton(false);
        setDisableApprovalButton(false);
        setDisableFullLeaseButton(false);
        setDisableApproveButton(true);
        setDisableRejectButton(true);
      }
      if (isApproved) {
        setDisableApprovalButton(true);
        setDisableFullLeaseButton(true);
        setDisableSaveDraftButton(true);
        setDisableRejectButton(true);
        setDisableApproveButton(true);
      }
      if (isConfimred) {
        setDisableFullLeaseButton(true);
        setDisableSaveDraftButton(true);
        setDisableApprovalButton(true);
      }
      if (isRejected) {
        setDisableSaveDraftButton(false);
        setDisableRejectButton(true);
        setDisableApproveButton(true);
      }
      if (isProcessImediate) {
        setDisableFullLeaseButton(true);
        setDisableSaveDraftButton(true);
        setDisableApprovalButton(true);
      }
    }
  }, [approvalData]);

  const [requiredDocArray, setRequiredDocArray] = useState([]);
  const [docFileOriObject, setDocFileOriObject] = useState({
    passportFile: {
      uniqueName: "",
      fileData: "",
    },
    visaFile: {
      uniqueName: "",
      fileData: "",
    },
    emiratesFile: {
      uniqueName: "",
      fileData: "",
    },
    financialSupportFile: {
      uniqueName: "",
      fileData: "",
    },
    signedBookFile: {
      uniqueName: "",
      fileData: "",
    },
    otherDocsFile: {
      uniqueName: "",
      fileData: "",
    },
  });

  const getDocKeys = (key) => {
    if (
      bookingTerms &&
      Object.keys(bookingTerms).length &&
      tenantDetails &&
      Object.keys(tenantDetails).length
    ) {
      // if (tenantDetails?.contacts?.length && bookingTerms?.selectedContact) {
      //   const defaultContact = tenantDetails?.contacts?.find(
      //     (e) => e.party_id === bookingTerms?.selectedContact,
      //   );
      //   if (defaultContact) {
      //     const passportKey = defaultContact?.documents?.find(
      //       (e) => e.purpose_type === "passport",
      //     )?.passport_key;
      //     const visaKey = defaultContact?.documents?.find(
      //       (e) => e.purpose_type === "visa",
      //     )?.visa_key;
      //     const emiratesKey = defaultContact?.documents?.find(
      //       (e) => e.purpose_type === "emirates_id",
      //     )?.emirates_id_key;

      //     switch (key) {
      //       case "passport":
      //         return passportKey;
      //       case "visa":
      //         return visaKey;
      //       case "emirates":
      //         return emiratesKey;
      //       default:
      //         break;
      //     }
      //   } else {
      //     const passportKey =
      //       tenantDetails?.documents?.passport?.object_key || "";
      //     const visaKey = tenantDetails?.documents?.visa?.object_key || "";
      //     const emiratesKey =
      //       tenantDetails?.documents?.emirates_id?.object_key || "";

      //     switch (key) {
      //       case "passport":
      //         return passportKey;
      //       case "visa":
      //         return visaKey;
      //       case "emirates":
      //         return emiratesKey;
      //       default:
      //         break;
      //     }
      //   }

      //   return "";
      // } else {
      //   const passportKey =
      //     tenantDetails?.documents?.passport?.object_key || "";
      //   const visaKey = tenantDetails?.documents?.visa?.object_key || "";
      //   const emiratesKey =
      //     tenantDetails?.documents?.emirates_id?.object_key || "";

      //   switch (key) {
      //     case "passport":
      //       return passportKey;
      //     case "visa":
      //       return visaKey;
      //     case "emirates":
      //       return emiratesKey;
      //     default:
      //       break;
      //   }
      // }
      const passportKey = tenantDetails?.documents?.passport?.object_key || "";
      const visaKey = tenantDetails?.documents?.visa?.object_key || "";
      const emiratesKey =
        tenantDetails?.documents?.emirates_id?.object_key || "";

      switch (key) {
        case "passport":
          return passportKey;
        case "visa":
          return visaKey;
        case "emirates":
          return emiratesKey;
        default:
          break;
      }
    } else {
      return "";
    }
  };

  // const watcher = useWatch({
  //   control: control,
  //   name: "contacts",
  // });

  // const getFromWatcher = (key) => {
  //   const defaultContact = tenantDetails?.contacts?.find(
  //     //@ts-ignore
  //     (e) => e.party_id === watcher?.partId,
  //   );
  //   const passportKey = defaultContact?.documents?.find(
  //     (e) => e.purpose_type === "passport",
  //   )?.passport_key;
  //   const visaKey = defaultContact?.documents?.find(
  //     (e) => e.purpose_type === "visa",
  //   )?.visa_key;
  //   const emiratesKey = defaultContact?.documents?.find(
  //     (e) => e.purpose_type === "emirates_id",
  //   )?.emirates_id_key;

  //   switch (key) {
  //     case "passport":
  //       return passportKey;
  //     case "visa":
  //       return visaKey;
  //     case "emirates":
  //       return emiratesKey;
  //     default:
  //       break;
  //   }
  //   return "";
  // };

  useEffect(() => {
    const arr = [];
    if (bookingFormDocuments && Object.keys(bookingFormDocuments).length) {
      const arr = [];
      let passportKey = "";
      let visaKey = "";
      let emiratesKey = "";

      // if (watcher && Object.keys(watcher).length) {
      //   console.log("hey there i am there");
      //   passportKey = getFromWatcher("passport");
      //   visaKey = getFromWatcher("visa");
      //   emiratesKey = getFromWatcher("emirates");
      // } else {
      //   passportKey = getDocKeys("passport");
      //   visaKey = getDocKeys("visa");
      //   emiratesKey = getDocKeys("emirates");
      // }
      passportKey = getDocKeys("passport");
      visaKey = getDocKeys("visa");
      emiratesKey = getDocKeys("emirates");

      const financialSupportKey =
        bookingFormDocuments?.financial_document || "";
      const signedFormKey =
        bookingFormDocuments?.ag_uae_pass_signed_booking_form || "";
      const otheDocKey = bookingFormDocuments?.other_document || "";

      const keyFileObj = { ...docFileOriObject };
      keyFileObj.passportFile.uniqueName = passportKey;
      keyFileObj.visaFile.uniqueName = visaKey;
      keyFileObj.emiratesFile.uniqueName = emiratesKey;
      keyFileObj.financialSupportFile.uniqueName = financialSupportKey;
      keyFileObj.signedBookFile.uniqueName = signedFormKey;
      keyFileObj.otherDocsFile.uniqueName = otheDocKey;

      arr.push({
        docName: "Passport",
        image: "",
        docData: passportKey,
        upKey: "passport",
      });
      arr.push({
        docName: "Valid UAE Visa",
        image: "",
        docData: visaKey,
        upKey: "visa",
      });
      arr.push({
        docName: "Emirates ID",
        image: "",
        docData: emiratesKey,
        upKey: "emirates",
      });
      arr.push({
        docName: "Financial Supporting Document",
        image: "",
        docData: financialSupportKey,
        upKey: "financial",
      });
      arr.push({
        docName: "Signed Booking Form",
        image: "",
        docData: signedFormKey,
        upKey: "signed",
      });
      arr.push({
        docName: "Other documents",
        image: "",
        docData: otheDocKey,
        upKey: "other",
      });
      setRequiredDocArray(arr);
      setDocFileOriObject(keyFileObj);
    } else {
      arr.push({
        docName: "Passport",
        image: "",
        docData: "",
        upKey: "passport",
      });
      arr.push({
        docName: "Valid UAE Visa",
        image: "",
        docData: "",
        upKey: "visa",
      });
      arr.push({
        docName: "Emirates ID",
        image: "",
        docData: "",
        upKey: "emirates",
      });
      arr.push({
        docName: "Financial Supporting Document",
        image: "",
        docData: "",
        upKey: "financial",
      });
      arr.push({
        docName: "Signed Booking Form",
        image: "",
        docData: "",
        upKey: "signed",
      });
      arr.push({
        docName: "Other documents",
        image: "",
        docData: "",
        upKey: "other",
      });
      setRequiredDocArray(arr);
    }
  }, [bookingFormDocuments, bookingTerms, tenantDetails]);

  const onClosePopUp = () => {
    setVisible(false);
  };

  const popup = [
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Reject Reason
        </Title>
      ),
      width: 650,
    },
  ];

  const popupContent = {
    title: popup[0].title,
    closable: true,
    visibility: visible,
    content: (
      <Rejectform
        closePopup={onClosePopUp}
        setRejectionReason={setRejectionReason}
        setRejectionComment={setRejectionComment}
      />
    ),
    width: popup[0].width,
    onCancel: onClosePopUp,
  };

  const uploadFiles = (key, data) => {
    setSendEmptyDoc(true);
    const obj = { ...docFileOriObject };
    switch (key) {
      case "passport":
        obj.passportFile.fileData = data;
        obj.passportFile.uniqueName = uniquiFileName(data?.name);
        break;
      case "visa":
        obj.visaFile.fileData = data;
        obj.visaFile.uniqueName = uniquiFileName(data?.name);
        break;
      case "emirates":
        obj.emiratesFile.fileData = data;
        obj.emiratesFile.uniqueName = uniquiFileName(data?.name);
        break;
      case "financial":
        obj.financialSupportFile.fileData = data;
        obj.financialSupportFile.uniqueName = uniquiFileName(data?.name);
        break;
      case "signed":
        obj.signedBookFile.fileData = data;
        obj.signedBookFile.uniqueName = uniquiFileName(data?.name);
        break;
      case "other":
        obj.otherDocsFile.fileData = data;
        obj.otherDocsFile.uniqueName = uniquiFileName(data?.name);
        break;
      default:
        break;
    }
    setDocFileOriObject(obj);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const allData = data;
    let documnetsArray = [];

    if (sendEmptyDoc) {
      documnetsArray = [
        {
          type: "visa",
          fileName: docFileOriObject?.visaFile?.uniqueName,
        },
        {
          type: "passport",
          fileName: docFileOriObject?.passportFile?.uniqueName,
        },
        {
          type: "emirates_id",
          fileName: docFileOriObject?.emiratesFile?.uniqueName,
        },
        {
          type: "financial_document",
          fileName: docFileOriObject?.financialSupportFile?.uniqueName,
        },
        {
          type: "signed_booking_form",
          fileName: docFileOriObject?.signedBookFile?.uniqueName,
        },
        {
          type: "other_document",
          fileName: docFileOriObject?.otherDocsFile?.uniqueName,
        },
      ];
    }

    const {
      leasenature,
      parkingslots,
      freeparkingslots,
      commencementdate,
      leaseexpirydate,
      rentfreestart,
      rentfreeend,
      occupants,
      dewa,
      annualBaseRent,
      installment,
      nonRefundable,
      bookingstartdate,
      bookingexpirydate,
      movedate,
      // contacts,
      termsCondition,
    } = allData;

    const payload = {
      lead_id: Number(leadId),
      lease_terms: {
        lease_nature: {
          value: leasenature?.value,
        },
        paid_parking_slots: {
          value: parkingslots?.value,
        },
        free_parking_slots: {
          value: freeparkingslots?.value,
        },
        lease_duration: {
          commencement_date: moment(commencementdate).format("YYYY-MM-DD"),
          expiry_date: moment(leaseexpirydate).format("YYYY-MM-DD"),
        },
        rent_free: {
          start_date: moment(rentfreestart).format("YYYY-MM-DD"),
          end_date: moment(rentfreeend).format("YYYY-MM-DD"),
        },
      },
      tenant_details: {
        number_of_occupants: {
          value: occupants,
        },
        // contacts_name: { value: null },
      },
      unit_details: {
        dewa_details: dewa,
      },
      booking_terms: {
        annual_base_rent_amount: {
          amount: annualBaseRent,
        },
        number_of_installments: {
          amount: installment?.value,
        },
        non_refundable_amount: {
          amount: nonRefundable,
        },
        booking_duration: {
          start_date: moment(bookingstartdate).format("YYYY-MM-DD"),
          end_date: moment(bookingexpirydate).format("YYYY-MM-DD"),
        },
        move_in_date: {
          value: moment(movedate).format("YYYY-MM-DD"),
        },
        terms_and_condition_entity: {
          value: termsCondition?.value,
        },
      },
      documents: documnetsArray,
    }; // ends
    if (
      payload?.lease_terms?.rent_free?.start_date === "Invalid date" ||
      payload?.lease_terms?.rent_free?.end_date === "Invalid date"
    ) {
      payload.lease_terms.rent_free.start_date = "";
      payload.lease_terms.rent_free.end_date = "";
    }
    try {
      // if (!sendEmptyDoc) {}
      if (
        docFileOriObject?.passportFile.uniqueName &&
        docFileOriObject?.passportFile.fileData
      ) {
        let furl = await getSingleUpload(
          docFileOriObject?.passportFile.uniqueName,
          docFileOriObject?.passportFile.fileData,
        );
        if (furl == false) {
          message.error("Invalid Url (Passport)");
          return false;
        }
      }
      if (
        docFileOriObject?.visaFile.uniqueName &&
        docFileOriObject?.visaFile.fileData
      ) {
        let furl = await getSingleUpload(
          docFileOriObject?.visaFile.uniqueName,
          docFileOriObject?.visaFile.fileData,
        );
        if (furl == false) {
          message.error("Invalid Url (Visa)");
          return false;
        }
      }
      if (
        docFileOriObject?.emiratesFile.uniqueName &&
        docFileOriObject?.emiratesFile.fileData
      ) {
        let furl = await getSingleUpload(
          docFileOriObject?.emiratesFile.uniqueName,
          docFileOriObject?.emiratesFile.fileData,
        );
        if (furl == false) {
          message.error("Invalid Url (Emirates ID)");
          return false;
        }
      }
      if (
        docFileOriObject?.financialSupportFile.uniqueName &&
        docFileOriObject?.financialSupportFile.fileData
      ) {
        let furl = await getSingleUpload(
          docFileOriObject?.financialSupportFile.uniqueName,
          docFileOriObject?.financialSupportFile.fileData,
        );
        if (furl == false) {
          message.error("Invalid Url (Financial Document)");
          return false;
        }
      }
      if (
        docFileOriObject?.signedBookFile.uniqueName &&
        docFileOriObject?.signedBookFile.fileData
      ) {
        let furl = await getSingleUpload(
          docFileOriObject?.signedBookFile.uniqueName,
          docFileOriObject?.signedBookFile.fileData,
        );
        if (furl == false) {
          message.error("Invalid Url (Signed Booking Form)");
          return false;
        }
      }
      if (
        docFileOriObject?.otherDocsFile.uniqueName &&
        docFileOriObject?.otherDocsFile.fileData
      ) {
        let furl = await getSingleUpload(
          docFileOriObject?.otherDocsFile.uniqueName,
          docFileOriObject?.otherDocsFile.fileData,
        );
        if (furl == false) {
          message.error("Invalid Url (Other Document)");
          return false;
        }
      }
      const url = `${baseContractService}/save-booking-draft`;
      await axios.post(url, payload);
      setLoading(false);
      message.success("Draft saved successfully");
      setTimeout(() => history.push("/bookingforms"), 1000);
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.statusText);
    }
  };
  const clearData = () => {
    return (
      <Button type="link" onClick={() => setIsClearTriggered(true)}>
        Clear All Data
      </Button>
    );
  };

  const processToFullLease = async () => {
    setLoading(true);
    const payload = {
      leadID: leadId,
    };
    try {
      const url = `${baseContractService}/process-to-full-lease`;
      await axios.post(url, payload);
      setLoading(false);
      message.success("Notification sent for Process to Full lease");
      setTimeout(() => history.push("/bookingforms"), 1000);
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.statustext);
    }
  };

  const sendForApproval = async () => {
    setLoading(true);
    const allData = getValues();

    let documnetsArray = [];

    if (sendEmptyDoc) {
      documnetsArray = [
        {
          type: "visa",
          fileName: docFileOriObject?.visaFile?.uniqueName,
        },
        {
          type: "passport",
          fileName: docFileOriObject?.passportFile?.uniqueName,
        },
        {
          type: "emirates_id",
          fileName: docFileOriObject?.emiratesFile?.uniqueName,
        },
        {
          type: "financial_document",
          fileName: docFileOriObject?.financialSupportFile?.uniqueName,
        },
        {
          type: "signed_booking_form",
          fileName: docFileOriObject?.signedBookFile?.uniqueName,
        },
        {
          type: "other_document",
          fileName: docFileOriObject?.otherDocsFile?.uniqueName,
        },
      ];
    }

    const {
      leasenature,
      parkingslots,
      freeparkingslots,
      commencementdate,
      leaseexpirydate,
      rentfreestart,
      rentfreeend,
      occupants,
      dewa,
      annualBaseRent,
      installment,
      nonRefundable,
      bookingstartdate,
      bookingexpirydate,
      movedate,
      // contacts,
      termsCondition,
    } = allData;

    const payload = {
      leadID: Number(leadId),
      lease_terms: {
        lease_nature: {
          value: leasenature?.value,
        },
        paid_parking_slots: {
          value: parkingslots?.value,
        },
        free_parking_slots: {
          value: freeparkingslots?.value,
        },
        lease_duration: {
          commencement_date: moment(commencementdate).format("YYYY-MM-DD"),
          expiry_date: moment(leaseexpirydate).format("YYYY-MM-DD"),
        },
        rent_free: {
          start_date: moment(rentfreestart).format("YYYY-MM-DD"),
          end_date: moment(rentfreeend).format("YYYY-MM-DD"),
        },
      },
      tenant_details: {
        number_of_occupants: {
          value: occupants,
        },
        // contacts_name: {
        //   value: contacts?.party_id,
        // },
      },
      unit_details: {
        // lease_id: 15,
        dewa_details: dewa,
      },
      booking_terms: {
        annual_base_rent_amount: {
          amount: annualBaseRent,
        },
        number_of_installments: {
          amount: installment?.value,
        },
        non_refundable_amount: {
          amount: nonRefundable,
        },
        booking_duration: {
          start_date: moment(bookingstartdate).format("YYYY-MM-DD"),
          end_date: moment(bookingexpirydate).format("YYYY-MM-DD"),
        },
        move_in_date: {
          value: moment(movedate).format("YYYY-MM-DD"),
        },
        terms_and_condition_entity: {
          value: termsCondition?.value,
        },
      },
      documents: documnetsArray,
    }; // ends
    if (
      payload?.lease_terms?.rent_free?.start_date === "Invalid date" ||
      payload?.lease_terms?.rent_free?.end_date === "Invalid date"
    ) {
      payload.lease_terms.rent_free.start_date = "";
      payload.lease_terms.rent_free.end_date = "";
    }
    try {
      // if (!sendEmptyDoc) {}
      if (
        docFileOriObject?.passportFile.uniqueName &&
        docFileOriObject?.passportFile.fileData
      ) {
        await getSingleUpload(
          docFileOriObject?.passportFile.uniqueName,
          docFileOriObject?.passportFile.fileData,
        );
      }
      if (
        docFileOriObject?.visaFile.uniqueName &&
        docFileOriObject?.visaFile.fileData
      ) {
        await getSingleUpload(
          docFileOriObject?.visaFile.uniqueName,
          docFileOriObject?.visaFile.fileData,
        );
      }
      if (
        docFileOriObject?.emiratesFile.uniqueName &&
        docFileOriObject?.emiratesFile.fileData
      ) {
        await getSingleUpload(
          docFileOriObject?.emiratesFile.uniqueName,
          docFileOriObject?.emiratesFile.fileData,
        );
      }
      if (
        docFileOriObject?.financialSupportFile.uniqueName &&
        docFileOriObject?.financialSupportFile.fileData
      ) {
        await getSingleUpload(
          docFileOriObject?.financialSupportFile.uniqueName,
          docFileOriObject?.financialSupportFile.fileData,
        );
      }
      if (
        docFileOriObject?.signedBookFile.uniqueName &&
        docFileOriObject?.signedBookFile.fileData
      ) {
        await getSingleUpload(
          docFileOriObject?.signedBookFile.uniqueName,
          docFileOriObject?.signedBookFile.fileData,
        );
      }
      if (
        docFileOriObject?.otherDocsFile.uniqueName &&
        docFileOriObject?.otherDocsFile.fileData
      ) {
        await getSingleUpload(
          docFileOriObject?.otherDocsFile.uniqueName,
          docFileOriObject?.otherDocsFile.fileData,
        );
      }
      const url = `${baseContractService}/approve-booking`;
      await axios.post(url, payload);
      setLoading(false);
      message.success("Booking sent for Approval");
      setTimeout(() => history.push("/bookingforms"), 1000);
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.statusText);
    }
  };

  useEffect(() => {
    if (rejectionReason) {
      acceptOrRejectForManger("reject");
    }
  }, [rejectionComment]);

  const acceptOrRejectForManger = async (type) => {
    setLoading(true);
    const mainObj = {
      leadID: leadId,
      bookingStatus: "reject",
      rejectionReasonTypeID: rejectionReason,
      comment: rejectionComment,
    };

    if (type === "accept") {
      mainObj.bookingStatus = "approve";
    }
    try {
      const url = `${baseContractService}/accept-or-reject-booking-approval`;
      await axios.post(url, mainObj);
      setLoading(false);
      type == "accept"
        ? message.success("Booking form Approved")
        : message.success("Booking form Rejected");
      setTimeout(() => history.push("/bookingforms"), 1000);
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.statustext);
    }
  };
  return (
    <DashboardLayout load={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Title level={2} className="mb-0 ag-fontSize32">
                {`Booking Form - ${
                  unitDetails?.usage_type?.name || ""
                } leasing`}
              </Title>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Form
            layout="vertical"
            name="bookingform"
            onFinish={handleSubmit(onSubmit)}
            scrollToFirstError
          >
            <Row gutter={24}>
              <Col span={24}>
                <Collapse
                  defaultActiveKey={["1", "2", "3", "4"]}
                  expandIconPosition="right"
                  ghost
                  className="ag-collapse ag-border-card"
                >
                  <Panel header="1. Tenant Details" key="1" forceRender={true}>
                    <Row gutter={24}>
                      <TenantDetails
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        details={tenantDetails}
                        bookingTerms={bookingTerms}
                        bookingFormDetails={bookingFormDetails}
                      />
                    </Row>
                  </Panel>

                  <Panel header="2. Unit Details" key="2" forceRender={true}>
                    <Row gutter={24}>
                      <UnitDetails
                        control={control}
                        errors={errors}
                        unitDetails={unitDetails}
                        setValue={setValue}
                        bookingFormDetails={bookingFormDetails}
                      />
                    </Row>
                  </Panel>
                  <Panel header="3. Booking Terms" key="3" forceRender={true}>
                    <Row gutter={24}>
                      <BookingTerms
                        setValue={setValue}
                        getValues={getValues}
                        control={control}
                        errors={errors}
                        details={bookingTerms}
                        termsConditions={termsConditions}
                        bookingFormDetails={bookingFormDetails}
                      />
                    </Row>
                  </Panel>
                  <Panel
                    header="4. Lease Terms"
                    key="4"
                    extra={clearData()}
                    forceRender={true}
                    collapsible="disabled"
                  >
                    <LeaseTerms
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      getValues={getValues}
                      bookingFormDetails={bookingFormDetails}
                      parkingSlotsDataFromApi={parkingSlotsDataFromApi}
                      isClearTriggered={isClearTriggered}
                    />
                  </Panel>
                </Collapse>
              </Col>
            </Row>
            <Space direction="vertical">
              <Row gutter={24}>
                <Col span={24}>
                  <Row gutter={[24, 24]} justify="space-between">
                    <Col span={24}>
                      <Title level={3}>5. Required Documents:</Title>
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]}>
                    {requiredDocArray.map((val, key) => {
                      return val.upKey == "signed" && !val.docData ? null : (
                        <Col key={key} span={24}>
                          <RequireDocument
                            title={val.docName}
                            setValue={setValue}
                            control={control}
                            docsData={val.docData}
                            upKey={val.upKey}
                            uploadFiles={uploadFiles}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Collapse
                    expandIconPosition="right"
                    ghost
                    className="ag-collapse ag-border-card"
                    defaultActiveKey={["6"]}
                  >
                    <Panel
                      header="6. Approval History"
                      key="6"
                      forceRender={true}
                    >
                      <ApprovalHistory approvalData={approvalData} />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Space>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <div className="steps-bookingForm">
                  <Button onClick={() => history.goBack()}>Back</Button>
                  {loginMode?.includes("Manager") ? (
                    <div className="buttonSteps">
                      <Button
                        type="primary"
                        danger
                        // className="btn-blue"
                        onClick={() => setVisible(true)}
                        disabled={disableRejectButton}
                      >
                        Reject
                      </Button>
                      <Button
                        type="primary"
                        className="btn-blue"
                        onClick={() => acceptOrRejectForManger("accept")}
                        disabled={disableApproveButton}
                      >
                        Approve
                      </Button>
                    </div>
                  ) : (
                    (loginMode === "Lease_Executive" ||
                      loginMode === "propertyExecutive") && (
                      <div className="buttonSteps">
                        <Button
                          type="primary"
                          className="btn-green"
                          htmlType="submit"
                          disabled={disableSaveDraftButton}
                        >
                          Save as draft
                        </Button>
                        <Button
                          type="primary"
                          className="btn-blue"
                          disabled={disableApprovalButton}
                          onClick={sendForApproval}
                        >
                          Send for approval
                        </Button>
                        <Button
                          type="primary"
                          className="btn-blue"
                          onClick={processToFullLease}
                          disabled={disableFullLeaseButton}
                        >
                          Process to full lease
                        </Button>
                      </div>
                    )
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Popup {...popupContent} />
    </DashboardLayout>
  );
};

export default ContractBookingForm;
