import React from "react";
import { Button, Form, Input, message, Space } from "antd";
import axios from "../../../../../../utils/axiosInceptor";
import { propertyService } from "../../../../../../configs/constants";

export default (props) => {
  const { selected, type, onSubmit, onCancel } = props;
  const [form] = Form.useForm();

  const handleFinish = async (val) => {
    const postJson = {
      party_id: 1,
      feature_name: val.feature,
      classification: selected,
      asset_subtype: type ? "Multi Unit" : "Single Unit",
    };
    const url = `${propertyService}/property-landlord/create-feature-amenity`;
    try {
      await axios.post(url, postJson);
      form.resetFields();
      onSubmit();
    } catch (e) {
      const { response } = e;
      console.log("error", e);
      message.error("Already Exist");
    }
  };

  return (
    <Form onFinish={handleFinish} form={form}>
      <Form.Item name="feature">
        <Input />
      </Form.Item>
      <Space className="w-100" align="end">
        <Button htmlType="submit">Add</Button>
        <Button
          htmlType="button"
          onClick={() => {
            form.resetFields();
            onCancel();
          }}
        >
          Cancel
        </Button>
      </Space>
    </Form>
  );
};
