import axios from "../../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import {
  baseContractService,
  leaseService,
} from "../../../../../configs/constants";

export const getTenantDetails = (id) => {
  return async (dispatch) => {
    const url = `${baseContractService}/${id}/tenant-details`;
    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_TENANT_DETAILS,
      data: result,
    });
  };
};

export const getLease = (id) => {
  return async (dispatch) => {
    const url = `${leaseService}/leads/lease/${id}/list`;
    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_TENANT_LEASE,
      data: result,
    });
  };
};


export const getDependants = (id, lease_id) => {
  return async (dispatch) => {
    const url = `${leaseService}/tenant/${id}/dependants/${lease_id}`;
    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_DEPENDENTS,
      data: result,
    })
  }
}
  

export const emptyTenantDetail = () => {
  return (dispatch) => {
    dispatch({
      type: action_types.GET_EMPTY,
      data: {},
    });
  };
};
