import axios from "../../../../utils/axiosInceptor";
import * as actions from "./constants";
import {
  baseContractService,
  propertyService,
} from "../../../../configs/constants";

export const getTenantIncomeRanges = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/income-ranges`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_INCOME_RANGES,
      data: response?.data?.result,
    });
  };
};

export const getMaritalStatus = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/marital-status`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_MARITAL_STATUS,
      data: response?.data?.result,
    });
  };
};

export const getTenantNationalities = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/nationalities`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_NATIONALITIES,
      data: response?.data?.result,
    });
  };
};

export const getTenantCities = () => {
  return async (dispatch) => {
    const url = `${propertyService}/property/cities`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_TENANT_CITIES,
      data: response?.data?.result,
    });
  };
};

export const getTenantCountires = () => {
  return async (dispatch) => {
    const url = `${propertyService}/property/countries`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_TENANT_COUNTRIES,
      data: response?.data?.result,
    });
  };
};

export const getTenantDetails = (partyId) => {
  return async (dispatch) => {
    const url = `${baseContractService}/${partyId}/tenant-details`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_TENANT_DETAILS,
      data: response?.data?.result,
    });
  };
};

export const getTenantEmploymentSector = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/employment-sectors`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_EMPLOYMENT_SECTOR,
      data: response?.data?.result,
    });
  };
};

export const getTenantRejectionReason = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/reject-reasons`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_REJECT_REASONS,
      data: response?.data?.result,
    });
  };
};

export const getContactRoles = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/get-tenant-role-types`;
    const response = await axios.get(url);

    dispatch({
      type: actions.GET_CONTACT_ROLES,
      data: response?.data?.result,
    });
  };
};

export const getTenantContacts = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/get-tenant-contacts`;
    const response = await axios.get(url);
    dispatch({
      type: actions.GET_TENANT_CONTACTS,
      data: response?.data?.result
    });
  };
};
/*

https://eqari-dev-api.agp-dev.com/lead-to-lease/api/v1/contract/income-ranges
https://eqari-dev-api.agp-dev.com/lead-to-lease/api/v1/contract/nationalities
https://eqari-dev-api.agp-dev.com/api/v1/property/cities
https://eqari-dev-api.agp-dev.com/api/v1/property/countries
https://eqari-dev-api.agp-dev.com/lead-to-lease/api/v1/contract/:party_id/tenant-details
Get Employment Sector API:
Request Type: GET
API: https://eqari-dev-api.agp-dev.com/lead-to-lease/api/v1/contract/employment-sectors


*********
View(Download Url)
Api_Url: /api/v1/property/download-signed-url
Payload:
{
"url": "property-dev.png"
}

Upload URL
Api_Url: /api/v1/property/upload-signed-url
Payload:
{
"keys": [
"property-dev.png"
 ]
 ******************************


 Confirm Booking API: 
Request Type: POST
API: https://eqari-dev-api.agp-dev.com/lead-to-lease/api/v1/contract/confirm-booking
Body:

{
"leadId": 83,
"tenantId": 12,
"assetId": 152
}

****************************************


API END POINT FOR ADD CONTACTS

https://eqari-dev-api.agp-dev.com/lead-to-lease/api/v1/contract/create-tenant-contact

PAYLOAD:


{
 "first_name": "moeez6",
 "nationality": "pakistan",
 "passport_no": "my_passport_no_4",
 "passport_expiry": "2021-04-17",
 "visa_no": "my visa_no_3",
 "visa_expiry": "2021-04-17",
 "emirates_no": "my_emirates_Id_3",
 "emirates_no_expiry": "2021-04-17",
 "contact_role": "secondary_contact",
 "media": {
 "visa_media": {
 "key": "my file key 2",
 "url": "myurl.com",
 "mime_type": "png"
 },
 "passport_media": {
 "key": "my passport file key 2",
 "url": "myurl.com",
 "mime_type": "png"
 },
 "emirates_no_media": {
 "key": "my emirates file key 2",
 "url": "myurl.com2",
 "mime_type": "png"
 }
 },
 "contact_info": {
 "contact_number": "12321321222",
 "country_code": "111222",
 "email": "zehra2@gmail.com"
 }
}
************************************************************





*************************************************

Payment Plan API:
Request Type: POST
API: https://eqari-dev-api.agp-dev.com/lead-to-lease/api/v1/contract/associate-plan-to-lead
Body:
{
    "leadId": 83,
    "assetId": 152,
    "paymentPlanId": 2445
}

**********************************************************



    UPDATE TENANT DETAILS

API_URL: https://eqari-dev-api.agp-dev.com/lead-to-lease/api/v1/contract/337/tenant-details

PAYLOAD:

{​​​​​​​​ "visa_designation": "dev", "tenant_usage_type_id": 1, "income_range_id": 1, "employment_sector_id": 1, "first_name": "tenant", "last_name": "user", "email": "tenant124@yopmail.com", "contact_number": "232131232", "address": "585-d,canal view", "city_id": 1, "country_id": 1, "nationality_id": 149, "marital_status_id": 2 }​​​​​​​​
​[Wednesday 3:25 PM] Sayed Afzal Hasan
    
https://eqari-dev-api.agp-dev.com/lead-to-lease/api/v1/contract/:party_id/tenant-details

**************************************

[4/6 1:55 PM] Sayed Afzal Hasan
    Get Tenant Contacts (API) 

​[4/6 1:55 PM] Sayed Afzal Hasan
    
https://eqari-dev-api.agp-dev.com/lead-to-lease/api/v1/contract/get-tenant-contacts



aprove reject reason api

getReason api



1 - POST api/v1/contract/documents/reject
[ { "tenant_object_id": 82, "tenant_object_status_reason_type_id": 2 } ]
 
2 - GET api/v1/contract/tenants/:tenantId/documentsFetch Tenant Documents (as returned in tenant-details endpoint)
 
3 - GET api/v1/contract/tenants/12/documents/rejected Fetch Tenant Rejected Documents
*/


// [1:50 PM] Sayed Afzal Hasan
    
// https://eqari-dev-api.agp-dev.com/lead-to-lease/api/v1/contract/update-tenant-contact


// {
//   "tenant_id": 12,
//   "first_name": "tenant",
//   "contact_info": {
//   "contact_number": "232131232",
//   "nationality_id": 150,
//   "email": "tenant124@yopmail.com"
//   },
//   "passport_no": "my_passport_no_4",
//   "passport_expiry": "2021-04-17",
//   "visa_no": "my visa_no_3",
//   "visa_expiry": "2021-04-17",
//   "emirates_no": "my_emirates_Id_3",
//   "emirates_no_expiry": "2021-04-17",
//   "media": {
//   "visa_media": {
//   "key": "my file key 2",
//   "url": "myurl.com",
//   "mime_type": "png"
//   },
//   "passport_media": {
//   "key": "my passport file key 2",
//   "url": "myurl.com",
//   "mime_type": "png"
//   },
//   "emirates_no_media": {
//   "key": "my emirates file key 2",
//   "url": "myurl.com2",
//   "mime_type": "png"
//   }
//   }
//  }

/* 
  Pending taks and dependency
  1. Get reasons api
  2. reject/ accept api
  3. contact roles api needed






  UI side
  Select dropdown issue
  upload in contact pending
  after filling all details we need to add contactsinfo so need add button clarification
*/
