import axios from "../utils/axiosInceptor";
import { baseContractService } from "../configs/constants";
import { message } from "antd";
const headers = {
  Authorization:
    "Bearer" +
    " " +
    JSON.parse(window.localStorage.getItem("login-response"))?.token,
};

export const downloadBookingForm = (leadID) => {
  axios
    .get(`${baseContractService}/download-booking-form/${leadID}`)
    .then((response) => {
      if (
        response?.data?.result?.imageUrl !=
        "https://s3.ap-south-1.amazonaws.com/"
      ) {
        window.open(response?.data?.result?.imageUrl);
      } else {
        downloadBookingForm(leadID);
      }
    })
    .catch((e) => {
      const { response } = e;
      message.error(response.data.message);
    });
};

export const cancelBookingForm = (leadID) => {
  const reqPayload = {
    leadID: leadID,
  };
  return axios.post(`${baseContractService}/cancel-booking`, reqPayload);
};

export const addUnit = (data) => {
  return axios.post(`${baseContractService}/copy-booking-form`, data);
};
