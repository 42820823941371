import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Layout,
  Row,
  Col,
  Button,
  Typography,
  Space,
  Form,
  Upload,
  message,
  Collapse,
  Alert,
} from "antd";
import Sidebar from "../../../molecules/Sidebar";
import Header from "../../../molecules/Header";
import Footer from "../../../molecules/Footer";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { InputField } from "../../../atoms/FormElement";
import { PopupSuccess } from "../../../atoms/Popup";
import {
  getUnitDetails,
  getLeaseTerms,
  getBookingTerms,
  getTenantDetails,
  getPaymentSchedule,
} from "./ducks/actions";
import moment from "moment";
import axios from "../../../../utils/axiosInceptor";
import { leaseService } from "../../../../configs/constants";
// Components
import UnitDetails from "./components/UnitDetails";
import TenantDetails from "./components/TenantDetails";
import LeaseTerms from "./components/LeaseTerms";
import BookingTerms from "./components/BookingTerms";
import PaymentSchedule from "./components/PaymentSchedule";

const { Content } = Layout;
const { Title } = Typography;
const { Panel } = Collapse;

const BookingForm = (props) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [paymentConfig, setPaymentConfig] = useState({});
  const [installment, setInstallment] = useState([]);
  const [docsArray, setDocsArray] = useState([]);

  const apiTenantData = useSelector(
    (state: any) => state.bookingReducer.getTenantData,
  );

  const apiUnitData = useSelector(
    (state: any) => state.bookingReducer.getUnitData,
  );
  const apiBookingTermsData = useSelector(
    (state: any) => state.bookingReducer.getBookingData,
  );
  // const apiLeaseTermsData = useSelector((state) => state.bookingReducer.getLeaseData);
  const apiPaymentScheduleData = useSelector(
    (state: any) => state.bookingReducer.getPaymentData,
  );

  const { control, handleSubmit, errors, setValue, getValues, reset } = useForm(
    {},
  );
  const { fields } = useFieldArray({
    control,
    name: "installment",
  });

  const popupProps = {
    title: "Submitted!",
    visible: visible,
    content: "You can now share the contract with the tenant.",
    okText: "Done",
    cancelText: "Back",
    onCancel: () => setVisible(false),
    onOk: () => setVisible(false),
  };

  const leaseid = window.location.pathname.split("/")[2];
  const partyId = window.localStorage.getItem("selectedPartyId");

  useEffect(() => {
    dispatch(getPaymentSchedule(leaseid));
    dispatch(getTenantDetails(partyId));
    // dispatch(getLeaseTerms(leaseid))
    dispatch(getBookingTerms(leaseid));
    dispatch(getUnitDetails(leaseid));
  }, []);

  useEffect(() => {
    if (Object.keys(apiTenantData).length) {
      const {
        name,
        country_code,
        contact_number,
        email,
        marital_status,
      } = apiTenantData;
      setValue("tenantName", name);
      setValue("nationality", country_code);
      setValue("mobile", contact_number?.replace("+971", ""));
      setValue("email", email);
      setValue("maritalstatus", marital_status);
    }
  }, [apiTenantData]);

  useEffect(() => {
    if (Object.keys(apiUnitData).length) {
      const { name, size, sub_type, type, unit_no } = apiUnitData;
      setValue("buildingName", name);
      setValue("unitno", unit_no);
      setValue("unittype", type.id);
      setValue("unitsubtype", sub_type.id);
      setValue("unitsize", size);
    }
  }, [apiUnitData]);

  useEffect(() => {
    if (Object.keys(apiBookingTermsData).length) {
      const {
        annual_base_rent,
        booking_end_date,
        booking_start_date,
        non_refundable_amount,
        number_of_installments,
      } = apiBookingTermsData;
      setValue("annualbaserent", annual_base_rent);
      setValue("bookingstartdate", moment(booking_start_date));
      setValue(
        "bookingexpirydate",
        booking_end_date ? moment(booking_end_date) : "",
      );
      setValue("bookingamount", non_refundable_amount);
      setValue("installmentproposed", number_of_installments);
    }
  }, [apiBookingTermsData]);

  useEffect(() => {
    if (Object.keys(apiPaymentScheduleData).length) {
      setValue("marketrent", apiPaymentScheduleData.price);
      setValue("installmentplan", apiPaymentScheduleData.leaseDuration);
      const ejari_fee = apiPaymentScheduleData.charges.filter(
        (item) => item.planType === "ejari_fee",
      )[0];
      const processing_fee = apiPaymentScheduleData.charges.filter(
        (item) => item.planType === "processing_fee",
      )[0];
      const security_deposit = apiPaymentScheduleData.charges.filter(
        (item) => item.planType === "security_deposit",
      )[0];
      const installments = apiPaymentScheduleData.charges
        .filter((item) => item.planType === "installment")
        .map((item, index) => {
          const a = {};
          a["installment"] = item.price;
          a["installmentvat"] = item.otherCharges[0].price;
          return a;
        });
      setPaymentConfig({
        ejari: ejari_fee?.price,
        ejarivat: ejari_fee?.otherCharges[0].price,
        processing: processing_fee?.price,
        processingvat: processing_fee?.otherCharges[0].price,
        security: security_deposit?.price || 0,
        securityvat: security_deposit?.otherCharges[0].price || 0,
      });
      setInstallment(installments);
    }
  }, [apiPaymentScheduleData]);

  const uploadOptions: any = {
    name: "file",
    multiple: true,
    accept: ".pdf,.doc,.docx",
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },

    defaultFileList: [
      {
        uid: "192",
        name: "Passport - (Uploaded via UAE Pass)",
        status: "done",
        url:
          "https://ekari-media-dest-dev.s3.ap-south-1.amazonaws.com/ids/passport.jpeg",
      },
      {
        uid: "194",
        name: "Valid UAE Visa - (Uploaded via UAE Pass)",
        status: "done",
        url:
          "https://ekari-media-dest-dev.s3.ap-south-1.amazonaws.com/ids/visa.jpg",
      },
      {
        uid: "193",
        name: "Emirates ID - (Uploaded via UAE Pass)",
        status: "done",
        url:
          "https://ekari-media-dest-dev.s3.ap-south-1.amazonaws.com/ids/Emirates-ID.jpg",
      },
      // {
      //   uid: '4',
      //   name: 'Financial Supporting Document - (Uploaded by tenant)',
      //   status: 'done',
      //   url: 'http://www.baidu.com/zzz.png',
      // },
    ],

    showUploadList: {
      showRemoveIcon: false,
      // showDownloadIcon: true,
      // downloadIcon: "View",
    },
  };

  const onSubmit = async (data) => {
    try {
      const url = `${leaseService}/leads/create-booking`;
      const postJson = {
        lease_id: leaseid,
        object_ids: [192, 193, 194],
      };
      const headers = {
        Authorization:
          "Bearer" +
          " " +
          JSON.parse(window.localStorage.getItem("login-response")).token,
      };
      await axios.post(url, postJson, { headers });
      PopupSuccess(popupProps);
    } catch (e) {
      console.log("EE", e);
      // PopupSuccess(popupProps)
    }
  };

  return (
    <Layout className="main-layout">
      <Header />
      <Layout>
        <Sidebar />
        <Layout className="layout-space">
          <Content className="main-content">
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space direction="vertical" size={16}>
                  <Title level={2} className="mb-0 ag-fontSize32">
                    Booking Form
                  </Title>
                  <Alert
                    message="Please fill out the missing booking details."
                    type="info"
                    showIcon
                    closable
                  />
                </Space>
              </Col>
            </Row>
            <Form
              layout="vertical"
              name="bookingform"
              onFinish={handleSubmit(onSubmit)}
            >
              <Collapse
                defaultActiveKey={["1", "2", "3", "4"]}
                expandIconPosition="right"
                ghost
                className="ag-collapse ag-border-card"
              >
                <Panel header="1. Tenant Details" key="1" forceRender={true}>
                  <Row gutter={24}>
                    <TenantDetails control={control} errors={errors} />
                  </Row>
                </Panel>
                <Panel header="2. Unit Details" key="2" forceRender={true}>
                  <Row gutter={24}>
                    <UnitDetails
                      control={control}
                      errors={errors}
                      useWatch={useWatch}
                      setValue={setValue}
                      propertyData={{}}
                    />
                    <Col span={8}>
                      <InputField
                        label="DEWA"
                        fieldname={`dewa`}
                        control={control}
                        initValue=""
                        iProps={{ placeholder: "Enter details" }}
                      />
                    </Col>
                  </Row>
                </Panel>
                {/* <Panel header="3. Lease Terms" key="3" forceRender={true}>
                  <LeaseTerms control={control} errors={errors} />
                </Panel> */}
                <Panel header="3. Booking Terms" key="3" forceRender={true}>
                  <BookingTerms control={control} errors={errors} />
                </Panel>
                <Panel header="4. Payment Schedule" key="4" forceRender={true}>
                  <PaymentSchedule
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    getValues={getValues}
                    useWatch={useWatch}
                    paymentConfig={paymentConfig}
                    installmentPlan={installment}
                  />
                </Panel>
              </Collapse>

              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Title level={3} className="mb-0">
                    Required Documents:
                  </Title>
                </Col>
                <Col span={24}>
                  <div className="upload_docs no-space-upload">
                    <Upload {...uploadOptions}>
                      {/* <Button type="link">+ Add more documents</Button> */}
                    </Upload>
                  </div>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <div className="steps-action">
                    {/* <Button>Back</Button> */}
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
            <Footer />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default BookingForm;
