import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import {
  Row,
  Col,
  Layout,
  Avatar,
  Dropdown,
  Menu,
  Image,
  Space,
  Button,
  Badge,
  Typography,
} from "antd";
import {
  UserOutlined,
  CalendarOutlined,
  BellOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { actionLogout } from "./duck/actions";
import { deleteCookie } from "../../../utils/helper";
import storeLogo from "../../../assets/img/eqari-logo.svg";
import {
  getNotifications,
  getNewNotifications,
} from "../../modules/Properties/AddProperty/ducks/actions/addInfoAction";
import NotificationPanel from "../NotificationPanel";
import { Link } from "react-router-dom";

const { Header } = Layout;
const { Text } = Typography;

const HeaderBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  let notificationInterval = null;

  const logoutHandler = () => {
    window.localStorage.clear();
    deleteCookie("landlordUser");
    history.push("/login");
  };

  const notificationsData = useSelector(
    (state: any) => state.addInfoReducer.getNotifications,
  );

  const newNotificationsData = useSelector(
    (state: any) => state.addInfoReducer.getNewNotifications,
  );

  useEffect(() => {
    if (JSON.parse(window.localStorage.getItem("login-response"))?.token) {
      dispatch(getNotifications());
      dispatch(getNewNotifications());
    }
  }, []);

  useEffect(() => {
    if (notificationsData.length) {
      setNotifications(notificationsData);
    }
  }, [notificationsData]);

  useEffect(() => {
    notificationInterval = setInterval(() => {
      dispatch(getNotifications());
      dispatch(getNewNotifications());
    }, 1000 * 60);
    return () => {
      clearInterval(notificationInterval);
    };
  }, []);

  const menu = (
    <Menu>
      <Menu.Item>
        <Button
          onClick={logoutHandler}
          type="link"
          className="btn-link"
          icon={<LogoutOutlined />}
        >
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  const Notification: any = NotificationPanel;

  return (
    <Header className="site-header">
      <Row>
        <Col flex={200}>
          <Image
            src={storeLogo}
            preview={false}
            width={150}
            alt="EQARCOM logo"
            style={{ marginTop: 5 }}
          />
        </Col>
        <Col flex="auto">
          <Space size={12}>
            <Space size={0}>
              <Button
                icon={<CalendarOutlined />}
                className="headerIcon-btn p-0"
                type="link"
                onClick={() => history.push("/notifications/calendar")}
              />
              <Dropdown
                overlay={
                  <Notification
                    notificationsData={notifications}
                    newNotificationsData={newNotificationsData}
                  />
                }
                placement="bottomRight"
                trigger={["click"]}
                arrow
              >
                <Button type="link" className="headerIcon-btn" size="large">
                  <Badge {...(newNotificationsData.length && { dot: true })}>
                    <BellOutlined />
                  </Badge>
                </Button>
              </Dropdown>
            </Space>
            <Dropdown overlay={menu} placement="bottomRight">
              <Space>
                <Avatar size={24} icon={<UserOutlined />} />
                <Text>
                  {
                    JSON.parse(window.localStorage.getItem("login-response"))
                      ?.data?.firstName
                  }
                </Text>
              </Space>
            </Dropdown>
          </Space>
        </Col>
      </Row>
    </Header>
  );
};

export default HeaderBar;
