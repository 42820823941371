import * as action_types from "./constants";

const initialState = {
  leaseList: {
    count: 0,
    rows: [],
  },
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_LEASEFORMS:
      return { ...state, leaseList: data };
    default:
      return state;
  }
};
