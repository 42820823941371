import React, { useEffect } from "react";
import { Form, Row, Col, Button, message } from "antd";
import { ReactSelectField, TextAreaField } from "../../../atoms/FormElement";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getTenantRejectionReason } from "../../../modules/TenantDetail/ducks/actions";
import { baseContractService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";
import { useHistory } from "react-router-dom";

const RejectForm = (props) => {
  const history = useHistory();
  const { onClose, leadId } = props;
  const dispatch = useDispatch();
  const { control, errors, setValue, handleSubmit } = useForm();

  const reasonsData = useSelector(
    (state: any) => state.tenantDetails.getRejectReasons,
  );

  useEffect(() => {
    dispatch(getTenantRejectionReason());
  }, []);

  const handleReject = async (data) => {
    const payload = {
      leadId: leadId,
      rejectionReasonTypeId: data.reason.value,
      comment: data.rejectReason,
    };

    try {
      const url = `${baseContractService}/reject-lease`;
      await axios.post(url, payload);
      message.success("Lease Form has been Rejected");
      onClose();
      setTimeout(() => {
        history.push("/lease");
      }, 1000);
    } catch (e) {
      const { response } = e;
      message.error(response.data.result);
    }
  };
  return (
    <Form layout="vertical" onFinish={handleSubmit(handleReject)}>
      <Row gutter={24}>
        <Col span={12}>
          <ReactSelectField
            label="Select a reason"
            fieldname="reason"
            iProps={{ placeholder: "Select reasons" }}
            initValue=""
            setValue={setValue}
            isRequired={true}
            control={control}
            rules={{ required: "Required" }}
            validate={errors.reason && "error"}
            validMessage={errors.reason && errors.reason.message}
            selectOption={reasonsData?.map((e) => {
              return {
                label: e.name,
                value: e.id,
              };
            })}
          />
        </Col>
        <Col span={24}>
          <TextAreaField
            label="Enter a comment for rejection:"
            fieldname="rejectReason"
            iProps={{ placeholder: "Some reason...." }}
            initValue=""
            setValue={setValue}
            control={control}
          />
        </Col>
      </Row>
      <Row gutter={24} justify="space-between">
        <Col>
          <Button size="large" onClick={onClose}>
            Back
          </Button>
        </Col>
        <Col>
          <Button size="large" className="ag-red-button" htmlType="submit">
            Reject
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default RejectForm;
