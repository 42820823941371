import React, { useState, useEffect, Fragment } from "react";
import { Popup } from "../../atoms/Popup";
import { Card, Row, Col, Typography, Button, Image, Upload, Space } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import PdfViewer from "../../molecules/PDFViewer/pdfView";
import { getPresignedImage } from "../../modules/Properties/AddProperty/ducks/actions/addInfoAction";

const RequirDoc = (props) => {
  const [visible, Setvisible] = useState(false);
  const { title, popup, docsData, uploadFiles, upKey } = props;
  const { Title } = Typography;
  const [viewOrDownloadUrl, setViewOrDownloadUrl] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const [file, setFile] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);

  const onClosePopUp = () => {
    Setvisible(false);
  };

  const isExistFromApi = docsData ? true : false;

  const onOpenPopUpHandler = () => {
    if (docsData && docsData?.split(".")[1] === "pdf") {
      setPdfFile(viewOrDownloadUrl);
      setShowPdf(!showPdf);
    } else if (
      Object.keys(file).length &&
      !Array.isArray(file) &&
      // @ts-ignore
      file?.name.includes("pdf")
    ) {
      let urlf = window.URL.createObjectURL(file["originFileObj"]);
      setPdfFile(urlf);
      setShowPdf(!showPdf);
    } else {
      Setvisible(true);
    }
  };

  const getUrl = async (data) => {
    const url = await getPresignedImage(data);
    setViewOrDownloadUrl(url);
  };

  useEffect(() => {
    if (docsData) {
      getUrl(docsData);
    }
  }, [docsData]);

  const popupContent = {
    closable: true,
    visibility: visible,
    content: (
      <Image
        preview={false}
        width="100%"
        style={{ padding: "20px" }}
        src={viewOrDownloadUrl}
      />
    ),
    width: 650,
    onCancel: onClosePopUp,
  };

  const fileProps = {
    className: "file-upload",
    name: "file",
    multiple: false,
    showUploadList: false,
    onChange(info) {
      setFile(info.file);
      uploadFiles(upKey, info.file.originFileObj);
      if (Object.keys(info.file).length && !Array.isArray(info.file)) {
        const url = window.URL.createObjectURL(info.file.originFileObj);
        setViewOrDownloadUrl(url);
      }
    },
    file,
  };

  return (
    <Fragment>
      <PdfViewer
        pdf={pdfFile}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
      />
      <Card bordered={false}>
        <Row gutter={24} justify="space-between" align="middle">
          <Col span={12} style={{ display: "flex" }}>
            <FileTextOutlined className="require-doc" />
            <Title level={5}>{`${title}`}</Title>
          </Col>
          <Col>
            {isExistFromApi && (
              <Button type="link" href={viewOrDownloadUrl} target="_blank">
                Download
              </Button>
            )}
            <Space>
              {upKey != "signed" && (
                <Upload {...fileProps}>
                  <Button type="link" className="p-0 ag-primary">
                    {isExistFromApi ? "Change" : "Upload"}
                  </Button>
                </Upload>
              )}
              {(isExistFromApi || viewOrDownloadUrl) && (
                <Button type="link" onClick={onOpenPopUpHandler}>
                  View
                </Button>
              )}
            </Space>
            {/* <Radio.Group
              className=""
              name="radiogroup"
              onChange={(e) => popup(e.target.value)}
            >
              <Radio className="radio-group-reject" value="reject">
                Reject
              </Radio>
              <Radio className="radio-group-accept" value="accept">
                Accept
              </Radio>
            </Radio.Group> */}
          </Col>
        </Row>
      </Card>
      <Popup {...popupContent} />
    </Fragment>
  );
};

export default RequirDoc;
