import React, { useEffect, useState } from "react";
import { Row, Card, Typography, Form, Button, Divider, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { propertyService } from "../../../../../configs/constants";
import { EditOutlined, CheckCircleOutlined } from "@ant-design/icons";

import { useForm } from "react-hook-form";
import moment from "moment";

import {
  getUnitSubType,
  getUnitType,
} from "../../AddProperty/ducks/actions/addInfoAction";
import axios from "axios";
import { bedroomCategory, bathList, tfValue } from "../../ducks/constants";
import General from "../../AddProperty/components/AddInformation/General";
import Address from "../../AddProperty/components/AddInformation/Address";
import MoreDetails from "../../AddProperty/components/AddInformation/MoreDetails";
import FeaturesAmenities from "../../AddProperty/components/AddInformation/FeaturesAmenities";

const { Title } = Typography;
var _ = require("lodash");

export default ({ propertyDetails, updated }) => {
  const dispatch = useDispatch();
  const { control, handleSubmit, errors, setValue, reset, watch } = useForm();
  const [noEdit, setNoEdit] = useState(true);
  const [featureList, setFeatureList] = useState([]);
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [comm, setComm] = useState(false);

  const apiUsageTypeData = useSelector(
    (state: any) => state.addInfoReducer.getUsageType,
  );
  const apiUnitTypeData = useSelector(
    (state: any) => state.addInfoReducer.getUnitType,
  );
  const unitsApi = useSelector((state: any) => state.propertyDetails.unitsList);
  const apiUnitSubTypeData = useSelector(
    (state: any) => state.addInfoReducer.getUnitSubType,
  );
  const usageWatch = watch("usage_type_id");
  const unitWatch = watch("unit_type_id");

  useEffect(() => {
    if (usageWatch) {
      dispatch(getUnitType(usageWatch.value));
      if (usageWatch.value == 2) {
        setComm(true);
      } else {
        setComm(false);
      }
    }
  }, [usageWatch]);

  useEffect(() => {
    if (unitWatch) {
      dispatch(getUnitSubType(unitWatch.value));
    }
  }, [unitWatch]);

  useEffect(() => {
    if (apiUnitTypeData.length) {
      let unitlist = apiUnitTypeData.find(
        (item) => item.id === propertyDetails.unit_type_id,
      );
      if (unitlist) {
        setValue("unit_type_id", { value: unitlist.id, label: unitlist.name });
      }
    }
  }, [apiUnitTypeData]);

  useEffect(() => {
    if (apiUnitSubTypeData.length) {
      let sublist: any = {};
      sublist = apiUnitSubTypeData.find(
        (item) => item.id === propertyDetails.unit_subtype_id,
      );
      if (sublist) {
        setValue("unit_subtype_id", { value: sublist.id, label: sublist.name });
      } else {
        setValue("unit_subtype_id", "");
      }
    }
  }, [apiUnitSubTypeData]);

  useEffect(() => {
    if (!noEdit) {
      let featureSelected = [];
      let amenitySelected = [];
      let f = _.map(propertyDetails.property_features, (item) => {
        let a = featureList.find((x) => x.label === item.label);
        featureSelected.push(a);
      });
      let am = _.map(propertyDetails.property_amenities, (item) => {
        let a = amenitiesList.find((x) => x.label === item.label);
        amenitySelected.push(a);
      });
      setValue("property_features", featureSelected);
      setValue("property_amenities", amenitySelected);
    }
  }, [noEdit]);

  useEffect(() => {
    if (Object.keys(propertyDetails).length) {
      let usage = apiUsageTypeData.find(
        (item) => item.id === propertyDetails.usage_type_id,
      );
      if (usage) {
        setValue("usage_type_id", { value: usage.id, label: usage.name });
      }
      setValue("name", propertyDetails.name);
      setValue(
        "builtIn",
        propertyDetails.builtIn ? moment(propertyDetails.builtIn) : null,
      );
      setValue(
        "available_from",
        propertyDetails.effectiveStartDate
          ? moment(propertyDetails.effectiveStartDate)
          : null,
      );
      setValue("size", propertyDetails.size);
      setValue("asset_status", propertyDetails.asset_status);
      setValue("description", propertyDetails.description);
      setValue("neighborhood", propertyDetails.property_info[0]?.neighborhood);
      setValue("city", {
        value: propertyDetails.property_info[0]?.city.id,
        label: propertyDetails.property_info[0]?.city.name,
      });
      setValue(
        "street_address",
        propertyDetails.property_info[0]?.street_address,
      );
      setValue("region", {
        value: propertyDetails.property_info[0]?.region.id,
        label: propertyDetails.property_info[0]?.region.name,
      });
      setValue("zipcode", propertyDetails.property_info[0]?.zipcode);
      setValue("country", {
        value: propertyDetails.property_info[0]?.country.id,
        label: propertyDetails.property_info[0]?.country.name,
      });
      if (propertyDetails.is_parking_available) {
        setValue(
          "is_parking_available",
          tfValue.find(
            (item) => item.value == propertyDetails.is_parking_available,
          ),
        );
      }
      setValue(
        "is_360tour_available",
        tfValue.find(
          (item) => item.value == propertyDetails.is_360tour_available,
        ),
      );

      let long = Number(propertyDetails.property_info[0]?.longitude);
      let lat = Number(propertyDetails.property_info[0]?.latitude);
      if (long) {
        setValue("longitude", long);
      }
      if (lat) {
        setValue("latitude", lat);
      }

      if (propertyDetails.property_info[0]?.location) {
        setValue("location", {
          value: propertyDetails.property_info[0]?.location,
          label: propertyDetails.property_info[0]?.location,
        });
      }
      if (propertyDetails.property_info[0]?.sub_location) {
        setValue("sub_location", {
          value: propertyDetails.property_info[0]?.sub_location,
          label: propertyDetails.property_info[0]?.sub_location,
        });
      }

      setValue(
        "number_of_baths",
        bathList.find((item) => item.value == propertyDetails.number_of_baths),
      );
      setValue(
        "number_of_beds",
        bedroomCategory.find(
          (item) => item.value.toString() == propertyDetails.number_of_beds,
        ),
      );
    }
  }, [propertyDetails]);

  const onSubmit = async (val) => {
    let removeFeature = [];
    let features = [];
    if (propertyDetails.property_amenities?.length > 0) {
      propertyDetails.property_amenities?.forEach((item) => {
        let x = val.property_amenities.find((y) => y.label === item.label);
        if (x) {
          features.push({
            asset_feature_value_id: item.id, // selected feature BE generated id
            component_value: item.value, // selected feature value e.g. 1 Tons
          });
        } else {
          removeFeature.push({
            asset_feature_id: item.asset_feature_id, // remove feature from property
          });
        }
      });
    }
    if (val.property_amenities.length > 0) {
      val.property_amenities.forEach((item) => {
        let x = propertyDetails.property_amenities?.find(
          (y) => y.label === item.label,
        );
        if (!x) {
          features.push({
            component_label: item.label,
            value_type_meta_id: item.value,
            feature_classification: "property_amenities",
            component_value: true,
          });
        }
      });
    }

    if (propertyDetails.property_features?.length > 0) {
      propertyDetails.property_features?.forEach((item) => {
        let x = val.property_features?.find((y) => y.label === item.label);
        if (x) {
          features.push({
            asset_feature_value_id: item.id, // selected feature BE generated id
            component_value: item.value, // selected feature value e.g. 1 Tons
          });
        } else {
          removeFeature.push({
            asset_feature_id: item.asset_feature_id, // remove feature from property
          });
        }
      });
    }

    if (val.property_features.length > 0) {
      val.property_features.forEach((item) => {
        let x = propertyDetails.property_features?.find(
          (y) => y.label === item.label,
        );
        if (!x) {
          features.push({
            component_label: item.label,
            value_type_meta_id: item.value,
            feature_classification: "property_features",
            component_value: true,
          });
        }
      });
    }

    let builtinDate = null;
    let availDate = null;
    let bed = null;
    let bath = null;
    if (val.builtIn != null) {
      builtinDate = moment(val.builtIn).format("YYYY-MM-DD");
    }

    if (val.available_from != null) {
      availDate = moment(val.available_from).format("YYYY-MM-DD");
    }

    if (val.number_of_baths) {
      bath = val.number_of_baths.value;
    }
    if (val.number_of_beds) {
      bed = val.number_of_beds.value;
    }

    const postJson = {
      name: val.name,
      built_in: builtinDate,
      asset_type_id: propertyDetails.asset_type.id,
      available_from: availDate,
      description: val.description,
      street_address: val.street_address,
      neighborhood: val.neighborhood,
      zipcode: val.zipcode,
      region_id: val.region.value,
      city_id: val.city.value,
      country_id: val.country.value,
      usage_type_id: val.usage_type_id.value,
      unit_type_id: val.unit_type_id.value,
      unit_subtype_id: val.unit_subtype_id?.value || 1,
      size: val.size,
      number_of_baths: +bath,
      number_of_beds: +bed,
      location: val.location.value,
      sub_location: val.sub_location.value,
      longitude: String(val.longitude),
      latitude: String(val.latitude),
      is_parking_available: val.is_parking_available.value === "true",
      is_360tour_available: val.is_360tour_available.value === "true",
      contact_mechanism_id:
        propertyDetails?.property_info[0]?.contact_mechanism_id,
      physical_address_id:
        propertyDetails?.property_info[0]?.physical_address_id,
      property_features: features,
      remove_features: removeFeature,
    };

    try {
      await axios.put(
        `${propertyService}/property-landlord/${propertyDetails.asset_id}`,
        postJson,
      );
      message.success("Property Details Updated");
      updated();
      setNoEdit(true);
    } catch (e) {
      console.log("error", e);
      message.error("Something went wrong");
      setNoEdit(true);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        name="bookingform"
        onFinish={handleSubmit(onSubmit)}
      >
        <Card
          bordered={false}
          bodyStyle={{ padding: "16px 32px 0" }}
          headStyle={{ padding: "16px 32px 0", borderBottom: 0 }}
          title={
            <Title level={5} className="ag-primary mb-0">
              General Information
            </Title>
          }
          extra={[
            <Button
              key={1}
              type="link"
              className={`p-0 no-border no-shadow ${noEdit && "opacity0"}`}
              icon={<CheckCircleOutlined />}
              htmlType="submit"
            >
              Save
            </Button>,
            <Button
              key={2}
              type="link"
              className={`p-0 no-border no-shadow ${!noEdit && "opacity0"}`}
              icon={<EditOutlined />}
              onClick={() => setNoEdit(false)}
              htmlType="button"
            />,
          ]}
        >
          <General
            control={control}
            errors={errors}
            setValue={setValue}
            noEdit={noEdit}
          />
        </Card>
        <Divider className="m-0" />
        <Card
          bordered={false}
          bodyStyle={{ padding: "16px 32px 0" }}
          headStyle={{ padding: "16px 32px 0", borderBottom: 0 }}
          title={
            <Title level={5} className="ag-primary mb-0">
              Property Information
            </Title>
          }
        >
          <Row gutter={24}>
            <Address
              control={control}
              errors={errors}
              setValue={setValue}
              noEdit={noEdit}
            />

            <MoreDetails
              control={control}
              errors={errors}
              setValue={setValue}
              noEdit={noEdit}
              watch={watch}
              hasUnit={!unitsApi.length}
              hasComm={comm}
            />
          </Row>
          <FeaturesAmenities
            noEdit={noEdit}
            control={control}
            errors={errors}
            hasUnit={unitsApi.length}
            propertyDetails={propertyDetails}
            setFeatureList={setFeatureList}
            setAmenitiesList={setAmenitiesList}
          />
        </Card>
      </Form>
    </>
  );
};
