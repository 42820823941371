import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../molecules/DashboardLayout";
import { Row, Col, Card, Button, Space, Typography, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getContactDetail } from "../ducks/actions";
import SectionHeader from "../../../molecules/SectionHeader";
import HeadingChip from "../../../atoms/HeadingChip";
import BreadCrumb from "../../../atoms/BreadCrumb";

const { Title, Text } = Typography;
const breadcrumbList: any = [
  {
    label: "Contracts",
    route: "/contracts",
  },
  {
    label: "Details",
    route: "",
  },
];

export default (props) => {
  const dispatch = useDispatch();
  const contractApi = useSelector((state: any) => state.contracts.contractData);
  const contractId = location.pathname?.split("/")[2];

  useEffect(() => {
    dispatch(getContactDetail(contractId));
  }, []);

  return (
    <DashboardLayout>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <BreadCrumb list={breadcrumbList} />
            </Col>
            <Col span={24}>
              <SectionHeader heading="Contract Details" noBorder={true} />
            </Col>
            <Col span={8}>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <HeadingChip heading="Lease Details" />
                </Col>
                <Col span={24}>
                  <Card
                    bordered={false}
                    style={{ borderRadius: 4 }}
                    bodyStyle={{
                      padding: "24px 0",
                      height: 625,
                      overflowX: "auto",
                    }}
                  >
                    <Space
                      direction="vertical"
                      size={24}
                      style={{ width: "100%" }}
                    >
                      <Space
                        direction="vertical"
                        size={12}
                        className="w-100 px-2"
                      >
                        <Text className="defaultFont fontWeight600">
                          Lease Status
                        </Text>
                        <Title
                          level={5}
                          className="ag-primary mb-0 sentanceCase"
                        >
                          {contractApi?.lease_status
                            ? contractApi?.lease_status.replace("_", " ")
                            : "N/A"}
                        </Title>
                        <Text className="defaultFont fontWeight600 sentanceCase">
                          Updated on {contractApi?.lease_last_updated || "N/A"}
                        </Text>
                      </Space>
                      {/* <Divider style={{ borderColor: "#d2d2d2", margin: 0 }} /> */}
                      {/* <Space
                        direction="vertical"
                        size={12}
                        className="w-100 px-2"
                      >
                        <Title level={5} className="ag-primary">
                          Unit ID
                        </Title>
                        <Text className="defaultFont fontWeight600">
                          {contractApi?.unit_id}
                        </Text>
                      </Space> */}
                      <Divider style={{ borderColor: "#d2d2d2", margin: 0 }} />
                      <Space
                        direction="vertical"
                        size={12}
                        className="w-100 px-2"
                      >
                        <Title level={5} className="ag-primary">
                          Building Name
                        </Title>
                        <Text className="defaultFont fontWeight600 sentanceCase">
                          {contractApi?.building_name || "N/A"}
                        </Text>
                      </Space>
                      <Divider style={{ borderColor: "#d2d2d2", margin: 0 }} />
                      <Space
                        direction="vertical"
                        size={12}
                        className="w-100 px-2"
                      >
                        <Title level={5} className="ag-primary">
                          Unit Code
                        </Title>
                        <Text className="defaultFont fontWeight600">
                          {contractApi?.unit_code || "N/A"}
                        </Text>
                      </Space>
                      <Divider style={{ borderColor: "#d2d2d2", margin: 0 }} />
                      <Space
                        direction="vertical"
                        size={12}
                        className="w-100 px-2"
                      >
                        <Title level={5} className="ag-primary">
                          Lease Start Date | Lease End Date
                        </Title>
                        <Text className="defaultFont fontWeight600">
                          {contractApi?.lease_start_date || "N/A"} |{" "}
                          {contractApi?.lease_end_date || "N/A"}
                        </Text>
                      </Space>
                      <Divider style={{ borderColor: "#d2d2d2", margin: 0 }} />
                      <Space
                        direction="vertical"
                        size={12}
                        className="w-100 px-2"
                      >
                        <Title level={5} className="ag-primary">
                          Unit Type
                        </Title>
                        <Text className="defaultFont fontWeight600 sentanceCase">
                          {contractApi?.unit_type || "N/A"}
                        </Text>
                      </Space>
                      <Divider style={{ borderColor: "#d2d2d2", margin: 0 }} />
                      <Space
                        direction="vertical"
                        size={12}
                        className="w-100 px-2"
                      >
                        <Title level={5} className="ag-primary">
                          Category
                        </Title>
                        <Text className="defaultFont fontWeight600 sentanceCase">
                          {contractApi?.category || "N/A"}
                        </Text>
                      </Space>
                      <Divider style={{ borderColor: "#d2d2d2", margin: 0 }} />
                      <Space
                        direction="vertical"
                        size={12}
                        className="w-100 px-2"
                      >
                        <Title level={5} className="ag-primary">
                          Address
                        </Title>
                        <Text className="defaultFont fontWeight600 sentanceCase">
                          {contractApi?.address || "N/A"}
                        </Text>
                      </Space>
                      <Divider style={{ borderColor: "#d2d2d2", margin: 0 }} />
                      <Space
                        direction="vertical"
                        size={12}
                        className="w-100 px-2"
                      >
                        <Title level={5} className="ag-primary">
                          Vacate Reason
                        </Title>
                        <Text className="defaultFont fontWeight600 sentanceCase">
                          {contractApi?.vacate_reason || "N/A"}
                        </Text>
                      </Space>
                    </Space>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col span={8}>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <HeadingChip heading="Renewal Status" />
                </Col>
                <Col span={24}>
                  <Card
                    bordered={false}
                    style={{ borderRadius: 4 }}
                    bodyStyle={{
                      padding: "24px 0",
                      height: 625,
                      overflowX: "auto",
                    }}
                  >
                    <Space
                      direction="vertical"
                      size={24}
                      style={{ width: "100%" }}
                    >
                      <Space
                        direction="vertical"
                        size={12}
                        className="w-100 px-2"
                      >
                        <Title level={5} className="ag-primary mb-0">
                          Package Number
                        </Title>
                        <Text className="defaultFont fontWeight600 sentanceCase">
                          {contractApi?.package_number || "N/A"}
                        </Text>
                      </Space>
                      {/* <Divider style={{ borderColor: "#d2d2d2", margin: 0 }} />
                      <Space
                        direction="vertical"
                        size={12}
                        className="w-100 px-2"
                      >
                        <Title level={5} className="ag-primary">
                          Preferred Time
                        </Title>
                        <Text className="defaultFont fontWeight600">
                          {contractApi?.prefered_time || "N/A"}
                        </Text>
                      </Space>
                      <Divider style={{ borderColor: "#d2d2d2", margin: 0 }} />
                      <Space
                        direction="vertical"
                        size={12}
                        className="w-100 px-2"
                      >
                        <Title level={5} className="ag-primary">
                          Renewal Date
                        </Title>
                        <Text className="defaultFont fontWeight600">
                          {contractApi?.renewal_date || "N/A"}
                        </Text>
                      </Space>
                      <Divider style={{ borderColor: "#d2d2d2", margin: 0 }} />
                      <Space
                        direction="vertical"
                        size={12}
                        className="w-100 px-2"
                      >
                        <Title level={5} className="ag-primary">
                          Renewal Time
                        </Title>
                        <Text className="defaultFont fontWeight600">
                          {contractApi?.renewal_time || "N/A"}
                        </Text>
                      </Space> */}
                      <Divider style={{ borderColor: "#d2d2d2", margin: 0 }} />
                      <Space
                        direction="vertical"
                        size={12}
                        className="w-100 px-2"
                      >
                        <Title level={5} className="ag-primary">
                          Renewal Status
                        </Title>
                        <Text className="defaultFont fontWeight600 sentanceCase">
                          {contractApi?.renewal_status || "N/A"}
                        </Text>
                      </Space>
                      <Divider style={{ borderColor: "#d2d2d2", margin: 0 }} />
                      <Space
                        direction="vertical"
                        size={12}
                        className="w-100 px-2"
                      >
                        <Title level={5} className="ag-primary">
                          Renewal Type
                        </Title>
                        <Text className="defaultFont fontWeight600 sentanceCase">
                          {contractApi?.renewal_type || "N/A"}
                        </Text>
                      </Space>

                      <Divider style={{ borderColor: "#d2d2d2", margin: 0 }} />
                      <Space
                        direction="vertical"
                        size={12}
                        className="w-100 px-2"
                      >
                        <Title level={5} className="ag-primary">
                          Submission Type
                        </Title>
                        <Text className="defaultFont fontWeight600 sentanceCase">
                          {contractApi?.submission_type || "N/A"}
                        </Text>
                      </Space>
                    </Space>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row gutter={24}>
            <Col span={8} className="text-right">
              <Button size="large" type="primary" danger>
                Reject
              </Button>
            </Col>
            <Col span={8} className="text-right">
              <Button size="large" type="primary">
                Update Status
              </Button>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </DashboardLayout>
  );
};
