import axios from "../../../../utils/axiosInceptor";
import { baseContractService } from "../../../../configs/constants";

export const sendTenantSignature = (payload) => {
  return axios.post(
    `${baseContractService}/send-ejari-contract-to-prospect`,
    payload,
  );
};

export const draftEjariRenewal = (payload) => {
  return axios.post(
    `${baseContractService}/renew-pending-ejari-contract`,
    payload,
  );
};
export const cancelEjariRenewal = (payload) => {
  return axios.post(`${baseContractService}/cancel-ejari-contract`, payload);
};

// export const downloadEjariRenewal = (id) => {
//     return axios.get(`${baseContractService}/download-ejari-contract?lease_id=${id}`)
// }

export const downloadEjariRenewal = (id) => {
  return axios.get(
    `${baseContractService}/get-signed-contract-url?lease_id=${id}&type=signed_ejari_contract`,
  );
};

export const downloadDraftEjari = (id) => {
  return axios.get(
    `${baseContractService}/download-renewal-contract-from-ejari?lease_id=${id}`,
  );
};
export const ejariRenewal = (payload) => {
  return axios.post(
    `${baseContractService}/activate-renew-ejari-contract`,
    payload,
  );
};

export const createEjariContractID = (payload) => {
  return axios.post(
    `${baseContractService}/add-ejari-contract-number`,
    payload,
  );
};
