import React, { useState, useEffect } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  Row,
  Col,
  Pagination,
  Card,
  Button,
  Form,
  Space,
  Select,
  Input,
} from "antd";
import SectionHeader from "../../molecules/SectionHeader";
import Grid from "../../atoms/Grid";
import { useDispatch, useSelector } from "react-redux";
import { emptyTenants, getTenants } from "./ducks/actions";
import { RightArrow } from "../../atoms/Icons";
import { useHistory } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 200,
};

const limit = 10;

const searchBy = [
  { label: "Tenant Name", value: "name" },
  { label: "Email", value: "email" },
  { label: "Phone", value: "phone" },
];

export default (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const id = location.pathname?.split("/")[2];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const tenantApi = useSelector((state: any) => state.tenants.tenantList);
  const [search, setSearch] = useState("");
  const [searchByT, setSearchBy] = useState(searchBy[0].value);

  function viewRender(record) {
    console.log(record.data);
    return (
      <Button
        icon={<RightArrow />}
        onClick={() => history.push(`/tenants-detail/${record.data.party_id}`)}
        type="link"
        className="ag-primary"
      />
    );
  }

  const tenantCol = [
    {
      headerName: "ID",
      field: "party_id",
      maxWidth: 120,
      filter: false,
    },
    {
      headerName: "Tenant Name",
      field: "name",
    },
    {
      headerName: "Mobile Number",
      field: "mobile",
    },
    {
      headerName: "Email",
      field: "email",
    },
    {
      headerName: "View Details",
      cellRendererFramework: viewRender,
      cellStyle: { textAlign: "center" },
      filter: false,
      maxWidth: 140,
    },
  ];
  //@ts-ignore
  useEffect(() => {
    dispatch(getTenants(id, limit, currentPage, "", ""));
    return () => dispatch(emptyTenants());
  }, []);

  const handlePagination = (pg) => {
    setCurrentPage(pg);
    dispatch(getTenants(id, limit, pg, "", ""));
  };

  const onSearch = (val) => {
    setCurrentPage(0);
    if (val.searchText) {
      setSearch(val.searchText);
      setSearchBy(val.searchBy);
      dispatch(getTenants(id, limit, 0, val.searchText, val.searchBy));
    } else {
      setSearch("");
      dispatch(getTenants(id, limit, 0, "", ""));
    }
  };

  const SearchFelds = () => {
    return (
      <Form onFinish={onSearch}>
        <Space wrap>
          <Form.Item name="searchText" className="mb-0" initialValue={search}>
            <Input type="text" placeholder="Search" value={search} />
          </Form.Item>
          <Form.Item name="searchBy" className="mb-0" initialValue={searchByT}>
            <Select placeholder="Select" value={searchByT}>
              {searchBy.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className="mb-0 noborder-btn">
            <Button
              htmlType="submit"
              className="ag-gray-button"
              icon={<SearchOutlined />}
            />
          </Form.Item>
        </Space>
      </Form>
    );
  };

  useEffect(() => {
    if (tenantApi.results) {
      let temp = [];
      tenantApi?.results?.map((item) => {
        temp.push({
          id: item.lease_id,
          party_id: item.party_id,
          name: `${item.first_name} ${item.last_name}`,
          mobile: `${
            item.contact_info?.telecommunication_number?.country_code
              ? "+" + item.contact_info?.telecommunication_number?.country_code
              : ""
          }${item.contact_info?.telecommunication_number?.contact_number}`,
          email: item.contact_info?.electronic_address?.email,
        });
      });
      setData(temp);
    }
  }, [tenantApi]);

  return (
    <DashboardLayout>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <SectionHeader
                heading="Tenants"
                noBorder={true}
                total={tenantApi?.count}
              />
            </Col>

            <Col span={24}>
              <Card
                bordered={false}
                className="ag-nospace-body"
                extra={<SearchFelds />}
              >
                <Grid
                  data={data}
                  columns={tenantCol}
                  pagination={false}
                  defaultSettings={gridProps}
                  noRowlabel="Tenants"
                  fullPage={true}
                />
              </Card>
            </Col>
            <Col span={24}>
              <Pagination
                className="ag-property-text-pagination"
                total={tenantApi?.count || 0}
                showTotal={(total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} Tenants`
                }
                defaultPageSize={10}
                defaultCurrent={1}
                showSizeChanger={false}
                current={currentPage + 1}
                onChange={(e) => handlePagination(e - 1)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </DashboardLayout>
  );
};
