/* eslint-disable react/display-name */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Typography, Form, Input, Button, Select } from "antd";
import LoginTemplate from "./LoginTemplate";

const { Text, Title } = Typography;
const { Option } = Select;

const Success = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const onSubmit = () => {
    history.push("/properties");
  };
  return (
    <LoginTemplate load={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Title level={5} className="ag-primary highlight text-center">
            You have successfully signed up! We would like a few more details to
            set you up:
          </Title>
        </Col>
        <Col span={24}>
          <Form
            name="normal_login"
            layout="vertical"
            className="forgot-password-form text-left"
            onFinish={onSubmit}
          >
            <Form.Item
              name="work_add"
              label="Where do you work?"
              rules={[
                {
                  required: true,
                  message: "Please provide work place",
                },
              ]}
            >
              <Input
                type="text"
                name="work_add"
                placeholder="Al Ghurair"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="account_type"
              label="Select an account type"
              //   rules={[
              //     {
              //       required: true,
              //       message: "Please input your Username!",
              //     },
              //   ]}
            >
              <Select
                size="large"
                placeholder="Select an account type"
                defaultValue="Landlord"
                disabled
              >
                <Option value="Landlord">Landlord</Option>
                <Option value="usa">Facility Manager</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="signup-form-button"
                size="large"
              >
                Let’s go!
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </LoginTemplate>
  );
};

export default Success;
