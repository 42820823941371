import * as action_types from "../constants";

export const activateSpinner = (data) => {
  return (dispatch) => {
    dispatch({
      type: action_types.ACTIVATE_SPINNER,
      data,
    });
  };
};
