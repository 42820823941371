export const dummyRequest = (response) => {
  // console.log("check response", response);
  setTimeout(() => {
    response.onSuccess("ok");
  }, 0);
};

export const uniq = (val) => {
  const seen = new Set();
  return val.filter((el) => {
    const duplicate = seen.has(el.labelName);
    seen.add(el.labelName);
    return !duplicate;
  });
};
