import React from "react";
import { Row, Col } from "antd";
import { InputField, DateField } from "../../../../atoms/FormElement";

export default ({ control, errors }) => {

  return (
    <Row gutter={24}>
      <Col span={8}>
        <InputField
          isRequired={true}
          label="No. of Instalments Proposed"
          fieldname="installmentproposed"
          control={control}
          initValue=""
          validate={errors.installmentproposed && "error"}
          validMessage={
            errors.installmentproposed &&
            errors.installmentproposed.message
          }
          iProps={{ placeholder: "Enter number" }}
          rules={{
            pattern: {
              value: /^[0-9]*$/,
              message: "Value should be a Number",
            },
            required: "Enter No. of Instalments Proposed",
          }}
        />
      </Col>
      <Col span={8}>
        <DateField
          isRequired={true}
          label="Booking start date"
          fieldname="bookingstartdate"
          control={control}
          validate={errors.bookingstartdate && "error"}
          validMessage={
            errors.bookingstartdate &&
            errors.bookingstartdate.message
          }
          initValue=""
          rules={{
            required: "Enter Booking start date",
            setValueAs: (value) =>
              value && value.unix().toString(),
          }}
          iProps={{ format: "DD-MM-YYYY" }}
        />
      </Col>
      <Col span={8}>
        <DateField
          isRequired={true}
          label="Booking expiry date"
          fieldname="bookingexpirydate"
          control={control}
          validate={errors.bookingexpirydate && "error"}
          validMessage={
            errors.bookingexpirydate &&
            errors.bookingexpirydate.message
          }
          initValue=""
          rules={{
            required: "Enter Booking expiry date",
            setValueAs: (value) =>
              value && value.unix().toString(),
          }}
          iProps={{ format: "DD-MM-YYYY" }}
        />
      </Col>

      <Col span={8}>
        <InputField
          isRequired={true}
          label="Annual base rent"
          fieldname="annualbaserent"
          control={control}
          initValue=""
          validate={errors.annualbaserent && "error"}
          validMessage={
            errors.annualbaserent && errors.annualbaserent.message
          }
          iProps={{ placeholder: "Enter rent amount" }}
          rules={{
            pattern: {
              value: /^[0-9]*$/,
              message: "Value should be a Number",
            },
            required: "Enter Annual base rent",
          }}
        />
      </Col>
      <Col span={8}>
        <InputField
          isRequired={true}
          label="Non-Refundable booking amount"
          fieldname="bookingamount"
          control={control}
          initValue=""
          validate={errors.bookingamount && "error"}
          validMessage={
            errors.bookingamount && errors.bookingamount.message
          }
          iProps={{ placeholder: "Enter rent amount" }}
          rules={{
            pattern: {
              value: /^[0-9]*$/,
              message: "Value should be a Number",
            },
            required: "Enter Non-Refundable booking amount",
          }}
        />
      </Col>
    </Row>
  )
}