import React, { useEffect } from "react";
import { Row, Col } from "antd";
import { ReactSelectField, DateField } from "../../../atoms/FormElement";
import { useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import moment from "moment";

var _ = require("lodash");

export default ({
  control,
  errors,
  getValues,
  setValue,
  bookingFormDetails,
  parkingSlotsDataFromApi,
  isClearTriggered,
}) => {
  const leaseNatureData = useSelector(
    (state: any) => state.contractBookingForm.leaseNature,
  );

  let parkingSlotsData = [];

  if (parkingSlotsDataFromApi && parkingSlotsDataFromApi?.length > 0) {
    let temp = [];
    let x = [];
    temp = Array.from(
      Array(parkingSlotsDataFromApi.length),
      (_, index) => index + 1,
    );
    temp.unshift(0);
    temp.map((e) => x.push({ label: e, value: e }));
    parkingSlotsData = x;
  }

  const commenceDate = useWatch({
    control,
    name: "commencementdate",
  });

  const setLeaseEndDate = (start) => {
    setValue(
      "leaseexpirydate",
      moment(start).add(1, "years").subtract(1, "day"),
    );
    setRentfree();
  };

  const setRentfree = () => {
    let x: any = "";
    let enddate = getValues("leaseexpirydate");
    let startdate = getValues("commencementdate");
    x = moment(enddate).diff(moment(startdate), "months", true);
    if (x > 12) {
      setValue("rentfreestart", moment(startdate).add(12, "month"));
      setValue("rentfreeend", moment(enddate));
    } else {
      setValue("rentfreestart", null);
      setValue("rentfreeend", null);
    }
  };

  useEffect(() => {
    if (isClearTriggered) {
      setValue("leasenature", "");
      setValue("parkingslots", "");
      setValue("freeparkingslots", "");
      setValue("commencementdate", "");
      setValue("leaseexpirydate", "");
      setValue("rentfreestart", "");
      setValue("rentfreeend", "");
    }
  }, [isClearTriggered]);

  useEffect(() => {
    if (bookingFormDetails) {
      if (bookingFormDetails?.leaseDuration?.commencementDate) {
        setValue(
          "commencementdate",
          moment(
            bookingFormDetails?.leaseDuration?.commencementDate,
            "YYYY-MM-DD",
          ),
        );
      }
      if (bookingFormDetails?.leaseDuration?.expiryDate) {
        setValue(
          "leaseexpirydate",
          moment(bookingFormDetails?.leaseDuration?.expiryDate, "YYYY-MM-DD"),
        );
      }
      if (bookingFormDetails?.rentFree?.startDate) {
        setValue(
          "rentfreestart",
          moment(bookingFormDetails?.rentFree?.startDate, "YYYY-MM-DD"),
        );
      }
      if (bookingFormDetails?.rentFree?.endDate) {
        setValue(
          "rentfreeend",
          moment(bookingFormDetails?.rentFree?.endDate, "YYYY-MM-DD"),
        );
      }
      setValue("freeparkingslots", {
        label: bookingFormDetails?.freeParkingSlots,
        value: bookingFormDetails?.freeParkingSlots,
      });
      setValue("parkingslots", {
        label: bookingFormDetails?.paidParkingSlots,
        value: bookingFormDetails?.paidParkingSlots,
      });
    }
  }, [bookingFormDetails]);

  const disableCommenceDate = (current) => {
    return (
      current &&
      current.valueOf() <
        (bookingFormDetails?.leaseDuration?.commencementDate
          ? moment(
              bookingFormDetails?.leaseDuration?.commencementDate,
              "YYYY-MM_DD",
            )
          : moment().subtract(1, "day"))
    );
  };

  const disableExpiryDate = (current) => {
    let dateFormat = "MM-DD-YYYY";
    let _first_month = moment(commenceDate)
      .add(1, "year")
      .add(1, "month")
      .subtract(1, "day");
    let _second_month = moment(commenceDate)
      .add(1, "year")
      .add(2, "month")
      .subtract(1, "day");
    let currentDateFormat = current.format(dateFormat);

    return (
      current &&
      moment(commenceDate)
        .add(1, "year")
        .subtract(1, "day")
        .format(dateFormat) !== currentDateFormat &&
      _second_month.format(dateFormat) !== currentDateFormat &&
      _first_month.format(dateFormat) !== currentDateFormat
    );

    // return (
    //   (current &&
    //     current.valueOf() <
    //       moment(commenceDate).add(1, "year").subtract(1, "day")) ||
    //   moment(commenceDate).add(1, "year").add(2, "month").subtract(1, "day") <
    //     current.valueOf()
    // );
  };

  return (
    <Row gutter={24}>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={8}>
            <ReactSelectField
              isRequired={true}
              label="Lease nature"
              fieldname="leasenature"
              valueGot={
                bookingFormDetails?.leaseNature && {
                  label: bookingFormDetails?.leaseNature,
                  value: bookingFormDetails?.leaseNature,
                }
              }
              control={control}
              setValue={setValue}
              validate={errors.leasenature && "error"}
              validMessage={errors.leasenature && errors.leasenature.message}
              rules={{ required: "Select lease nature" }}
              iProps={{ placeholder: "Select one" }}
              initValue=""
              selectOption={_.map(leaseNatureData, (e) => {
                return { label: e.name, value: e.name };
              })}
            />
          </Col>

          <Col span={8}>
            <ReactSelectField
              label="No. of paid parking slots"
              fieldname="parkingslots"
              control={control}
              isRequired={true}
              validate={errors.parkingslots && "error"}
              validMessage={errors.parkingslots && errors.parkingslots.message}
              rules={{ required: "Select parking slots" }}
              initValue=""
              iProps={{ placeholder: "Select paid parking slots" }}
              selectOption={parkingSlotsData}
            />
          </Col>
          <Col span={8}>
            <ReactSelectField
              label="No. of free parking slots"
              fieldname="freeparkingslots"
              control={control}
              isRequired={true}
              validate={errors.freeparkingslots && "error"}
              validMessage={
                errors.freeparkingslots && errors.freeparkingslots.message
              }
              rules={{ required: "Select free parking slots" }}
              initValue=""
              iProps={{ placeholder: "Select free parking slots" }}
              selectOption={parkingSlotsData}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={8}>
            <DateField
              isRequired={true}
              label="Lease commencement date"
              fieldname="commencementdate"
              control={control}
              validate={errors.commencementdate && "error"}
              onChange={(e) => setLeaseEndDate(e)}
              validMessage={
                errors.commencementdate && errors.commencementdate.message
              }
              initValue=""
              rules={{
                required: "Enter Lease commencement date",
                setValueAs: (value) => value && value.toString(),
              }}
              iProps={{
                placeholder: "DD-MM-YYYY",
                format: "DD-MM-YYYY",
                disabledDate: disableCommenceDate,
              }}
            />
          </Col>
          <Col span={8}>
            <DateField
              isRequired={true}
              label="Lease expiry date"
              fieldname="leaseexpirydate"
              control={control}
              onChange={(e) => setRentfree()}
              validate={errors.leaseexpirydate && "error"}
              validMessage={
                errors.leaseexpirydate && errors.leaseexpirydate.message
              }
              initValue=""
              rules={{
                required: "Enter Lease expiry date",
                setValueAs: (value) => value && value.toString(),
              }}
              iProps={{
                placeholder: "DD-MM-YYYY",
                format: "DD-MM-YYYY",
                disabledDate: disableExpiryDate,
              }}
            />
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row gutter={24}>
          <Col span={8}>
            <DateField
              label="Rent free start date"
              fieldname="rentfreestart"
              control={control}
              initValue=""
              iProps={{
                placeholder: "DD-MM-YYYY",
                format: "DD-MM-YYYY",
                disabled: true,
              }}
            />
          </Col>
          <Col span={8}>
            <DateField
              label="Rent free end date"
              fieldname="rentfreeend"
              control={control}
              initValue=""
              iProps={{
                placeholder: "DD-MM-YYYY",
                format: "DD-MM-YYYY",
                disabled: true,
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
