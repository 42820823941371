import React from "react";
import { Card, Button, Typography, Col, Row } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const Task = (props) => {
  const history = useHistory();
  const { Title } = Typography;
  const { title, route, status, disable } = props;

  return (
    <Card className={disable ? "disableTask" : ""}>
      <Row gutter={24} justify="space-between">
        <Col span={12}>
          <Title level={5} className="mb-0">
            {title}
          </Title>
        </Col>
        <Col>
          <Button
            type="link"
            disabled={disable}
            className="p-0 h-auto sentanceCase ag-success"
            onClick={() => history.push(route)}
          >
            {status && status}
            <RightOutlined />
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default Task;
