/* eslint-disable react/prop-types */
import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { checkCookie, deleteCookie } from "../utils/helper";
// import firebase from "../utils/firebase";
import Login from "../app/pages/Login";
// import Dashboard from "../app/pages/Dashboard";
import SignUp from "../app/modules/SignUp";
import ForgotPassword from "../app/modules/Login/ForgotPassword";
import ChangePassword from "../app/modules/Login/ChangePassword";
import Success from "../app/modules/Login/Success";
import Properties from "../app/modules/Properties";
import PropertyList from "../app/modules/Properties/PropertyList";
import CreateProperty from "../app/modules/Properties/AddProperty/CreateProperty";
import OfferByTenant from "../app/modules/Properties/OfferByTenant";
import BookingForm from "../app/modules/Properties/BookingForm";
import Leads from "../app/modules/LeadManagement/Leads/Leads";
import LeadsDetail from "../app/modules/LeadManagement/LeadsDetail/leadsDetail";
import PropertyDetail from "../app/modules/Properties/PropertyDetail";
import Reviews from "../app/modules/Reviews";
import PropertyReview from "../app/modules/Reviews/PropertyReview";
import Tenants from "../app/modules/Tenants";
import AddTenant from "../app/modules/Tenants/AddTenant";
import RouteWithSubRoutes from "./interpolation";
import Maintenance from "../app/modules/Maintenance";
import VisitScheduling from "../app/modules/Properties/VisitScheduling";
import PropertiesOnMap from "../app/modules/Properties/PropertiesOnMap";
import OpportuntiyList from "../app/modules/LeadManagement/OpportunityList";
import OpportunityDetail from "../app/modules/LeadManagement/OpportunityDetail";
import TenantDetail from "../app/modules/TenantDetail";
import ContractBookingForm from "../app/modules/ContractFlow/ContractBookingForm";
import LeaseForm from "../app/modules/LeasingForm";
import LeaseFormNew from "../app/modules/LeasingFormNew";
import TenantDetails from "../app/modules/Tenants/TenantDetails";
import UnitDetail from "../app/modules/Properties/PropertyDetail/UnitDetail";
import EjariList from "../app/modules/Ejari";
import LeaseList from "../app/modules/LeaseListing";
import BookingformList from "../app/modules/BookingformList";
import ContractsList from "../app/modules/Contracts";
import Notification from "../app/modules/Notification";
import SignUpNew from "../app/modules/SignUpNew";
import OTP from "../app/modules/SignUpNew/OTP";
import CreatePassword from "../app/modules/SignUpNew/CreatePassword";
import PostDatedCheques from "../app/modules/Contracts/PostDatedCheques";
import ContractDetail from "../app/modules/Contracts/ContractDetail";
import AllProspects from "../app/modules/Tenants/AllProspects";
import ContractTenantDetails from "../app/modules/ContractTenantDetails";
import NotificationCalendar from "../app/modules/Notification/NotificationCalendar";

function isAuthenticated() {
  const login = JSON.parse(localStorage.getItem("login-response"));
  try {
    const { exp } = login;
    if (Date.now() >= exp * 1000) {
      return false;
    }
  } catch (err) {
    return false;
  }
  return true;
}

function clearAll() {
  deleteCookie("landlordUser");
  localStorage.clear();
  sessionStorage.clear();
}

const Pages = (props) => {
  // React.useEffect(() => {
  //   const messaging = firebase.messaging();
  //   messaging
  //     .requestPermission()
  //     .then(() => {
  //       return messaging.getToken();
  //     })
  //     .then((token) => {
  //       console.log("TOKEM FIRE", token);
  //     })
  //     .catch((e) => console.log("ERR", e));
  // }, []);

  const {
    match: { path },
  } = props;
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={SignUp} />
      <Route exact path="/signup" component={SignUpNew} />
      <Route exact path="/otp" component={OTP} />
      <Route exact path="/create-password" component={CreatePassword} />
      <Route exact path="/success" component={Success} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/change-password" component={ChangePassword} />

      <RouteWithSubRoutes
        path="/offer-by-tenant/:id"
        baseComponent={OfferByTenant}
        subRoutes={["/profile/:id"]}
      />
      <RouteWithSubRoutes
        path="/booking-form/:id"
        baseComponent={BookingForm}
        subRoutes={["/booking-form/:id"]}
      />
      <Route exact path="/dashboard" component={Properties} />
      <Route exact path="/properties" component={PropertyList} />
      <Route exact path="/reviews" component={Reviews} />
      <Route exact path="/tenants/:id" component={Tenants} />
      <Route exact path="/add-tenant" component={AddTenant} />
      <Route exact path="/property-review" component={PropertyReview} />
      <Route exact path="/create-a-property" component={CreateProperty} />
      <Route exact path="/leads" component={Leads} />
      <Route exact path="/lead-detail/:id" component={LeadsDetail} />
      <Route exact path="/property-detail/:id" component={PropertyDetail} />
      <Route
        exact
        path="/property-detail/:id/unit/:id"
        component={UnitDetail}
      />
      <Route exact path="/maintenance/:id" component={Maintenance} />
      <Route exact path="/visit-scheduling/:id" component={VisitScheduling} />
      <Route exact path="/visit-scheduling" component={VisitScheduling} />
      <Route exact path="/properties-map" component={PropertiesOnMap} />
      <Route exact path="/opportunities" component={OpportuntiyList} />
      <Route
        exact
        path="/opportunity-detail/:id/:assetId/:partyId"
        component={OpportunityDetail}
      />
      <Route
        exact
        path="/detail-tenant/:id/:partyId"
        component={ContractTenantDetails}
      />
      <Route exact path="/tenants" component={Tenants} />
      <Route exact path="/tenants-detail/:id" component={TenantDetails} />
      <Route exact path="/bookingforms" component={BookingformList} />
      <Route exact path="/lease/:leadId" component={LeaseFormNew} />
      <Route
        exact
        path="/leads/:leadId/booking-form/:assetId/:partyId"
        component={ContractBookingForm}
      />
      <Route exact path="/ejari" component={EjariList} />
      <Route exact path="/lease" component={LeaseList} />
      <Route exact path="/contracts" component={ContractsList} />
      <Route exact path="/contract-detail/:id" component={ContractDetail} />
      <Route exact path="/post-dated-cheques" component={PostDatedCheques} />
      <Route exact path="/prospects" component={AllProspects} />
      <Route exact path="/prospect-detail/:id" component={TenantDetails} />
      <Route exact path="/notifications" component={Notification} />
      <Route
        exact
        path="/notifications/calendar"
        component={NotificationCalendar}
      />

      <Redirect to="/login" />
    </Switch>
  );
};

export default Pages;
