import React, { useEffect, useState } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  Row,
  Col,
  Pagination,
  Card,
  Button,
  Space,
  Input,
  Form,
  Select,
  message,
} from "antd";
import SectionHeader from "../../molecules/SectionHeader";
import Grid from "../../atoms/Grid";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { getLeaseForms } from "./ducks/actions";
import { useHistory } from "react-router-dom";
import axios from "../../../utils/axiosInceptor";
import { baseContractService } from "../../../configs/constants";

const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 220,
};

const searchBy = [{ label: "Tenant Name", value: "tenant_name" }];

const limit = 10;

const LeaseListing = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchByT, setSearchBy] = useState("");
  const [search, setSearch] = useState("");
  const listLease = useSelector((state: any) => state.lease.leaseList);

  useEffect(() => {
    dispatch(getLeaseForms(limit, currentPage, "", ""));
  }, []);

  const handlePagination = (pg) => {
    setCurrentPage(pg);
    dispatch(getLeaseForms(limit, pg, search, searchByT));
  };

  const SearchFelds = () => {
    return (
      <Form onFinish={onSearch}>
        <Space wrap>
          <Form.Item name="searchText" className="mb-0" initialValue={search}>
            <Input type="text" placeholder="Search" value={search} />
          </Form.Item>
          <Form.Item
            name="searchBy"
            className="mb-0"
            initialValue={"tenant_name"}
          >
            <Select placeholder="Select">
              {searchBy.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className="mb-0 noborder-btn">
            <Button
              htmlType="submit"
              className="ag-gray-button"
              icon={<SearchOutlined />}
            />
          </Form.Item>
        </Space>
      </Form>
    );
  };

  const onSearch = (val) => {
    setCurrentPage(0);
    if (val.searchText) {
      setSearch(val.searchText);
      setSearchBy(val.searchBy);
      dispatch(getLeaseForms(limit, 0, val.searchText, val.searchBy));
    } else {
      setSearch("");
      dispatch(getLeaseForms(limit, 0, "", ""));
    }
  };

  const onDownload = async (id) => {
    let url = `${baseContractService}/download-lease-form/${id}`;
    await axios
      .get(url)
      .then((response) => {
        if (
          response?.data?.result?.imageUrl !=
          "https://s3.ap-south-1.amazonaws.com/"
        ) {
          window.open(response?.data?.result?.imageUrl);
        } else {
          onDownload(id);
        }
      })
      .catch((e) => {
        const { response } = e;
        message.error(response.data.message);
      });
  };

  const DownloadLease = (param) => {
    return (
      <Button
        className="w-100"
        type="primary"
        size="large"
        onClick={() => onDownload(param.data.id)}
        disabled={!param.data.downloadStatus}
      >
        Download
      </Button>
    );
  };

  const ManageLease = (param) => {
    return (
      <Button
        className="w-100"
        type="primary"
        size="large"
        onClick={() => history.push(`lease/${param.data.id}`)}
      >
        {param.data.status == "Approved" ? "View Lease" : "Manage Lease"}
      </Button>
    );
  };

  const leadCol = [
    {
      headerName: "ID",
      field: "id",
      maxWidth: 100,
    },
    {
      headerName: "Tenant Name",
      field: "tenant_name",
    },
    {
      headerName: "Unit Code",
      field: "unit_code",
      maxWidth: 140,
    },
    {
      headerName: "Contract Type",
      field: "contract_type",
    },
    {
      headerName: "Annual Base Rent",
      field: "base_rent",
    },
    {
      headerName: "Status",
      field: "status",
    },
    {
      headerName: "Download Lease",
      field: "download_lease",
      cellRendererFramework: DownloadLease,
    },
    {
      headerName: "Manage/Status",
      field: "status",
      cellRendererFramework: ManageLease,
    },
  ];

  return (
    <DashboardLayout>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <SectionHeader heading="Lease" total={listLease?.count || 0} />
            </Col>
            <Col span={24}>
              <Pagination
                className="ag-property-text-pagination"
                total={listLease?.count || 0}
                showTotal={(total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} lease`
                }
                defaultPageSize={10}
                defaultCurrent={1}
                showSizeChanger={false}
                current={currentPage + 1}
                onChange={(e) => handlePagination(e - 1)}
              />
            </Col>
            <Col span={24}>
              <Row gutter={[24, 24]} justify="end">
                <Col span={24}>
                  <Card
                    bordered={false}
                    className="ag-nospace-body"
                    extra={<SearchFelds />}
                  >
                    <Grid
                      refCol="status"
                      data={listLease.rows}
                      columns={leadCol}
                      pagination={false}
                      defaultSettings={gridProps}
                      noRowlabel="Lease"
                      fullPage={true}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default LeaseListing;
