import React, { useState, useEffect } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  Row,
  Col,
  Pagination,
  Card,
  Button,
  Input,
  Select,
  Form,
  Space,
  Dropdown,
  Menu,
  message,
  Typography,
} from "antd";
import SectionHeader from "../../molecules/SectionHeader";
import Grid from "../../atoms/Grid";
import { useDispatch, useSelector } from "react-redux";
import { RightArrow } from "../../atoms/Icons";
import { getContactList } from "./ducks/actions";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Popup } from "../../atoms/Popup";
import {
  sendTenantSignature,
  draftEjariRenewal,
  cancelEjariRenewal,
  downloadEjariRenewal,
  ejariRenewal,
  downloadDraftEjari,
} from "./ducks/services";
import AddContractID from "./AddContractID";

const { Title } = Typography;
const { Option } = Select;
const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 220,
};

const dropDown = [
  {
    id: "DraftDownload",
    name: "Download Draft Ejari Contract", // Old Label: View & Download signed Ejari contract
    status: "#447093",
  },
  { id: "Send", name: "Send for Tenant Signature", status: "#1C5D3B" },
];
const dropDown1 = [
  { id: "Create", name: "Add Ejari Contract Number", status: "#1C5D3B" },
];
const dropDown2 = [
  { id: "Create", name: "Add Ejari Contract Number", status: "#1C5D3B" },
  { id: "Draft", name: "Draft Ejari Renewal Contract", status: "#1C5D3B" },
];

const dropDown4 = [
  {
    id: "Download",
    name: "View & Download signed Ejari contract",
    status: "#447093",
  },
  // { id: "Cancel", name: "Cancel", status: "#FB7171" },
];

const dropDown3 = [
  {
    id: "Download",
    name: "View & Download signed Ejari contract",
    status: "#447093",
  },
  { id: "Renew", name: "Activate Ejari contract", status: "#1C5D3B" },
  // { id: "Cancel", name: "Cancel", status: "#FB7171" },
];

const Contracts = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [visible, setVisible] = useState(false);
  const [searchByT, setSearchBy] = useState("unitCode");
  const [search, setSearch] = useState("");
  const [leaseID, setLeaseID] = useState(null);
  const contractApi = useSelector((state: any) => state.contracts.contractList);

  useEffect(() => {
    dispatch(getContactList(limit, currentPage, "", ""));
  }, []);

  const viewDetails = (row) => {
    return (
      <Button
        icon={<RightArrow />}
        onClick={() =>
          history.push(`/contract-detail/${row.data.renewal_feedback_id}`)
        }
        type="link"
      />
    );
  };

  const onAction = async (action, id) => {
    setLoading(true);
    if (action === "Download") {
      try {
        let res = await downloadEjariRenewal(id);
        if (
          res.data.result.imageUrl != "https://s3.ap-south-1.amazonaws.com/"
        ) {
          setLoading(false);
          const win = window.open(res.data.result.imageUrl, "_blank");
          win.focus();
        } else {
          setLoading(false);
          onAction("Download", id);
        }
      } catch (e) {
        setLoading(false);
        const { response } = e;
        message.error(response.data.message);
      }
    } else if (action == "Cancel") {
      const body = {
        lease_id: id,
      };
      cancelEjariRenewal(body)
        .then(() => {
          setLoading(false);
          message.success(`${action} successfully`);
        })
        .then(() => {
          setLoading(false);
          dispatch(getContactList(limit, currentPage, search, searchByT));
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.message);
        });
    } else if (action === "Send") {
      const body = {
        lease_id: id,
        type: "ejari_contract_renewed",
      };
      sendTenantSignature(body)
        .then(() => {
          message.success(`${action} successfully`);
          setLoading(false);
        })
        .then(() =>
          dispatch(getContactList(limit, currentPage, search, searchByT)),
        )
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.message);
        });
    } else if (action === "Draft") {
      const body = {
        lease_id: id,
      };
      draftEjariRenewal(body)
        .then(() => {
          setLoading(false);
          message.success(`${action} successfully`);
        })
        .then(() =>
          dispatch(getContactList(limit, currentPage, search, searchByT)),
        )
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.message);
        });
    } else if (action === "Renew") {
      const body = {
        lease_id: id,
      };
      ejariRenewal(body)
        .then(() => {
          setLoading(false);
          message.success(`${action} successfully`);
        })
        .then(() =>
          dispatch(getContactList(limit, currentPage, search, searchByT)),
        )
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.message);
        });
    } else if (action === "DraftDownload") {
      try {
        let res = await downloadDraftEjari(id);
        if (
          res.data.result.imageUrl != "https://s3.ap-south-1.amazonaws.com/"
        ) {
          dispatch(getContactList(limit, currentPage, search, searchByT)),
            setLoading(false);
          const win = window.open(res.data.result.imageUrl, "_blank");
          win.focus();
        } else {
          setLoading(false);
          onAction("DraftDownload", id);
        }
      } catch (e) {
        setLoading(false);
        const { response } = e;
        message.error(response.data.message);
      }
    } else if (action === "Create") {
      setLoading(false);
      setLeaseID(id);
      setVisible(true);
    }
  };

  const valueExist = (row) => {
    let col = row?.column?.colId;
    if (
      col == "lease_status" ||
      col == "renewal_status" ||
      col == "unit_status"
    ) {
      return (
        <span style={{ textTransform: "uppercase" }}>
          {row?.data[col]
            ? row.data[col].includes("_")
              ? row.data[col].replace(/[_]/g, " ")
              : row.data[col]
            : "N/A"}
        </span>
      );
    } else {
      return (
        <span className="sentanceCase">
          {row?.data[col] ? row.data[col] : "N/A"}
        </span>
      );
    }
  };

  const menu = (id) => (
    <Menu onClick={(e) => onAction(e.key, id)}>
      {dropDown.map((item, index) => (
        <Menu.Item key={item.id} style={{ color: `${item.status}` }}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  const menu2 = (id, stat) => (
    <Menu onClick={(e) => onAction(e.key, id)}>
      {dropDown2.map((item, index) => (
        <Menu.Item key={item.id} style={{ color: `${item.status}` }}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  const menu3 = (id, stat) => (
    <Menu onClick={(e) => onAction(e.key, id)}>
      {dropDown3.map((item, index) => (
        <Menu.Item
          key={item.id}
          // disabled={checkDisable(item, stat)}
          style={{ color: `${item.status}` }}
        >
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const menu4 = (id, stat) => (
    <Menu onClick={(e) => onAction(e.key, id)}>
      {dropDown4.map((item, index) => (
        <Menu.Item
          key={item.id}
          // disabled={checkDisable(item, stat)}
          style={{ color: `${item.status}` }}
        >
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  const menu5 = (id) => (
    <Menu onClick={(e) => onAction(e.key, id)}>
      <Menu.Item key={"DraftDownload"}>Download Draft Ejari Contract</Menu.Item>
    </Menu>
  );
  const MoreAction = (param) => {
    console.log("checking", param.data.lease_id);
    return (
      <>
        {param?.data?.submission_type == "RENEWAL" ? (
          <Dropdown
            overlay={
              param.data?.renewal_status === "submitted"
                ? menu2(param.data.lease_id, param.data.renewal_status)
                : param.data?.renewal_status === "draft"
                ? param.data?.lease_status === "ejari_contract_downloaded"
                  ? menu(param.data?.lease_id)
                  : menu5(param.data?.lease_id)
                : param.data?.renewal_status === "active"
                ? menu4(param.data.lease_id, param.data.renewal_status)
                : menu3(param.data.lease_id, param.data.renewal_status)
            }
            trigger={["click"]}
          >
            <Button>
              Select an Action
              <DownOutlined />
            </Button>
          </Dropdown>
        ) : (
          <Dropdown
            overlay={menu3(param.data.lease_id, param.data.renewal_status)}
            disabled
          >
            <Button disabled>
              Select an Action
              <DownOutlined />
            </Button>
          </Dropdown>
        )}
      </>
    );
  };
  const contractsCol = [
    {
      headerName: "Tenant Name",
      field: "tenant_name",
      cellRendererFramework: valueExist,
      cellStyle: { textTransform: "CAPITALIZE" },
    },
    {
      headerName: "Renewal Status",
      field: "renewal_status",
      cellRendererFramework: valueExist,
      cellStyle: { textTransform: "CAPITALIZE" },
    },
    {
      headerName: "Renewal Type",
      field: "renewal_type",
      cellRendererFramework: valueExist,
      cellStyle: { textTransform: "CAPITALIZE" },
    },
    // {
    //   headerName: "Renewal Date",
    //   field: "renewal_date",
    //   cellRendererFramework: valueExist,
    // },
    {
      headerName: "Unit Code",
      field: "unit_code",
      cellRendererFramework: valueExist,
    },
    {
      headerName: "Building Name",
      field: "building_name",
      cellRendererFramework: valueExist,
      cellStyle: { textTransform: "CAPITALIZE" },
    },
    {
      headerName: "Lease Status",
      field: "lease_status",
      cellRendererFramework: valueExist,
      cellStyle: { textTransform: "CAPITALIZE" },
    },

    {
      headerName: "Unit Status",
      field: "unit_status",
      cellRendererFramework: valueExist,
      cellStyle: { textTransform: "CAPITALIZE" },
    },
    {
      headerName: "Submission Type",
      field: "submission_type",
      cellRendererFramework: valueExist,
      cellStyle: { textTransform: "CAPITALIZE" },
    },
    {
      headerName: "Lease Admin",
      field: "lease_admin",
      cellRendererFramework: valueExist,
      cellStyle: { textTransform: "CAPITALIZE" },
    },
    // {
    //   headerName: "Property Executive",
    //   field: "property_executive",
    //   cellRendererFramework: (row) => MoreAction(row)
    // },
    {
      headerName: "AWB Number",
      field: "package_number",
      cellRendererFramework: valueExist,
    },
    {
      headerName: "Customer Care Email",
      field: "customer_care_email",
      cellRendererFramework: valueExist,
    },
    {
      headerName: "More Action",
      field: "m_action",
      filter: false,
      maxWidth: 250,
      cellRendererFramework: MoreAction,
    },
    {
      headerName: "View Details",
      filter: false,
      maxWidth: 140,
      cellStyle: { textAlign: "center" },
      cellRendererFramework: viewDetails,
    },
  ];

  const handlePagination = (pg) => {
    setCurrentPage(pg);
    dispatch(getContactList(limit, pg, search, searchByT));
  };

  const handleLimit = (current, pageSize) => {
    setLimit(pageSize);
    setCurrentPage(0);
    dispatch(getContactList(pageSize, 0, search, searchByT));
  };

  const onSearch = (val) => {
    setCurrentPage(0);
    if (val.searchText) {
      setSearch(val.searchText);
      setSearchBy(val.searchBy);
      dispatch(getContactList(limit, 0, val.searchText, val.searchBy));
    } else {
      setSearch("");
      dispatch(getContactList(limit, 0, "", ""));
    }
  };

  const SearchFelds = () => {
    return (
      <Form onFinish={onSearch}>
        <Space>
          <Form.Item name="searchText" className="mb-0" initialValue={search}>
            <Input placeholder="Building, Unit Code" value={search} />
          </Form.Item>
          <Form.Item name="searchBy" className="mb-0" initialValue={searchByT}>
            <Select placeholder="Search By" value={searchByT}>
              <Option value="building">Building</Option>
              <Option value="unitCode">Unit Code</Option>
            </Select>
          </Form.Item>
          <Form.Item className="mb-0">
            <Button
              htmlType="submit"
              icon={<SearchOutlined />}
              className="ag-gray-button"
              type="link"
            />
          </Form.Item>
        </Space>
      </Form>
    );
  };

  const onSubmit = () => {
    setLeaseID(null);
    setVisible(false);
    dispatch(getContactList(limit, currentPage, search, searchByT));
  };

  const popupProps = {
    closable: true,
    visibility: visible,
    title: (
      <Title level={3} className="ag-primary mb-0">
        Add Ejari Contract Number
      </Title>
    ),
    content: (
      <AddContractID
        onCancel={() => setVisible(false)}
        onSubmit={onSubmit}
        lease={leaseID}
      />
    ),
    width: 650,
    onCancel: () => setVisible(false),
  };
  return (
    <DashboardLayout load={loading}>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <SectionHeader heading="Contracts Listing" noBorder={true} />
            </Col>
            <Col span={24}>
              <Pagination
                className="ag-property-text-pagination"
                total={contractApi.length ? contractApi[0]?.total_count : 0}
                showTotal={(total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} Contracts`
                }
                pageSize={limit}
                onShowSizeChange={handleLimit}
                defaultCurrent={1}
                current={currentPage + 1}
                onChange={(e) => handlePagination(e - 1)}
              />
            </Col>
            <Col span={24}>
              <Row gutter={[24, 24]} justify="end">
                <Col span={24}>
                  <Card
                    bordered={false}
                    className="ag-nospace-body"
                    extra={<SearchFelds />}
                  >
                    <Grid
                      data={contractApi}
                      columns={contractsCol}
                      pagination={false}
                      defaultSettings={gridProps}
                      noRowlabel="Contract"
                      fullPage={true}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Popup {...popupProps} />
    </DashboardLayout>
  );
};

export default Contracts;
