import { Card, Col, Row, Typography, Button, Table } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Grid from "../../../../../atoms/Grid";

const { Title, Text } = Typography;

const dataSource = [
  {
    key: "1",
    startdate: "02/08/20",
    enddate: "02/08/21",
    agreement: "2618",
    type: "Owner",
    propertiesunits: "1 Unit",
    status: (
      <Button type="link" className="p-0">
        View Offer
      </Button>
    ),
  },
];

const columns = [
  {
    headerName: "Property Name",
    field: "building_name",
  },
  {
    headerName: "Unit Number",
    field: "unit_number",
  },
  {
    headerName: "Unit Code",
    field: "unit_code",
  },
  {
    headerName: "Type",
    field: "type",
  },
  {
    headerName: "Unit Type",
    field: "unit_type",
  },
  {
    headerName: "Lease Start Date",
    field: "lease_start_date",
  },
  {
    headerName: "Lease End Date",
    field: "lease_end_date",
  },
  {
    headerName: "Lease Status",
    field: "status",
    maxWidth: 150,
    cellRendererFramework: viewRender,
  },
];

function viewRender(param) {
  let _text = "";
  if (param?.value == "lease_activated") {
    _text = "Lease Activated";
  }
  return <Text className="ag-success f-16">{_text}</Text>;
}

export default (props) => {
  const [leaseData, setLeaseData] = useState([]);
  const lease = useSelector((state: any) => state.tenantsSummary.leaseData);
  useEffect(() => {
    setLeaseData(lease);
  }, [lease]);

  return (
    <Card bordered={false} bodyStyle={{ padding: 0 }}>
      <Row gutter={24}>
        <Col span={24}>
          <Grid
            data={leaseData}
            columns={columns}
            pagination={false}
            noRowlabel="Lease"
          />
        </Col>
      </Row>
    </Card>
  );
};
