import React, { useEffect } from "react";
import { Form, Row, Col, Button } from "antd";
import { InputField, ReactSelectField } from "../../../atoms/FormElement";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getBookingRejectionReason } from "./ducks/actions";

var _ = require("lodash");
const RejectForm = (props) => {
  const { closePopup, setRejectionReason, setRejectionComment } = props;
  const dispatch = useDispatch();
  const { control, errors, setValue, handleSubmit } = useForm();

  const reasonsData = useSelector(
    (state: any) => state.contractBookingForm.getBookingRejectReason,
  );

  useEffect(() => {
    dispatch(getBookingRejectionReason());
  }, []);

  const handleReject = (data) => {
    setRejectionReason(data?.reason?.value);
    setRejectionComment(data?.rejectReason);
    closePopup();
  };
  return (
    <Form layout="vertical" onFinish={handleSubmit(handleReject)}>
      <Row gutter={24}>
        <Col span={12}>
          <ReactSelectField
            label="Select a reason"
            fieldname="reason"
            iProps={{ placeholder: "Select reasons" }}
            initValue=""
            setValue={setValue}
            isRequired={true}
            control={control}
            rules={{ required: "Required" }}
            validate={errors.reason && "error"}
            validMessage={errors.reason && errors.reason.message}
            selectOptions={[{ label: "yes", value: "yes" }]}
            selectOption={_.map(reasonsData, (e) => ({
              label: e.name,
              value: e.id,
            }))}
          />
        </Col>
        <Col span={24}>
          <InputField
            label="Enter a comment for rejection:"
            fieldname="rejectReason"
            iProps={{
              placeholder: "Some reason....",
              style: { height: "70px" },
            }}
            initValue=""
            setValue={setValue}
            control={control}
            rules={{ required: "Reason Required" }}
            validate={errors.rejectReason && "error"}
            validMessage={errors.rejectReason && errors.rejectReason.message}
          />
        </Col>
      </Row>
      <Row gutter={24} justify="space-between">
        <Col>
          <Button size="large" onClick={closePopup}>
            Back
          </Button>
        </Col>
        <Col>
          <Button size="large" className="ag-red-button" htmlType="submit">
            Reject
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default RejectForm;
