import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Typography } from "antd";
import { useFieldArray, useWatch } from "react-hook-form";
import PayCharge from "./PayCharge";
import { useSelector } from "react-redux";
import moment from "moment";

const initArry = {
  instno: "",
  type: "",
  amount: "",
  vat: "",
  total: "",
  mode: "",
  docNo: "",
  dueDate: "",
  chequeDate: null,
};

const { Text, Title } = Typography;

export default (props) => {
  const {
    control,
    setValue,
    errors,
    data,
    getValues,
    register,
    paymentSchedule,
    ejariCharges,
    statusForm,
  } = props;

  const billingsData: any = useWatch({
    control,
    name: "billings",
  });

  const payment_Modes = useSelector(
    (state: any) => state.leasingForm.getPaymentModes,
  );

  const fieldArray = getValues().billings || [];
  const charges = getValues().othercharges || [];
  const usageType = getValues("usage_type")?.value;

  const [billingScheduleTotal, setBillingScheduleTotal] = useState(0);

  const watchf = useWatch({
    control,
    name: "othercharges",
  });

  let a = [];
  let amount_parking_charges_data = [];
  let parkingTotalAmount = [];
  let parkingTotalAmountVat = [];
  let amount_parking_charges_data_childs = [];
  let a_charges_data = [];
  let paymentSchedule_totalPayment = 0;

  useEffect(() => {
    if (fieldArray) {
      charges.map((item, index) => {
        let amount = Number(item.amount) / Number(item.intallments.value);
        let vat = 0;
        let amount_parking_charges = [];
        let amount_parking_vat = [];
        let a__inner_charges = [];

        if (
          item.type.label === "BASE RENT" ||
          item.type.label == "SECURITY DEPOSIT"
        ) {
          vat = usageType == 1 ? amount * 0.05 : 0;
        } else {
          vat = amount * 0.05;
        }
        let total = amount + vat;

        for (let i = 0; i < item.intallments.value; i++) {
          let y = billingsData?.find((x) => x.billtype == item.type.label);
          let dated = "";
          if (y && y.instaArray) {
            dated = y?.instaArray[i]?.start_date;
            if (
              item.type.label === "BASE RENT" ||
              item.type.label == "SECURITY DEPOSIT" ||
              item.type.label == "RENTAL COMMISSION" ||
              item.type.label == "EJARI FEE"
            ) {
              let charge_dara = {};
              if (item.type.label === "BASE RENT" && paymentSchedule) {
                let x = paymentSchedule?.installments[i];
                let md = { name: null, id: null };
                md = payment_Modes.find((a) => a.name == x?.PaymentType);
                charge_dara = {
                  instno: i + 1,
                  type: item.type.label,
                  typeName: item.type,
                  amount: amount,
                  vat: vat,
                  total: total,
                  mode: {
                    label: md?.name,
                    value: md?.id,
                  },
                  docNo: x?.documentNumber,
                  dueDate: dated,
                  chequeDate: x?.cheque_date
                    ? moment(x?.cheque_date, "YYYY-MM-DD")
                    : null,
                  instnoTotal: item.intallments.value,
                };
              } else if (
                item.type.label == "RENTAL COMMISSION" &&
                paymentSchedule
              ) {
                let x =
                  paymentSchedule?.installments[
                    paymentSchedule?.installments.length - 1
                  ];
                let md = { name: null, id: null };
                md = payment_Modes.find((a) => a.name == x?.PaymentType);
                charge_dara = {
                  instno: i + 1,
                  type: item.type.label,
                  typeName: item.type,
                  amount: amount,
                  vat: vat,
                  total: total,
                  mode: {
                    label: md?.name,
                    value: md?.id,
                  },
                  docNo: x?.documentNumber,
                  dueDate: dated,
                  chequeDate: x?.cheque_date
                    ? moment(x?.cheque_date, "YYYY-MM-DD")
                    : null,
                  instnoTotal: item.intallments.value,
                };
              } else if (item.type.label == "EJARI FEE") {
                let m = ejariCharges?.find((x) => x.type == "ejari_fee");
                charge_dara = {
                  instno: i + 1,
                  type: item.type.label,
                  typeName: item.type,
                  amount: amount,
                  vat: vat,
                  total: total,
                  mode: {
                    label: m?.PaymentType,
                    value: m?.PaymentType,
                  },
                  docNo: m?.documentNumber,
                  dueDate: dated,
                  chequeDate: m?.cheque_date
                    ? moment(m?.cheque_date, "YYYY-MM-DD")
                    : null,
                  instnoTotal: item.intallments.value,
                };
              } else if (item.type.label == "SECURITY DEPOSIT") {
                let s = ejariCharges?.find((x) => x.type == "security_deposit");
                charge_dara = {
                  instno: i + 1,
                  type: item.type.label,
                  typeName: item.type,
                  amount: amount,
                  vat: vat,
                  total: total,
                  mode: {
                    label: s?.PaymentType,
                    value: s?.PaymentType,
                  },
                  docNo: s?.documentNumber,
                  dueDate: dated,
                  chequeDate: s?.cheque_date
                    ? moment(s?.cheque_date, "YYYY-MM-DD")
                    : null,
                  instnoTotal: item.intallments.value,
                };
              } else {
                charge_dara = {
                  instno: i + 1,
                  type: item.type.label,
                  typeName: item.type,
                  amount: amount,
                  vat: vat,
                  total: total,
                  mode: "",
                  docNo: "",
                  dueDate: dated,
                  chequeDate: null,
                  instnoTotal: item.intallments.value,
                };
              }
              a.push(charge_dara);
              if (item.type.label === "BASE RENT") {
                a__inner_charges.push({
                  instno: i + 1,
                  amount: y?.instaArray[i]?.amount,
                  vat: y?.instaArray[i]?.vat,
                  total: y?.instaArray[i]?.total,
                  type: item.type.label,
                  typeKey: item.type.value,
                  instnoTotal: item.intallments.value,
                  installmentStartDate: y?.instaArray?.[i]?.start_date,
                  installmentEndDate: y?.instaArray?.[i]?.end_date,
                });
              }
            } else if (item.type.label != "PARKING") {
              amount_parking_charges.push({
                instno: i + 1,
                type: item.type.label,
                amount: amount,
                vat: vat,
                total: total,
                mode: "",
                docNo: "",
                dueDate: dated,
                chequeDate: null,
                typeKey: item.type.value,
                instnoTotal: item.intallments.value,
                installmentStartDate: y?.instaArray?.[i]?.start_date,
                installmentEndDate: y?.instaArray?.[i]?.end_date,
                parkingSlot: item?.slot?.value,
              });
            }
          }
        }
        if (amount_parking_charges.length > 0) {
          amount_parking_charges_data.push(amount_parking_charges);
        }
        if (a.length > 0) {
          a_charges_data.push(a__inner_charges);
        }
      });

      let park = charges.filter((x) => x.type.label == "PARKING");
      park.map((item, index) => {
        let amount = Number(item.amount) / Number(item.intallments.value);
        let vat = amount * 0.05;
        let amount_parking_charges = [];
        let total = amount + vat;
        for (let i = 0; i < item.intallments.value; i++) {
          let y = billingsData?.filter((x) => x.billtype == item.type.label)[
            index
          ];
          let dated = "";
          if (y && y.instaArray) {
            dated = y?.instaArray[i]?.start_date;
            amount_parking_charges.push({
              instno: i + 1,
              type: item.type.label,
              amount: amount,
              vat: vat,
              total: total,
              mode: "",
              docNo: "",
              dueDate: dated,
              chequeDate: null,
              typeKey: item.type.value,
              instnoTotal: item.intallments.value,
              installmentStartDate: y?.instaArray?.[i]?.start_date,
              installmentEndDate: y?.instaArray?.[i]?.end_date,
              parkingSlot: item?.slot?.value,
            });
          }
        }

        if (amount_parking_charges.length > 0) {
          amount_parking_charges_data.push(amount_parking_charges);
        }
      });

      const n = amount_parking_charges_data.reduce(
        (max, xs) => Math.max(max, xs.length),
        0,
      );

      const result = Array.from({ length: n });
      result.map((_, i) => {
        parkingTotalAmount.push(
          amount_parking_charges_data
            .map((xs) => xs[i]?.amount || 0)
            .reduce((sum, x) => sum + x, 0),
        );
      });

      const nV = amount_parking_charges_data.reduce(
        (max, xs) => Math.max(max, xs.length),
        0,
      );

      const resultV = Array.from({ length: nV });
      resultV.map((_, i) => {
        parkingTotalAmountVat.push(
          amount_parking_charges_data
            .map((xs) => xs[i]?.vat || 0)
            .reduce((sum, x) => sum + x, 0),
        );
      });

      const charge_merge = a_charges_data
        .concat(amount_parking_charges_data)
        .filter((el) => el.length > 0);
      const nVA = charge_merge.reduce((max, xs) => Math.max(max, xs.length), 0);
      const resultVA = Array.from({ length: nVA });
      resultVA.map((_, i) => {
        amount_parking_charges_data_childs.push(
          charge_merge
            .map((xs, _i) => {
              return xs[i] || null;
            })
            .filter((el) => el != null),
        );
      });

      register("amount_parking_charges_data");
      register("amount_parking_charges_data_childs");
      setValue(
        "amount_parking_charges_data",
        amount_parking_charges_data_childs,
      );
      setValue(
        "amount_parking_charges_data_childs",
        amount_parking_charges_data_childs,
      );

      setBillingScheduleTotal(getValues().billingScheduleTotal);
    }
  }, [fieldArray, charges]);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={1}>
          <Text className="fontWeight600 ag-default">Sr. No</Text>
        </Col>
        <Col span={3}>
          <Text className="fontWeight600 ag-default mb-1">Description</Text>
        </Col>
        <Col span={2}>
          <Text className="fontWeight600 ag-default mb-2">Actual Amount</Text>
        </Col>
        <Col span={2}>
          <Text className="fontWeight600 ag-default mb-2">VAT</Text>
        </Col>
        <Col span={2}>
          <Text className="fontWeight600 ag-default mb-2">Total Amount</Text>
        </Col>
        <Col span={3}>
          <Text className="fontWeight600 ag-default mb-2">Mode of Payment</Text>
        </Col>
        <Col span={2}>
          <Text className="fontWeight600 ag-default mb-2">Document No.</Text>
        </Col>
        <Col span={3}>
          <Text className="fontWeight600 ag-default mb-2">Bank Name</Text>
        </Col>
        <Col span={2}>
          <Text className="fontWeight600 ag-default mb-2">Entity</Text>
        </Col>
        <Col span={2}>
          <Text className="fontWeight600 ag-default mb-2">Due Date</Text>
        </Col>
        <Col span={2}>
          <Text className="fontWeight600 ag-default mb-2">Cheque Date</Text>
        </Col>
      </Row>

      <Row gutter={24}>
        <PayCharge
          control={control}
          setValue={setValue}
          register={register}
          errors={errors}
          disableAll={props.disableAll}
          data={a}
          parkingTotalAmount={{ parkingTotalAmount, parkingTotalAmountVat }}
          paymentModes={payment_Modes}
          getValues={getValues}
          statusForm={statusForm}
        />
      </Row>
      <Row>
        <Col span={24}>
          <Title level={4} style={{ textAlign: "end" }}>
            Total: {billingScheduleTotal?.toFixed(2)}
          </Title>
        </Col>
      </Row>
    </>
  );
};
