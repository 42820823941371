import React, { useState, useEffect } from "react";
import RequireDocument from "../../../molecules/RequirDoc";
import { Col } from "antd";
import { uniquiFileName } from "../../../../utils/fileUpload";
import { useWatch } from "react-hook-form";

const fileObjectinit = {
  passportFile: {
    uniqueName: "",
    fileData: "",
  },
  visaFile: {
    uniqueName: "",
    fileData: "",
  },
  emiratesFile: {
    uniqueName: "",
    fileData: "",
  },
  financialSupportFile: {
    uniqueName: "",
    fileData: "",
  },
  signedBookFile: {
    uniqueName: "",
    fileData: "",
  },
  otherDocsFile: {
    uniqueName: "",
    fileData: "",
  },
};

export default (props) => {
  const [docFileOriObject, setDocFileOriObject] = useState(fileObjectinit);
  const [requiredDocArray, setRequiredDocArray] = useState([]);
  const {
    control,
    setValue,
    leaseData,
    setFinalRequiredDocuments,
    setSendEmptyDoc,
  } = props;

  const getDocKeys = (key) => {
    if (
      leaseData &&
      leaseData?.tenantDetail &&
      Object.keys(leaseData?.tenantDetail).length
    ) {
      if (
        leaseData?.tenantDetail?.contacts?.length &&
        leaseData?.tenantDetail?.selectedContact
      ) {
        const defaultContact = leaseData?.tenantDetail?.contacts?.find(
          (e) => e.party_id === leaseData?.tenantDetail?.selectedContact,
        );
        if (defaultContact) {
          const passportKey = defaultContact?.documents?.find(
            (e) => e.purpose_type === "passport",
          )?.passport_key;
          const visaKey = defaultContact?.documents?.find(
            (e) => e.purpose_type === "visa",
          )?.visa_key;
          const emiratesKey = defaultContact?.documents?.find(
            (e) => e.purpose_type === "emirates_id",
          )?.emirates_id_key;

          switch (key) {
            case "passport":
              return passportKey;
            case "visa":
              return visaKey;
            case "emirates":
              return emiratesKey;
            default:
              break;
          }
        } else {
          const passportKey = leaseData?.tenantDetail?.documents?.find(
            (e) => e.purpose_type === "passport",
          )?.passport_key;
          const visaKey = leaseData?.tenantDetail?.documents?.find(
            (e) => e.purpose_type === "visa",
          )?.visa_key;
          const emiratesKey = leaseData?.tenantDetail?.documents?.find(
            (e) => e.purpose_type === "emirates_id",
          )?.emirates_id_key;

          switch (key) {
            case "passport":
              return passportKey;
            case "visa":
              return visaKey;
            case "emirates":
              return emiratesKey;
            default:
              break;
          }
        }

        return "";
      } else {
        const passportKey = leaseData?.tenantDetail?.documents?.find(
          (e) => e.purpose_type === "passport",
        )?.passport_key;
        const visaKey = leaseData?.tenantDetail?.documents?.find(
          (e) => e.purpose_type === "visa",
        )?.visa_key;
        const emiratesKey = leaseData?.tenantDetail?.documents?.find(
          (e) => e.purpose_type === "emirates_id",
        )?.emirates_id_key;

        switch (key) {
          case "passport":
            return passportKey;
          case "visa":
            return visaKey;
          case "emirates":
            return emiratesKey;
          default:
            break;
        }
      }
    } else {
      return "";
    }
  };

  const watcher = useWatch({
    control: control,
    name: "contacts",
  });

  const getFromWatcher = (key) => {
    const defaultContact = leaseData?.tenantDetail?.contacts?.find(
      //@ts-ignore
      (e) => e.party_id === watcher?.value,
    );
    const passportKey = defaultContact?.documents?.find(
      (e) => e.purpose_type === "passport",
    )?.passport_key;
    const visaKey = defaultContact?.documents?.find(
      (e) => e.purpose_type === "visa",
    )?.visa_key;
    const emiratesKey = defaultContact?.documents?.find(
      (e) => e.purpose_type === "emirates_id",
    )?.emirates_id_key;

    switch (key) {
      case "passport":
        return passportKey;
      case "visa":
        return visaKey;
      case "emirates":
        return emiratesKey;
      default:
        break;
    }
    return "";
  };

  useEffect(() => {
    const arr = [];
    if (
      leaseData &&
      leaseData?.requiredDocuments &&
      Object.keys(leaseData?.requiredDocuments).length
    ) {
      const arr = [];
      let passportKey = "";
      let visaKey = "";
      let emiratesKey = "";

      if (watcher && Object.keys(watcher).length) {
        passportKey = getFromWatcher("passport");
        visaKey = getFromWatcher("visa");
        emiratesKey = getFromWatcher("emirates");
      } else {
        passportKey = getDocKeys("passport");
        visaKey = getDocKeys("visa");
        emiratesKey = getDocKeys("emirates");
      }

      const financialSupportKey =
        leaseData?.requiredDocuments?.financial_document || "";
      const signedFormKey =
        leaseData?.requiredDocuments?.signed_booking_form || "";
      const otheDocKey = leaseData?.requiredDocuments?.other_document || "";

      const keyFileObj = { ...docFileOriObject };
      keyFileObj.passportFile.uniqueName = passportKey;
      keyFileObj.visaFile.uniqueName = visaKey;
      keyFileObj.emiratesFile.uniqueName = emiratesKey;
      keyFileObj.financialSupportFile.uniqueName = financialSupportKey;
      keyFileObj.signedBookFile.uniqueName = signedFormKey;
      keyFileObj.otherDocsFile.uniqueName = otheDocKey;

      arr.push({
        docName: "Passport",
        image: "",
        docData: passportKey,
        upKey: "passport",
      });
      arr.push({
        docName: "Valid UAE Visa",
        image: "",
        docData: visaKey,
        upKey: "visa",
      });
      arr.push({
        docName: "Emirates ID",
        image: "",
        docData: emiratesKey,
        upKey: "emirates",
      });
      arr.push({
        docName: "Financial Supporting Document",
        image: "",
        docData: financialSupportKey,
        upKey: "financial",
      });
      arr.push({
        docName: "Signed Booking Form",
        image: "",
        docData: signedFormKey,
        upKey: "signed",
      });
      arr.push({
        docName: "Other documents",
        image: "",
        docData: otheDocKey,
        upKey: "other",
      });
      setRequiredDocArray(arr);
      setDocFileOriObject(keyFileObj);
      setFinalRequiredDocuments(keyFileObj);
    } else {
      arr.push({
        docName: "Passport",
        image: "",
        docData: "",
        upKey: "passport",
      });
      arr.push({
        docName: "Valid UAE Visa",
        image: "",
        docData: "",
        upKey: "visa",
      });
      arr.push({
        docName: "Emirates ID",
        image: "",
        docData: "",
        upKey: "emirates",
      });
      arr.push({
        docName: "Financial Supporting Document",
        image: "",
        docData: "",
        upKey: "financial",
      });
      arr.push({
        docName: "Signed Booking Form",
        image: "",
        docData: "",
        upKey: "signed",
      });
      arr.push({
        docName: "Other documents",
        image: "",
        docData: "",
        upKey: "other",
      });
      setRequiredDocArray(arr);
    }
  }, [leaseData, watcher]);

  const uploadFiles = (key, data) => {
    setSendEmptyDoc(true);
    const obj = { ...docFileOriObject };
    switch (key) {
      case "passport":
        obj.passportFile.fileData = data;
        obj.passportFile.uniqueName = uniquiFileName(data?.name);
        break;
      case "visa":
        obj.visaFile.fileData = data;
        obj.visaFile.uniqueName = uniquiFileName(data?.name);
        break;
      case "emirates":
        obj.emiratesFile.fileData = data;
        obj.emiratesFile.uniqueName = uniquiFileName(data?.name);
        break;
      case "financial":
        obj.financialSupportFile.fileData = data;
        obj.financialSupportFile.uniqueName = uniquiFileName(data?.name);
        break;
      case "signed":
        obj.signedBookFile.fileData = data;
        obj.signedBookFile.uniqueName = uniquiFileName(data?.name);
        break;
      case "other":
        obj.otherDocsFile.fileData = data;
        obj.otherDocsFile.uniqueName = uniquiFileName(data?.name);
        break;
      default:
        break;
    }
    setDocFileOriObject(obj);
    setFinalRequiredDocuments(obj);
  };

  return (
    <>
      {requiredDocArray.map((val, index) => {
        return val.upKey == "signed" && !val.docData ? null : (
          <React.Fragment key={index}>
            <Col span={24}>
              <RequireDocument
                title={val.docName}
                setValue={setValue}
                control={control}
                docsData={val.docData}
                upKey={val.upKey}
                uploadFiles={uploadFiles}
              />
            </Col>
          </React.Fragment>
        );
      })}
    </>
  );
};
