import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  Image,
  Button,
  Typography,
  Dropdown,
  Menu,
} from "antd";
import { useHistory, useLocation } from "react-router-dom";
import {
  EnvironmentFilled,
  TeamOutlined,
  ExportOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import * as status_types from "./constants";
import { getPresignedImage } from "../../modules/Properties/AddProperty/ducks/actions/addInfoAction";
import Logo from "../../../assets/img/ag.png";
import { CSVLink } from "react-csv";
import { propertyService } from "../../../configs/constants";
import axios from "../../../utils/axiosInceptor";

const { Text, Title } = Typography;

const PropertyCard = (props) => {
  const role = JSON.parse(window.localStorage.getItem("login-response"))?.data
    ?.role_name;
  const history = useHistory();
  const location: any = useLocation();
  const [exportData, setExportData] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const { property, exportAction, propertyData, propertyAction } = props;
  const {
    name,
    property_info,
    asset_id,
    asset_status,
    createdAt,
    image,
  } = property;

  const csvLinkEl = useRef();

  const onExport = async (asset_id) => {
    const url = `${propertyService}/property-landlord/export/tickets?asset_id=${asset_id}`;
    try {
      const res = await axios.get(url);
      setCsvData(res?.data?.result);
    } catch (e) {
      const { response } = e;
      console.log("error", response?.data?.message);
    }
  };

  const exportDataHeader = [
    { label: "Case Id", key: "case_id" },
    { label: "Type", key: "type" },
    { label: "SubType", key: "subtype" },
    { label: "Tenant Name", key: "tenant_name" },
    { label: "Technician", key: "technician" },
    { label: "Appartment", key: "appartment" },
    { label: "Recuring", key: "recuring" },
    { label: "Description", key: "description" },
    { label: "Date", key: "date" },
  ];

  useEffect(() => {
    const data = [];
    if (csvData.length > 0) {
      csvData.map((value, key) => {
        data.push({
          case_id: value.case_id,
          type:
            value.CaseCategoryClassification[0]?.CaseCategory?.category_type,
          subtype:
            value.CaseCategorySecondaryClassification[0]?.CaseSubCategory
              ?.category_sub_type,
          tenant_name:
            value.CaseRoleTenant?.length > 0
              ? value.CaseRoleTenant[0]?.Party?.name
              : "",
          technician:
            value.CaseRoleTechnician?.length > 0
              ? value.CaseRoleTechnician[0]?.Party?.name
              : "",
          appartment: value.Asset.name,
          recuring: value.ChildCase[0]?.parent_id,
          description: value.description,
          date:
            (value.ticket_date ? value.ticket_date : "") +
            " " +
            (value.ticket_start_time
              ? moment(value.ticket_start_time, "hh:mm:ss").format("LT") +
                " to "
              : "") +
            (value.ticket_end_time
              ? moment(value.ticket_end_time, "hh:mm:ss").format("LT")
              : ""),
        });
      });
      setExportData(data);
      setTimeout(() => {
        //@ts-ignore
        csvLinkEl?.current?.link.click();
      }, 300);
    }
  }, [csvData]);

  const [propertyImage, setPropertyImage] = useState("");

  const Statuses = () => {
    switch (asset_status) {
      case status_types.PROPERTY_DISABLED:
        return false;
      case status_types.PROPERTY_DRAFT:
        return false;
      case status_types.PROPERTY_RENTED:
        return true;
      case status_types.PROPERTY_PUBLISHED:
        return true;
    }
  };

  useEffect(() => {
    caller();
  }, [image]);

  const presinedImageUrl = async (media) => {
    if (media) {
      const url = await getPresignedImage(media);
      return url;
    }
  };

  const caller = async () => {
    let img = "";
    if (image.length) {
      img = await presinedImageUrl(image[0]);
    }
    setPropertyImage(img);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button
          type="link"
          className="p-0 h-auto"
          onClick={() => history.push(`/property-detail/${asset_id}`)}
        >
          View Property
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button
          type="link"
          className="p-0 h-auto"
          danger
          disabled={asset_status === status_types.PROPERTY_RENTED}
          onClick={() =>
            props.onStatusChanged(
              asset_id,
              Statuses() ? "disabled" : "published",
            )
          }
        >
          {Statuses() ? "Unpublish" : "Publish"} Property
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Card bordered={false}>
      <Row gutter={[24, 24]}>
        <Col span={8} className="imageCard">
          <Image
            preview={false}
            width={235}
            height={165}
            placeholder={true}
            alt="Al Ghurair Residence"
            src={propertyImage ? propertyImage : Logo}
            fallback={Logo}
          />
        </Col>
        <Col span={16}>
          <Card
            bordered={false}
            title={name}
            className="ag-nospace-head ag-nospace-body"
            extra={
              role == "Landlord" && (
                <Dropdown.Button overlay={menu} onClick={propertyAction}>
                  {/* <img src={agEdit} alt="" /> */}
                </Dropdown.Button>
              )
            }
          >
            <Text className="lineHeight28 ag-default">
              <EnvironmentFilled /> {property_info[0]?.street_address}{" "}
              {property_info[0]?.city?.name}, {property_info[0]?.country?.name}
            </Text>
            <Title level={5} className="lineHeight28 mb-0">
              Status:{" "}
              <span
                className={`${Statuses() === true ? "ag-success" : "ag-error"}`}
              >
                {Statuses() ? `Active ` : `Inactive `}
              </span>
            </Title>
            <Title level={5} className="lineHeight28 m-0">
              Uploaded On:{" "}
              <span className="colorGrey">
                {createdAt && moment(createdAt).format("DD-MM-YY")}
              </span>
            </Title>
            {/*<Title level={5} className="lineHeight28 mb-0">
              1 Unit(s)
            </Title>
             <Progress percent={percent} showInfo={false} />
            <Row>
              <Col flex="200">
                <Text className="lineHeight28">
                 0 occupied
                </Text>
              </Col>
              <Col flex="auto">
                <Text className="lineHeight28">
                  1 vacant
                </Text>
              </Col>
            </Row> */}
          </Card>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span="8">
          <Button
            className="w-100"
            type="primary"
            icon={<TeamOutlined />}
            onClick={() => history.push(`/tenants/${asset_id}`)}
          >
            Tenants
          </Button>
        </Col>
        <Col span="8">
          <Button
            className="w-100"
            type="primary"
            icon={<UserOutlined />}
            onClick={() =>
              history.push({
                pathname: `/maintenance/${asset_id}`,
                state: {
                  name: name,
                  parentpath: `/property-detail/${asset_id}`,
                },
              })
            }
          >
            Maintenance
          </Button>
        </Col>
        <Col span="8">
          <Button
            className="w-100"
            type="primary"
            icon={<ExportOutlined />}
            onClick={() => onExport(asset_id)}
          >
            Export
          </Button>
          <CSVLink
            target="_blank"
            data={exportData}
            headers={exportDataHeader}
            filename={"Propertydetail.csv"}
            ref={csvLinkEl}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default PropertyCard;
