import React, { useState } from 'react';
import { Row, Col, Card, Typography, Space, Select, Form } from "antd";
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const { Text, Title, Paragraph } = Typography;
const { Option } = Select;

export default () => {

    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }

    return (
        <Card
            bordered={false}
            bodyStyle={{ padding: '0 24px' }}
            headStyle={{ padding: '16px 24px 0', borderBottom: 0 }}
            title={<Title level={3} className='mb-0'>Terms & Conditions</Title>}
            extra={(<Link to="#"><EditOutlined className='ag-primary ag-fontSize24' /></Link>)}
        >
            <Form layout="vertical" name="addInformation">
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Text className='defaultFont ag-secondary'>You can select one from the drop down menu below.</Text>
                    </Col>
                    <Col span={24}>
                        <Paragraph className='ag-primary'>Preview:</Paragraph>
                        <Editor
                            editorState={editorState}
                            wrapperClassName="editor-main"
                            editorClassName="editor-body"
                            onEditorStateChange={onEditorStateChange}
                        />
                    </Col>
                </Row>
            </Form>
        </Card >
    )
}
