export const notificationsDirector = {
  lead_item_id: "",
  lease_id: "",
};

export const SITEKEY = "6LfyGo8bAAAAAPNwvOlUiLfMelq9dII867qVxKvs";
export const SECRETKEY = "6LfyGo8bAAAAAAj78sbeF8l4ShR-WPmN0Lkt4-ND";
export const EQARI_GOOGLE_KEY = "AIzaSyB1eh7ZlrBG46F_bkJUQcn-4WDjMEs3Unc";

// baseUrl
//
// export const BASE_URL = "";
// export const baseUrl = "https://eqari-dev-api.agp-dev.com";
// export const propertyService = baseUrl + "/api/v1";
// export const leaseService = baseUrl + "/lead-to-lease/api/v1";
// export const authService = baseUrl + "/auth/api/v1";
// export const maintenanceService = baseUrl + "/maintenance/api/v1";
// export const maintenanceTicketsService =
//   baseUrl + "/api/v1/property-landlord/asset/tickets";
// export const singleMaintenanceTicketsService =
//   baseUrl + "/api/v1/property-landlord/asset/ticket";
// export const scheduleVisitService = baseUrl + "/api/v1/property-landlord";
// export const baseContractService = baseUrl + "/lead-to-lease/api/v1/contract";
// export const bookingFormUnit = baseUrl + "/api/v1/property-landlord";

// //QA Base Url
// export const BASE_URL = "https://eqari-dev-api.agp-dev.com/qa-auth/api/v1";
// export const baseUrl = "https://eqari-dev-api.agp-dev.com";
// export const propertyService = baseUrl + "/qa-propety/api/v1";
// export const leaseService = baseUrl + "/qa-lead-to-lease/api/v1";
// export const authService = baseUrl + "/qa-auth/api/v1";
// export const maintenanceService = baseUrl + "/qa-auth/maintenance/api/v1";
// export const maintenanceTicketsService =
//   baseUrl + "/qa-propety/api/v1/property-landlord/asset/tickets";
// export const singleMaintenanceTicketsService =
//   baseUrl + "/qa-propety/api/v1/property-landlord/asset/ticket";
// export const scheduleVisitService =
//   baseUrl + "/qa-propety/api/v1/property-landlord";
// export const baseContractService =
//   baseUrl + "/qa-lead-to-lease/api/v1/contract";
// export const bookingFormUnit = baseUrl + "/qa-propety/api/v1/property-landlord";

//staging Base Url
// export const BASE_URL = "https://eqari-staging-api.agp-dev.com/auth/api/v1";
// export const baseUrl = "https://eqari-staging-api.agp-dev.com";
// export const propertyService = baseUrl + "/api/v1";
// export const leaseService = baseUrl + "/lead-to-lease/api/v1";
// export const authService = baseUrl + "/auth/api/v1";
// export const maintenanceService = baseUrl + "/maintenance/api/v1";
// export const maintenanceTicketsService =
//   baseUrl + "/api/v1/property-landlord/asset/tickets";
// export const singleMaintenanceTicketsService =
//   baseUrl + "/api/v1/property-landlord/asset/ticket";
// export const scheduleVisitService = baseUrl + "/api/v1/property-landlord";
// export const baseContractService = baseUrl + "/lead-to-lease/api/v1/contract";
// export const bookingFormUnit = baseUrl + "/api/v1/property-landlord";

// Production
// export const BASE_URL = "https://prod-api.agp-dev.com/auth/api/v1";
// export const baseUrl = "https://prod-api.agp-dev.com";
// export const propertyService = baseUrl + "/api/v1";
// export const leaseService = baseUrl + "/lead-to-lease/api/v1";
// export const authService = baseUrl + "/auth/api/v1";
// export const maintenanceService = baseUrl + "/maintenance/api/v1";
// export const maintenanceTicketsService =
//   baseUrl + "/api/v1/property-landlord/asset/tickets";
// export const singleMaintenanceTicketsService =
//   baseUrl + "/api/v1/property-landlord/asset/ticket";
// export const scheduleVisitService = baseUrl + "/api/v1/property-landlord";
// export const baseContractService = baseUrl + "/lead-to-lease/api/v1/contract";
// export const bookingFormUnit = baseUrl + "/api/v1/property-landlord";

export const baseUrl = process.env.API_URL;
export const extra = process.env.EXTRA;
export const addition = process.env.ADDITON;

export const propertyService = baseUrl + extra + "api/v1";
export const leaseService = baseUrl + addition + "lead-to-lease/api/v1";
export const authService = baseUrl + addition + "auth/api/v1";
export const maintenanceService = baseUrl + addition + "maintenance/api/v1";
export const maintenanceTicketsService =
  baseUrl + extra + "api/v1/property-landlord/asset/tickets";
export const singleMaintenanceTicketsService =
  baseUrl + extra + "api/v1/property-landlord/asset/ticket";
export const scheduleVisitService =
  baseUrl + extra + "api/v1/property-landlord";
export const baseContractService =
  baseUrl + addition + "lead-to-lease/api/v1/contract";
export const bookingFormUnit = baseUrl + extra + "api/v1/property-landlord";
