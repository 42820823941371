// import React, { useState, useEffect } from "react";
// import { Modal } from "antd";
// import { Document, Page, pdfjs } from "react-pdf";

// const PdfViewer = ({ pdf, onCancel, visible }) => {
//   const [page, setPage] = useState(1);
//   const [pages, setPages] = useState(null);

//   useEffect(() => {
//     pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//   }, []);

//   const onDocumentError = (err) => console.error("pdf viewer error:", err);
//   const onDocumentComplete = ({ numPages }) => setPages(numPages);

//   return (
//     <Modal
//       visible={visible}
//       onCancel={onCancel}
//       cancelText="Close"
//       okButtonProps={{ style: { display: "none" } }}
//       closable={false}
//       style={{ top: 20 }}
//       width={"50%"}
//     >
//       <Document file={pdf} onLoadSuccess={onDocumentComplete}>
//         {Array.apply(null, Array(pages))
//           .map((x, i) => i + 1)
//           .map((page, index) => (
//             <Page pageNumber={page} key={index} />
//           ))}
//       </Document>
//       <p style={{ textAlign: "center" }}>
//         Page {page} of {pages}
//       </p>
//     </Modal>
//   );
// };
// export default PdfViewer;

import React, { useState, useEffect } from "react";
import { Modal } from "antd";
// import { Document, Page, pdfjs } from "react-pdf";

const PdfViewer = ({ pdf, onCancel, visible }) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);

  // useEffect(() => {
  //   pdfjs.GlobalWorkerOptions.workerSrc =
  //     "//unpkg.com/@react-pdf-viewer/pdfjs-dist-signature@2.5.207/build/pdf.worker.js";
  // }, []);

  const onDocumentError = (err) => console.error("pdf viewer error:", err);
  const onDocumentComplete = ({ numPages }) => setPages(numPages);

  return <p>MNI</p>;
};
export default PdfViewer;
