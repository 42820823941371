import * as action_types from "./constants";
import axios from "../../../../utils/axiosInceptor";
import {
  baseContractService,
  leaseService,
} from "../../../../configs/constants";

export const getContactList = (limit = 10, page = 0, value = "", field) => {
  return async (dispatch) => {
    const url = `${baseContractService}/get-all-contracts?limit=${limit}&page=${page}${
      field && `&${field}=${value}`
    }`;
    const response = await axios.get(url);
    dispatch({
      type: action_types.GET_CONTRACTS,
      data: response?.data?.result,
    });
  };
};

export const getContactDetail = (id) => {
  return async (dispatch) => {
    const url = `${baseContractService}/get-lease-renewal?id=${id}`;
    const response = await axios.get(url);
    dispatch({
      type: action_types.GET_CONTRACT,
      data: response?.data?.result,
    });
  };
};

export const getPDC = (limit, page, value) => {
  return async (dispatch) => {
    const url = `${leaseService}/leads/pdc-payment?limit=${limit}&page=${page}${
      value && `&lease_location=${value}`
    }`;
    const response = await axios.get(url);
    dispatch({
      type: action_types.GET_PDC,
      data: response?.data?.result,
    });
  };
};
