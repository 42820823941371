import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, Typography, Popover, Button, Space } from "antd";
import moment from "moment";

const VisitScheduledConfirmationPopup = props => {

      const { Title, Paragraph, Text } = Typography;


      useEffect(()=>{
        console.log(props?.popup)
      },[props?.popup])

      const { value, callBack,setPopup,popup,key } = props;
      const currentStartTime = moment(
        value.start_date + " " + value.start_time,
      ).format("hh:mm A");
      const currentStartDayInDigits = moment(
        value.start_date + " " + value.start_time,
      ).format("Do");
      const currentStartDay = moment(
        value.start_date + " " + value.start_time,
      ).format("dddd");
      const currentStartMonth = moment(
        value.start_date + " " + value.start_time,
      ).format("MMMM");
      const currentEndTime = moment(value.end_date + " " + value.end_time).format(
        "hh:mm A",
      );

      return (
        <Card bordered={false} className='pop-up-notification'>
          <Title level={4}>{`Request for viewing ${value.property.name}`}</Title>
          <Paragraph>{`${currentStartDay}, ${currentStartMonth} ${currentStartDayInDigits}, ${currentStartTime} to ${currentEndTime}`}</Paragraph>
          <Title level={5}>
            {`Would you like to accept ${value.username}’s request to view ${value.property.name}?`}
          </Title>
          {/* <Paragraph>
            This property will not be viewed by anyone else for x hours.
          </Paragraph> */}
          <Space className="pop-up">
            <Button
              onClick={() => {
                callBack(value.id, "rejected");
                setPopup(!popup);
              }}
            >
              Reject
            </Button>
            <Button
              className="ag-green-button"
              onClick={() => {
                callBack(value.id, "approved");
                setPopup(!popup);
              }}
            >
              Accept
            </Button>
          </Space>
        </Card>
      );

};

export default VisitScheduledConfirmationPopup;
