import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleProperty,
  getSinglePropertyEmpty,
} from "../AddProperty/ducks/actions/addInfoAction";
import SideDetail from "./SideDetail";
import PropertyInfo from "./propertyInfoTab/index";
import BreadCrumb from "../../../atoms/BreadCrumb";
import DashboardLayout from "../../../molecules/DashboardLayout";
import PropertyUnits from "./PropertyUnits";
import PaymentPlan from "./PaymentPlan";
import TermsAndCondition from "./TermsAndCondition/index";
import { getPropertyUnits } from "./ducks/actions";
// import EarningsChart from "./EarningsChart";

const { TabPane } = Tabs;

export default () => {
  const dispatch = useDispatch();
  const propertyId = location.pathname?.split("/")[2];

  const propertyDetails = useSelector(
    (state: any) => state.addInfoReducer.getSingleProperty,
  );
  const unitsApi = useSelector((state: any) => state.propertyDetails.unitsList);

  // @ts-ignore
  useEffect(() => {
    dispatch(getSingleProperty(propertyId));
    dispatch(getPropertyUnits(propertyId));
    return () => dispatch(getSinglePropertyEmpty());
  }, []);

  const onUpdateProperty = () => {
    dispatch(getSingleProperty(propertyId));
  };
  const onUpdateUnit = () => {
    dispatch(getPropertyUnits(propertyId));
  };

  const breadcrumbList: any = [
    {
      label: "Properties",
      route: "/properties",
    },
    {
      label: propertyDetails?.name,
      route: "",
    },
  ];

  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BreadCrumb list={breadcrumbList} />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xxl={6} xl={7}>
          <SideDetail
            propertyDetails={propertyDetails}
            updated={onUpdateProperty}
            isUnit={false}
            parentName=""
          />
        </Col>
        <Col xxl={18} xl={17}>
          <Row gutter={[24, 24]}>
            {/* <Col span={24}>
              <EarningsChart />
            </Col> */}
            <Col span={24}>
              <Card
                bordered={false}
                className="ag-nospace-head ag-nospace-body h-auto"
              >
                <Tabs
                  defaultActiveKey="1"
                  animated={false}
                  tabBarGutter={16}
                  style={{ overflow: "unset" }}
                >
                  <TabPane key="1" tab="Property Information">
                    <PropertyInfo
                      propertyDetails={propertyDetails}
                      updated={onUpdateProperty}
                    />
                  </TabPane>
                  {unitsApi?.length > 0 ? (
                    <TabPane key="2" tab="Property Units">
                      <PropertyUnits
                        propertyDetails={propertyDetails}
                        units={unitsApi}
                        updated={onUpdateUnit}
                      />
                    </TabPane>
                  ) : (
                    <TabPane key="2" tab="Payment Plan">
                      <PaymentPlan propertyDetails={propertyDetails} />
                    </TabPane>
                  )}
                  <TabPane key="3" tab="Terms and Condition">
                    <TermsAndCondition
                      propertyDetails={propertyDetails}
                      updated={onUpdateProperty}
                    />
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </DashboardLayout>
  );
};
