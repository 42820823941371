import React, { useEffect, useState } from "react";
import { Button, Card, Collapse, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentConfig } from "../ducks/actions/paymentAction";
import InstallmentPanel from "./patmentPlanAccordion";
import InstallmentPlan from "../../PropertyDetail/PaymentPlan/components/InstallmentPlan";
import { paymentIndex } from "../../ducks/constants";

const { Title } = Typography;
const { Panel } = Collapse;

export default ({ currentStep, setCurrentStep, uid }) => {
  const dispatch = useDispatch();
  const [paymentConfig, setPaymentConfig] = useState({});
  const [planCreated, setPlanCreated] = useState(true);
  const [paymentArray, setPaymentArray] = useState([]);

  const paymentConfigData = useSelector(
    (state: any) => state.paymentReducer.getPaymentConfig,
  );

  useEffect(() => {
    dispatch(getPaymentConfig());
  }, []);

  useEffect(() => {
    setPaymentConfig(paymentConfigData);
  }, [paymentConfigData]);

  const updatePay = (data) => {
    let temp = [...paymentArray];
    temp.push(data);
    setPaymentArray(Object.assign([], temp));
  };

  return (
    <>
      <Collapse
        defaultActiveKey={"adding"}
        accordion={true}
        expandIconPosition="right"
        ghost
        className="ag-collapse custom-collapse ag-border-card"
      >
        {paymentArray.length > 0 &&
          paymentArray.map((data, index) => (
            <Panel
              header={
                <>
                  <Title level={5} className="mb-0 normalFont18">
                    {index + 1}. {paymentIndex[index]} Installment Plan
                  </Title>
                </>
              }
              style={{ marginBottom: 24 }}
              key={`${index + 1}`}
            >
              <InstallmentPlan
                details={data}
                fees={paymentConfig}
                staticData={true}
                uid={uid}
              />
            </Panel>
          ))}
        <Panel
          header={
            <>
              <Title level={5} className="mb-0 normalFont18">
                {paymentArray.length + 1}. {paymentIndex[paymentArray.length]}{" "}
                Installment Plan
              </Title>
            </>
          }
          style={{ marginBottom: 24 }}
          key={"adding"}
        >
          <InstallmentPanel
            paymentConfig={paymentConfig}
            setPlanCreated={setPlanCreated}
            onAdd={(e) => updatePay(e)}
            uid={uid}
          />
        </Panel>
      </Collapse>
      <div className="steps-action py-3">
        {/* <Button type="primary" onClick={() => setCurrentStep(currentStep - 1)}>
          Back
        </Button> */}
        <Button
          type="primary"
          onClick={() => setCurrentStep(currentStep + 1)}
          disabled={planCreated}
        >
          Next
        </Button>
      </div>
    </>
  );
};
