export const GET_PROPERTIES_LATLONG = "GET_PROPERTIES_LATLONG";
export const GET_DASHBOARD_STATS = "GET_DASHBOARD_STATS";
export const GET_PROPERTIES_LIST_O = "GET_PROPERTIES_LIST_O";
export const GET_PROPERTIES_LIST_V = "GET_PROPERTIES_LIST_V";
export const GET_PROPERTIES_CARD = "GET_PROPERTIES_CARD";
export const GET_SCHEDULED_VISITS = "GET_SCHEDULED_VISITS";
export const UPDATE_SCHEDULED_VISIT_REQUEST = "UPDATE_SCHEDULED_VISIT_REQUEST";
export const GET_PROPERTIES_COUNT = "GET_PROPERTIES_COUNT";
export const GET_PROPERTY_VISITS = "GET_PROPERTY_VISITS";
export const DOWNLOAD_DATA = "DOWNLOAD_DATA";
export const RESET_STATE = "RESET_STATE";
export const EMPTY_SCHEDULE = "EMPTY_SCHEDULE";

// Location and sublocation
export const subLocationList = [
  " Abu Hail",
  "Al Asbaq",
  "Al Aweer First",
  "Al Aweer Second",
  "Al Baagh",
  "Al Bada",
  "Al Baharna",
  "Al Baraha",
  "Al Barsha",
  "Al Barsha First",
  "Al Barsha Second",
  "Al Barsha South Fifth",
  "Al Barsha South Fourth",
  "Al Barsha Third",
  "Al Barshaa South First",
  "Al Barshaa South Second",
  "Al Barshaa South Third",
  "Al Buteen",
  "Al Dhagaya",
  "Al Eyas",
  "Al Faga'A",
  "Al Fahidi",
  "Al Garhoud",
  "Al Goze First",
  "Al Goze Fourth",
  "Al Goze Industrial First",
  "Al Goze Industrial Fourth",
  "Al Goze Industrial Second",
  "Al Goze Industrial Third",
  "Al Goze Second",
  "Al Goze Third",
  "Al Hamriya",
  "Al Hamriya Port",
  "Al Hathmah",
  "Al Hebiah Fifth",
  "Al Hebiah First",
  "Al Hebiah Fourth",
  "Al Hebiah Second",
  "AL HEBIAH SIXTH",
  "Al Hebiah Third",
  "Al Hudaiba",
  "Al Jadaf",
  "Al Jafliya",
  "Al Karama",
  "Al Khabeesi",
  "Al Khairan Second",
  "Al Khairan First",
  "Al Khawaneej",
  "Al Khawaneej First",
  "Al Khawaneej Second",
  "Al Kheeran",
  "Al Kifaf",
  "Al Layan 2",
  "Al Layan1",
  "Al Lusaily",
  "Al Maha",
  "Al Mamzer",
  "Al Manara",
  "Al Mararr",
  "Al Marmoom",
  "Al Merkadh",
  "Al Meryal",
  "Al Mezhar",
  "Al Mezhar First",
  "Al Mezhar Second",
  "Al Murqabat",
  "Al Musalla (Dubai)",
  "Al Muteena",
  "Al Nahda First",
  "Al Nahda Second",
  "Al Oshoosh",
  "Al Qoaz",
  "Al Qusais",
  "Al Qusais First",
  "Al Qusais Industrial Fifth",
  "Al Qusais Industrial First",
  "Al Qusais Industrial Fourth",
  "Al Qusais Industrial Second",
  "Al Qusais Industrial Third",
  "Al Qusais Second",
  "Al Qusais Third",
  "Al Raffa",
  "Al Ras",
  "Al Rashidiya",
  "Al Rega",
  "Al Rowaiyah First",
  "Al Rowaiyah Second",
  " Al Rowaiyah Third",
  "Al Ruwayyah",
  "Al Sabkha",
  "Al Safaa",
  "Al Saffa First",
  "Al Saffa Second",
  "Al Safouh First",
  "Al Safouh Second",
  "Al Sanaiyya",
  "Al Satwa",
  "Al Suq Al Kabeer",
  "Al Thanayah Fourth",
  "Al Thanyah Fifth",
  "Al Thanyah First",
  "Al Thanyah Second",
  "Al Thanyah Third",
  "Al Ttay",
  "Al Twar First",
  "Al Twar Second",
  " Al Twar Third",
  "Al Waheda",
  "Al Wajehah Al Bhariyah",
  "Al Warqa Fifth",
  "Al Warqa First",
  "Al Warqa Fourth",
  "Al Warqa Second",
  "Al Warqa Third",
  "Al Warsan First",
  "Al Warsan Second",
  "Al Warsan Third",
  "Al Wasl",
  "Al Wohoosh",
  "Al Yelayiss 1",
  "Al Yelayiss 2",
  "Al Yelayiss 3",
  "Al Yelayiss 4",
  "Al Yelayiss 5",
  "Al Yufrah 1",
  "Al Yufrah 2",
  "Al Yufrah 3",
  "Al Yufrah 4",
  "Al Zaroob",
  "Al-Aweer",
  "Al-Baloosh",
  " Al-Bastakiyah",
  "Al-Cornich",
  "Al-Dzahiyyah Al-Jadeedah",
  "Almarmum First",
  "Almarmum Third",
  "Almeydan",
  "Al-Muhaisnah North",
  "Al-Murar Jadeed",
  "Al-Murar Qadeem",
  "Al-Musalla (Deira)",
  "Al-Mustashfa West",
  "Al-Nabgha",
  "Al-Nahdah",
  "Al-Nakhal",
  "Al-Qiyadah",
  "Al-Raulah",
  "Al-Riqqa East",
  "Al-Riqqa West",
  "Al-Safiyyah",
  " Al-Shumaal",
  "Al-Souq Al Kabeer (Deira)",
  "Al-Souq Al-Muqayatah",
  "Al-Tawar",
  "Al-Zarouniyyah",
  "Anakhali",
  "Bukadra",
  "Bur Dubai",
  "Burj Khalifa",
  "Burj Nahar",
  "Business Bay",
  "Cornich Deira",
  "Dubai International Airport",
  "Dubai Investment Park First",
  "Dubai Investment Park Second",
  "Emirates Hills Fourth",
  "Esalal",
  "Eyal Nasser",
  "Festival City First",
  "Ghadeer Barashy",
  "Grayteesah",
  "Hadaeq Sheikh Mohammed Bin Rashid",
  "Hafair",
  "Hatta",
  "Hessyan First",
  "Hessyan Second",
  "Hor Al Anz",
  "Hor Al Anz East",
  "Island 2",
  "Jabal Ali",
  "Jabal Ali First",
  "Jabal Ali Industrial First",
  "Jabal Ali Industrial Second",
  "Jabal Ali Industrial Third",
  "Jabal Ali Second",
  "Jabal Ali Third",
  "JUMEIRA ISLAND 2",
  "Jumeira Island First",
  "Jumeirah",
  "Jumeirah First",
  "Jumeirah Second",
  "Jumeirah Third",
  "Le Hemaira",
  "Lehbab",
  "Lehbab First",
  "Lehbab Second",
  "Madinat Al Mataar",
  "Madinat Dubai Almelaheyah",
  "Mankhool",
  "Margham",
  "Marsa Dubai",
  "Me'Aisem First",
  "Me'Aisem Second",
  "Mena Jabal Ali",
  "Mereiyeel",
  "Mirdif",
  "Muashrah Al Bahraana",
  "Mugatrah",
  "Muhaisanah Fifth",
  "Muhaisanah First",
  "Muhaisanah Fourth",
  "Muhaisanah Second",
  "Muhaisanah Third",
  "Muhaisna",
  "Muragab",
  "Mushrif",
  "Mushrif",
  "Nad Al Hamar",
  "Nad Al Shiba",
  "Nad Al Shiba First",
  "Nad Al Shiba Fourth",
  "Nad Al Shiba Second",
  "Nad Al Shiba Third",
  "Nad Rashid",
  "Nad Shamma",
  "Nadd Hessa",
  "Naif",
  "Naif North",
  "Naif South",
  "Nazwah",
  "Oud Al Muteena",
  "Oud Al Muteena First",
  "Oud Al Muteena Second",
  "Oud Almuteena Third",
  "Oud Metha",
  "Palm Deira",
  "Palm Jabal Ali",
  "Palm Jumeirah",
  "Port Saeed",
  "Ras Al Khor",
  "Ras Al Khor Industrial First",
  "Ras Al Khor Industrial Second",
  "Ras Al Khor Industrial Third",
  "Rega Al Buteen",
  "Remah",
  "Riqat Masali",
  "Saih Aldahal",
  "Saih Alsalam",
  "Saih Shuaelah",
  "Saih Shuaib 1",
  "Saih Shuaib 2",
  "Saih Shuaib 3",
  "Saih Shuaib 4",
  "Shandagha",
  "Shandagha East",
  "Shandagha West",
  "Sikka Al Khail",
  "Sikkat Al Khail North",
  "Sikkat Al Khail South",
  "Souq Al-Lariyyah",
  "Souq Al-Tamar",
  "Souq Sikkat Al Khail",
  "Tareeq Abu Dhabi",
  "Tareeq Al Aweer",
  "Tawaa Al Sayegh",
  "Tawi Al Muraqqab",
  "Tawi Alfuqa",
  "Trade Center First",
  "Trade Center Second",
  "Um Al Sheif",
  "Um Almoameneen",
  "Um Esalay",
  "Um Hurair First",
  "Um Hurair Second",
  "Um Nahad First",
  "Um Nahad Fourth",
  "Um Nahad Second",
  "Um Nahad Third",
  "Um Ramool",
  "Um Suqaim",
  "Um Suqaim First",
  "Um Suqaim Second",
  "Um Suqaim Third",
  "Umm Addamin",
  "Umm Hurair",
  "Universe Islands",
  "Wadi Al Amardi",
  "Wadi Al Safa 2",
  "Wadi Al Safa 3",
  "Wadi Al Safa 4",
  "Wadi Al Safa 5",
  "Wadi Al Safa 6",
  "Wadi Al Safa 7",
  "Wadi Alshabak",
  "Warsan Fourth",
  "World Islands",
  "Yaraah",
  "Zaabeel First",
  "Zaabeel Second",
  "Zabeel East",
  "Zareeba Duviya",
];
export const bedroomCategory = [
  { value: 0, label: "0" },
  { value: 1, label: "1BR" },
  { value: 2, label: "2BR" },
  { value: 3, label: "3BR" },
  { value: 4, label: "4BR" },
  { value: 5, label: "5BR" },
];

export const locationsList = ["Deira", "Dubai"];

export const paymentIndex = [
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eighth",
  "Ninth",
  "Tenth",
  "Eleventh",
  "twelfth",
  "thirtieenth",
  "fourteenth",
  "fifteenth",
];

export const installmentIndex = [
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
];

export const tfValue = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

export const bathList = [
  { label: 1, value: "1" },
  { label: 2, value: "2" },
  { label: 3, value: "3" },
  { label: 4, value: "4" },
  { label: 5, value: "5" },
];

export const installmentsList = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "4", value: 4 },
  { label: "6", value: 6 },
  { label: "12", value: 12 },
];
