import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Card,
  Image,
  Button,
  Tabs,
  Input,
  Pagination,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../molecules/Sidebar";
import Header from "../../molecules/Header";
import Footer from "../../molecules/Footer";
import agEdit from "../../../assets/img/ag-edit.svg";
import DashboardHeader from "../../molecules/DashboardHeader";
import userimage from "../../../assets/img/ag.png";
import Grid from "../../atoms/Grid";
import { Pie } from "@ant-design/charts";
import {
  getPinProperties,
  getStats,
  getPropertiesList,
  getCountProperties,
} from "./ducks/actions";
import PropertyCards from "./components/AllProperties/PropertyCards";
import { useHistory } from "react-router-dom";
import { WrappedMap } from "../../../utils/WrappedMap";
import { EQARI_GOOGLE_KEY } from "../../../configs/constants";
import EmptyPlaceholder from "../../atoms/EmptyPlaceholder";
// import { IDatasource, IGetRowsParams } from "ag-grid-community";

const { Content } = Layout;
const { TabPane } = Tabs;
const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
};

const chartData = [
  {
    type: "Occupied",
    value: 0,
  },
  {
    type: "Vacant",
    value: 0,
  },
  {
    type: "Draft",
    value: 0,
  },
  {
    type: "Unpublished",
    value: 0,
  },
  {
    type: "Reserved",
    value: 0,
  },
];

const propertyCol = [
  {
    headerName: "Property Name",
    field: "propertyName",
  },
  {
    headerName: "Property Type",
    field: "propertyType",
  },
  {
    headerName: "Bedrooms",
    field: "bedrooms",
  },
  {
    headerName: "Bathrooms",
    field: "bathrooms",
  },
  {
    headerName: "Size",
    field: "size",
  },
  {
    headerName: "Location",
    field: "location",
  },
];

const figures = [
  {
    label: "Buildings",
    value: 0,
  },
  {
    label: "Units",
    value: 0,
  },
  {
    label: "Visits",
    value: 0,
  },
  {
    label: "Maintenance",
    value: 0,
  },
];

const list = {
  search: "",
  sortby: null,
  sort: null,
  page: 0,
  limit: 10,
};

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [tabKey, setTabkey] = useState("1");
  const [propertyGridData, setPropertyGridData] = useState<any>({
    occupied: [],
    vacant: [],
  });
  const [propertyCount, setPropertyCount] = useState(figures);
  const [propertyListO, setPropertylistO] = useState(list);
  const [propertyListV, setPropertylistV] = useState(list);
  const [marker, setMarker] = useState([]);
  const [pieData, setPieData] = useState(chartData);
  const [userDetail, setUserDetail] = useState({
    username: "",
    designation: "",
    userPic: userimage,
  });
  const searchField = (
    <Input
      type="text"
      onChange={(e) => onSearch(e)}
      placeholder=""
      suffix={<SearchOutlined />}
    />
  );
  const markerApi = useSelector((state: any) => state.dashboard.mapData);
  const statsApi = useSelector((state: any) => state.dashboard.stats);
  const propertiesPieApi = useSelector(
    (state: any) => state.dashboard.propertiesChart,
  );
  const propertiesListingOApi = useSelector(
    (state: any) => state.dashboard.propertiesListO,
  );
  const propertiesListingVApi = useSelector(
    (state: any) => state.dashboard.propertiesListV,
  );

  const chartconfig = {
    appendPadding: 10,
    data: pieData,
    angleField: "value",
    colorField: "type",
    color: ["#A2D69E", "#1C5D3B", "#184461", "#447093", "#FB7171"],
    radius: 0.85,
    innerRadius: 0.75,
    // meta: {
    //   value: {
    //     formatter: function formatter(value) {
    //       return "".concat(value);
    //     },
    //   },
    // },
    label: {
      type: "outer",
      offset: "-50%",
      // style: { textAlign: "center", opacity: 0 },
      autoRotate: false,
      content: (data) => `${Math.round(data.percent * 100)}%`,
    },
    legend: {
      itemName: {
        style: {
          fontSize: 14,
        },
      },
    },
    state: {
      active: {
        style: {
          lineWidth: 0,
          fillOpacity: 0.65,
        },
      },
    },
    statistic: {
      title: {
        offsetY: -20,
        customHtml: () => "Total Units",
      },
      // content: {
      //   style: {
      //     whiteSpace: "pre-wrap",
      //     overflow: "hidden",
      //     textOverflow: "ellipsis",
      //   },
      //   // formatter: function formatter() {
      //   //   return ''.concat("Total");
      //   // },
      // },
    },
    interactions: [
      { type: "element-selected" },
      { type: "pie-statistic-active" },
    ],
  };

  function listProperties(pProps) {
    let searchVacant = propertyListV.search;
    let searchOccupied = propertyListO.search;
    let Vpage = propertyListV.page;
    let Opage = propertyListO.page;
    if (pProps.status === "published") {
      if (pProps.search != undefined) {
        searchVacant = pProps.search;
      }
      if (pProps.page != undefined) {
        Vpage = pProps.page;
      }
      dispatch(
        getPropertiesList(
          searchVacant,
          Vpage,
          propertyListV.limit,
          false,
          pProps.status,
          true,
        ),
      );
    } else if (pProps.status === "rented") {
      if (pProps.page != undefined) {
        Opage = pProps.page;
      }
      if (pProps.search != undefined) {
        searchOccupied = pProps.search;
      }
      dispatch(
        getPropertiesList(
          searchOccupied,
          Opage,
          propertyListO.limit,
          false,
          pProps.status,
          true,
        ),
      );
    }
  }

  const initApiCall = () => {
    setTimeout(() => {
      dispatch(getCountProperties());
      dispatch(getPinProperties());
      dispatch(getStats());
      listProperties({ status: "published" });
      listProperties({ status: "rented" });
    }, 1000);
  };

  useEffect(() => {
    if (propertiesPieApi) {
      const temp = chartData;
      Object.entries(propertiesPieApi).map(([Key, Val]) => {
        if (Key == "rented") {
          const d: any = temp.find((item) => item.type == "Occupied");
          d.value = Val;
        }
        if (Key == "draft") {
          const d: any = temp.find((item) => item.type == "Draft");
          d.value = Val;
        }
        if (Key == "published") {
          const d: any = temp.find((item) => item.type == "Vacant");
          d.value = Val;
        }
        if (Key == "disabled") {
          const d: any = temp.find((item) => item.type == "Unpublished");
          d.value = Val;
        }
        if (Key == "reserved") {
          const d: any = temp.find((item) => item.type == "Reserved");
          d.value = Val;
        }
      });
      setPieData(Object.assign([], temp));
    }
  }, [propertiesPieApi]);

  useEffect(() => {
    initApiCall();
    const data = JSON.parse(window.localStorage.getItem("login-response"))
      ?.data;
    setUserDetail({
      ...userDetail,
      username: data?.firstName,
      designation: data?.role_name.replace("_", " "),
    });
  }, []);

  useEffect(() => {
    if (statsApi) {
      const figureData = Array.from(propertyCount);
      figureData[0].value = statsApi.buildingsCount;
      figureData[1].value = statsApi.propertyCount;
      figureData[2].value = statsApi.applicationCount;
      figureData[3].value = statsApi.maintenanceCount;
      setPropertyCount(figureData);
    }
  }, [statsApi]);

  useEffect(() => {
    propertiesListingOApi &&
      propertiesListingOApi.properties &&
      setPropertyGridData(
        Object.assign({}, propertyGridData, {
          occupied: propertiesListingOApi.properties.map((item, index) => ({
            propertyName: item.name,
            propertyType: item.asset_type,
            bedrooms: item.number_of_beds,
            bathrooms: item.number_of_baths,
            size: Math.round(item.size),
            location: item.property_info[0]?.city?.name,
          })),
        }),
      );
  }, [propertiesListingOApi]);

  useEffect(() => {
    markerApi &&
      setMarker(
        markerApi.map((e, index) => ({
          ...e,
          key: index,
        })),
      );
  }, [markerApi]);

  useEffect(() => {
    propertiesListingVApi &&
      propertiesListingVApi.properties &&
      setPropertyGridData(
        Object.assign({}, propertyGridData, {
          vacant: propertiesListingVApi.properties.map((item, index) => ({
            propertyName: item.name,
            propertyType: item.asset_type,
            bedrooms: item.number_of_beds,
            bathrooms: item.number_of_baths,
            size: Math.round(item.size),
            location: item.property_info[0]?.city?.name,
          })),
        }),
      );
  }, [propertiesListingVApi]);

  const onSearch = (e) => {
    if (tabKey === "1") {
      setPropertylistV({ ...propertyListV, search: e.target.value });
      listProperties({ status: "published", search: e.target.value });
    } else if (tabKey === "2") {
      setPropertylistO({ ...propertyListO, search: e.target.value });
      listProperties({ status: "rented", search: e.target.value });
    }
  };

  const fetchVacantData = (pg) => {
    setPropertylistV({ ...propertyListV, page: pg });
    listProperties({ status: "published", page: pg, search: undefined });
  };

  const fetchOccupiedData = (pg) => {
    setPropertylistO({ ...propertyListO, page: pg });
    listProperties({ status: "rented", page: pg, search: undefined });
  };

  return (
    <Layout className="main-layout">
      <Header />
      <Layout>
        <Sidebar />
        <Layout className="layout-space">
          <DashboardHeader user={userDetail} figures={propertyCount} />
          <Content className="main-content">
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Card
                  bordered={false}
                  title="Properties on map"
                  className="noicon-space"
                  bodyStyle={{ position: "relative" }}
                  extra={
                    <Button type="link" className="p-0">
                      <Image
                        preview={false}
                        src={agEdit}
                        alt=""
                        onClick={() => history.push("/properties-map")}
                      />
                    </Button>
                  }
                >
                  {marker.length === 0 && (
                    <EmptyPlaceholder
                      class="overlay-pos"
                      label="properties"
                      action={() => history.push("/create-a-property")}
                    />
                  )}
                  <WrappedMap
                    isMarkerShown
                    positions={marker}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${EQARI_GOOGLE_KEY}`}
                    loadingElement={
                      <div style={{ height: "100%", minHeight: "450px" }} />
                    }
                    containerElement={<div style={{ height: "400px" }} />}
                    mapElement={<div style={{ height: "100%" }} />}
                    dashboard={true}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  bordered={false}
                  title="Total Properties"
                  bodyStyle={{ paddingRight: "40px" }}
                >
                  <Pie {...chartconfig} />
                </Card>
              </Col>
              <Col span={24}>
                <PropertyCards />
              </Col>
              <Col span={24}>
                <Tabs
                  defaultActiveKey={tabKey}
                  onChange={setTabkey}
                  tabBarExtraContent={searchField}
                  tabBarGutter={48}
                  className="ag-property-status"
                >
                  <TabPane tab="Vacant" key="1">
                    <Row gutter={[24, 24]} justify="end">
                      <Col span={24}>
                        <Grid
                          data={propertyGridData.vacant}
                          columns={propertyCol}
                          pagination={false}
                          defaultSettings={gridProps}
                          noRowlabel="Properties"
                        />
                      </Col>
                      <Col span={24}>
                        <Pagination
                          style={{ justifyContent: "flex-end" }}
                          className="ag-property-text-pagination"
                          total={propertiesListingVApi?.count}
                          defaultPageSize={propertyListV.limit}
                          current={propertyListV.page + 1}
                          onChange={(e) => fetchVacantData(e - 1)}
                          showSizeChanger={false}
                        />
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tab="Occupied" key="2">
                    <Row gutter={[24, 24]}>
                      <Col span={24}>
                        <Grid
                          data={propertyGridData.occupied}
                          columns={propertyCol}
                          pagination={false}
                          defaultSettings={gridProps}
                          noRowlabel="Properties"
                        />
                      </Col>
                      <Col span={24}>
                        <Pagination
                          style={{ justifyContent: "flex-end" }}
                          className="ag-property-text-pagination"
                          total={propertiesListingOApi?.count}
                          defaultPageSize={propertyListO.limit}
                          current={propertyListO.page + 1}
                          onChange={(e) => fetchOccupiedData(e - 1)}
                          showSizeChanger={false}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>

            <Footer />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
