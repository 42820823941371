import { Row, Col, Typography, Card } from "antd";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ListPanel from "../../../../../molecules/ListPanel";

export default (props) => {
  const tenantData = useSelector(
    (state: any) => state.tenantsSummary.tenantData,
  );

  useEffect(() => {
    console.log("tenantData", tenantData?.documents);
  }, [tenantData]);

  const passportData = [
    {
      key: "Passport Status",
      value: tenantData?.documents?.passport?.passport_no_expiry || "N/A",
    },
    {
      key: "Passport Number",
      value: tenantData?.documents?.passport?.passport_no || "N/A",
    },
    {
      key: "Passport Expiry Date",
      value: tenantData?.documents?.passport?.passport_no_expiry || "N/A",
    },
    {
      key: "Passport Document",
      value: tenantData?.documents?.passport?.object_key
        ? "View Document"
        : "N/A",
      name: tenantData?.documents?.passport?.object_key,
    },
  ];

  const visaData = [
    {
      key: "Visa Status",
      value: tenantData?.documents?.visa?.visa_no_expiry || "N/A",
    },
    {
      key: "Visa Number",
      value: tenantData?.documents?.visa?.visa_no || "N/A",
    },
    {
      key: "Visa Expiry Date",
      value: tenantData?.documents?.visa?.visa_no_expiry || "N/A",
    },
    {
      key: "Visa Document",
      value: tenantData?.documents?.visa?.object_key ? "View Document" : "N/A",
      name: tenantData?.documents?.visa?.object_key,
    },
  ];

  const emirateData = [
    {
      key: "Emirates ID Status",
      value: tenantData?.documents?.emirates_id?.emirates_no_expiry || "N/A",
    },
    {
      key: "Emirates ID Number",
      value: tenantData?.documents?.emirates_id?.emirates_no || "N/A",
    },
    {
      key: "Emirates ID Expiry Date",
      value: tenantData?.documents?.emirates_id?.emirates_no_expiry || "N/A",
    },
    {
      key: "Emirates ID Document",
      value: tenantData?.documents?.emirates_id?.object_key
        ? "View Document"
        : "N/A",
      name: tenantData?.documents?.emirates_id?.object_key,
    },
  ];

  const tradeData = [
    {
      key: "Trade License Number",
      value: tenantData?.documents?.trade_license?.trade_license_no || "N/A",
    },
    {
      key: "Trade License Expiry Date",
      value:
        tenantData?.documents?.trade_license?.trade_license_expiry || "N/A",
    },
    {
      key: "Trade License Docuemnt",
      value: tenantData?.documents?.trade_license?.object_key
        ? "View Document"
        : "N/A",
      name: tenantData?.documents?.trade_license?.object_key,
    },
  ];

  return (
    <Card bordered={false} bodyStyle={{ padding: "3em 32% 3em 3.5em" }}>
      <Row gutter={[40, 40]}>
        <Col span={12}>
          <ListPanel data={passportData} title={"Passport"} />
        </Col>
        <Col span={12}>
          <ListPanel data={visaData} title={"Visa"} />
        </Col>
        <Col span={12}>
          <ListPanel data={emirateData} title={"Emirates ID"} />
        </Col>
        {tenantData?.documents?.trade_license?.trade_license_no && (
          <Col span={12}>
            <ListPanel data={tradeData} title={"Trade License"} />
          </Col>
        )}
      </Row>
    </Card>
  );
};
