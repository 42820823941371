import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Typography, Select, DatePicker } from "antd";
import {
  InputField,
  ReactSelectField,
  DateField,
} from "../../../atoms/FormElement";
import moment from "moment";

import { useFieldArray, useWatch } from "react-hook-form";
import Item from "antd/lib/list/Item";
const { Text, Title } = Typography;

export default (props) => {
  const {
    paymentModes,
    control,
    errors,
    setValue,
    data,
    index,
    parkingTotalAmount: { parkingTotalAmount, parkingTotalAmountVat },
    register,
    getValues,
    statusForm,
  } = props;

  const { fields, append } = useFieldArray({
    control,
    name: "payment",
  });

  const [a, setA] = useState([]);
  const [modifyFormData, setModifyFormData] = useState([]);
  useEffect(() => {
    setA(data);
  }, [data]);

  useEffect(() => {
    let modifyData = [];
    data.map((item, index) => {
      let actualAmount = item.amount;
      let vat = item.vat;
      let total = item.total;
      if (item.type === "BASE RENT") {
        if (parkingTotalAmount[index]) {
          actualAmount = parkingTotalAmount[index] + item.amount;
          vat = item.vat + parkingTotalAmountVat[index];
          //vat = item.vat;
          total = actualAmount + vat;
        }
      }

      item.vat = vat.toFixed(2);
      item.amount = actualAmount.toFixed(2);
      item.total = total;

      modifyData.push(item);
    });

    let totalAmountFixes = [];
    modifyData.map((_item, index) => {
      if (_item.type === "BASE RENT" && _item?.total % 1 !== 0 && index !== 0) {
        totalAmountFixes.push(_item?.total % 1);
      }
    });

    const totalPointsValues = totalAmountFixes.reduce((a, b) => a + b, 0);
    modifyData.map((_item, index) => {
      if (_item.type === "BASE RENT" && index == 0) {
        let innerTotal = _item.total + totalPointsValues;
        _item.total = innerTotal.toFixed(2);
      } else if (_item.type === "BASE RENT") {
        _item.total = Math.floor(_item.total);
      } else {
        _item.total = _item?.total?.toFixed(2);
      }

      if (_item.type === "BASE RENT") {
        _item.type = "Instalment " + (index + 1);
      }

      return _item;
    });

    register("modifyData");
    setValue("modifyData", modifyData);

    setModifyFormData(modifyData);
  }, [a]);

  return (
    <>
      {modifyFormData.map((item, index) => {
        return (
          <Fragment key={index}>
            <Col span={1}>
              <InputField
                label=""
                fieldname={`payment[${index}].instno`}
                setValue={setValue}
                valueGot={item.instno}
                control={control}
                initValue=""
                iProps={{
                  readOnly: true,
                  disabled: props.disableAll
                    ? true
                    : item.type == "EJARI FEE" ||
                      item.type == "SECURITY DEPOSIT"
                    ? true
                    : false,
                }}
              />
            </Col>
            <Col span={3}>
              <InputField
                label=""
                fieldname={`payment[${index}].type`}
                setValue={setValue}
                valueGot={item.type}
                control={control}
                initValue=""
                iProps={{
                  readOnly: true,
                  disabled: props.disableAll
                    ? true
                    : item.type == "EJARI FEE" ||
                      item.type == "SECURITY DEPOSIT"
                    ? true
                    : false,
                }}
              />
            </Col>
            <Col span={2}>
              <InputField
                label=""
                fieldname={`payment[${index}].amount`}
                setValue={setValue}
                valueGot={item.amount}
                control={control}
                initValue=""
                iProps={{
                  readOnly: true,
                  type: "number",
                  disabled: props.disableAll
                    ? true
                    : item.type == "EJARI FEE" ||
                      item.type == "SECURITY DEPOSIT"
                    ? true
                    : false,
                }}
              />
            </Col>
            <Col span={2}>
              <InputField
                label=""
                fieldname={`payment[${index}].vat`}
                setValue={setValue}
                valueGot={item.vat}
                control={control}
                initValue={0}
                iProps={{
                  readOnly: true,
                  type: "number",
                  disabled: props.disableAll
                    ? true
                    : item.type == "EJARI FEE" ||
                      item.type == "SECURITY DEPOSIT"
                    ? true
                    : false,
                }}
              />
            </Col>
            <Col span={2}>
              <InputField
                label=""
                fieldname={`payment[${index}].total`}
                setValue={setValue}
                valueGot={item.total}
                control={control}
                initValue=""
                iProps={{
                  readOnly: true,
                  type: "number",
                  disabled: props.disableAll
                    ? true
                    : item.type == "EJARI FEE" ||
                      item.type == "SECURITY DEPOSIT"
                    ? true
                    : false,
                }}
              />
            </Col>
            <Col span={3}>
              <ReactSelectField
                label=""
                fieldname={`payment[${index}].mode`}
                control={control}
                //initValue={item.mode}
                setValue={setValue}
                valueGot={item.mode}
                selectOption={paymentModes?.map((item, index) => {
                  return { label: item?.name, value: item?.id };
                })}
                iProps={{
                  isDisabled: props.disableAll
                    ? true
                    : item.type == "EJARI FEE" ||
                      item.type == "SECURITY DEPOSIT"
                    ? true
                    : false,
                }}
              />
            </Col>
            <Col span={2}>
              <InputField
                label=""
                fieldname={`payment[${index}].docNo`}
                // setValue={setValue}
                control={control}
                //initValue={item.docNo?item.docNo:""}
                setValue={setValue}
                valueGot={!!item.docNo ? item.docNo : "empty"}
                iProps={{
                  disabled: props.disableAll
                    ? true
                    : item.type == "EJARI FEE" ||
                      item.type == "SECURITY DEPOSIT"
                    ? true
                    : false,
                }}
              />
            </Col>
            <Col span={3}>
              <ReactSelectField
                label=""
                fieldname={`payment[${index}].bankName`}
                control={control}
                //initValue={item.mode}
                // setValue={setValue}
                // valueGot={item.mode}
                selectOption={paymentModes?.map((item, index) => {
                  return { label: item?.name, value: item?.id };
                })}
                iProps={{
                  isDisabled: props.disableAll
                    ? true
                    : item.type == "EJARI FEE" ||
                      item.type == "SECURITY DEPOSIT"
                    ? true
                    : false,
                }}
              />
            </Col>
            <Col span={2}>
              <InputField
                label=""
                fieldname={`payment[${index}].entity`}
                // setValue={setValue}
                control={control}
                //initValue={item.docNo?item.docNo:""}
                // setValue={setValue}
                // valueGot={!!item.docNo ? item.docNo : "empty"}
                iProps={{ disabled: true }}
              />
            </Col>
            <Col span={2}>
              <InputField
                label=""
                fieldname={`payment[${index}].dueDate`}
                setValue={setValue}
                valueGot={item.dueDate}
                control={control}
                initValue=""
                iProps={{
                  readOnly: true,
                  disabled: props.disableAll
                    ? true
                    : item.type == "EJARI FEE" ||
                      item.type == "SECURITY DEPOSIT"
                    ? true
                    : false,
                }}
              />
            </Col>
            <Col span={2}>
              <DateField
                label=""
                fieldname={`payment[${index}].chequeDate`}
                control={control}
                iProps={{
                  disabled: props.disableAll
                    ? true
                    : item.type == "EJARI FEE" ||
                      item.type == "SECURITY DEPOSIT"
                    ? true
                    : false,
                }}
                //initValue={item.chequeDate}
                setValue={setValue}
                valueGot={
                  !!item.chequeDate
                    ? item.chequeDate
                    : moment(item.dueDate, "YYYY-MM-DD")
                }
              />
            </Col>
          </Fragment>
        );
      })}
    </>
  );
};
